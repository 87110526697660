import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Create = () => {
  // !---------------------- STATES ----------------------
  const [imageloading, setImageLoading] = useState(false);
  const [media,setMedia]=useState({
    image:null,
    video:null,
    reel:null
  });
  const [textLength, setTextLength] = useState({
    streamTitle: 0,
    description: 0,
  });
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Create Post
        </Typography>
      </Box>

      <Grid
        container
        spacing={3}
        sx={{
          px: "10px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography
              sx={{
                fontSize: { xs: "13px", sm: "15px" },
                fontWeight: "500",
                color: "#3F3F3F",
              }}
            >
              Title
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <input
                label="Title"
                placeholder="Add a suitable title.."
                id="outlined-size-small"
                size="small"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  outline: "none",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  paddingTop: "8.5px",
                  paddingBottom: "8.5px",
                }}
                name="streamTitle"
              />
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: { xs: "13px", sm: "14px" },
                  fontWeight: "400",
                  color: textLength.streamTitle > 100 ? "red" : "#3F3F3F",
                }}
              >
                {textLength.streamTitle}/100
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "13px", sm: "15px" },
                fontWeight: "500",
                color: "#3F3F3F",
              }}
            >
              Description
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "|",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "fontColor",
                    "fontBackgroundColor",
                    "fontSize",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "link",
                    "|",
                    "undo",
                    "redo",
                    "|",
                  ],
                  fontSize: {
                    options: [9, 11, 13, "default", 17, 19, 21, 25, 30],
                    supportAllValues: true,
                  },
                  placeholder: "Add a suitable description for your post...",
                }}
              />
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: { xs: "13px", sm: "14px" },
                  fontWeight: "400",
                  color: textLength.streamTitle > 100 ? "red" : "#3F3F3F",
                }}
              >
                {textLength.streamTitle}/100
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* media  section*/}
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              width: "100%",
              height: "258px",
            }}
          >
            {/* dropdown options for upload media  */}
            <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "60%",
              height: "100%",
              justifyContent: "start",
            }}
            >
            <select
              name="media"
              id="media"
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                outline: "none",
                border: "1px solid #ccc",
                borderRadius: "5px",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "5.5px",
                paddingBottom: "5.5px",
                width:"130px"
              }}
            >
              <option value="none">Select Media</option>
              <option value="image">Image</option>
              <option value="video">Video</option>
              <option value="reel">Reel</option>
            </select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: `1px dashed ${theme.palette.seprator_color.main}`,
                }}
                multiple={false}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {imageloading ? (
                    <CircularProgress />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CloudUploadIcon
                        sx={{
                          fontSize: "50px",
                          color: theme.palette.seprator_color.main,
                        }}
                      />
                      <Typography sx={{ color: "#000" }}>
                        Drag & Drop here
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: "#000", opacity: 0.5 }}
                      >
                        or
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#fff",
                          color: "#000",
                          textTransform: "none",
                          borderRadius: "15px",
                          mt: 1,
                          ":hover": {
                            backgroundColor: "#fff",
                          },
                        }}
                      >
                        Browse Files
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
              </Box>
           
          </Box>
        </Grid>
      </Grid>
      {/* seprator */}
        <Box
            sx={{
            my: "10px",
            width: "100%",
            height: "1px",
            backgroundColor: theme.palette.seprator_color.main,
            flexShrink: 0,
            }}
        />
    </Box>
  );
};

export default Create;
