export async function createLiveVideo(pageId, accessToken, title, description) {
  const url = `https://graph.facebook.com/${pageId}/live_videos?status=LIVE_NOW&title=${encodeURIComponent(
    title
  )}&description=${encodeURIComponent(
    description
  )}&access_token=${accessToken}`;

  try {
    const response = await fetch(url, {
      method: "POST",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getIframeUrl (videoId, accessToken, type) {
  const url = `https://graph.facebook.com/${videoId}?access_token=${accessToken}`;

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    const data = await response.json();

    const embedHtml = data.embed_html;
    // check if type is portrait then update the embedHtml iframe width = 267 and height = 476
    if (type === 'portrait') {
      const updatedEmbedHtml = embedHtml.replace(/width="[^"]+"/, 'width="267"').replace(/height="[^"]+"/, 'height="476"');
      return updatedEmbedHtml;
    } else {
      // width = 560 and height = 316
      const updatedEmbedHtml = embedHtml.replace(/width="[^"]+"/, 'width="560"').replace(/height="[^"]+"/, 'height="316"');
      return updatedEmbedHtml;
    }
    // const srcMatch = embedHtml.match(/src="([^"]+)"/);

    // if (srcMatch && srcMatch[1]) {
    //   return srcMatch[1];
    // } else {
    //   throw new Error("Failed to extract src from embed_html");
    // }
  } catch (error) {
    throw error;
  }
}

export async function checkFBEligibility(pageId, accessToken) {
  const url = `https://graph.facebook.com/${pageId}?fields=is_published,followers_count&access_token=${accessToken}`;

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}