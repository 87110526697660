import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export function SocketProvider({ children }) {
  
  const socket = io(process.env.REACT_APP_WS_MAIN_URL);

  const [leftGuest, setLeftGuest] = useState(null);
  const [roomTitle, setRoomTitle] = useState("Stream Title Loading ...");

  const joinRoom = (room, session_id) => {
    socket.emit('joinStreamingRoom', room, session_id);
  };

  const addGuestOnScreen = (room, session_id) => {
    socket.emit('addGuestOnScreen', room, session_id);
  };

  const kickParticipant = (room, session_id) => {
    socket.emit('kickParticipant', room, session_id);
  };

  const toggleVideo = (room, session_id, videoEnabled) => {
    socket.emit('toggleVideo', room, session_id, videoEnabled);
  };

  const toggleAudio = (room, session_id, audioEnable) => {
    socket.emit('toggleAudio', room, session_id, audioEnable);
  };

  const disconnectRoom = (room) => {
    socket.emit('disconnectingStreamingRoom', room);
  };

  const streamingOrientation = (room, orientation) => {
    socket.emit('streamingOrientation', room, orientation);
  };
  
  const changeRoomTitle = (room, title) => {
    socket.emit('changeRoomTitle', room, title);
  };

  useEffect(() => {
    socket.on('joinedStreamingRoom', (room) => {
      console.log(`Joined room ${room}`);
    });

    socket.on('guestLeft', (session_id) => {
      setLeftGuest(session_id);
    })

    return () => {
      socket.disconnect();
      socket.off('joinedStreamingRoom');
    };
  }, []);
  return (
    <SocketContext.Provider value={{
      socket,
      joinRoom,
      addGuestOnScreen,
      kickParticipant,
      toggleVideo,
      disconnectRoom,
      streamingOrientation,
      changeRoomTitle,
      toggleAudio,
      leftGuest,
      roomTitle
    }}>
      {children}
    </SocketContext.Provider>
  );
}
