import imageCompression from 'browser-image-compression';

export const convertToWebP = async (files) => {
    const compressOptions = {
      maxSizeMB: 1, // Set the desired max size of the compressed image
      maxWidthOrHeight: 1920, // Set the maximum width or height of the compressed image
    };
  
    const promises = files.map((file) => {
      return new Promise(async (resolve, reject) => {
        try {
          const compressedFile = await imageCompression(file, compressOptions);
  
          const reader = new FileReader();
  
          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
  
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
  
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, img.width, img.height);
  
              // Convert to WebP format
              canvas.toBlob(
                (blob) => {
                  const webpFile = new File([blob], `${file.name}.webp`, {
                    type: 'image/webp',
                  });
                  resolve(webpFile);
                },
                'image/webp',
                1
              );
            };
          };
  
          reader.onerror = (error) => {
            reject(error);
          };
  
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          reject(error);
        }
      });
    });
  
    try {
      const webpFiles = await Promise.all(promises);
      return webpFiles;
    } catch (error) {
      throw error;
    }
};