import { Stack } from "@mui/material";

import StatusFilters from "./StatusFilters";
import Sorters from "./Sorters";
import AllFilters from "./AllFilters";

const PostsTableToolbar = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
    >
      <StatusFilters />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Sorters />
        <AllFilters />
      </Stack>
    </Stack>
  );
};

export default PostsTableToolbar;
