import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getUserRecordedStreams } from "../../api/StreamApis";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const LiveStreams = () => {
  // !--------------------------------- STATES HERE ---------------------------------!
  const [streams, setStreams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  // !--------------------------------- REACT ROUTER  HERE ---------------------------------!
  const navigate = useNavigate();
  const theme = useTheme();
  // !--------------------------------- ARRAYS HERE ---------------------------------!
  const defaultThumbnails = [
    require("../../assets/Img/t1.png"),
    require("../../assets/Img/t2.png"),
    require("../../assets/Img/t3.png"),
    require("../../assets/Img/t4.png"),
  ];
  // !--------------------------------- FUNCTIONS HERE ---------------------------------!
  const getRandomIndex = () =>
    Math.floor(Math.random() * defaultThumbnails.length);

  const fetchUsersStreams = async (page) => {
    setIsLoading(true);
    const response = await getUserRecordedStreams(page);
    if (response?.status === 200) {
      setStreams(response?.data?.Recorded);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    fetchUsersStreams(newPage);
  };
  const handleCardClick = (roomid) => {
    navigate(`/broadcast/live-stream/${roomid}`);
  };
  // !--------------------------------- USE EFFECT HERE ---------------------------------!
  useEffect(() => {
    fetchUsersStreams(currentPage);
  }, []);

  const maxLength = 15;
  // !--------------------------------- RETURN HERE ---------------------------------!
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 70px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#646669",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#FDC532",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#fff",
          borderRadius: "50px",
        },
        mb:{xs:4,md:0}
      }}
      className="scrollable-content"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          py: { xs: "6px", md: "13px" },
          pr:{xs:"5px",md:0}
        }}
      >
        <Typography
          sx={{
            p: "10px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Live Streams
        </Typography>
    
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
      
      </Box>
      {/* Separator */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: theme.palette.seprator_color.main,
          flexShrink: 0,
        }}
      ></Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          p: "10px",
        }}
      >
        <Grid container spacing={2} rowSpacing={1} mb={2}>
          {streams.length > 0 ? (
            streams.map((el, i) => (
              <Grid item xs={12} sm={6} xmd={6} lg={3} my={2} key={i}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              >
                <Card
                  sx={{
                    position: "relative",
                    maxWidth: 350,
                    backgroundColor: "#F2F2F2",
                    boxShadow: "0px 3px 6px #00000029",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCardClick(el?.room)}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    src={
                      el?.thumbnail
                        ? el?.thumbnail
                        : defaultThumbnails[getRandomIndex()]
                    }
                    alt="thumbnail"
                  />

                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <Tooltip title={el?.title} placement="top">
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {el?.title && el.title.length > 18
                            ? el.title.slice(0, 18) + "..."
                            : el?.title}
                        </Typography>
                      </Tooltip>
                      <Tooltip title={el?.description} placement="bottom">
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "13px",
                            mb: 1,
                          }}
                        >
                          {el?.description && el.description.length > 30
                            ? el.description.slice(0, 30) + "..."
                            : el?.description}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        mx: { xs: "3px" },
                        pb: 1,
                        width: "100%",
                        height: "auto",
                        overflowX: "auto",
                        scrollbarWidth: "thin",
                        "&::-webkit-scrollbar": {
                          height: "2px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#808080",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: (theme) =>
                            theme.palette.background.paper,
                        },
                      }}
                    >
                      <Tooltip title={el?.category} placement="bottom">
                        <Button
                          size="small"
                          variant="contained"
                          sx={{
                            backgroundColor: "black",
                            borderRadius: "25px",
                            color: "white",
                            textTransform: "none",
                            fontSize: { xs: "9px", sm: "11px" },
                            whiteSpace: "nowrap",
                            marginRight: 1,
                            padding: "5px 10px",
                            minWidth: "auto",
                            flexShrink: 0,
                            "&:hover": {
                              backgroundColor: "black",
                            },
                          }}
                        >
                          {el?.category.length > maxLength
                            ? `${el?.category.substring(0, maxLength)}...`
                            : el?.category || "Others"}
                        </Button>
                      </Tooltip>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "11px",
                        color: "#272727",
                      }}
                    >
                      {moment(el?.createdAt).format("YYYY-MM-DD")}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "11px",
                        color: "#272727",
                      }}
                    >
                      {moment(el?.createdAt).format("hh:mm:ss A")}
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <>
              {!isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    ml: 2,
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "18px",
                      fontWeight: 400,
                    }}
                  >
                    No Stream Found
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Grid>
        {isLoading && streams.length === 0 && (
          <Grid container spacing={2}>
            {Array.from(new Array(8))?.map((items, idx) => (
              <Grid item xs={12} sm={6} xmd={6} lg={3} my={4} key={idx}>
                <Box
                  sx={{
                    background: "#EFF9FD",
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                    "&:hover": {
                      boxShadow: "2px 2px 4px #888888",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {items ? (
                      <img
                        src={items?.images[0]?.image}
                        alt="item"
                        style={{
                          width: "210px",
                          height: "250px",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: "280px" }}
                      />
                    )}
                  </Box>

                  {items ? (
                    <>
                      <Typography
                        sx={{
                          textAlign: "left",
                          fontSize: "12px",
                          p: "5px",
                        }}
                      >
                        {items.store.stream_name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          p: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          {items.store.stream_name}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ py: 0.5, px: "10px" }}>
                      <Skeleton />
                      <Skeleton width="60%" />
                    </Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
       
      </Box>
    </Box>
  );
};

export default LiveStreams;
