import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { customStyles } from "./UploadcustomStyle";
import { customStyles2 } from "./UploadcustomStyle2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Dropzone, { useDropzone } from "react-dropzone";
import { styled, useTheme } from "@mui/material/styles";
import { toast } from "../Toast";
import TestPrev from "../../assets/Img/test.png";
import {
  addProduct,
  addProductImage,
  uploadVideo,
  removeProductVideo,
  checkKeyword,
  deleteVariant,
  deleteVariantOptionApi,
  getSingleProduct,
  removeProductImage,
  updateProducts,
  deleteGroupsOption,
} from "../../api/ProductApis";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { countries } from "../../utils/CountryCodes";
import { convertToWebP } from "../../utils/image_copmression";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../ckeditor.styles.css";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import DataTable from "react-data-table-component";
import { v4 as uuidv4 } from "uuid";
import EditVariantDialog from "./EditVariantDialog";
import { useLocation, useNavigate } from "react-router-dom";
const categoryList = {
  Fashion: ["Dresses", "Shoes", "Accessories", "Other"],
  Beauty: ["Skincare", "Makeup", "Fragrances", "Other"],
  Electronics: ["Smartphones", "Laptops", "Headphones", "Other"],
  "Home Goods": ["Furniture", "Kitchen Appliances", "Decor", "Other"],
  Toys: ["Action Figures", "Board Games", "Dolls", "Other"],
  "Health and Wellness": [
    "Vitamins",
    "Fitness Equipment",
    "Supplements",
    "Other",
  ],
  "Pet Supplies": ["Dog Food", "Cat Toys", "Fish Tanks", "Other"],
  "Outdoor Gear": ["Camping Gear", "Hiking Boots", "Bicycles", "Other"],
  Books: ["Fiction", "Non-Fiction", "Mystery", "Other"],
  Others: ["Miscellaneous", "Random", "Unspecified", "Other"],
  "Groceries & Pets": [
    "Fresh Produce",
    "Fresh Fruit",
    "Fresh Vegetables",
    "Fish & Cold Pressed Juices",
    "Bread",
    "Eggs",
    "Dry Fruits",
    "Breakfast, Choco's & Snacks",
    "Breakfast Cereals",
    "Jams, Honey & Spreads",
    "Breakfast Bars",
    "Instant Breakfast Drinks",
    "Chocolate & Candy",
    "Chewing Gums & Mints",
    "Biscuit & Cookies",
    "Cakes & Sweets",
    "Chips & Crisps",
    "Nuts & Legumes",
    "Oatmeal",
    "Pancake & Waffle Mixes",
    "Beverages",
    "Tea",
    "Coffee",
    "UHT, Milk & Milk Powder",
    "Soft Drinks",
    "Juices",
    "Water",
    "Flavouring Syrup",
    "Powdered Drinks",
    "Food Staples",
    "Oil",
    " Rice",
    "Condiment Dressing",
    "Cooking Ingredients",
    "Noodles & Pasta",
    "Instant & Ready-to-Eat",
    "Grains, Beans & Pulses",
    "Home Baking & Sugar",
    "Jarred Food",
    "Canned Food",
    "Laundry & Household",
    "AirCare",
    "Cleaning",
    "Dishwashing",
    "Laundry",
    "Pest Control",
    "Tissue Paper",
    "Foils & Cling Films",
    " Frozen Food",
    "Chicken",
    "Beef",
    "Other Frozen Food",
    "Cat Food",
    "Cat Beds, Mats & Houses",
    "Cat Carriers & Travel",
    "Cat Grooming",
    "Cat Litter & Toilet",
    "Cat Cages, Crates & Doors",
    "Cat Toys",
    "Cat Treats",
    "Cat Trees, Condos & Scratchers",
    "Cat Bowls & Feeders",
    "Dog Collars",
    "Dog Toys",
    "Dog Harnesses",
    "Dog Brushes & Combs",
    "Dog Shampoos & Bath Accessories ",
    "Dog Bowls & Diners",
    "Dog Beds",
    "Fish",
    "Decorations",
    "Water pumps",
    "Aquariums",
    "Filters & Accessories",
    "Air Pumps & Accessories",
    "Temperature control supplies",
    "Food",
  ],
  "Health & Beauty": [
    "Makeup",
    "Makeup Accessories",
    "Lips",
    "Nails",
    "Face",
    "Eyes",
    "Makeup Removers",
    "Makeup Palettes & Sets",
    "Bulk Deals",
    "Beauty Tools",
    "Skin Care Tools",
    "Hair Removal Appliances",
    "Slimming & Electric Massagers",
    "Hair Styling Appliances",
    "Foot Relief Tools",
    "Skin Care",
    "Moisturizers and Cream",
    "Facial Cleansers",
    "Serum & Essence",
    "Face Mask & Packs",
    "Sunscreen & Aftersun",
    "Lip Balm & Treatment",
    "Eye Care",
    "Face Scrubs & Exfoliators",
    "Toner & Mists",
    "Hair Care",
    "Shampoo & Conditioner",
    "Hair Care Accessories",
    "Hair Treatments",
    "Oil & Serums",
    "Hair Coloring",
    " Hair Styling",
    "Body Soaps & Shower Gels",
    "Hair Removal",
    "Body & Massage Oils",
    "Bath & Body Accessories",
    "Foot Care",
    "Hand Care",
    "Bath & Body",
    "Hair Care",
    "Shaving & Grooming",
    "Personal Care",
    "Pads & Tampons",
    "Menstrual Cups",
    "Oral Care",
    "Eye Care",
    "Deodorants",
    "Adult Diapers",
    "Personal Safety & Security",
    " Ear Care",
    "Fragrances for Women",
    "Fragrances for Men",
    "Fragrances for Unisex",
    "Sexual Wellness",
    "Condoms",
    "Lubricants",
    "Medical Supplies",
    "Health Monitors & Tests",
    "Health Accessories",
    "First Aid Supplies",
    "Ointments and Creams",
    "Surgical Masks",
    "Nebulizer & Aspirators",
    "Stethoscopes",
  ],

  "Men's Fashion": [
    "T Shirts & Tanks",
    "V Neck",
    "Round Neck",
    "Graphic tees",
    "Top Rated",
    "Shirts & Polo",
    "Casual Shirts",
    "Formal Shirts",
    "Polos",
    "Pants & Jeans",
    "Chinos",
    "Cargo",
    "Jeans",
    "Shorts, Joggers & Sweats",
    "Shorts",
    "Joggers & Sweats",
    "Unstitched Fabric",
    "Kurtas & Shalwar Kameez",
    "Kurtas",
    "Shalwar",
    "Shawls",
    "Winter Clothing",
    "Jackets & Coats",
    "Hoodies & Sweatshirts",
    "Sweaters & Cardigans",
    "Inner Wear",
    "Briefs",
    "Trunk & Boxers",
    "Nightwear",
    "Vests",
    "Thermal",
    "Socks",
    "Shoes",
    "Slip-Ons & Loafers",
    "Flip Flops & Sandals",
    "Sneakers",
    "Formal Shoes",
    "Boots",
    "Khusa & Kolapuri",
    "Shoes Accessories",
    "Accessories",
    "Belts",
    "Sunglasses",
    "Hats & Caps",
    "Boy's Clothing",
    "T-Shirts & Shirts",
    "Pants & Jeans",
    "Shorts",
    "Kurtas & Shalwar Kameez",
    "Underwear & Socks",
    "Ties & Bows",
    "Shoes",
    "Sneakers",
    "Slip-Ons & Loafers",
    "School Shoes",
    "Flip Flops, Slides & Sandals",
    "Formal Shoes",
    "Boots ",
    "Sandals",
    "Boy's Accessories",
    "Belts",
    "Inner Wear",
    "Socks",
    "Hats & Caps",
  ],

  "Women's Fashion": [
    "Unstitched Fabric",
    "Branded Unstitched",
    "Sarees",
    "Kurtas & Shalwar Kameez",
    "Kurtis",
    " Shalwar Kameez",
    "Branded Pret",
    "Trousers & Palazzos",
    "Muslim Wear",
    "Dupattas, Stoles & Shawls",
    "Abayas & Hijabs",
    "Scarves",
    " Hair Accessories",
    "Tops",
    "Blouses & Shirts",
    "Tunics",
    " T-Shirts",
    "Bras",
    "Panties",
    "Lingerie Sets",
    "Beachwear and Bikinis",
    "Socks & Tights",
    "Sleepwear & Innerwear",
    "Nightwear",
    "Robe and Gown sets",
    "Tanks & Camisoles",
    "Shapewear",
    "Pants",
    "Leggings",
    "Jeans",
    "Shorts",
    "Jeggings",
    "Dresses & Skirts",
    "Ethnic Dresses",
    "Western Dresses",
    "Skirts",
    " Formal Wear",
    "Winter Clothing",
    "Jackets & Coats",
    "Hoodies & Sweatshirts",
    "Sweaters & Cardigans",
    "Shawls and Poncho's",
    "Shrugs",
    "Shoes",
    "Sandals",
    "Flat Shoes",
    "Heels",
    "Khussa & Kohlapuri",
    "Slides & Flip FLops",
    " Wedges",
    "Sneakers",
    "Boots",
    "Shoes Accessories",
    "Girls Clothing",
    "Dresses",
    "Tops",
    "Underwear & Sleepwear",
    "Bottoms",
    "Socks & Tights",
    "Jackets & Coats",
    "Swimsuits",
    "Hoodies",
    "Hair Accessories",
    "Hats & Caps",
    "Belts",
    "Gloves, Scarves & Cold Weather",
    "Girls Shoes",
    "Sneakers",
    "Flats & Slip - Ons",
    "Formal Shoes",
    "Sandals",
    "Flip Flops, Slides & Sandals",
    "School Shoes",
    "Boots",
  ],

  "Mother & Baby": [
    "Milk Formula & Baby Food",
    " Maternal",
    "Infant Milk (0-6 Months)",
    "Infant Milk (6-12 Months)",
    " Toddler Milk (1 - under 3 yrs)",
    "Growing-up Milk (3 yrs +)",
    "Baby & Toddler Foods",
    "Diapering & Potty",
    "Disposable diapers",
    "Diaper Bags",
    "Wipes & Holders",
    "Changing Tables, Pads & Kits",
    "Diapering Care",
    "Cloth Diapers & Accessories",
    "Potty Training",
    "Feeding",
    "Utensils",
    "Bottle-Feeding",
    "Breastfeeding",
    "Food Blenders",
    "Pacifiers & Teethers",
    "Baby & Toddler Foods",
    "Maternity Care",
    "Maternity Wear",
    "Maternity Accessories",
    "Nursing Covers",
    "Pregnancy Pillows",
    "Nipple Care",
    "Breast Shells",
    "Breast Pumps",
    "Baby Gear",
    "Kids Bag",
    "Swings, Jumpers & Bouncers",
    "Strollers",
    "Car Seats",
    "Walkers",
    "Backpacks & Carriers",
    "Harnesses & Leashes",
    "Baby Safety",
    "Baby Monitor",
    "Highchairs & Booster Seats",
    "Baby Sofas",
    "Nursery ",
    "Baby Furniture",
    "Mattresses & Bedding",
    "Storage & Organization",
    "Nursery Decor",
    "Baby Personal Care",
    "Skin Care",
    "Bathing Tubs & Seats",
    "Soaps, Cleansers & Bodywash",
    "Baby Groomings & Care Kits",
    "Grooming & Healthcare Kits",
    "Toothbrushes & Toothpaste",
    "Shampoo & Conditioners",
    "Washcloths & Towels",
    "Baby Bath Mats",
    "Clothing & Accessories",
    "Newborn",
    "New Born Unisex (0 - 6 mnths)",
    "New born bodysuits",
    "New born sets & Packs",
    "Accessories",
    "Boys (Under 3 Years)",
    "Girls (Under 3 Years)",
    "Baby & Toddler Toys",
    "Activity Gym & Playmats",
    "Bath Toys",
    "Building Blocks Toys",
    "Crib Toys & Attachments",
    "Early Development Toys",
    "Music & Sound",
    "Rattles",
    "Push & Pull Toys",
    "Remote Control & Vehicles",
    "Die-Cast Vehicles",
    "Drones & Accessories",
    "Play Trains & Railway Sets",
    "Play Vehicles",
    "RC Vehicles & Batteries",
    "Sports & Outdoor Play",
    "Inflatable Bouncers",
    "Swimming Pool & Water Toys",
    "Fidget Spinners",
    "Toys & Games",
    "Arts & Crafts for Kids",
    "Action Figures & Collectibles",
    "Blocks & Building toys",
    "Dolls & Accessories",
    "Learning & Education",
    "Puzzle & Boardgames",
    "Slime & Squishy Toys",
    "Stuffed Toys",
    "Pretend Play",
  ],

  " Home & Lifestyle": [
    "Bath Mats",
    "Bath Towels",
    "Bathrobes",
    "Bathroom Scales",
    "Bathroom Shelving",
    "Shower Caddies & Hangers",
    "Shower Curtains",
    "Towel Rails & Warmers",
    "Bed Sheets",
    "Bedding Accessories",
    "Blankets & Throws",
    "Comforters, Quilts & Duvets",
    "Mattress Protectors",
    "Pillow Cases",
    "Pillows & Bolsters",
    "Artificial Flowers & Plants",
    "Candles & Candleholders",
    "Clocks",
    "Curtains",
    "Cushions & Covers",
    "Mirrors",
    "Picture Frames",
    "Rugs & Carpets",
    "Vases & Vessels",
    "Wall Stickers & Decals",
    "Bedroom",
    "Gaming Furniture",
    "Home Office",
    "Kitchen Furniture",
    "Living Room",
    "Bakeware",
    "Coffee & Tea",
    "Cookware",
    "Dinnerware",
    "Drinkware",
    "Kitchen & Table Linen",
    "Kitchen Storage & Accessories",
    "Kitchen Utensils",
    "Knives & Accessories",
    "Serve ware",
    "Ceiling Lights",
    "Floor Lamps",
    "Lamp Shades",
    "Light Bulbs",
    "Lighting Fixtures & Components",
    "Outdoor Lighting",
    "Rechargeables & Flashlights",
    "Table Lamps",
    "Laundry & Cleaning",
    "Brushes, Sponges & Wipers",
    "Brooms, Mops & Sweepers",
    "Laundry Baskets & Hampers",
    "Clothes Line & Drying Racks",
    "Ironing Boards",
    "Fixtures & Plumbing",
    " Electrical",
    "Hand Tools",
    "Lawn & Garden",
    "Power Tools",
    "Security",
    "Home Build Up",
    "Primers",
    "Paints",
    "Art Supplies",
    "Gifts & Wrapping",
    "Packaging & Cartons",
    "Paper Products",
    "School & Office Equipment",
    "Writing & Correction",
    "School Uniforms",
    "Books",
    " English Books",
    "Islamic Books",
    "English Literature Books",
    " English Story Books",
    "History Books",
    "eBooks",
    "Magazines",
    "Musical Instruments",
  ],

  "Electronic Devices": [
    "Apple iPhones",
    "Xiaomi Mi Mobiles",
    "Nokia Mobiles",
    "Redmi Mobiles",
    "Realme Mobiles",
    "Oppo Mobile Phones",
    "Oneplus Mobiles",
    "Infinix Mobiles",
    "Honor Mobiles",
    "Tecno Mobiles",
    "Samsung Mobile Phones",
    "Vivo Mobiles",
    "Feature Phones",
    "Tablets",
    "Monitors",
    "Laptops",
    "Refurbished Laptops",
    "Traditional Laptops",
    "Like New Laptops",
    "Desktops",
    "Smart Watches",
    "PlayStation Consoles",
    " Playstation Games",
    "Playstation Controllers",
    "Nintendo Games",
    "Xbox Games",
    "Video Camera",
    "DSLR",
    "Drones",
    "Point & Shoot",
    "Instant Cameras",
    "Security Cameras",
    "Landline Phones",
  ],

  "Electronic Accessories": [
    "Phone Cases",
    "Power Banks",
    "Cables & Converters",
    " Wall Chargers",
    "Wireless Chargers",
    "Tablet Accessories",
    "Car Chargers",
    "Screen Protectors",
    "Phone Camera Flash Lights",
    "Selfie Sticks",
    "Docks & Stands",
    "Car Mounts",
    "Headphones & Headsets",
    "Bluetooth Headsets",
    "Headphones & Headsets Access.",
    "Mono Headsets",
    "Over-The-Ear Headphones",
    "Wired Headsets",
    "Wireless Earbuds",
    "In-Ear Headphones",
    "Wearable",
    "Smartwatches",
    "Fitness & Activity Trackers",
    "Fitness Tracker Accessories",
    "Virtual Reality",
    "Camera Accessories",
    "Memory Cards",
    "Lenses",
    "Tripods & Monopods",
    "Camera Cases, Covers and Bags",
    "Action Camera Accessories",
    "Gimbals & Stabilizers",
    "Lighting & Studio Equipment",
    "Batteries",
    "Computer Accessories",
    "Cooling Pads/Cooling Stands",
    "Mouse",
    "Adapters & Cables",
    "PC Audio",
    "Mac Accessories",
    "Keyboards",
    "Storage",
    "External Hard Drives",
    "Internal Hard Drives",
    "Flash Drives",
    "Storage for Mac",
    "OTG Drives",
    "Printers",
    " Printers",
    "Ink & Toners",
    "Fax Machines",
    "Computer Components",
    "Graphic Cards",
    "Desktop Casings",
    "Motherboards",
    "Fans & Heatsinks",
    "Processors",
    "Portable Speakers",
    "Network Components",
    " Access Points",
    "Gaming Accessories",
    "Gaming Mouse",
    "Gaming Keyboards",
    "Gaming Headsets",
    "Monitors & Accessories",
  ],

  "TV & Home Appliances": [
    "Air Conditioner",
    "Televisions",
    "Smart Televisions",
    "LED Televisions",
    "Refrigerators & Freezers",
    "Refrigerators",
    " Freezers",
    "Home Audio & Theater",
    "Home Entertainment",
    "Live Sound & Stage Equipment",
    "Home Theater Systems",
    "Soundbar Speakers",
    "Washing Machine",
    "Kitchen Appliances",
    "Microwave",
    "Oven",
    "Air & Deep Fryers",
    "Blender, Mixer & Grinder",
    "Juicer & Fruit Extraction",
    "Water Dispensers",
    "Electric Kettle",
    "Dishwashers",
    "Cooktop & Range",
    "Rice Cooker",
    "Pressure Cookers",
    "Specialty Cookware",
    "Cooling & Heating",
    " Air Cooler",
    " Fan",
    " Air Purifier",
    " Dehumidifier",
    " Humidifier",
    "Water Heater",
    " Room Heater",
    "Irons & Garment Care",
    " Irons",
    " Garment Steamer",
    "Sewing Machine",
    "Generator, UPS & Solar",
    "Generators",
    "UPS",
    "Solar Inverters",
    "Projectors & Players",
    "Blu Ray & DVD Players",
    "Projectors",
    "TV Accessories",
    "TV Receivers",
    "Wall Mounts & Protectors",
    "TV Adapters",
    "TV Remote Controllers",
    "3D Glasses",
    "Antennas",
    "Cables",
    "Vacuums & Floor Care",
    "Vacuum Cleaner",
    "Floor Polisher",
    "Steam Mops",
    "Electric Brooms",
  ],

  "Sports & Outdoor": [
    "Exercise & Fitness",
    "Cardio Training Equipment",
    "Strength Training Equipments",
    "Treadmills",
    "Fitness Accessories",
    "Boxing, Martial Arts & MMA",
    "Weight",
    "Yoga",
    "Exercise Bands",
    "Exercise Bikes",
    "Supplements",
    "Proteins",
    "Post Workouts and Recovery",
    "Pre Workouts",
    "Fat Burners",
    "Post Workouts and recovery",
    "Shoes & Clothing",
    "Mens Shoes",
    "Mens Clothing",
    "Womens Shoes",
    "Womens Clothing",
    "Men Accessories",
    "Men Bags",
    "Women Accessories",
    "Women Bags",
    "Team Sports",
    "Cricket",
    "Football",
    "Hockey",
    "Basket Ball",
    "Volley balls",
    "Baseballs",
    "Racket Sports",
    "Rackets",
    "Shuttlecocks",
    "Balls",
    "Bats",
    "Nets",
    "Grips & Tapes",
    "Balls",
    "Outdoor Recreation",
    "Cycling",
    "Cycle accessories",
    "Camping / Hiking",
    "Other activities",
    "Water Sports",
    "Fishing",
    "Skate Boards",
    "Fitness Gadgets",
    "Fitness Trackers",
    "Fitness Trackers Accessories",
    "Sports Accessories",
    "Water Bottles",
    "Home Gym",
  ],

  "Watches, Bags & Jewellery": [
    "Men's Watches",
    "Chronograph-Men's Watches",
    "Branded-Men's Watches",
    "Analog-Men's Watches",
    "Digital-Men's Watches",
    "Accessories-Men's Watches",
    "Smart Watches-Men's Watches",
    "Women's Watches",
    "Chronograph-Women's Watches",
    "Branded-Women's Watches",
    "Analog-Women's Watches",
    "Digital-Women's Watches",
    "Accessories-Women's Watches",
    "Smart Watches-Women's Watches",
    "Kid's Watches",
    "Boys",
    "Girls",
    "Womens Bags",
    "Cross Body & Shoulder Bags",
    "Top-Handle Bags",
    "Tote Bags",
    "Backpacks",
    "Clutches",
    "Wallets & Accessories",
    "Wristlets",
    "Mens Bags",
    "Wallets & Cardholders",
    "Backpacks",
    "Crossbody Bags",
    "Business Bags",
    "Messenger Bags",
    "Cardholders & Keychains",
    "Luggage & Suitcase",
    "Travel Accessories",
    "Laptop Bags",
    "Luggage",
    "Duffel Bags",
    "Womens Jewellery",
    "Rings",
    "Earrings",
    "Necklaces",
    "Bracelets",
    "Anklets",
    "Jewellery Sets",
    "Mens Jewellery",
    "Rings",
    "Chains",
    "Bracelets",
    "Studs",
    "Pendants",
    "Mens Accessories",
    "Belts",
    "Hats & Caps",
    "Scarves",
    "Socks",
    "Ties & Bow Ties",
    "Gloves",
    "Women's Accessories",
    "Scarves",
    "Belts",
    "Socks & Tights",
    "Hats & Caps",
    "Gloves",
    "Hair Accessories",
    "Sunglasses & Eyewear",
    "Sunglasses",
    "Unisex Sunglasses",
    "Women Eyeglasses",
    "Unisex Eyeglasses",
    "Kids Sunglasses",
    "Women Sunglasses",
    "Men Eyeglasses",
    "Kids Eyeglasses",
    "Lenses",
  ],

  "Automotive & Motorbikes": [
    "Automotive",
    "Car Tires & Wheels",
    "Car Oils & Fluids",
    "Car Interior Accessories",
    "Car Tools & Equipment",
    "Car Parts & Spares",
    "Car Care",
    "Car Exterior Accessories",
    "Car Electronics",
    "Car Safety & Security",
    "Motorcycle",
    "Electric Bikes",
    "Standard Bikes",
    "ATVs & UTVs",
    "Sports Bikes",
    "Riding Gear",
    "Motorcycle Helmets",
    "Motorcycle Tires & Wheels",
    "Motorcycle Parts & Spares",
    "Motorcycle Oil & Fluids",
    "Motorcycle Accessories",
    "Loaders & Rickshaw",
    "Loaders",
    "Auto Rikshaw",
  ],
};

const initialObj = {
  product_name: "",
  description: "",
  category: "",
  price: "",
  keyword: "",
  sub_category: "",
  cost: "",
  discount_price: 0,
  total_stock: "",
  images: [],
  thumbnail_image: "",
  weight: "",
  is_varient: false,
  variants: [],
  sellingMethod: "product",
  affiliate_productlink: "",
  phoneNumber: localStorage.getItem("productWhatsappNumber"),
  countryCode: "",
  whatsappNum: localStorage.getItem("productWhatsappNumber"),
  product_link: true,
  video: "",
  is_video_thumbnail: false,
  is_variant_display: true,
  chart_img: null,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UploadProduct = () => {
  const theme = useTheme();

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [removeImgLoading, setRemoveImgLoading] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [error, setError] = useState({
    nameError: false,
    nameText: "Product name already exists",
  });

  const [edit, setEdit] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  //----------Product Object State----------------
  const [productObj, setProductObj] = useState(initialObj);
  const [variants, setVariants] = useState([]);
  const [chartImageLoading, setChartImageLoading] = useState(false);
  const [variantsErrors, setVariantsErrors] = useState({
    variantName: "",
    variantOptionType: "",
  });
  const [editVariantsMoods, setEditVariantsMoods] = useState({
    is_disabled_add: true,
    is_disabled_done: true,
    is_disabled_update: false,
    is_disabled_publish: true,
    is_edit_variant_dialog: true,
  });
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleVariantNameChange = (variantIndex, value) => {
    const updatedVariants = [...variants];
    const variantNames = updatedVariants.map((variant) => variant.varient_name);

    if (variantIndex >= 0 && variantIndex < updatedVariants.length) {
      if (
        variantNames.filter(
          (name, index) => index !== variantIndex && name === value
        ).length > 0
      ) {
        setVariantsErrors((pre) => ({
          ...pre,
          variantName: "Already exists",
        }));
      } else {
        setVariantsErrors((pre) => ({
          ...pre,
          variantName: "",
        }));
        updatedVariants[variantIndex] = {
          ...updatedVariants[variantIndex],
          varient_name: value,
        };
        setVariants(updatedVariants);
        setEditVariantsMoods((pre) => ({
          ...pre,
          is_disabled_done: false,
          is_disabled_update: true,
          // is_disabled_publish: false,
        }));
      }
    }
  };
  const handleVariantDefaultStockChange = (variantIndex, value) => {
    const updatedVariants = [...variants];
    if (variantIndex >= 0 && variantIndex < updatedVariants.length) {
      updatedVariants[variantIndex] = {
        ...updatedVariants[variantIndex],
        default_stock: value,
      };
      setVariants(updatedVariants);
      setEditVariantsMoods((pre) => ({
        ...pre,
        is_disabled_done: false,
        is_disabled_update: true,
        // is_disabled_publish: false,
      }));
    }
  };

  const handleVarientOptionChange = (
    variantIndex,
    optionIndex,
    field,
    value
  ) => {
    const updatedVariants = [...variants];

    if (
      variantIndex >= 0 &&
      variantIndex < updatedVariants.length &&
      optionIndex >= 0 &&
      optionIndex < updatedVariants[variantIndex].options.length
    ) {
      const optionTypes = updatedVariants[variantIndex].options.map(
        (option) => option.type
      );

      if (
        optionTypes.filter(
          (type, index) => index !== optionIndex && type === value
        ).length > 0
      ) {
        setVariantsErrors((pre) => ({
          ...pre,
          variantOptionType: "Already exists",
        }));
      } else {
        setVariantsErrors((pre) => ({
          ...pre,
          variantOptionType: "",
        }));
        updatedVariants[variantIndex].options[optionIndex] = {
          ...updatedVariants[variantIndex].options[optionIndex],
          [field]: value,
        };
        setEditVariantsMoods((pre) => ({
          ...pre,
          is_disabled_done: false,
          is_disabled_update: true,
          // is_disabled_publish: false,
        }));
        setVariants(updatedVariants);
      }
    }
  };

  const addVariant = (is_new) => {
    if (productObj?.product_name === "") {
      toast.error("Please enter a product name.");
      return;
    }
    if (productObj?.description === "") {
      toast.error("Please enter a product description.");
    }
    if (productObj?.category === "") {
      toast.error("Please select a product category.");
    }
    if (productObj?.price === "") {
      toast.error("Please enter a product price.");
      return;
    }
    if (productObj?.total_stock === "") {
      toast.error("Please enter the product stock.");
      return;
    }
    if (productObj?.weight === "") {
      toast.error("Please enter the product weight.");
      return;
    }
    if (productObj?.images?.length === 0) {
      toast.error("Please add product images.");
      return;
    }
    if (productObj?.keyword === "") {
      toast.error("Please add a product keyword.");
      return;
    }
    if (
      productObj?.sellingMethod === "link" &&
      productObj?.affiliate_productlink === ""
    ) {
      toast.error("Please add an affiliate product link.");
      return;
    }
    if (
      productObj?.sellingMethod === "number" &&
      productObj?.phoneNumber === ""
    ) {
      toast.error("Please add a phone number.");
      return;
    }
    if (
      productObj?.sellingMethod === "number" &&
      productObj?.countryCode === ""
    ) {
      toast.error("Please add a country code.");
      return;
    }
    if (productObj?.product_link && productObj?.product_link === "") {
      toast.error("Please add a product link.");
      return;
    }

    if (variants.length >= 2) {
      toast.error("You can only add up to 2 variants.");
      return;
    }
    setVariants((pre) => [
      ...pre,
      {
        varient_name: "",
        default_stock: 0,
        options: [
          {
            type: "",
            v_price: productObj?.price || 0,
            sku: "",
            v_weight: productObj?.weight || 0,
            discounted_price: productObj?.discount_price || 0,
            v_total_stock: 0,
            v_image: productObj?.thumbnail_image || "",
            is_new: is_new,
          },
        ],
      },
    ]);
    setEditVariantsMoods((pre) => ({
      ...pre,
      is_disabled_done: false,
      is_disabled_update: true,
    }));
  };

  // handleDoneVariant
  const handleDoneVariant = () => {
    if (variants.length === 0) {
      toast.error("Error: Add Variant");
      return;
    }
    setProductObj((pre) => ({
      ...pre,
      variants: variants,
    }));
    let transformedProductObj = transformProductObj();
    setProductObj((pre) => ({
      ...pre,
      variants: transformedProductObj?.variants || [],
    }));
    setEditVariantsMoods((pre) => ({
      ...pre,
      is_disabled_done: true,
      is_disabled_update: false,
      is_disabled_publish: false,
    }));
  };

  const addVariantOption = (variantIndex, is_new) => {
    const updatedVariants = [...variants];
    if (variantIndex >= 0 && variantIndex < updatedVariants.length) {
      updatedVariants[variantIndex].options.push({
        type: "",
        v_price: productObj?.price || 0,
        sku: "",
        v_weight: productObj?.weight || 0,
        discounted_price: productObj?.discount_price || 0,
        v_total_stock: 0,
        v_image: productObj?.thumbnail_image || "",
        is_new: is_new,
      });
      setEditVariantsMoods((pre) => ({
        ...pre,
        is_disabled_done: false,
        is_disabled_update: true,
        // is_disabled_publish: false,
      }));
      setVariants(updatedVariants);
    }
  };
  // removeVariantOption
  const removeVariantOption = (variantIndex, optionIndex, option) => {
    const updatedVariants = [...variants];
    // const updatedVariants = [...productObj.variants];
    if (
      variantIndex >= 0 &&
      variantIndex < updatedVariants.length &&
      optionIndex >= 0 &&
      optionIndex < updatedVariants[variantIndex].options.length
    ) {
      updatedVariants[variantIndex].options.splice(optionIndex, 1);
      setVariants(updatedVariants);
      if (option._id) deleteVariantOption(option._id);
    }
    setEditVariantsMoods((pre) => ({
      ...pre,
      is_disabled_done: false,
      is_disabled_update: true,
      is_disabled_publish: false,
    }));
  };
  // handleDeleteVariant
  const handleDeleteVariant = async (id) => {
    try {
      const resp = await deleteVariant(id);
      if (resp?.status === 200) {
        toast.success("Variant has been successfully deleted.");
        fetchSingleProduct();
      }
    } catch (error) {
      toast.error(
        "Oops! Something went wrong while deleting the variant. Please try again later."
      );
    }
  };
  // deleteVariantOption
  const deleteVariantOption = async (id) => {
    try {
      const resp = await deleteVariantOptionApi(id);
      if (resp?.status === 200) {
        toast.success("Variant option has been deleted successfully.");
        fetchSingleProduct();
      }
    } catch (error) {
      toast.error(
        "Oops! Something went wrong while deleting the variant option. Please try again later."
      );
    }
  };
  // remove variant and that variant options
  const removeVariant = (variantIndex, variant) => {
    const updatedVariants = [...variants];
    // const updatedVariants = [...productObj.variants];
    if (variantIndex >= 0 && variantIndex < updatedVariants.length) {
      updatedVariants.splice(variantIndex, 1);
      setVariants(updatedVariants);
    }
    if (variant._id) handleDeleteVariant(variant._id);
  };

  //-------All products State
  const [keywordError, setKeywordError] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [editRow, setEditRow] = useState({});
  const [callFrom, setcallFrom] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const navigate = useNavigate();
  const [productId, setProductId] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.product_id) {
      setProductId(location?.state?.product_id);
    }
  }, [location?.state?.product_id]);

  // fetch single product
  const fetchSingleProduct = async () => {
    setLoading(true);
    const resp = await getSingleProduct(productId);
    if (resp?.status === 200) {
      const product = { ...resp.data.product }; // Create a copy of the product object
      if (
        product?.whatsappNum &&
        !product?.product_link &&
        product?.affiliate_productlink === ""
      ) {
        setProductObj((prev) => ({
          ...prev,
          sellingMethod: "number",
          phoneNumber: product?.whatsappNum?.split("-")[1],
          countryCode: product?.whatsappNum?.split("-")[0],
          whatsappNum: product?.whatsappNum,
          discount_price: product?.discount_price ? product?.discount_price : 0,
        }));
      } else if (product?.product_link) {
        setProductObj((prev) => ({
          ...prev,
          sellingMethod: "product",
          discount_price: product?.discount_price ? product?.discount_price : 0,
        }));
      } else {
        setProductObj((prev) => ({
          ...prev,
          sellingMethod: "link",

          discount_price: product?.discount_price ? product?.discount_price : 0,
        }));
      }

      setProductObj((prev) => ({ ...prev, ...product }));
      setVariants((prev) =>
        resp?.data?.product?.variants ? [...resp.data.product.variants] : []
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    if (productId) {
      fetchSingleProduct();
    }
  }, [productId]);

  //-------Add Product Handler
  const AddProductHandler = async () => {
    let prodCopy = {
      product_name: productObj.product_name,
      description: productObj.description,
      category: productObj.category,
      sub_category: productObj.sub_category,
      price: productObj.price,
      discount_price: productObj.discount_price ? productObj.discount_price : 0,
      weight: productObj.weight,
      total_stock: productObj.total_stock,
      thumbnail_image: productObj.thumbnail_image,
      images: productObj.images,
      keyword: productObj.keyword,
      affiliate_productlink:
        productObj.sellingMethod === "link"
          ? productObj.affiliate_productlink
          : "",
      phoneNumber:
        productObj.sellingMethod === "number" ? productObj.phoneNumber : "",
      countryCode:
        productObj.sellingMethod === "number" ? productObj.countryCode : "",
      added_from: "dashboard",
      product_link: productObj.product_link,
      variants: productObj?.variants,
      is_varient: productObj?.is_varient,
      video: productObj.video,
      is_video_thumbnail: productObj.is_video_thumbnail,
    };

    if (error?.nameError) {
      return;
    }
    if (prodCopy?.product_name === "") {
      toast.error("Please enter a product name.");
      return;
    }
    if (prodCopy?.description === "") {
      toast.error("Please enter a product description.");
      return;
    }
    if (prodCopy?.price === "") {
      toast.error("Please enter a product price.");
      return;
    }
    if (prodCopy?.total_stock === "") {
      toast.error("Please enter the product stock.");
      return;
    }
    if (prodCopy?.keyword === "") {
      toast.error("Please enter a product keyword.");
      return;
    }

    if (prodCopy?.images?.length === 0) {
      toast.error("Please add product images.");
      return;
    }

    if (prodCopy?.category?.length === 0) {
      toast.error("Please select a product category.");
      return;
    }

    if (parseInt(prodCopy.weight) === 0 || prodCopy.weight === "") {
      toast.error("Please enter the product weight.");
      return;
    }

    if (parseInt(prodCopy.discount_price) > parseInt(prodCopy.price)) {
      toast.error("Discount price cannot be greater than the actual price.");
      return;
    }

    setLoading(true);

    prodCopy.is_Active = true;
    localStorage.setItem("productWhatsappNumber", productObj.phoneNumber);
    const resp = await addProduct(prodCopy);

    setLoading(false);

    if (resp?.status === 201) {
      setProductObj((pre) => ({
        ...pre,
        images: [],
        thumbnail_image: "",
      }));
      navigate("/dashboard/products");
      toast.success("Product created successfully.");
    }
  };

  const handleDrop = async (newFiles) => {
    setImageLoading(true);
    if (!newFiles || newFiles.length === 0) {
      setImageLoading(false);
      return;
    }

    const webpFiles = await convertToWebP(newFiles);

    if (!webpFiles || webpFiles.length === 0) {
      setImageLoading(false);
      return;
    }

    let formData = new FormData();
    webpFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const resp = await addProductImage(formData);
      setImageLoading(false);

      if (resp?.status === 200) {
        setProductObj({
          ...productObj,
          images: [...productObj.images, ...resp.data.imageUrls],
          thumbnail_image: resp.data.imageUrls[0],
        });
      }
    } catch (error) {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    const pakistanCountryCode = countries.find(
      (c) => c.code === "PK"
    )?.dial_code;
    setProductObj((pre) => ({
      ...pre,
      countryCode: pakistanCountryCode || "",
    }));
  }, []);
  const handleChange = (type, event) => {
    setProductObj({
      ...productObj,
      [type]: event.target.value,
    });
  };

  const handleCodeChange = (event) => {
    setProductObj({ ...productObj, countryCode: event.target.value });
  };

  const handleOptionChange = (event) => {
    if (event.target.value === "number") {
      setProductObj({
        ...productObj,
        sellingMethod: event.target.value,
        phoneNumber: "",
        product_link: false,
      });
    } else if (event.target.value === "product") {
      setProductObj({
        ...productObj,
        sellingMethod: event.target.value,
        product_link: true,
      });
    } else {
      setProductObj({
        ...productObj,
        sellingMethod: event.target.value,
        product_link: false,
      });
    }
  };

  const handleProductName = (e) => {
    const productName = e.target.value;
    const keyword = autoGenerateKeyword(productName);

    setProductObj((prevState) => ({
      ...prevState,
      product_name: productName,
      keyword: keyword,
    }));
  };

  const autoGenerateKeyword = (productName) => {
    const parsedData = JSON.parse(localStorage.getItem("businessUserdata"));

    const usernamePrefix = parsedData.name.slice(0, 1).toUpperCase();

    let productPrefix = (
      productName.length >= 2
        ? productName.slice(0, 2)
        : (productName + "X".repeat(2)).slice(0, 2)
    ).toUpperCase();

    productPrefix = productPrefix.replace(/O/g, () =>
      getRandomLetter(productPrefix)
    );

    const alphabet = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
    const selfLetter = alphabet[Math.floor(Math.random() * alphabet.length)];

    // Generate a random two-digit number from 0-9
    // const randomNumber = Math.floor(Math.random() * 15) + 11;

    // gerneate 1 digit number from 0-9
    const randomNumber = Math.floor(Math.random() * 9) + 1;

    // Combine the parts to form the keyword
    // const keyword = `${usernamePrefix}${productPrefix}${selfLetter}${randomNumber
    //   .toString()
    //   .padStart(2, "0")}`;

    const keyword = `${usernamePrefix}${productPrefix}${selfLetter}${randomNumber.toString()}`;

    return keyword;
  };

  const getRandomLetter = (excluded) => {
    const alphabet = "ABCDEFGHIJKLMNPQRSTUVWXYZ"; // Excluding 'O'
    let randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];

    // Ensure the random letter is not in the excluded string
    while (excluded.includes(randomLetter)) {
      randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
    }

    return randomLetter;
  };

  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();

  const customKeywordChangeHandler = async (e) => {
    setKeywordError("");

    const keyword = e.target.value.trim().toUpperCase();

    if (keyword.includes(" ")) {
      setKeywordError("Keyword should not contain spaces");
      return;
    }

    if (keyword.length > 10) {
      setKeywordError("Keyword should not be more than 10 characters");
      return;
    }

    setProductObj({ ...productObj, keyword });

    source.cancel("Operation canceled by the user.");

    source = cancelToken.source();

    // Check keyword in the database
    try {
      const resp = await checkKeyword(keyword, source);

      if (resp?.status === 200 && resp?.data?.status === true) {
        setKeywordError("Keyword already exists");
        return;
      }
    } catch (error) {
      return error;
    }
  };
  const handleRowSelect = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
  const handleEditOpen = (row, index, fromVal) => {
    setEditDialog(true);
    setEditRow(row);
    setEditIndex(index);
    setcallFrom(fromVal);
  };
  const handleEditProduct = async () => {
    if (productObj?.discount_price === "") {
      setProductObj((pre) => ({
        ...pre,
        discount_price: 0,
      }));
    }
    if (productObj?.discount_price) {
      let discount_price = parseInt(productObj?.discount_price);
      // if discount price is 00,or 000,0000,00000,0000000 then discount should be 0
      if (discount_price === 0) {
        setProductObj((pre) => ({
          ...pre,
          discount_price: 0,
        }));
      }
      // ensure discount should not be in negative value
      if (discount_price < 0) {
        toast.error("Discount price must be greater than 0.");
        return;
      }
    }
    let updateArr = [
      {
        ...productObj,
        affiliate_productlink:
          productObj.sellingMethod === "link"
            ? productObj.affiliate_productlink
            : "",
        phoneNumber:
          productObj.sellingMethod === "number" ? productObj.phoneNumber : "",
        countryCode:
          productObj.sellingMethod === "number" ? productObj.countryCode : "",
      },
    ];

    if (parseInt(updateArr[0].discount_price) > parseInt(updateArr[0].price)) {
      toast.error("Discount price cannot be greater than the actual price.");
      return;
    }

    setLoading(true);

    const resp = await updateProducts(updateArr);

    setEdit(false);
    setLoading(false);

    if (resp.status === 200) {
      setProductObj(initialObj);
      toast.success(resp?.data?.message);
      navigate("/dashboard/products");
    } else {
      toast.error(resp?.data?.message);
    }
  };

  const [isRemoveImg, setIsRemoveImg] = useState(false);

  const handleRemove = async (imagePath) => {
    setRemoveImgLoading(true);
    try {
      const urlObject = new URL(imagePath);
      const pathWithoutHost = urlObject.pathname.slice(1);

      const resp = await removeProductImage(pathWithoutHost);

      if (resp?.status === 200) {
        const updatedImages = productObj.images.filter(
          (url) => url !== imagePath
        );
        if (imagePath === productObj.thumbnail_image) {
          setProductObj((pre) => ({
            ...pre,
            images: updatedImages,
            thumbnail_image:
              updatedImages.length > 1
                ? updatedImages[updatedImages.length - 1]
                : updatedImages[0],
          }));
        }

        setProductObj((pre) => ({
          ...pre,
          images: updatedImages,
        }));
        setIsRemoveImg(true);
      }
    } catch (error) {
      toast.error(
        "Oops! Something went wrong while removing the image. Please try again later."
      );
    } finally {
      setRemoveImgLoading(false);
    }
  };
  const handleRemoveVideo = async (videoPath) => {
    setVideoLoading(true);
    try {
      const urlObject = new URL(videoPath);
      const pathWithoutHost = urlObject.pathname.slice(1);
      const resp = await removeProductVideo(pathWithoutHost);
      if (resp?.status === 200) {
        setProductObj((pre) => ({
          ...pre,
          video: "",
        }));
      }
    } catch (error) {
      toast.error(
        "Oops! There was an error while removing the video. Please try again later."
      );
    } finally {
      setVideoLoading(false);
    }
  };

  const handleChangeThumbnail = (e) => {
    setProductObj({
      ...productObj,
      thumbnail_image: e.target.value,
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
  });
  const handleVideoDrop = async (videoFiles) => {
    setVideoLoading(true);
    if (!videoFiles || videoFiles.length === 0) {
      setVideoLoading(false);
      return;
    }
    const videoFile = videoFiles[0];
    let formData = new FormData();
    if (!videoFile.type.startsWith("video/mp4")) {
      toast.error(
        "Sorry, the video format is not supported. Please upload a video in MP4 format."
      );
      setVideoLoading(false);
      return;
    }
    // Check file size
    const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
    if (videoFile.size > maxSizeInBytes) {
      toast.error(
        "Sorry, the video size exceeds the limit. Please upload a video smaller than 10 MB."
      );
      setVideoLoading(false);
      return;
    }
    formData.append("file", videoFile);
    try {
      const resp = await uploadVideo(formData);

      if (resp?.status === 200) {
        setProductObj((pre) => ({
          ...pre,
          video: resp.data.videoUrl,
        }));
      }
    } catch (error) {
      setVideoLoading(false);
    } finally {
      setVideoLoading(false);
    }
  };

  const {
    getRootProps: getVideoRootProps,
    getInputProps: getVideoInputProps,
    isVideoDragActive,
  } = useDropzone({
    onDrop: handleVideoDrop,
    multiple: false, // Assuming only one video is allowed to be uploaded at a time
    accept: "video/*", // Accepts all video formats
  });

  const columns = [
    {
      name: (
        <Typography
          sx={{
            // textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Image
        </Typography>
      ),
      selector: (row) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={row?.v_image || TestPrev}
            alt=""
            style={{
              width: "35px",
              height: "35px",
              objectFit: "contain",
              border: "1px solid gray",
              borderRadius: "3px",
            }}
          />
        </Box>
      ),
      // width: "200px",
      // center: true,
      // width: "180px",
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Variants
        </Typography>
      ),
      selector: (row, idx) => (
        <Typography
          sx={{
            fontSize: "12px",
            color: "#7B7B7B",
          }}
        >
          {row?.type}
        </Typography>
      ),
      // width: "100px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          SKU
        </Typography>
      ),
      selector: (row) => (
        <Tooltip title={row?.sku} placement="top">
          <Typography
            sx={{ fontSize: "12px", color: "#7B7B7B", cursor: "pointer" }}
          >
            {row?.sku}
          </Typography>
        </Tooltip>
      ),
      // width: "110px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Price
        </Typography>
      ),
      selector: (row) => (
        <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
          Rs {row?.v_price}
        </Typography>
      ),
      // width: "110px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Discount
        </Typography>
      ),
      selector: (row, idx) => {
        return (
          <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
            Rs {row?.discounted_price}
          </Typography>
        );
      },
      // width: "90px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Weight
        </Typography>
      ),
      selector: (row, idx) => {
        return (
          <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
            {row?.v_weight} gm
          </Typography>
        );
      },
      // width: "90px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Stock
        </Typography>
      ),
      selector: (row, idx) => {
        return (
          <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
            {row?.v_total_stock}
          </Typography>
        );
      },
      // width: "120px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Modify
        </Typography>
      ),
      selector: (row, idx) => (
        <Button
          sx={{
            m: 0,
            minWidth: "0",
            // borderRadius: "20px",
            // background: "black",
            color: "#0AB2FF",
            textTransform: "none",
            textDecoration: "underline",
            p: "0",
            py: "1px",
            px: "20px",
          }}
          onClick={() => handleEditOpen(row, idx, "parent")}
        >
          Edit
        </Button>
      ),
      // width: "120px",
      center: true,
    },
  ];
  const columns2 = [
    {
      name: (
        <Typography
          sx={{
            // textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Image
        </Typography>
      ),
      selector: (row) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={row?.v_image || TestPrev}
            alt=""
            style={{
              width: "35px",
              height: "35px",
              objectFit: "contain",
              border: "1px solid gray",
              borderRadius: "3px",
            }}
          />
        </Box>
      ),
      // width: "200px",
      // center: true,
      // width: "180px",
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Variants
        </Typography>
      ),
      selector: (row, idx) => (
        <Typography
          sx={{
            fontSize: "12px",
            color: "#7B7B7B",
          }}
        >
          {row?.type}
        </Typography>
      ),
      // width: "100px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          SKU
        </Typography>
      ),
      selector: (row) => (
        <Tooltip title={row?.sku} placement="top">
          <Typography
            sx={{ fontSize: "12px", color: "#7B7B7B", cursor: "pointer" }}
          >
            {row?.sku}
          </Typography>
        </Tooltip>
      ),
      // width: "110px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Price
        </Typography>
      ),
      selector: (row) => (
        <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
          Rs {row?.v_price}
        </Typography>
      ),
      // width: "110px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Discount
        </Typography>
      ),
      selector: (row, idx) => {
        return (
          <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
            Rs {row?.discounted_price}
          </Typography>
        );
      },
      // width: "90px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Weight
        </Typography>
      ),
      selector: (row, idx) => {
        return (
          <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
            {row?.v_weight} gm
          </Typography>
        );
      },
      // width: "90px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Stock
        </Typography>
      ),
      selector: (row, idx) => {
        return (
          <Typography sx={{ fontSize: "12px", color: "#7B7B7B" }}>
            {row?.v_total_stock}
          </Typography>
        );
      },
      // width: "120px",
      center: true,
    },
    {
      name: (
        <Typography
          sx={{
            textAlign: "center",
            width: "100%",
            // fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          Modify
        </Typography>
      ),
      selector: (row, idx) => (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                m: 0,
                minWidth: "0",
                // borderRadius: "20px",
                // background: "black",
                color: "#0AB2FF",
                textTransform: "none",
                textDecoration: "underline",
                p: "0",
                py: "1px",
                px: "20px",
              }}
              onClick={() => handleEditOpen(row, idx, "child")}
            >
              Edit
            </Button>
            {productId && (
              <DeleteIcon
                sx={{
                  color: "#FDD365",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveGroupOption(row)}
              />
            )}
          </Box>
        </>
      ),
      width: "130px",
      center: true,
    },
  ];

  const handleRemoveGroupOption = async (row) => {
    if (row?._id) {
      try {
        const resp = await deleteGroupsOption(productObj?._id, row._id);
        if (resp?.status === 200) {
          toast.success("Group option deleted successfully.");
          fetchSingleProduct();
        }
      } catch (error) {
        toast.error(
          "Oops! Something went wrong while deleting the group option. Please try again later."
        );
      }
    }
  };

  // ExpandableComponent
  const ExpandableComponent = ({ data }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: 2,
          ml: 8,
        }}
      >
        <DataTable
          columns={columns2}
          data={data?.groups && data?.groups}
          noTableHead
          customStyles={customStyles2}
        />
      </Box>
    );
  };

  const VOptions =
    (productObj.variants && productObj?.variants?.[0]?.options) || [];

  const transformProductObj = () => {
    const parentVariant = variants[0];
    const childVariant = variants[1];
    let totalStock = 0;
    let parentDefaultStock = parentVariant?.default_stock;
    const transformedParentOptions = parentVariant?.options?.map(
      (parentOption) => ({
        ...parentOption,
        v_total_stock: parentDefaultStock,
        sku:
          parentOption?.sku === ""
            ? `SKU-${productObj.product_name
                .slice(0, 3)
                .toUpperCase()}-${Math.floor(
                Math.random() * 1000
              )}-${parentOption.type.slice(0, 3).toUpperCase()}`
            : parentOption?.sku,
        index: uuidv4(),
        groups: childVariant?.options?.map((childOption) => {
          if (productId === "") {
            return {
              ...childOption,
              sku:
                productId === "" && childOption?.sku === ""
                  ? `SKU-${productObj.product_name
                      .slice(0, 3)
                      .toUpperCase()}-${Math.floor(
                      Math.random() * 1000
                    )}-${parentOption.type
                      .slice(0, 3)
                      .toUpperCase()}-${childOption.type
                      .slice(0, 3)
                      .toUpperCase()}`
                  : childOption?.sku,
              index: uuidv4(),
              v_total_stock: childVariant?.default_stock,
            };
          } else {
            const existingGroup = parentOption?.groups?.find(
              (group) => group.type === childOption.type
            );
            if (existingGroup) {
              return {
                ...existingGroup,
                index: uuidv4(),
                v_total_stock: childVariant?.default_stock,
              };
            } else {
              return {
                ...childOption,
                sku:
                  childOption?.sku === ""
                    ? `SKU-${productObj.product_name
                        .slice(0, 3)
                        .toUpperCase()}-${Math.floor(
                        Math.random() * 1000
                      )}-${parentOption.type
                        .slice(0, 3)
                        .toUpperCase()}-${childOption.type
                        .slice(0, 3)
                        .toUpperCase()}`
                    : childOption?.sku,
                index: uuidv4(),
                v_total_stock: childVariant?.default_stock,
              };
            }
          }
        }),
        variant_name: parentVariant?.variant_name,
      })
    );

    // calculate the total stock of the product

    transformedParentOptions?.forEach((parentOption) => {
      let stock = 0;
      if (parentOption?.groups && parentOption?.groups?.length > 0) {
        parentOption?.groups?.forEach((group) => {
          stock += parseInt(group.v_total_stock);
        });

        parentOption.v_total_stock = stock;
        totalStock += stock;
      } else {
        totalStock += parseInt(parentOption.v_total_stock);
      }
    });
    setProductObj((pre) => ({
      ...pre,
      total_stock: totalStock,
    }));

    const transformedChildOptions = childVariant?.options?.map(
      (childOption) => ({
        ...childOption,
        groups: [],
        variant_name: childVariant.variant_name,
      })
    );

    const transformedVariants = [
      {
        ...parentVariant,
        options: transformedParentOptions,
      },
      ...(transformedChildOptions && transformedChildOptions.length > 0
        ? [
            {
              ...childVariant,
              options: transformedChildOptions,
            },
          ]
        : []),
    ];
    return {
      variants: transformedVariants,
      is_Active: true,
    };
  };
  const handleImageCapture = async (event) => {
    setChartImageLoading(true);

    const file = event.target.files[0];
    // make array of files

    if (file) {
      const webpFiles = await convertToWebP([file]);
      if (!webpFiles || webpFiles.length === 0) {
        setChartImageLoading(false);
        return;
      }
      let formData = new FormData();
      formData.append("files", webpFiles[0]);
      try {
        let resp = await addProductImage(formData);
        if (resp) {
          setProductObj({
            ...productObj,
            chart_img: resp?.data?.imageUrls[0],
          });
          setChartImageLoading(false);
        }
      } catch (error) {
        setChartImageLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FDC532",
          borderRadius: "20px 0px",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "40px",
          opacity: 1,
          width: "auto",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <Typography sx={{ fontWeight: 500, color: "#fff", fontSize: "20px" }}>
          Upload Product
        </Typography>
        {((productObj?.variants && productObj?.variants?.length) ||
          variants.length) > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {!productId && (
              <Button
                variant="contained"
                onClick={productId ? handleEditProduct : AddProductHandler}
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  textTransform: "none",
                  borderRadius: "15px",
                  height: "30px",
                  opacity: 0.9,
                  ":hover": {
                    backgroundColor: "#000",
                    opacity: 1,
                  },
                }}
                disabled={editVariantsMoods?.is_disabled_publish}
              >
                Publish Product
              </Button>
            )}
            {productId && (
              <Button
                variant="contained"
                onClick={handleEditProduct}
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  textTransform: "none",
                  borderRadius: "15px",
                  height: "30px",
                  opacity: 0.9,
                  ":hover": {
                    backgroundColor: "#000",
                    opacity: 1,
                  },
                }}
                disabled={editVariantsMoods?.is_disabled_update}
              >
                Save
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "calc(100vh - 140px)",
          overflow: "visible",
          position: "relative",
        }}
      >
        {/* title part */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              borderRadius: "50px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#646669",
              borderRadius: "50px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FDC532",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#fff",
              borderRadius: "50px",
            },
          }}
          className="scrollable-content"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "auto",
              borderRadius: "20px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#5D5D5D",
                  mb: 1,
                  letterSpacing: "0px",
                  opacity: 1,
                }}
              >
                Add Product Details
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <TextField
                      label="Product Name"
                      id="outlined-size-small"
                      size="small"
                      onBlur={handleProductName}
                      required={true}
                      value={productObj?.product_name}
                      onChange={(e) => {
                        setProductObj((prevState) => ({
                          ...prevState,
                          product_name: e.target.value,
                        }));
                      }}
                      helperText={error?.nameError ? error?.nameText : ""}
                    />

                    <CKEditor
                      editor={ClassicEditor}
                      data={productObj.description}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                          "|",
                          "numberedList",
                          "bulletedList",
                          "|",
                          "fontColor",
                          "fontBackgroundColor",
                          "|",
                          "fontSize",
                          "|",
                          "indent",
                          "outdent",
                          "|",
                          "link",
                          "|",
                          "undo",
                          "redo",
                          "|",
                        ],
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setProductObj((pre) => ({
                          ...pre,
                          description: data,
                        }));
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <FormControl sx={{ minWidth: 200 }} size="small">
                      <InputLabel id="demo-multiple-chip-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple
                        value={productObj?.category}
                        onChange={(e) => handleChange("category", e)}
                        sx={{ width: "100%" }}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Category"
                          />
                        }
                        renderValue={(value) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            <Chip key={value} label={value} />
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {Object.keys(categoryList).map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, productObj?.category, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      sx={{}}
                      label="Price"
                      id="outlined-size-small"
                      type="number"
                      size="small"
                      value={productObj?.price}
                      onChange={(e) =>
                        setProductObj({ ...productObj, price: e.target.value })
                      }
                    />

                    <TextField
                      label="Discount Price"
                      id="outlined-size-small"
                      size="small"
                      value={productObj?.discount_price}
                      onChange={(e) =>
                        setProductObj({
                          ...productObj,
                          discount_price: e.target.value,
                        })
                      }
                    />

                    <TextField
                      // sx={{
                      //   width:325
                      // }}
                      label="Total Stock"
                      id="outlined-size-small"
                      type="number"
                      size="small"
                      value={productObj.total_stock}
                      disabled={productObj?.is_varient ? true : false}
                      onChange={(e) =>
                        setProductObj({
                          ...productObj,
                          total_stock: e.target.value,
                        })
                      }
                      inputProps={{ min: 0 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <FormControl sx={{ minWidth: 200 }} size="small">
                      <InputLabel id="demo-multiple-chip-label">
                        Sub Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        value={productObj?.sub_category}
                        onChange={(e) => handleChange("sub_category", e)}
                        sx={{ width: "100%" }}
                        label="Sub Category" // Add this line
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Sub Category"
                          />
                        } // Ensure this matches the label prop
                        renderValue={(value) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            <Chip key={value} label={value} />
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {productObj?.category &&
                          categoryList[productObj?.category].map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(
                                name,
                                productObj?.category,
                                theme
                              )}
                            >
                              {name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      // sx={{
                      //   width:325
                      // }}
                      label="Weight (Grams)"
                      id="outlined-size-small"
                      type="number"
                      size="small"
                      value={productObj?.weight}
                      onChange={(e) =>
                        setProductObj({ ...productObj, weight: e.target.value })
                      }
                      inputProps={{ min: 0 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        // label="Product Keyword"
                        placeholder="Product Keyword"
                        id="outlined-size-small"
                        size="small"
                        sx={{ width: "100%" }}
                        value={productObj?.keyword}
                        onChange={customKeywordChangeHandler}
                      />
                      {keywordError && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                          {keywordError}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                mt: "20px",
                mb: "20px",
                display: { xs: "block", lg: "flex" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mt: "15px",
                }}
              >
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#959595",
                        fontWeight: 500,
                      }}
                    >
                      Choose your prefered selling method for your product:
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    columns
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ justifyContent: "center", display: "flex" }}
                    value={productObj?.sellingMethod}
                    onChange={handleOptionChange}
                  >
                    <FormControlLabel
                      value="product"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#959595",
                            fontWeight:
                              productObj.sellingMethod === "product" && "500",
                          }}
                        >
                          Product link
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      value="link"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#959595",
                            fontWeight:
                              productObj.sellingMethod === "link" && "500",
                          }}
                        >
                          Custom link
                        </Typography>
                      }
                    />
                    {productObj?.sellingMethod === "link" && (
                      <TextField
                        label="Custom Link"
                        id="outlined-size-small"
                        size="small"
                        sx={{ width: "100%" }}
                        value={productObj?.affiliate_productlink}
                        onChange={(event) =>
                          setProductObj({
                            ...productObj,
                            affiliate_productlink: event.target.value,
                          })
                        }
                      />
                    )}
                    <FormControlLabel
                      value="number"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#959595",
                            fontWeight:
                              productObj.sellingMethod === "number" && "500",
                          }}
                        >
                          Whatsapp
                        </Typography>
                      }
                    />
                    {productObj?.sellingMethod === "number" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "5px",
                          background: "#fff",
                          border: "1px solid gray",
                          height: "40px",
                          overflow: "hidden",
                        }}
                      >
                        <Select
                          labelId="phone-code-select-label"
                          id="phone-code-select"
                          value={
                            productObj.sellingMethod === "number"
                              ? productObj?.countryCode
                              : ""
                          }
                          onChange={handleCodeChange}
                          disableUnderline
                          label="Phone Code"
                          sx={{
                            width: "110px",
                            pl: "10px",
                            pt: "5px",
                          }}
                          variant="standard"
                          size="small"
                        >
                          {countries?.map((c) => (
                            <MenuItem
                              key={c.code}
                              value={c.dial_code}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span
                                className={`fi fi-${c.code.toLocaleLowerCase()}`}
                              ></span>{" "}
                              {c.dial_code}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          size="small"
                          label="Contact No."
                          type={"number"}
                          value={
                            productObj?.phoneNumber || productObj?.whatsappNum
                          }
                          onChange={(event) => {
                            setProductObj({
                              ...productObj,
                              phoneNumber: event.target.value,
                              whatsappNum:
                                productObj?.countryCode +
                                "-" +
                                event.target.value,
                            });
                          }}
                          placeholder="3456789100"
                          sx={{
                            width: "100%",
                          }}
                          InputProps={{ disableUnderline: true }}
                          variant="filled"
                        />
                      </Box>
                    )}
                  </RadioGroup>
                </FormControl>
                {/* input feild to file upload */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#959595",
                      fontWeight: "500",
                    }}
                  >
                    Upload size chart:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageCapture}
                      style={{ display: "none" }}
                      id="chartImageUploadInput"
                    />
                    <label htmlFor="chartImageUploadInput">
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          justifyItems: "center",
                          width: "60px",
                          height: "60px",
                          border: "1px dashed #494949",
                          opacity: 0.5,
                        }}
                        component="span" // Make the button behave like a regular file input button
                      >
                        {chartImageLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <CameraAltIcon />
                        )}
                      </Button>
                    </label>
                    {productObj?.chart_img && (
                      <Box
                        sx={{
                          width: "auto",
                          height: "auto",
                          position: "relative",
                          height: "60px",
                          width: "60px",
                        }}
                      >
                        <img
                          src={productObj?.chart_img}
                          alt="imgprev"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "8px",
                            zIndex: 3,
                          }}
                        />

                        {/* {!chartImageLoading && (
                              <Tooltip title="Remove">
                                <Button
                                  sx={{
                                    position: "absolute",
                                    p: "0",
                                    m: "0",
                                    minWidth: "0",
                                    top: "6px",
                                    left: "1px",
                                    color: "#f1807e",
                                    background: "#fff",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => handleRemove(productObj?.chart_img, "chart_img")}
                                >
                                  <DeleteRoundedIcon sx={{ p: "0", m: "0" }} />
                                </Button>
                              </Tooltip>
                            )} */}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  width: { xs: "100%", lg: "calc(100% - 40%)" },
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="inherit"
                >
                  <Tab
                    label="Image"
                    sx={{
                      width: "auto",
                      fontSize: "13px",
                      fontWeight: selectedTab === 0 ? 700 : 400,
                      color: selectedTab === 0 ? "#000000" : "#9E9E9E",
                    }}
                  />
                  <Tab
                    label="Video"
                    sx={{
                      width: "auto",
                      fontSize: "13px",
                      fontWeight: selectedTab === 1 ? 700 : 400,
                      color: selectedTab === 1 ? "#000000" : "#9E9E9E",
                    }}
                  />
                </Tabs>
                {selectedTab === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      mt: "10px",
                    }}
                  >
                    {/* upload media box/container */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#00000019",
                        borderColor: "#C5C5C5",
                        position: "relative",
                        width: {
                          xs: "calc(100% - 120px)",
                          lg: "calc(100% - 300px)",
                        },
                        height: "200px",
                        borderRadius: "20px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          {...getRootProps()}
                          multiple={false}
                        >
                          <input
                            {...getInputProps()}
                            multiple={true}
                            disabled={imageloading}
                            accept="image/*"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {imageloading ? (
                              <CircularProgress />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <CloudUploadIcon
                                  sx={{
                                    fontSize: "50px",
                                    color: "#fff",
                                  }}
                                />
                                <Typography sx={{ color: "#000" }}>
                                  Drag & Drop here
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#000", opacity: 0.5 }}
                                >
                                  or
                                </Typography>
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    textTransform: "none",
                                    borderRadius: "15px",
                                    mt: 1,
                                    ":hover": {
                                      backgroundColor: "#fff",
                                    },
                                  }}
                                >
                                  Browse Files
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "block", lg: "flex" },
                        gap: 1,
                        width: {
                          xs: "cal(100%-140px)",
                          lg: "calc(100% - 300px)",
                        },
                        overflowX: "auto",
                        height: "200px",

                        ml: 1,
                      }}
                      className="scrollable-content"
                    >
                      <Box
                        sx={{
                          display: { xs: "block", lg: "flex" },
                          gap: 1,
                        }}
                      >
                        {productObj?.images?.map((image, idx) => (
                          <Box
                            sx={{
                              width: "120px",
                              height: "100px",
                              position: "relative",
                            }}
                            key={idx}
                          >
                            <img
                              src={image}
                              alt="imgprev"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "8px",
                                zIndex: 3,
                              }}
                            />

                            {!removeImgLoading && (
                              <Tooltip title="Remove">
                                <Button
                                  sx={{
                                    position: "absolute",
                                    p: "0",
                                    m: "0",
                                    minWidth: "0",
                                    top: "6px",
                                    left: "1px",
                                    color: "#f1807e",
                                    background: "#fff",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => handleRemove(image)}
                                >
                                  <DeleteRoundedIcon sx={{ p: "0", m: "0" }} />
                                </Button>
                              </Tooltip>
                            )}

                            <Tooltip title="Set Thumbnail">
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: "6px",
                                  right: "1px",
                                }}
                              >
                                <Radio
                                  checked={
                                    productObj?.thumbnail_image === image
                                  }
                                  onChange={handleChangeThumbnail}
                                  value={image}
                                  sx={{
                                    background: "#fff",
                                    p: "0",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                  }}
                                  size="small"
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                )}
                {selectedTab === 1 && (
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    {/* upload video container */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#00000019",
                        position: "relative",
                        mt: 1,
                        width: {
                          xs: "calc(100% - 50px)",
                          lg: "calc(100% - 300px)",
                        },
                        height: "200px",
                        borderRadius: "20px",
                        borderColor: "#C5C5C5",
                        opacity: 1,
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                          {...getVideoRootProps()}
                          multiple={false}
                        >
                          <input
                            {...getVideoInputProps()}
                            disabled={videoLoading}
                            accept=".mp4"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {videoLoading ? (
                              <CircularProgress />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <CloudUploadIcon
                                  sx={{
                                    fontSize: "50px",
                                    color: "#fff",
                                  }}
                                />
                                <Typography sx={{ color: "#000" }}>
                                  Drag & Drop here
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ color: "#000", opacity: 0.5 }}
                                >
                                  or
                                </Typography>
                                <Button
                                  variant="contained"
                                  sx={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    textTransform: "none",
                                    borderRadius: "15px",
                                    mt: 1,
                                    ":hover": {
                                      backgroundColor: "#fff",
                                    },
                                  }}
                                >
                                  Browse Files
                                </Button>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* video thumbnail container */}
                    {productObj?.video && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          width: {
                            xs: "cal(100%-100px)",
                            lg: "calc(100% - 300px)",
                          },
                          overflowX: "auto",
                          height: "200px",
                          ml: 1,
                          position: "relative",
                        }}
                      >
                        <video
                          src={productObj.video}
                          controls
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "8px",
                          }}
                        />
                        {/* delete video icon on the top left of the video */}
                        {!removeImgLoading && (
                          <Tooltip title="Remove">
                            <Button
                              sx={{
                                position: "absolute",
                                p: "0",
                                m: "0",
                                minWidth: "0",
                                top: "6px",
                                left: "1px",
                                color: "#f1807e",
                                background: "#fff",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                borderRadius: "50%",
                              }}
                              onClick={() =>
                                handleRemoveVideo(productObj?.video)
                              }
                            >
                              <DeleteRoundedIcon sx={{ p: "0", m: "0" }} />
                            </Button>
                          </Tooltip>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* add check box for is video thumbnail */}
                  {productObj?.video && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productObj.is_video_thumbnail}
                          onChange={(e) =>
                            setProductObj({
                              ...productObj,
                              is_video_thumbnail: e.target.checked,
                            })
                          }
                          name="is_video_thumbnail"
                        />
                      }
                      label="Video as thumbnail?"
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {productId && productObj?.variants?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  {/* add checkbox for the "This product has varient" */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={productObj?.is_variant_display}
                        onChange={(e) =>
                          setProductObj({
                            ...productObj,
                            is_variant_display: e.target.checked,
                          })
                        }
                        name="is_variant_display"
                      />
                    }
                    label="Activate variant display"
                  />
                </Box>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                {/* add checkbox for the "This product has varient" */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={productObj?.is_varient}
                      onChange={(e) =>
                        setProductObj({
                          ...productObj,
                          is_varient: e.target.checked,
                        })
                      }
                      name="is_varient"
                      disabled={
                        productId && productObj?.variants?.length > 0
                          ? true
                          : false
                      }
                    />
                  }
                  label="This product has variant"
                />
              </Box>
              {/* save button to save product  */}
              {!productObj?.is_varient && (
                <Box>
                  <Button
                    variant="contained"
                    onClick={productId ? handleEditProduct : AddProductHandler}
                    sx={{
                      backgroundColor: "#FDC532",
                      color: "#000",
                      textTransform: "none",
                      borderRadius: "15px",
                      height: "30px",
                      ":hover": {
                        backgroundColor: "#FDC532",
                      },
                    }}
                  >
                    {productId ? "Update" : "Save"}
                  </Button>
                </Box>
              )}
            </Box>
            {productObj?.is_varient && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "block", md: "flex" },
                      alignItems: "center",

                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#5D5D5D",
                        opacity: 1,
                        fontWeight: 500,
                      }}
                    >
                      Variants
                    </Typography>
                    <Box
                      sx={{
                        display: { xs: "none", md: "block" },
                        width: "10px",
                        height: "1px",
                        backgroundColor: "#707070",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        color: "#272727",
                        fontSize: "14px",
                        opacity: 1,
                      }}
                    >
                      Expand your product with two additional variants
                    </Typography>
                  </Box>
                  {/* Add variant button */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => addVariant(true)}
                      sx={{
                        backgroundColor: "#272727",
                        color: "#fff",
                        textTransform: "none",
                        borderRadius: "15px",
                        height: "30px",
                        ":hover": {
                          backgroundColor: "#272727",
                        },
                      }}
                      disabled={
                        productObj?.variants?.length >= 2 ||
                        variants?.length >= 2
                      }
                    >
                      Add
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                ></Box>

                {/* map input options to add variant */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  {variants &&
                    variants?.map((variant, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#272727",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0px",
                              opacity: 1,
                              width: idx === 0 ? "100px" : "140px",
                            }}
                          >
                            {idx === 0
                              ? "First Variant"
                              : idx === 1
                              ? "Second Variant"
                              : "Third Variant"}
                          </Typography>
                          <AddCircleIcon
                            sx={{
                              color: "#FDC532",
                              cursor: "pointer",
                            }}
                          />
                          {/* a separator line */}
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              backgroundColor: "#00000019",
                            }}
                          ></Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "space-between",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TextField
                              label="Variant Name"
                              id="outlined-size-small"
                              size="small"
                              sx={{
                                width: "200px",
                                borderRight: "none",
                              }}
                              error={variantsErrors?.variantName}
                              value={variant?.varient_name}
                              onChange={(e) =>
                                handleVariantNameChange(idx, e.target.value)
                              }
                            />
                            {/* add more options button */}
                            <Tooltip title="Add Option" placement="top">
                              <Button
                                variant="contained"
                                onClick={() =>
                                  addVariantOption(
                                    idx,
                                    productId === "" ? false : true
                                  )
                                }
                                sx={{
                                  backgroundColor: "#FDC532",
                                  color: "#000",
                                  textTransform: "none",
                                  border: "0.5px solid #DDFFFF",
                                  height: "41px",
                                  borderLeft: "none",
                                  borderRadius: "0px 15px 15px 0px",
                                  ":hover": {
                                    backgroundColor: "#FDC532",
                                    border: "0.5px solid #000",
                                  },
                                }}
                              >
                                Add
                              </Button>
                            </Tooltip>
                          </Box>
                          <TextField
                            label="Default Stock"
                            id="outlined-size-small"
                            size="small"
                            type="number"
                            sx={{
                              width: "120px",
                              borderRight: "none",
                            }}
                            value={variant?.default_stock}
                            onChange={(e) =>
                              handleVariantDefaultStockChange(
                                idx,
                                e.target.value
                              )
                            }
                          />
                          {/* add remove variant button*/}
                          {/* <Tooltip title="Delete Variant" placement="top">
                          <Button

                            variant="contained"
                            onClick={() => removeVariant(idx, variant)}
                            sx={{
                              backgroundColor: "#FDC532",
                              color: "#000",
                              textTransform: "none",
                              borderRadius: "15px",
                              width: "60px",
                              height: "30px",
                              ":hover": {
                                backgroundColor: "#FDC532",
                              },
                            }}
                          >
                            Delete
                          </Button>
                        </Tooltip> */}
                          {/* remove variant */}
                          {/* <Tooltip title="Delete Variant" placement="top">
                          <DeleteIcon
                            sx={{
                              color: "#FDC532",
                              cursor: "pointer",
                            }}
                            onClick={() => removeVariant(idx, variant)}
                          />
                        </Tooltip> */}
                          {/* <Tooltip title="Add Option" placement="top">
                          <AddCircleIcon
                            sx={{
                              color: "#FDC532",
                              cursor: "pointer",
                            }}
                            onClick={() => addVariantOption(idx, productId === '' ? false : true)}
                          />
                        </Tooltip> */}
                        </Box>
                        {/* Variant options */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1,
                            pt: 1,
                          }}
                        >
                          {variant?.options &&
                            variant?.options?.map((option, optionIndex) => (
                              <Box
                                key={optionIndex}
                                sx={{ position: "relative" }}
                              >
                                <TextField
                                  label={`Option ${optionIndex + 1}`}
                                  id={`option-${idx}-${optionIndex}`}
                                  size="small"
                                  sx={{
                                    width: "150px",
                                  }}
                                  value={option.type}
                                  error={variantsErrors?.variantOptionType}
                                  onChange={(e) =>
                                    handleVarientOptionChange(
                                      idx,
                                      optionIndex,
                                      "type",
                                      e.target.value
                                    )
                                  }
                                />
                                <Tooltip title="Delete Option" placement="top">
                                  <ClearIcon
                                    sx={{
                                      color: "black",
                                      cursor: "pointer",
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      height: "20px",
                                      opacity: 0.5,
                                    }}
                                    onClick={() =>
                                      removeVariantOption(
                                        idx,
                                        optionIndex,
                                        option
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                    ))}
                  {variants?.length > 0 && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* done button */}
                      <Button
                        variant="contained"
                        onClick={() => handleDoneVariant()}
                        disabled={
                          variantsErrors?.variantName ||
                          variantsErrors?.variantOptionType ||
                          editVariantsMoods?.is_disabled_done
                        }
                        sx={{
                          backgroundColor: "#272727",
                          color: "#fff",
                          textTransform: "none",
                          borderRadius: "15px",
                          height: "30px",
                          mt: 1,
                          ":hover": {
                            backgroundColor: "#272727",
                          },
                        }}
                      >
                        Update
                      </Button>
                    </Box>
                  )}
                </Box>

                {/* data table to show variants */}
                {productObj?.variants?.length > 0 && (
                  <Box
                    sx={{
                      //   width: "100%",
                      //   height: "100%",
                      typography: "body1",
                      // border: "1px solid #00000029",
                      borderRadius: "20px",
                      // minWidth: "400px",
                    }}
                  >
                    <DataTable
                      data={VOptions}
                      columns={columns}
                      customStyles={customStyles}
                      expandableRows={true}
                      expandableRowsComponent={ExpandableComponent}
                      selectableRows
                      onSelectedRowsChange={handleRowSelect}
                      clearSelectedRows={toggleCleared}
                    />
                  </Box>
                )}
                {/* {
                  productObj?.variants && productObj?.variants?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1
                      }}
                    >
                      {
                        !productId && (
                          <Button
                          variant="contained"
                          onClick={
                            productId ? handleEditProduct :
                              AddProductHandler
                          }
                         
                          sx={{
                            backgroundColor: "#FDC532",
                            color: "#000",
                            textTransform: "none",
                            borderRadius: "15px",
                            height: "30px",
                            ":hover": {
                              backgroundColor: "#FDC532",
                            },
                          }}
                        >
                          
                             Publish Product
                          
  
                        </Button>
                        )
                      }
                     {
                        productId && (
                          <Button
                        variant="contained"
                        onClick={
                         handleEditProduct 
                        }
                        sx={{
                          backgroundColor: "#FDC532",
                          color: "#000",
                          textTransform: "none",
                          borderRadius: "15px",
                          height: "30px",
                          ":hover": {
                            backgroundColor: "#FDC532",
                          },
                        }}
                        disabled={editVariantsMoods?.is_disabled_update}
                      >
                        
                          Update Product
                        

                      </Button>
                        )
                     }
                      
                    </Box>
                  )
                } */}
              </Box>
            )}
          </Box>
        </Box>

        <EditVariantDialog
          editDialog={editDialog}
          handleCloseDialog={() => setEditDialog(false)}
          editRow={editRow}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          productObj={productObj}
          setProductObj={setProductObj}
          variants={variants}
          setVariants={setVariants}
          callFrom={callFrom}
          setcallFrom={setcallFrom}
          setEditRow={setEditRow}
          setEditVariantsMoods={setEditVariantsMoods}
          editVariantsMoods={editVariantsMoods}
        />
      </Box>
    </>
  );
};

export default UploadProduct;
