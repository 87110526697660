import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import React from "react";
import OverallSection from "../Streaming/OptionsComponents/SalesAnalyticsSec/OverallSection";
import LiveSection from "../Streaming/OptionsComponents/SalesAnalyticsSec/LiveSection";

const Analytics = () => {
  const [value, setValue] = React.useState("overall");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
    sx={{
      maxHeight: "calc(100vh - 70px)",
      overflowY: "auto",
    
      "&::-webkit-scrollbar": {
        width: "5px",
        borderRadius: "50px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#646669",
        borderRadius: "50px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#FDC532",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fff",
        borderRadius: "50px",
      },
    }}
    className="scrollable-content"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: "10px",
          py: "18px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Analytics
        </Typography>
      </Box>
      {/* seprator */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#00000029",
         
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          height: "100%",
          overflowY: "auto",
          px: "2px",
          // mb:6,
        }}
        className="scrollable-content"
      >
        <TabContext value={value}>
          <Box
            sx={{
              // borderBottom: "3px solid #FC6B00",
              mb: "10px",
              width: "100%",
            }}
          >
            <TabList
              onChange={handleTabChange}
              // indicatorColor=""
              variant="fullWidth"
              // sx={{ minHeight: "20px" }}
            >
              <Tab
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    Overall
                  </Typography>
                }
                value="overall"
                sx={{
                  fontWeight: "bold",
                }}
              />
              <Tab
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    Live Streams
                  </Typography>
                }
                value="stream"
                sx={{
                  fontWeight: "bold",
                }}
              />
            </TabList>
          </Box>
          <Box>
            <TabPanel
              value="overall"
              sx={{
                p: "0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <OverallSection />
            </TabPanel>
            <TabPanel
              value="stream"
              sx={{
                p: "0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LiveSection />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Analytics;
