import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import GradingRoundedIcon from "@mui/icons-material/GradingRounded";
import SendTimeExtensionRoundedIcon from "@mui/icons-material/SendTimeExtensionRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import OfflinePinRoundedIcon from "@mui/icons-material/OfflinePinRounded";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getFilteredStreamMatrics,
  getOverallMatrics,
  getTodayStreamMatrics,
} from "../../../../api/StreamApis";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";

const TODAY_CACHE_KEY = 'todayStreamData';
const OVERALL_CACHE_KEY = 'overallStreamData';
const FILTERED_CACHE_KEY = 'filteredStreamData';
const TODAY_TIMESTAMP_KEY = 'todayStreamDataTimestamp';
const OVERALL_TIMESTAMP_KEY = 'overallStreamDataTimestamp';
const FILTERED_TIMESTAMP_KEY = 'filteredStreamDataTimestamp';
const CACHE_EXPIRATION_MS = 1000 * 60 * 15; 

const OverallSection = () => {

  const [streamData, setStreamData] = React.useState(null);
  const [orderData, setOrderData] = React.useState(null);
  const [dateObj, setDateObj] = React.useState({
    startDate: "",
    endDate: "",
  });

  const getTodayStreamData = async () => {
    const cachedData = localStorage.getItem(TODAY_CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(TODAY_TIMESTAMP_KEY);
    const now = new Date().getTime();

    if (cachedData && cachedTimestamp && now - cachedTimestamp < CACHE_EXPIRATION_MS) {
      const parsedData = JSON.parse(cachedData);
      setStreamData(parsedData.streamData);
      setOrderData(parsedData.orderData);
    } else {
      try {
        const response = await getTodayStreamMatrics();
        if (response.status === 200) {
          setStreamData(response.data);
          setOrderData(response.data.ordersObj);
          localStorage.setItem(TODAY_CACHE_KEY, JSON.stringify({ streamData: response.data, orderData: response.data.ordersObj }));
          localStorage.setItem(TODAY_TIMESTAMP_KEY, now.toString());
        } else if (response.status === 201) {
          setOrderData(response.data.ordersObj);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const getOverallStreamData = async () => {
    const cachedData = localStorage.getItem(OVERALL_CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(OVERALL_TIMESTAMP_KEY);
    const now = new Date().getTime();

    if (cachedData && cachedTimestamp && now - cachedTimestamp < CACHE_EXPIRATION_MS) {
      const parsedData = JSON.parse(cachedData);
      setStreamData(parsedData.streamData);
      setOrderData(parsedData.orderData);
    } else {
      try {
        const response = await getOverallMatrics();
        if (response.status === 200) {
          const _data = response?.data?.overallStreamMetrics;
          const newStreamData = {
            ...streamData,
            todayStreamingMetaData: {
              streamTitle: "",
              streamDescription: "",
            },
            todayStreamAutoReplyCommentsCount: _data?.totalAutoReplyComments,
            todayStreamCommentsCount: _data?.totalComments,
            todayStreamMatrics: {
              facebook: _data?.totalStreamingHours,
              youtube: _data?.totalStreamingHours,
              instagram: _data?.totalStreamingHours,
            },
            todayStreamVisitorCount: _data?.totalLeads,
            todayWhatsappOrdersCount: _data?.totalWhatsappLeads,
            totalStreams: _data?.totalStreams,
          };
          setStreamData(newStreamData);
          setOrderData(response.data.ordersObj);
          localStorage.setItem(OVERALL_CACHE_KEY, JSON.stringify({ streamData: newStreamData, orderData: response.data.ordersObj }));
          localStorage.setItem(OVERALL_TIMESTAMP_KEY, now.toString());
        } else if (response.status === 201) {
          setOrderData(response.data.ordersObj);
        }
      } catch (error) {
        return error;
      }
    }
  };

  const filteredStreamData = async (start_date, end_date) => {
    const cacheKey = `${FILTERED_CACHE_KEY}_${start_date}_${end_date}`;
    const cacheTimestampKey = `${FILTERED_TIMESTAMP_KEY}_${start_date}_${end_date}`;
    const cachedData = localStorage.getItem(cacheKey);
    const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
    const now = new Date().getTime();

    if (cachedData && cachedTimestamp && now - cachedTimestamp < CACHE_EXPIRATION_MS) {
      const parsedData = JSON.parse(cachedData);
      setStreamData(parsedData.streamData);
      setOrderData(parsedData.orderData);
    } else {
      try {
        const response = await getFilteredStreamMatrics(start_date, end_date);
        if (response.status === 200) {
          let data = response?.data?.streamMatricsObj;
          let keys = Object.keys(data);
          let values = Object.values(data);

          if (keys.length === 1) {
            const newStreamData = {
              ...streamData,
              todayStreamingMetaData: {
                streamTitle: values[0].streams[0].streamTitle,
                streamDescription: values[0].streams[0].streamDescription,
              },
              todayStreamAutoReplyCommentsCount: values[0].StreamAutoReplyCommentsCount,
              todayStreamCommentsCount: values[0].StreamCommentsCount,
              todayStreamMatrics: {
                facebook: values[0].streams[0].facebookStreamDuration,
                youtube: values[0].streams[0].youtubeStreamDuration,
                instagram: values[0].streams[0].instagramStreamDuration,
              },
              todayStreamVisitorCount: values[0].totalLeads,
              todayWhatsappOrdersCount: 0,
              totalStreams: 0,
            };
            setStreamData(newStreamData);
            setOrderData(response.data.ordersObj);
            localStorage.setItem(cacheKey, JSON.stringify({ streamData: newStreamData, orderData: response.data.ordersObj }));
            localStorage.setItem(cacheTimestampKey, now.toString());
          }
        }
      } catch (error) {
        return error;
      }
    }
  };

  React.useEffect(() => {
    getOverallStreamData();
  }, []);

  React.useEffect(() => {
    if (dateObj.startDate !== "" && dateObj.endDate !== "") {
      let start_date =
        dateObj.startDate.$d.getFullYear() +
        "-" +
        (dateObj.startDate.$d.getMonth() + 1) +
        "-" +
        dateObj.startDate.$d.getDate();
      let end_date =
        dateObj.endDate.$d.getFullYear() +
        "-" +
        (dateObj.endDate.$d.getMonth() + 1) +
        "-" +
        dateObj.endDate.$d.getDate();

      filteredStreamData(start_date, end_date);
    }
  }, [dateObj]);

  return (
    <Box
    sx={{
      px: "6px",

    }}
    >
      <Box sx={{ display: "flex", gap: 1, mb: 1, pt: "8px", }}>
        <DatePicker
          label="Start Date"
          sx={{
            width: "100%",
          }}
          onChange={(e) => {
            setDateObj({
              ...dateObj,
              startDate: e,
            });
          }}
        />
        <DatePicker
          label="End Date"
          sx={{
            width: "100%",
          }}
          onChange={(e) => {
            setDateObj({
              ...dateObj,
              endDate: e,
            });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid black",
          pb: "7px",
          mb: "15px",
          px: "6px",

        }}
      >
        <Box>
          <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
            {streamData?.todayStreamingMetaData?.streamTitle || ""}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {streamData?.todayStreamingMetaData?.streamDescription || ""}
          </Typography>
        </Box>

        <Box>
          <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
            {/* Date */}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {/* {
              dateObj?.startDate?.$d?.getDate() + "-" + dateObj?.startDate?.$d?.getMonth() + "-" + dateObj?.startDate?.$d?.getFullYear() || "Date"
            } */}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #0000002E",
          mb: "5px",

        }}
      >
        <Typography sx={{ fontSize: "12px" }}>Keywords Generated</Typography>

        <Typography sx={{ fontSize: "12px" }}>
          {streamData?.todayStreamAutoReplyCommentsCount || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #0000002E",
          mb: "5px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>Stream Duration</Typography>

        <Typography sx={{ fontSize: "12px" }}>
          {streamData?.todayStreamMatrics?.facebook !== "00:00:00"
            ? streamData?.todayStreamMatrics?.facebook
            : streamData?.todayStreamMatrics?.youtube !== "00:00:00"
            ? streamData?.todayStreamMatrics?.youtube
            : streamData?.todayStreamMatrics?.instagram !== "00:00:00"
            ? streamData?.todayStreamMatrics?.instagram
            : "00:00:00" || "00:00:00"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #0000002E",
          mb: "5px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>Total Comments</Typography>

        <Typography sx={{ fontSize: "12px" }}>
          {streamData?.todayStreamCommentsCount || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #0000002E",
          mb: "5px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>Leads</Typography>

        <Typography sx={{ fontSize: "12px" }}>
          {streamData?.todayStreamVisitorCount || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #0000002E",
          mb: "5px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>WhatsApp link</Typography>

        <Typography sx={{ fontSize: "12px" }}>
          {streamData?.todayWhatsappOrdersCount || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #0000002E",
          mb: "5px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>Total Streams</Typography>

        <Typography sx={{ fontSize: "12px" }}>
          {streamData?.totalStreams || 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: "5px",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>Order Status Count</Typography>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #707070",
                  p: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <GradingRoundedIcon
                  sx={{
                    position: "absolute",
                    left: "15px",
                    color: "#4DA2F8",
                  }}
                />

                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  {orderData?.acceptedOrders || 0}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Accepted <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #707070",
                  p: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <SendTimeExtensionRoundedIcon
                  sx={{
                    position: "absolute",
                    left: "15px",
                    color: "#FDC532",
                  }}
                />
                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  {orderData?.dispatchedOrders || 0}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Dispatched{" "}
                  <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #707070",
                  p: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <CancelRoundedIcon
                  sx={{
                    position: "absolute",
                    left: "15px",
                    color: "#FF1E5A",
                  }}
                />
                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  {orderData?.cancelledOrders || 0}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Cancelled{" "}
                  <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #707070",
                  p: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <OfflinePinRoundedIcon
                  sx={{
                    position: "absolute",
                    left: "15px",
                    color: "#2EB43F",
                  }}
                />
                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  {orderData?.fullfilledOrders || 0}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Fillfilled{" "}
                  <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #707070",
                  p: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <HourglassBottomRoundedIcon
                  sx={{
                    position: "absolute",
                    left: "15px",
                    color: "orange",
                  }}
                />
                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  {orderData?.pendingOrders || 0}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Pending <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #707070",
                  p: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <AssignmentReturnedIcon
                  sx={{
                    position: "absolute",
                    left: "15px",
                    color: "brown",
                  }}
                />
                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  {orderData?.returnOrders || 0}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Returned <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default OverallSection;
