import { useNavigate } from "react-router-dom";

import { Container, Stack, Typography, Button } from "@mui/material";

import TableToolbar from "./TableToolbar";
import PostsTable from "./PostsTable";

const Main = () => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        my: 3,
      }}
    >
      <Stack direction="row" justifyContent="space-between" mb={8}>
        <Typography
          variant="h4"
          fontWeight="600"
          sx={(theme) => ({ color: theme.palette.saas_button_bg.main })}
        >
          Posts
        </Typography>

        <Button
          variant="contained"
          sx={(theme) => ({
            backgroundColor: theme.palette.saas_button_bg.main,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.saas_button_bg.hover,
            },
          })}
          onClick={() => navigate("create")}
        >
          Create Post
        </Button>
      </Stack>

      <TableToolbar />
      <PostsTable />
    </Container>
  );
};

export default Main;
