import { Box, Button, Chip, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, OutlinedInput, Radio, Select, Switch, Tab, Tabs, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import  useTags from '../../../../../hooks/useTags'
import InventoryIcon from '@mui/icons-material/Inventory';
import LinkIcon from '@mui/icons-material/Link';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { dummy_categories } from '../../../../../utils/dummy_categories';
import { CheckBox } from '@mui/icons-material';
import QueueIcon from '@mui/icons-material/Queue';
import MoreVertIcon from '@mui/icons-material/MoreVert';
const categoryNames = Object.keys(dummy_categories);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Campaign = () => {
// !---------------------- STATES ----------------------
const [selectedTab, setSelectedTab] = React.useState(0);
const [categories, setCategories] = useState([]);
    const {switchProps}=useTags();
    const theme=useTheme();
    // !---------------------- FUNCTIONS ----------------------
    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
        }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Campaign
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "10px",
          py:0
        }}
      >
        <Box
        sx={{
            display:"flex",
            gap:1,
            alignItems:"center",
        }}
        >
        <Typography
          sx={{
            fontSize:{xs:"13px",sm:"14px"},
            fontWeight: "500",
          }}
        >
          Create your campaign in three easy steps
        </Typography>
        <FormGroup>
            <FormControlLabel
            control={
                <Switch
                {...switchProps}
                />
            }
            />

        </FormGroup>
        </Box>
        <Button
                    variant="outlined"
                    sx={{
                      fontWeight: "500",
                      backgroundColor: "#3F3F3F",
                      gap: "4px",
                      color: "#fff",
                      textTransform: "none",
                      ":hover": {
                        backgroundColor: "transparent",
                        color: "#000000",
                      },
                      "&.Mui-disabled": {
                        color: "#fff",
                        backgroundColor: "#3F3F3F",
                      },
                    }}
                  >
                    <RestartAltIcon 
                    sx={{
                        fontSize: "20px",
                    }}
                    />
                     Restart 
                  </Button>
                  
      </Box>
      {/* configure campaign */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: "10px",
        }}
      >
        <Box
        sx={{
            width:"20px",
            height:"20px",
            borderRadius:"50%",
            backgroundColor:theme.palette.primary.main,
            color:theme.palette.saas_button_bg.main,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
        }}
        >
            <Typography
            sx={{
                fontSize:"12px",
                fontWeight:"600",
            }}
            >
                1
            </Typography>

        </Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Configure Your Campaign
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          pl: "20px",
        }}
      >
        <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          minHeight: "unset",
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main,
            height: "1px", 
          },
        }}
        >
           <Tab
              label={
                <Box
            sx={{
                display:"flex",
                gap:"4px",
                alignItems:"center",
                py:"4px",
                px:"5px",
                borderRadius:"5px",
                border:`1px solid ${selectedTab===0?theme.palette.primary.main:theme.palette.seprator_color.main}`,
                color:theme.palette.saas_button_bg.main,
            }}
            >
                <InventoryIcon
                sx={{
                    fontSize:"20px",
                    color:selectedTab===0?theme.palette.primary.main:theme.palette.seprator_color.main
                }}
                />
                <Typography
                sx={{
                    fontSize:"12px",
                    fontWeight:"500",
                    textTransform:"capitalize",
                }}
                >
                    Uchlo Product
                </Typography>
            </Box>
              }
           />
            <Tab
              label={
                <Box
            sx={{
                display:"flex",
                gap:"4px",
                alignItems:"center",
                py:"4px",
                px:"5px",
                borderRadius:"5px",
                border:`1px solid ${selectedTab===1?theme.palette.primary.main:theme.palette.seprator_color.main}`,
                color:theme.palette.saas_button_bg.main,
            }}
            >
                <LinkIcon
                sx={{
                    rotate:"90deg",
                    fontSize:"20px",
                    color:selectedTab===1?theme.palette.primary.main:theme.palette.seprator_color.main
                }}
                />
                <Typography
                sx={{
                    fontSize:"12px",
                    fontWeight:"500",
                    textTransform:"capitalize",
                }}
                >
                    external product URL
                </Typography>
            </Box>
              }
           />
            <Tab
              label={
                <Box
            sx={{
                display:"flex",
                gap:"4px",
                alignItems:"center",
                py:"4px",
                px:"5px",
                borderRadius:"5px",
                border:`1px solid ${selectedTab===2?theme.palette.primary.main:theme.palette.seprator_color.main}`,
                color:theme.palette.saas_button_bg.main,
            }}
            >
                <WhatsAppIcon
                sx={{
                    fontSize:"20px",
                    color:selectedTab===2?theme.palette.primary.main:theme.palette.seprator_color.main
                }}
                />
                <Typography
                sx={{
                    fontSize:"12px",
                    fontWeight:"500",
                    textTransform:"capitalize",
                }}
                >
                    whatsapp
                </Typography>
            </Box>
              }
           />
        </Tabs>
        <Box
        sx={{
            display:"flex",
            flexDirection:{xs:"column",sm:"row"},
            alignItems:"center",
            width:"100%",
            justifyContent:"space-between",
            gap:1
        }}
        >
          <Box
  sx={{
    display: "flex",
    gap: 1,
    alignItems: "flex-start", 
  }}
>
  <CheckBox
    sx={{
      color: theme.palette.saas_button_bg.main,
      alignSelf: "flex-start", 
    }}
  />
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      width: "300px",
    }}
  >
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: "400",
      }}
    >
      You can add up to 5 products
    </Typography>
    <FormControl
      sx={{
        background: "#fff",
        borderRadius: "20px",
        width: "100%",
        py: "6px",
      }}
      size="small"
      variant="standard"
    >
      <InputLabel
        id="demo-multiple-chip-label"
        variant="standard"
        sx={{ top: "-3px", left: "14px" }}
      >
        Select Product
      </InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        multiple
        value={categories}
        input={<OutlinedInput label="Select Product" />}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {categoryNames.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
         </FormControl>
  </Box>
</Box>

<Box
sx={{
  display: "flex",
  flexDirection: "column",
  alignItems:"center",
}}
>
<Typography
sx={{
  fontSize: "14px",
  fontWeight: "400",
}}
>
  Select one keyword
</Typography>
<select
              name="media"
              id="media"
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                outline: "none",
                border: "1px solid #ccc",
                borderRadius: "5px",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "5.5px",
                paddingBottom: "5.5px",
                width:"auto"
              }}
            >
              <option value="none">Select Keyword</option>
              <option value="image">1sdas2</option>
              <option value="video">354czxc3</option>
              <option value="reel">3423cad</option>
            </select>

</Box>
<Box
sx={{
  display: "flex",
  alignItems: "center",
  gap:2,
}}
>
<Box
sx={{
  display:"flex",
  gap:"4px",
}}
>
  <QueueIcon
  sx={{
    fontSize:"20px",
    color:theme.palette.primary.main,
  }}
  />
  <Typography
  sx={{
    fontSize:"14px",
    fontWeight:"500",
  }}
  >
    Add Product
  </Typography>

</Box>
<MoreVertIcon
sx={{
  fontSize:"20px",
  color:theme.palette.primary.main,
}}
/>
</Box>


        </Box>

        </Box>

        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: "10px",
        }}
      >
        <Box
        sx={{
            width:"20px",
            height:"20px",
            borderRadius:"50%",
            backgroundColor:theme.palette.primary.main,
            color:theme.palette.saas_button_bg.main,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
        }}
        >
            <Typography
            sx={{
                fontSize:"12px",
                fontWeight:"600",
            }}
            >
                2
            </Typography>
            

        </Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Customize Reply Message
        </Typography>
      </Box>
      <Box
      sx={{
        display:"flex",
        flexDirection:"column",
        pl:"20px",
      }}
      >
          <Typography
          sx={{
              fontSize:"14px",
              fontWeight:"400",
          }}
          >
            Chose your way to reply
          </Typography>
          <Box
          sx={{
              display:"flex",
              gap:2,
              alignItems:"center",
          }}
          >
             <Box
  sx={{
    display: "flex",
    gap: 1,
    alignItems: "center",
  }}
>
  <Radio
    sx={{
      color: theme.palette.saas_button_bg.main,
    }}
  />
  <Typography
    sx={{
      fontSize: "14px",
      fontWeight: "400",
    }}
  >
    Reply in Dm
  </Typography>
</Box>
<Box
  sx={{
    display: "flex",
    gap: 1,
    alignItems: "center",
  }}
>
  <Radio
    sx={{
      color: theme.palette.saas_button_bg.main,
    }}
  />
  <Typography
    sx={{
      fontSize: "14px",
      fontWeight: "400",
    }}
  >
    Reply in comments
  </Typography>
</Box>


          </Box>
          <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    px:{xs:"6px",sm:"0px"},
                    width:{xs:'95%',sm:'50%'},
                  }}
                >
                  <textarea
                    label="Stream Description"
                    placeholder="Craft a message that speaks to your audience..."
                    id="outlined-size-small"
                    size="small"
                    cols={10}
                    rows={5}
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      outline: "none",
                      resize: "vertical",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      paddingTop: "8.5px",
                      paddingBottom: "8.5px",
                    }}
                    name="message"
                  />
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: { xs: "13px", sm: "14px" },
                      fontWeight: "400",
                      color: "#3F3F3F",
                    }}
                  >
                    0/50
                  </Typography>
                </Box>

      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: "10px",
        }}
      >
        <Box
        sx={{
            width:"20px",
            height:"20px",
            borderRadius:"50%",
            backgroundColor:theme.palette.primary.main,
            color:theme.palette.saas_button_bg.main,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
        }}
        >
            <Typography
            sx={{
                fontSize:"12px",
                fontWeight:"600",
            }}
            >
                3
            </Typography>
            

        </Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Select Campaign Duration
        </Typography>
      </Box>
      <Box
      sx={{
        display:"flex",
        flexDirection:"column",
        pl:"20px",
      }}
      >
          <Typography
          sx={{
              fontSize:"14px",
              fontWeight:"400",
              pb:1,
          }}
          >
            Chose your way to reply
          </Typography>
         {/* select duration */}
          <select
              name="media"
              id="media"
              style={{
                fontFamily: "Poppins",
                fontSize: "14px",
                outline: "none",
                border: "1px solid #ccc",
                borderRadius: "5px",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "5.5px",
                paddingBottom: "5.5px",
                width:"150px"
              }}
            >
              <option value="none">Select Duration</option>
              <option value="image">1 week</option>
              <option value="video">2 week</option>
              <option value="reel">3 week</option>
            </select>
        

      </Box>

      {/* save as draft and publish now buttons */}
      <Box
      sx={{
        display:"flex",
        justifyContent:"end",
        gap:1,
        py:2,
        px:"10px",
      }}
      >
          <Button
          variant="outlined"
          sx={{
              backgroundColor:'transparent',
              color:theme.palette.saas_button_bg.main,
              fontWeight:"500",
              textTransform:"none",
              ":hover":{
                  backgroundColor:theme.palette.primary.main,
                  color:theme.palette.saas_button_bg.main,
              }
          }}
          >
              Save as Draft
          </Button>
          <Button
          variant="contained"
          sx={{
              backgroundColor:theme.palette.saas_button_bg.main,
              color:"#fff",
              fontWeight:"500",
              textTransform:"none",
              ":hover":{
                  backgroundColor:theme.palette.saas_button_bg.main,
                  color:theme.palette.primary.main,
              }
          }}
          >
              Publish Now
          </Button>

      </Box>


        </Box>
  )
}

export default Campaign