import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Tab,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { countries } from "../../utils/CountryCodes";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AuthContext } from "../../context/AuthContex";
// import { updateBusinessUser, updateStore } from "../../api/BusinessUsers.api";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "../Toast";
import { AuthContext } from "../../context/AuthContext";
import { updateProfile } from "../../api/BusinessUserApis";
import { updateStore } from "../../api/StoreApis";
import { Link, useLocation } from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import dayjs from "dayjs";
import {
  addShiper,
  getAllBanks,
  getAllCities,
  updateShipperBankInfo,
} from "../../api/LeopardApis";
import { cities } from "../../utils/leoapardCities";
import { object } from "yup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const categoryList = {
  Fashion: ["Dresses", "Shoes", "Accessories", "Other"],
  Beauty: ["Skincare", "Makeup", "Fragrances", "Other"],
  Electronics: ["Smartphones", "Laptops", "Headphones", "Other"],
  "Home Goods": ["Furniture", "Kitchen Appliances", "Decor", "Other"],
  Toys: ["Action Figures", "Board Games", "Dolls", "Other"],
  "Health and Wellness": [
    "Vitamins",
    "Fitness Equipment",
    "Supplements",
    "Other",
  ],
  "Pet Supplies": ["Dog Food", "Cat Toys", "Fish Tanks", "Other"],
  "Outdoor Gear": ["Camping Gear", "Hiking Boots", "Bicycles", "Other"],
  Books: ["Fiction", "Non-Fiction", "Mystery", "Other"],
  Others: ["Miscellaneous", "Random", "Unspecified", "Other"],
  "Groceries & Pets": [
    "Fresh Produce",
    "Fresh Fruit",
    "Fresh Vegetables",
    "Fish & Cold Pressed Juices",
    "Bread",
    "Eggs",
    "Dry Fruits",
    "Breakfast, Choco's & Snacks",
    "Breakfast Cereals",
    "Jams, Honey & Spreads",
    "Breakfast Bars",
    "Instant Breakfast Drinks",
    "Chocolate & Candy",
    "Chewing Gums & Mints",
    "Biscuit & Cookies",
    "Cakes & Sweets",
    "Chips & Crisps",
    "Nuts & Legumes",
    "Oatmeal",
    "Pancake & Waffle Mixes",
    "Beverages",
    "Tea",
    "Coffee",
    "UHT, Milk & Milk Powder",
    "Soft Drinks",
    "Juices",
    "Water",
    "Flavouring Syrup",
    "Powdered Drinks",
    "Food Staples",
    "Oil",
    " Rice",
    "Condiment Dressing",
    "Cooking Ingredients",
    "Noodles & Pasta",
    "Instant & Ready-to-Eat",
    "Grains, Beans & Pulses",
    "Home Baking & Sugar",
    "Jarred Food",
    "Canned Food",
    "Laundry & Household",
    "AirCare",
    "Cleaning",
    "Dishwashing",
    "Laundry",
    "Pest Control",
    "Tissue Paper",
    "Foils & Cling Films",
    " Frozen Food",
    "Chicken",
    "Beef",
    "Other Frozen Food",
    "Cat Food",
    "Cat Beds, Mats & Houses",
    "Cat Carriers & Travel",
    "Cat Grooming",
    "Cat Litter & Toilet",
    "Cat Cages, Crates & Doors",
    "Cat Toys",
    "Cat Treats",
    "Cat Trees, Condos & Scratchers",
    "Cat Bowls & Feeders",
    "Dog Collars",
    "Dog Toys",
    "Dog Harnesses",
    "Dog Brushes & Combs",
    "Dog Shampoos & Bath Accessories ",
    "Dog Bowls & Diners",
    "Dog Beds",
    "Fish",
    "Decorations",
    "Water pumps",
    "Aquariums",
    "Filters & Accessories",
    "Air Pumps & Accessories",
    "Temperature control supplies",
    "Food",
  ],
  "Health & Beauty": [
    "Makeup",
    "Makeup Accessories",
    "Lips",
    "Nails",
    "Face",
    "Eyes",
    "Makeup Removers",
    "Makeup Palettes & Sets",
    "Bulk Deals",
    "Beauty Tools",
    "Skin Care Tools",
    "Hair Removal Appliances",
    "Slimming & Electric Massagers",
    "Hair Styling Appliances",
    "Foot Relief Tools",
    "Skin Care",
    "Moisturizers and Cream",
    "Facial Cleansers",
    "Serum & Essence",
    "Face Mask & Packs",
    "Sunscreen & Aftersun",
    "Lip Balm & Treatment",
    "Eye Care",
    "Face Scrubs & Exfoliators",
    "Toner & Mists",
    "Hair Care",
    "Shampoo & Conditioner",
    "Hair Care Accessories",
    "Hair Treatments",
    "Oil & Serums",
    "Hair Coloring",
    " Hair Styling",
    "Body Soaps & Shower Gels",
    "Hair Removal",
    "Body & Massage Oils",
    "Bath & Body Accessories",
    "Foot Care",
    "Hand Care",
    "Bath & Body",
    "Hair Care",
    "Shaving & Grooming",
    "Personal Care",
    "Pads & Tampons",
    "Menstrual Cups",
    "Oral Care",
    "Eye Care",
    "Deodorants",
    "Adult Diapers",
    "Personal Safety & Security",
    " Ear Care",
    "Fragrances for Women",
    "Fragrances for Men",
    "Fragrances for Unisex",
    "Sexual Wellness",
    "Condoms",
    "Lubricants",
    "Medical Supplies",
    "Health Monitors & Tests",
    "Health Accessories",
    "First Aid Supplies",
    "Ointments and Creams",
    "Surgical Masks",
    "Nebulizer & Aspirators",
    "Stethoscopes",
  ],

  "Men's Fashion": [
    "T Shirts & Tanks",
    "V Neck",
    "Round Neck",
    "Graphic tees",
    "Top Rated",
    "Shirts & Polo",
    "Casual Shirts",
    "Formal Shirts",
    "Polos",
    "Pants & Jeans",
    "Chinos",
    "Cargo",
    "Jeans",
    "Shorts, Joggers & Sweats",
    "Shorts",
    "Joggers & Sweats",
    "Unstitched Fabric",
    "Kurtas & Shalwar Kameez",
    "Kurtas",
    "Shalwar",
    "Shawls",
    "Winter Clothing",
    "Jackets & Coats",
    "Hoodies & Sweatshirts",
    "Sweaters & Cardigans",
    "Inner Wear",
    "Briefs",
    "Trunk & Boxers",
    "Nightwear",
    "Vests",
    "Thermal",
    "Socks",
    "Shoes",
    "Slip-Ons & Loafers",
    "Flip Flops & Sandals",
    "Sneakers",
    "Formal Shoes",
    "Boots",
    "Khusa & Kolapuri",
    "Shoes Accessories",
    "Accessories",
    "Belts",
    "Sunglasses",
    "Hats & Caps",
    "Boy's Clothing",
    "T-Shirts & Shirts",
    "Pants & Jeans",
    "Shorts",
    "Kurtas & Shalwar Kameez",
    "Underwear & Socks",
    "Ties & Bows",
    "Shoes",
    "Sneakers",
    "Slip-Ons & Loafers",
    "School Shoes",
    "Flip Flops, Slides & Sandals",
    "Formal Shoes",
    "Boots ",
    "Sandals",
    "Boy's Accessories",
    "Belts",
    "Inner Wear",
    "Socks",
    "Hats & Caps",
  ],

  "Women's Fashion": [
    "Unstitched Fabric",
    "Branded Unstitched",
    "Sarees",
    "Kurtas & Shalwar Kameez",
    "Kurtis",
    " Shalwar Kameez",
    "Branded Pret",
    "Trousers & Palazzos",
    "Muslim Wear",
    "Dupattas, Stoles & Shawls",
    "Abayas & Hijabs",
    "Scarves",
    " Hair Accessories",
    "Tops",
    "Blouses & Shirts",
    "Tunics",
    " T-Shirts",
    "Bras",
    "Panties",
    "Lingerie Sets",
    "Beachwear and Bikinis",
    "Socks & Tights",
    "Sleepwear & Innerwear",
    "Nightwear",
    "Robe and Gown sets",
    "Tanks & Camisoles",
    "Shapewear",
    "Pants",
    "Leggings",
    "Jeans",
    "Shorts",
    "Jeggings",
    "Dresses & Skirts",
    "Ethnic Dresses",
    "Western Dresses",
    "Skirts",
    " Formal Wear",
    "Winter Clothing",
    "Jackets & Coats",
    "Hoodies & Sweatshirts",
    "Sweaters & Cardigans",
    "Shawls and Poncho's",
    "Shrugs",
    "Shoes",
    "Sandals",
    "Flat Shoes",
    "Heels",
    "Khussa & Kohlapuri",
    "Slides & Flip FLops",
    " Wedges",
    "Sneakers",
    "Boots",
    "Shoes Accessories",
    "Girls Clothing",
    "Dresses",
    "Tops",
    "Underwear & Sleepwear",
    "Bottoms",
    "Socks & Tights",
    "Jackets & Coats",
    "Swimsuits",
    "Hoodies",
    "Hair Accessories",
    "Hats & Caps",
    "Belts",
    "Gloves, Scarves & Cold Weather",
    "Girls Shoes",
    "Sneakers",
    "Flats & Slip - Ons",
    "Formal Shoes",
    "Sandals",
    "Flip Flops, Slides & Sandals",
    "School Shoes",
    "Boots",
  ],

  "Mother & Baby": [
    "Milk Formula & Baby Food",
    " Maternal",
    "Infant Milk (0-6 Months)",
    "Infant Milk (6-12 Months)",
    " Toddler Milk (1 - under 3 yrs)",
    "Growing-up Milk (3 yrs +)",
    "Baby & Toddler Foods",
    "Diapering & Potty",
    "Disposable diapers",
    "Diaper Bags",
    "Wipes & Holders",
    "Changing Tables, Pads & Kits",
    "Diapering Care",
    "Cloth Diapers & Accessories",
    "Potty Training",
    "Feeding",
    "Utensils",
    "Bottle-Feeding",
    "Breastfeeding",
    "Food Blenders",
    "Pacifiers & Teethers",
    "Baby & Toddler Foods",
    "Maternity Care",
    "Maternity Wear",
    "Maternity Accessories",
    "Nursing Covers",
    "Pregnancy Pillows",
    "Nipple Care",
    "Breast Shells",
    "Breast Pumps",
    "Baby Gear",
    "Kids Bag",
    "Swings, Jumpers & Bouncers",
    "Strollers",
    "Car Seats",
    "Walkers",
    "Backpacks & Carriers",
    "Harnesses & Leashes",
    "Baby Safety",
    "Baby Monitor",
    "Highchairs & Booster Seats",
    "Baby Sofas",
    "Nursery ",
    "Baby Furniture",
    "Mattresses & Bedding",
    "Storage & Organization",
    "Nursery Decor",
    "Baby Personal Care",
    "Skin Care",
    "Bathing Tubs & Seats",
    "Soaps, Cleansers & Bodywash",
    "Baby Groomings & Care Kits",
    "Grooming & Healthcare Kits",
    "Toothbrushes & Toothpaste",
    "Shampoo & Conditioners",
    "Washcloths & Towels",
    "Baby Bath Mats",
    "Clothing & Accessories",
    "Newborn",
    "New Born Unisex (0 - 6 mnths)",
    "New born bodysuits",
    "New born sets & Packs",
    "Accessories",
    "Boys (Under 3 Years)",
    "Girls (Under 3 Years)",
    "Baby & Toddler Toys",
    "Activity Gym & Playmats",
    "Bath Toys",
    "Building Blocks Toys",
    "Crib Toys & Attachments",
    "Early Development Toys",
    "Music & Sound",
    "Rattles",
    "Push & Pull Toys",
    "Remote Control & Vehicles",
    "Die-Cast Vehicles",
    "Drones & Accessories",
    "Play Trains & Railway Sets",
    "Play Vehicles",
    "RC Vehicles & Batteries",
    "Sports & Outdoor Play",
    "Inflatable Bouncers",
    "Swimming Pool & Water Toys",
    "Fidget Spinners",
    "Toys & Games",
    "Arts & Crafts for Kids",
    "Action Figures & Collectibles",
    "Blocks & Building toys",
    "Dolls & Accessories",
    "Learning & Education",
    "Puzzle & Boardgames",
    "Slime & Squishy Toys",
    "Stuffed Toys",
    "Pretend Play",
  ],

  " Home & Lifestyle": [
    "Bath Mats",
    "Bath Towels",
    "Bathrobes",
    "Bathroom Scales",
    "Bathroom Shelving",
    "Shower Caddies & Hangers",
    "Shower Curtains",
    "Towel Rails & Warmers",
    "Bed Sheets",
    "Bedding Accessories",
    "Blankets & Throws",
    "Comforters, Quilts & Duvets",
    "Mattress Protectors",
    "Pillow Cases",
    "Pillows & Bolsters",
    "Artificial Flowers & Plants",
    "Candles & Candleholders",
    "Clocks",
    "Curtains",
    "Cushions & Covers",
    "Mirrors",
    "Picture Frames",
    "Rugs & Carpets",
    "Vases & Vessels",
    "Wall Stickers & Decals",
    "Bedroom",
    "Gaming Furniture",
    "Home Office",
    "Kitchen Furniture",
    "Living Room",
    "Bakeware",
    "Coffee & Tea",
    "Cookware",
    "Dinnerware",
    "Drinkware",
    "Kitchen & Table Linen",
    "Kitchen Storage & Accessories",
    "Kitchen Utensils",
    "Knives & Accessories",
    "Serve ware",
    "Ceiling Lights",
    "Floor Lamps",
    "Lamp Shades",
    "Light Bulbs",
    "Lighting Fixtures & Components",
    "Outdoor Lighting",
    "Rechargeables & Flashlights",
    "Table Lamps",
    "Laundry & Cleaning",
    "Brushes, Sponges & Wipers",
    "Brooms, Mops & Sweepers",
    "Laundry Baskets & Hampers",
    "Clothes Line & Drying Racks",
    "Ironing Boards",
    "Fixtures & Plumbing",
    " Electrical",
    "Hand Tools",
    "Lawn & Garden",
    "Power Tools",
    "Security",
    "Home Build Up",
    "Primers",
    "Paints",
    "Art Supplies",
    "Gifts & Wrapping",
    "Packaging & Cartons",
    "Paper Products",
    "School & Office Equipment",
    "Writing & Correction",
    "School Uniforms",
    "Books",
    " English Books",
    "Islamic Books",
    "English Literature Books",
    " English Story Books",
    "History Books",
    "eBooks",
    "Magazines",
    "Musical Instruments",
  ],

  "Electronic Devices": [
    "Apple iPhones",
    "Xiaomi Mi Mobiles",
    "Nokia Mobiles",
    "Redmi Mobiles",
    "Realme Mobiles",
    "Oppo Mobile Phones",
    "Oneplus Mobiles",
    "Infinix Mobiles",
    "Honor Mobiles",
    "Tecno Mobiles",
    "Samsung Mobile Phones",
    "Vivo Mobiles",
    "Feature Phones",
    "Tablets",
    "Monitors",
    "Laptops",
    "Refurbished Laptops",
    "Traditional Laptops",
    "Like New Laptops",
    "Desktops",
    "Smart Watches",
    "PlayStation Consoles",
    " Playstation Games",
    "Playstation Controllers",
    "Nintendo Games",
    "Xbox Games",
    "Video Camera",
    "DSLR",
    "Drones",
    "Point & Shoot",
    "Instant Cameras",
    "Security Cameras",
    "Landline Phones",
  ],

  "Electronic Accessories": [
    "Phone Cases",
    "Power Banks",
    "Cables & Converters",
    " Wall Chargers",
    "Wireless Chargers",
    "Tablet Accessories",
    "Car Chargers",
    "Screen Protectors",
    "Phone Camera Flash Lights",
    "Selfie Sticks",
    "Docks & Stands",
    "Car Mounts",
    "Headphones & Headsets",
    "Bluetooth Headsets",
    "Headphones & Headsets Access.",
    "Mono Headsets",
    "Over-The-Ear Headphones",
    "Wired Headsets",
    "Wireless Earbuds",
    "In-Ear Headphones",
    "Wearable",
    "Smartwatches",
    "Fitness & Activity Trackers",
    "Fitness Tracker Accessories",
    "Virtual Reality",
    "Camera Accessories",
    "Memory Cards",
    "Lenses",
    "Tripods & Monopods",
    "Camera Cases, Covers and Bags",
    "Action Camera Accessories",
    "Gimbals & Stabilizers",
    "Lighting & Studio Equipment",
    "Batteries",
    "Computer Accessories",
    "Cooling Pads/Cooling Stands",
    "Mouse",
    "Adapters & Cables",
    "PC Audio",
    "Mac Accessories",
    "Keyboards",
    "Storage",
    "External Hard Drives",
    "Internal Hard Drives",
    "Flash Drives",
    "Storage for Mac",
    "OTG Drives",
    "Printers",
    " Printers",
    "Ink & Toners",
    "Fax Machines",
    "Computer Components",
    "Graphic Cards",
    "Desktop Casings",
    "Motherboards",
    "Fans & Heatsinks",
    "Processors",
    "Portable Speakers",
    "Network Components",
    " Access Points",
    "Gaming Accessories",
    "Gaming Mouse",
    "Gaming Keyboards",
    "Gaming Headsets",
    "Monitors & Accessories",
  ],

  "TV & Home Appliances": [
    "Air Conditioner",
    "Televisions",
    "Smart Televisions",
    "LED Televisions",
    "Refrigerators & Freezers",
    "Refrigerators",
    " Freezers",
    "Home Audio & Theater",
    "Home Entertainment",
    "Live Sound & Stage Equipment",
    "Home Theater Systems",
    "Soundbar Speakers",
    "Washing Machine",
    "Kitchen Appliances",
    "Microwave",
    "Oven",
    "Air & Deep Fryers",
    "Blender, Mixer & Grinder",
    "Juicer & Fruit Extraction",
    "Water Dispensers",
    "Electric Kettle",
    "Dishwashers",
    "Cooktop & Range",
    "Rice Cooker",
    "Pressure Cookers",
    "Specialty Cookware",
    "Cooling & Heating",
    " Air Cooler",
    " Fan",
    " Air Purifier",
    " Dehumidifier",
    " Humidifier",
    "Water Heater",
    " Room Heater",
    "Irons & Garment Care",
    " Irons",
    " Garment Steamer",
    "Sewing Machine",
    "Generator, UPS & Solar",
    "Generators",
    "UPS",
    "Solar Inverters",
    "Projectors & Players",
    "Blu Ray & DVD Players",
    "Projectors",
    "TV Accessories",
    "TV Receivers",
    "Wall Mounts & Protectors",
    "TV Adapters",
    "TV Remote Controllers",
    "3D Glasses",
    "Antennas",
    "Cables",
    "Vacuums & Floor Care",
    "Vacuum Cleaner",
    "Floor Polisher",
    "Steam Mops",
    "Electric Brooms",
  ],

  "Sports & Outdoor": [
    "Exercise & Fitness",
    "Cardio Training Equipment",
    "Strength Training Equipments",
    "Treadmills",
    "Fitness Accessories",
    "Boxing, Martial Arts & MMA",
    "Weight",
    "Yoga",
    "Exercise Bands",
    "Exercise Bikes",
    "Supplements",
    "Proteins",
    "Post Workouts and Recovery",
    "Pre Workouts",
    "Fat Burners",
    "Post Workouts and recovery",
    "Shoes & Clothing",
    "Mens Shoes",
    "Mens Clothing",
    "Womens Shoes",
    "Womens Clothing",
    "Men Accessories",
    "Men Bags",
    "Women Accessories",
    "Women Bags",
    "Team Sports",
    "Cricket",
    "Football",
    "Hockey",
    "Basket Ball",
    "Volley balls",
    "Baseballs",
    "Racket Sports",
    "Rackets",
    "Shuttlecocks",
    "Balls",
    "Bats",
    "Nets",
    "Grips & Tapes",
    "Balls",
    "Outdoor Recreation",
    "Cycling",
    "Cycle accessories",
    "Camping / Hiking",
    "Other activities",
    "Water Sports",
    "Fishing",
    "Skate Boards",
    "Fitness Gadgets",
    "Fitness Trackers",
    "Fitness Trackers Accessories",
    "Sports Accessories",
    "Water Bottles",
    "Home Gym",
  ],

  "Watches, Bags & Jewellery": [
    "Men's Watches",
    "Chronograph-Men's Watches",
    "Branded-Men's Watches",
    "Analog-Men's Watches",
    "Digital-Men's Watches",
    "Accessories-Men's Watches",
    "Smart Watches-Men's Watches",
    "Women's Watches",
    "Chronograph-Women's Watches",
    "Branded-Women's Watches",
    "Analog-Women's Watches",
    "Digital-Women's Watches",
    "Accessories-Women's Watches",
    "Smart Watches-Women's Watches",
    "Kid's Watches",
    "Boys",
    "Girls",
    "Womens Bags",
    "Cross Body & Shoulder Bags",
    "Top-Handle Bags",
    "Tote Bags",
    "Backpacks",
    "Clutches",
    "Wallets & Accessories",
    "Wristlets",
    "Mens Bags",
    "Wallets & Cardholders",
    "Backpacks",
    "Crossbody Bags",
    "Business Bags",
    "Messenger Bags",
    "Cardholders & Keychains",
    "Luggage & Suitcase",
    "Travel Accessories",
    "Laptop Bags",
    "Luggage",
    "Duffel Bags",
    "Womens Jewellery",
    "Rings",
    "Earrings",
    "Necklaces",
    "Bracelets",
    "Anklets",
    "Jewellery Sets",
    "Mens Jewellery",
    "Rings",
    "Chains",
    "Bracelets",
    "Studs",
    "Pendants",
    "Mens Accessories",
    "Belts",
    "Hats & Caps",
    "Scarves",
    "Socks",
    "Ties & Bow Ties",
    "Gloves",
    "Women's Accessories",
    "Scarves",
    "Belts",
    "Socks & Tights",
    "Hats & Caps",
    "Gloves",
    "Hair Accessories",
    "Sunglasses & Eyewear",
    "Sunglasses",
    "Unisex Sunglasses",
    "Women Eyeglasses",
    "Unisex Eyeglasses",
    "Kids Sunglasses",
    "Women Sunglasses",
    "Men Eyeglasses",
    "Kids Eyeglasses",
    "Lenses",
  ],

  "Automotive & Motorbikes": [
    "Automotive",
    "Car Tires & Wheels",
    "Car Oils & Fluids",
    "Car Interior Accessories",
    "Car Tools & Equipment",
    "Car Parts & Spares",
    "Car Care",
    "Car Exterior Accessories",
    "Car Electronics",
    "Car Safety & Security",
    "Motorcycle",
    "Electric Bikes",
    "Standard Bikes",
    "ATVs & UTVs",
    "Sports Bikes",
    "Riding Gear",
    "Motorcycle Helmets",
    "Motorcycle Tires & Wheels",
    "Motorcycle Parts & Spares",
    "Motorcycle Oil & Fluids",
    "Motorcycle Accessories",
    "Loaders & Rickshaw",
    "Loaders",
    "Auto Rikshaw",
  ],
};

const names = Object.keys(categoryList);

const SettingComp = () => {
  const location = useLocation();

  const [value, setValue] = React.useState(location?.state || "profile");

  const [loading, setLoading] = useState(false);
  const [isProfileEdited, setIsProfileEdited] = useState(false);
  const [isStoreEdited, setIsStoreEdited] = useState(false);

  const [leopardCities, setLeopardCities] = useState([]);
  const [leopardBanks, setLeopardBanks] = useState([]);

  const [selectedCode, setSelectedCode] = useState(
    JSON.stringify(countries[0])
  );

  //-----useContext for getting user data------
  const { userData, getUserData } = useContext(AuthContext);

  // initialize selectedCategories with user data
  // const [selectedCategories, setSelectedCategories] = useState(user.categories);

  const [porductCategoryVal, setProductCategoryVal] = useState("");

  const [edit, setEdit] = useState({
    isEdit: false,
    position: 0,
  });

  const [catModal, setCatModal] = useState(false);

  // ----states for setting request to server-----
  const [settingObj, setSettingObj] = useState({
    name: userData?.user?.name,
    email: userData?.user?.email,
    business_name: userData?.user?.store?.store_name,
    cnic: userData?.user?.cnic,
    ntn_no: userData?.user?.ntn_no,
    shop_url: userData?.user?.store?.shop_url,
    date_of_birth: userData?.user?.date_of_birth,
    address: userData?.user?.address,
    image: userData?.user?.image,
    city: userData?.user?.city,
    bankAccount: userData?.user?.bank_account,
    // password: userData?.user?.password,
  });

  const [storeObj, setStoreObj] = useState({
    minDays: userData?.user?.store?.delivery?.min_days,
    maxDays: userData?.user?.store?.delivery?.max_days,
    freeDeliveryAmount: userData?.user?.store?.delivery?.free_delivery_amount,
    deliveryCharges: userData?.user?.store?.delivery?.delivery_charges,
    deliveryStatus: userData?.user?.store?.delivery?.delivery_status,
    porductCategory: userData?.user?.store?.product_category || [],
    termNCondition: userData?.user?.store?.term_conditions,
    returnPolicy: userData?.user?.store?.return_policy,
    shopLogo: userData?.user?.store?.shop_logo,
    phoneNumber: userData?.user?.store?.phone_number,
    categories: userData?.user?.store?.shop_category || [],
    shopName: userData?.user?.store?.store_name,
    onWhatsapp: userData?.user?.store?.on_Whatsapp,
  });

  const [shiperObj, setShiperObj] = useState({
    shipment_name: userData?.user?.store?.store_shipper?.shipment_name,
    shipment_email: userData?.user?.store?.store_shipper?.shipment_email,
    shipment_phone: userData?.user?.store?.store_shipper?.shipment_phone,
    shipment_address: userData?.user?.store?.store_shipper?.shipment_address,
    city: userData?.user?.store?.store_shipper?.leopard_city,
    cnic: userData?.user?.store?.store_shipper?.cnic,
    return_address: userData?.user?.store?.store_shipper?.return_address,
  });

  const [bankObj, setBankObj] = useState({
    bank: userData?.user?.store?.store_shipper?.leopard_bank,
    bank_account_no: userData?.user?.store?.store_shipper?.bank_account_no,
    bank_account_title:
      userData?.user?.store?.store_shipper?.bank_account_title,
    bank_branch: userData?.user?.store?.store_shipper?.bank_branch,
    bank_account_iban_no:
      userData?.user?.store?.store_shipper?.bank_account_iban_no,
  });

  // const handleCategoryChange = (event) => {
  //   setSelectedCategories(event.target.value);
  //   setSettingObj({ ...settingObj, categories: event.target.value });
  // };

  // const handleCodeChange = (event) => {
  //   setSelectedCode(event.target.value);
  // };

  // const handleUpdate = async () => {
  //   try {
  //     setLoading(true);
  //     const resp = await updateBusinessUser(settingObj);
  //     if (resp.status === 200) {
  //       fetchData();
  //       toast.success("Setting updated");
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error("Something went wrong. Try again.");
  //   }
  // };

  const handleUpdateStore = async () => {
    try {
      setLoading(true);

      let storeSettings = {
        _id: userData?.user?.store?._id,
        shop_logo: storeObj.shopLogo,
        phone_number: storeObj.phoneNumber,
        shop_category: storeObj.categories,
        delivery: {
          min_days: storeObj.minDays,
          max_days: storeObj.maxDays,
          free_delivery_amount: storeObj.freeDeliveryAmount,
          delivery_charges: storeObj.deliveryCharges,
          delivery_status: storeObj.deliveryStatus,
        },
        product_category: storeObj.porductCategory,
        term_conditions: storeObj.termNCondition,
        return_policy: storeObj.returnPolicy,
      };

      const resp = await updateStore(storeSettings);

      if (resp.status === 200) {
        getUserData();
        toast.success("Store settings have been successfully updated");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update store settings. Please try again later.");
    }
  };

  const getLeopardCities = async () => {
    // const citiesData = await getAllCities();
    setLeopardCities(cities);
  };

  const getLeopardBanks = async () => {
    const bankData = await getAllBanks();
    setLeopardBanks(bankData?.data?.banks);
  };

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(typeof value === "string" ? value.split(",") : value);
  //   // setRegisterObj({
  //   //   ...registerObj,
  //   //   categories: typeof value === "string" ? value.split(",") : value,
  //   // });
  // };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setEdit({
      ...edit,
      isEdit: false,
    });
    setProductCategoryVal("");
    setCatModal(false);
  };

  const handleAdd = () => {
    if (
      !storeObj?.porductCategory?.find(
        (cat) =>
          cat.toLocaleLowerCase() === porductCategoryVal.toLocaleLowerCase()
      ) &&
      porductCategoryVal !== ""
    ) {
      setStoreObj({
        ...storeObj,
        porductCategory: [...storeObj?.porductCategory, porductCategoryVal],
      });
      setProductCategoryVal("");
    }
  };

  const handleRemove = (categry) => {
    setStoreObj({
      ...storeObj,
      porductCategory: storeObj.porductCategory.filter(
        (cat) => cat !== categry
      ),
    });
  };

  const handleEdit = (category, position) => {
    setProductCategoryVal(category);
    setEdit({
      isEdit: true,
      position,
    });
    setCatModal(true);
  };

  const handleSaveEdit = () => {
    let cpyArray = JSON.parse(JSON.stringify(storeObj.porductCategory));
    cpyArray[edit.position] = porductCategoryVal;
    setStoreObj({
      ...storeObj,
      porductCategory: cpyArray,
    });
    handleClose();
  };

  const handleCodeChange = (e) => {
    setIsProfileEdited(true);

    setStoreObj({
      ...storeObj,
      phoneNumber: e.target.value + "-" + storeObj?.phoneNumber?.split("-")[1],
    });
  };

  const handleImageChange = (event) => {
    setIsProfileEdited(true);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setSettingObj({
        ...settingObj,
        image: reader.result,
      });
    };
  };

  const handleShopImageChange = (event) => {
    setIsStoreEdited(true);

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setStoreObj({
        ...storeObj,
        shopLogo: reader.result,
      });
    };
  };

  const handleUpdateProfile = async () => {
    try {
      setLoading(true);
      let profile = {
        name: settingObj.name,
        email: settingObj.email,
        cnic: settingObj.cnic,
        date_of_birth: settingObj.date_of_birth,
        ntn_no: settingObj.ntn_no,
        address: settingObj.address,
        city: settingObj.city,
        bank_account: settingObj.bankAccount,
        image: settingObj.image,
        phoneNumber: storeObj.phoneNumber,
        on_Whatsapp: storeObj.onWhatsapp,
        store_id: userData?.user?.store?._id,
      };

      if (!validateName(profile.name)) {
        return toast.error("Please enter a valid name");
      }

      if (!validateEmail(profile.email)) {
        return toast.error("Please enter a valid email address");
      }

      // if (!validateCNIC(profile.cnic)) {
      //   return toast.error("Error: CNIC must be valid");
      // }

      // if (!validateNTNNo(profile.ntn_no)) {
      //   return toast.error("Error: CNIC must be valid");
      // }

      // if (!validateDateOfBirth(profile.date_of_birth)) {
      //   return toast.error("Error: CNIC must be valid");
      // }

      const resp = await updateProfile(profile);
      if (resp.status === 200) {
        getUserData();
        toast.success("Your settings have been successfully updated");
      }
      setLoading(false);

      setIsProfileEdited(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update profile. Please try again later.");
    }
  };

  const validateShipper = () => {
    if (
      !shiperObj.shipment_address ||
      !shiperObj.shipment_email ||
      !shiperObj.shipment_name ||
      !shiperObj.shipment_phone ||
      !shiperObj.city.name ||
      !shiperObj.cnic ||
      !shiperObj.return_address
    ) {
      toast.error("Please make sure to fill in all the required fields.");
      return false;
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    if (!emailRegex.test(shiperObj.shipment_email)) {
      toast.error("Please enter a valid email address.");
      return false; // Validation failed
    }

    // Validate phoneNumber as a proper Pakistani number format
    const phoneNumberRegex = /^(\+92|92|0)?3\d{9}$/;
    if (!phoneNumberRegex.test(shiperObj.shipment_phone)) {
      toast.error("Please enter a valid phone number.");
      return false; // Validation failed
    }
    return true;
  };

  const validateBank = () => {
    if (
      !bankObj.bank.bank_name_eng ||
      !bankObj.bank_account_no ||
      !bankObj.bank_account_title ||
      !bankObj.bank_branch ||
      !bankObj.bank_account_iban_no
    ) {
      toast.error("Please fill in all the required fields.");
      return false;
    }

    return true;
  };

  const handleAddShipper = async () => {
    try {
      setLoading(true);

      if (!validateShipper()) {
        return;
      }

      shiperObj.store = userData?.user?.store?._id;

      const resp = await addShiper(shiperObj);

      if (resp?.data?.status === 400) {
        getUserData();
        toast.error("The shipper already exists.");
      }

      if (resp?.status === 200) {
        getUserData();
        toast.success("Shipper successfully added.");
      }

      setIsProfileEdited(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to add shipper. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddShipperBankInfo = async () => {
    try {
      setLoading(true);

      if (!validateBank()) {
        return;
      }

      bankObj.store = userData?.user?.store?._id;

      const resp = await updateShipperBankInfo(bankObj);

      if (resp?.status === 400) {
        getUserData();
        toast.error("Invalid bank information. Please check your bank details and try again.");
      }

      if (resp?.status === 200) {
        getUserData();
        toast.success("Bank details have been successfully added.");
      }

      setLoading(false);

      setIsProfileEdited(false);
    } catch (error) {
      toast.error("Failed to add bank details. Please check your bank information and try again.");
    } finally {
      setLoading(false);
    }
  };

  function validateName(name) {
    // Example: Name should contain at least 2 characters
    return name.length >= 2;
  }

  function validateEmail(email) {
    // Example: Use regular expression to validate email format
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }

  function validateCNIC(cnic) {
    // Example: CNIC should be a 13-digit number
    const cnicRegex = /^\d{13}$/;
    return cnicRegex.test(cnic);
  }

  function validateDateOfBirth(dateOfBirth) {
    // Example: Validate date format or age restrictions
    // You can use libraries like Moment.js for more advanced date validation
    // Here, we assume dateOfBirth is a string in the format "YYYY-MM-DD"
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(dateOfBirth)) {
      return false;
    }

    const currentDate = new Date();
    const inputDate = new Date(dateOfBirth);
    const ageInMilliseconds = currentDate - inputDate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

    // Example: Minimum age requirement of 18 years
    return ageInYears >= 18;
  }

  function validateNTNNo(ntnNo) {
    const ntnRegex = /^\d{7}$/;
    return ntnRegex.test(ntnNo);
  }

  const handleDateChange = (e) => {
    setIsProfileEdited(true);

    setSettingObj({
      ...settingObj,
      date_of_birth: e,
    });
  };

  // useEffect(() => {
  //   setSettingObj({
  //     name: userData?.user?.name,
  //     email: userData?.user?.email,
  //     business_name: userData?.user?.store?.store_name,
  //     cnic: userData?.user?.cnic,
  //     ntn_no: userData?.user?.ntn_no,
  //     shop_url: userData?.user?.store?.shop_url,
  //     phone_number: userData?.user?.store?.phone_number,
  //     date_of_birth: userData?.user?.date_of_birth,
  //     categories: userData?.user?.store?.shop_category || [],
  //     address: userData?.user?.address,
  //   });
  // }, [userData]);

  useEffect(() => {
    getLeopardCities();
    getLeopardBanks();
  }, []);

  return (
    <Box
      sx={{
        // overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: "1px solid #00000029",
            pb: "10px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            indicatorColor=""
            sx={{ px: "0px" }}
          >
            <Tab
              label={"Profile"}
              value="profile"
              sx={{
                color: "#707070",
                borderRadius: "10px",
                textTransform: "none",
                p: { xs: "0px 2px", sm: "0px 20px" },
                m: { xs: "0px" },
                "&.Mui-selected": { backgroundColor: "#272727", color: "#fff" },
              }}
            />
            <Tab
              label="Store"
              value="store"
              sx={{
                p: { xs: "0px 2px", sm: "0px 20px" },
                m: { xs: "0px" },
                textTransform: "none",
                color: "#707070",
                borderRadius: "10px",
                "&.Mui-selected": { backgroundColor: "#272727", color: "#fff" },
              }}
            />
            <Tab
              label="Shipping"
              value="ship"
              sx={{
                p: { xs: "0px 2px", sm: "0px 20px" },
                m: { xs: "0px" },
                textTransform: "none",
                color: "#707070",
                borderRadius: "10px",
                "&.Mui-selected": { backgroundColor: "#272727", color: "#fff" },
              }}
            />
            <Tab
              label="Bank"
              value="bank"
              sx={{
                p: { xs: "0px 2px", sm: "0px 20px" },
                m: { xs: "0px" },
                textTransform: "none",
                color: "#707070",
                borderRadius: "10px",
                "&.Mui-selected": { backgroundColor: "#272727", color: "#fff" },
              }}
            />
          </TabList>
        </Box>

        <Box
          sx={{ height: "100% ", overflowY: "auto" }}
          className="scrollable-content"
        >
          <TabPanel value="profile" sx={{ p: "0", py: "25px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // p: "25px",
                // background: "#F1F1F1",
                borderRadius: "15px",
                mb: "15px",
                gap: 1,
                position: "relative",
              }}
            >
              <Container
                maxWidth="xlPlus"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box sx={{ position: "relative", height: "100px" }}>
                  <Typography sx={{ fontWeight: "500" }}>
                    Personal Details
                  </Typography>

                  <Box sx={{ mt: 1 }}>
                    <Box
                      sx={{
                        width: "100px",
                        height: "100px",
                        mb: "15px",
                        // ml: "auto",
                        position: "relative",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        id="file-input2"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                      />
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                          // border: "2px solid #707070",
                          boxShadow: "0px 3px 6px #00000054",
                          positon: "relative",
                          fontSize: "3rem",
                          borderRadius: "20px",
                        }}
                      >
                        {settingObj.image ? (
                          <img
                            src={settingObj.image}
                            alt="Selected"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <>{userData?.user?.name[0]}</>
                        )}
                      </Avatar>

                      <Button
                        onClick={() => {
                          const fileInput =
                            document.getElementById("file-input2");
                          fileInput.click();
                        }}
                        sx={{
                          position: "absolute",
                          right: "-10px",
                          bottom: "-5px",
                          background: "#fff",
                          color: "#264D59",
                          borderRadius: "50%",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                          minWidth: "fit-content",
                          padding: "5px",
                          ":hover": {
                            color: "#fff",
                            background: "#264D59",
                          },
                        }}
                      >
                        <EditIcon sx={{ fontSize: "17px" }} />
                      </Button>

                      {settingObj.image && (
                        <Button
                          onClick={() =>
                            setSettingObj({ ...settingObj, image: "" })
                          }
                          sx={{
                            position: "absolute",
                            left: "-10px",
                            bottom: "-5px",
                            background: "#fff",
                            color: "red",
                            borderRadius: "50%",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                            minWidth: "fit-content",
                            padding: "5px",
                            ":hover": {
                              color: "#fff",
                              background: "red",
                            },
                          }}
                        >
                          <DeleteRoundedIcon sx={{ fontSize: "17px" }} />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "start",
                    // width: "80%",
                    // mx: "auto",
                    mt: "45px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      flexDirection: "column",
                      width: { xs: "100%", sm: "50%" },
                    }}
                  >
                    <TextField
                      size="small"
                      label="Name"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={settingObj.name}
                      onChange={(e) => {
                        setSettingObj({ ...settingObj, name: e.target.value });
                        setIsProfileEdited(true);
                      }}
                    />

                    <Tooltip title="Disabled">
                      <TextField
                        size="small"
                        label="Email"
                        sx={{
                          background: "#fff",
                          width: "100%",
                        }}
                        value={settingObj.email}
                        disabled
                        // onChange={(e) =>
                        //   setSettingObj({ ...settingObj, email: e.target.value })
                        // }
                      />
                    </Tooltip>

                    <TextField
                      size="small"
                      label="City"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={settingObj?.city}
                      onChange={(e) => {
                        setSettingObj({ ...settingObj, city: e.target.value });
                        setIsProfileEdited(true);
                      }}
                    />

                    <Tooltip title="Disabled">
                      <TextField
                        size="small"
                        label="Business Name"
                        sx={{
                          background: "#fff",
                          width: "100%",
                        }}
                        value={settingObj.business_name}
                        disabled
                        // onChange={(e) =>
                        //   setSettingObj({
                        //     ...settingObj,
                        //     business_name: e.target.value,
                        //   })
                        // }
                      />
                    </Tooltip>

                    <TextField
                      size="small"
                      label="CNIC"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={settingObj.cnic}
                      onChange={(e) => {
                        setSettingObj({ ...settingObj, cnic: e.target.value });
                        setIsProfileEdited(true);
                      }}
                    />

                    {/* <Box sx={{ position: "relative" }}>
                      <TextField
                        size="small"
                        label="Password"
                        sx={{
                          background: "#fff",
                          width: "100%",
                        }}
                        type={isVisible ? "text" : "password"}
                        value={settingObj?.password}
                        onChange={(e) =>
                          setSettingObj({
                            ...settingObj,
                            password: e.target.value,
                          })
                        }
                      />
                      <Button
                        sx={{
                          p: "0",
                          m: "0",
                          minWidth: "0",
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "gray",
                        }}
                        onClick={() => setIsVisible(!isVisible)}
                      >
                        {!isVisible ? (
                          <RemoveRedEyeRoundedIcon />
                        ) : (
                          <VisibilityOffRoundedIcon />
                        )}
                      </Button>
                    </Box> */}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      flexDirection: "column",
                      width: { xs: "100%", sm: "50%" },
                    }}
                  >
                    <Tooltip title="Disabled">
                      <TextField
                        size="small"
                        label="Domian"
                        sx={{
                          background: "#fff",
                          width: "100%",
                        }}
                        disabled
                        value={settingObj.shop_url}
                        // onChange={(e) =>
                        //   setSettingObj({ ...settingObj, shop_url: e.target.value })
                        // }
                      />
                    </Tooltip>

                    <TextField
                      size="small"
                      label="Address"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={settingObj?.address}
                      onChange={(e) => {
                        setSettingObj({
                          ...settingObj,
                          address: e.target.value,
                        });
                        setIsProfileEdited(true);
                      }}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{
                          label: { top: "0px", lineHeight: "14px" },
                          pt: "0",
                          overflow: "visible",
                        }}
                      >
                        <DatePicker
                          label="Date of Birth"
                          sx={{
                            background: "#fff",
                            width: "100%",
                            "div > input": { py: "9px" },
                          }}
                          value={dayjs(settingObj?.date_of_birth || "")}
                          onChange={handleDateChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    <TextField
                      size="small"
                      label="NTN No."
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={settingObj.ntn_no}
                      onChange={(e) => {
                        setSettingObj({
                          ...settingObj,
                          ntn_no: e.target.value,
                        });
                        setIsProfileEdited(true);
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "5px",
                        background: "#fff",
                        border: "1px solid gray",
                        height: "40px",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      <Select
                        labelId="phone-code-select-label"
                        id="phone-code-select"
                        value={storeObj?.phoneNumber?.split("-")[0] || ""}
                        onChange={handleCodeChange}
                        disableUnderline
                        label="Phone Code"
                        sx={{
                          width: "110px",
                          pl: "10px",
                          pt: "5px",
                        }}
                        variant="standard"
                        size="small"
                      >
                        {countries.map((c) => (
                          <MenuItem
                            key={c.code}
                            value={c.dial_code}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              className={`fi fi-${c.code.toLocaleLowerCase()}`}
                            ></span>{" "}
                            {c.dial_code}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        size="small"
                        label="Contact"
                        type={"number"}
                        placeholder="3456789100"
                        sx={{
                          width: "100%",
                        }}
                        InputProps={{ disableUnderline: true }}
                        variant="filled"
                        value={storeObj?.phoneNumber?.split("-")[1]}
                        onChange={(e) => {
                          setStoreObj({
                            ...storeObj,
                            phoneNumber:
                              storeObj?.phoneNumber?.split("-")[0] +
                              "-" +
                              e.target.value,
                          });
                          setIsProfileEdited(true);
                        }}
                      />
                    </Box>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={storeObj?.onWhatsapp} />}
                        label={`Is this ${storeObj?.phoneNumber} available on WhatsApp?`}
                        onChange={(e) => {
                          setStoreObj({
                            ...storeObj,
                            onWhatsapp: e.target.checked,
                          });
                          setIsProfileEdited(true);
                        }}
                      />
                    </FormGroup>
                  </Box>
                </Box>

                <Button
                  sx={{
                    background: "#FDC532",
                    ":hover": { background: "#FDC532" },
                    "&.Mui-disabled": {
                      background: "#707070",
                      color: "#fff",
                    },
                    color: "#fff",
                    borderRadius: "10px",
                    mr: 0,
                    width: "100px",
                    height: "35px",
                    px: "30px",
                    ml: "auto",
                  }}
                  onClick={handleUpdateProfile}
                  disabled={!isProfileEdited}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Container>
            </Box>
          </TabPanel>
          <TabPanel value="store" sx={{ p: "0", py: "25px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // p: "25px",
                // background: "#F1F1F1",
                borderRadius: "15px",
                mb: "15px",
                gap: 1,
              }}
            >
              <Container
                maxWidth="xlPlus"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box
                  sx={{
                    // border: "1px solid #ACACAC",
                    // borderRadius: "15px",
                    // p: "20px",
                    position: "relative",
                    height: "100px",
                  }}
                >
                  <Typography sx={{ fontWeight: "500" }}>Store Logo</Typography>
                  <Box sx={{ mt: 1 }}>
                    <Box
                      sx={{
                        width: "100px",
                        height: "100px",
                        mb: "15px",
                        // ml: "auto",
                        position: "relative",
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        id="file-input2"
                        style={{ display: "none" }}
                        onChange={handleShopImageChange}
                      />
                      <Avatar
                        sx={{
                          width: "100%",
                          height: "100%",
                          // border: "2px solid #707070",
                          boxShadow: "0px 3px 6px #00000054",
                          positon: "relative",
                          fontSize: "3rem",
                          borderRadius: "20px",
                        }}
                      >
                        {storeObj?.shopLogo ? (
                          <img
                            src={storeObj.shopLogo}
                            alt="Selected"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <>
                            {Array.isArray(storeObj?.shopName)
                              ? storeObj.shopName[0] || ""
                              : ""}
                          </>
                        )}
                      </Avatar>

                      <Button
                        onClick={() => {
                          const fileInput =
                            document.getElementById("file-input2");
                          fileInput.click();
                        }}
                        sx={{
                          position: "absolute",
                          right: "-10px",
                          bottom: "-5px",
                          background: "#fff",
                          color: "#264D59",
                          borderRadius: "50%",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                          minWidth: "fit-content",
                          padding: "5px",
                          ":hover": {
                            color: "#fff",
                            background: "#264D59",
                          },
                        }}
                      >
                        <EditIcon sx={{ fontSize: "17px" }} />
                      </Button>

                      {storeObj.shopLogo && (
                        <Button
                          onClick={() =>
                            setStoreObj({ ...storeObj, shopLogo: "" })
                          }
                          sx={{
                            position: "absolute",
                            left: "-10px",
                            bottom: "-5px",
                            background: "#fff",
                            color: "red",
                            borderRadius: "50%",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                            minWidth: "fit-content",
                            padding: "5px",
                            ":hover": {
                              color: "#fff",
                              background: "red",
                            },
                          }}
                        >
                          <DeleteRoundedIcon sx={{ fontSize: "17px" }} />
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    // border: "1px solid #ACACAC",
                    borderRadius: "15px",
                    // p: "20px",
                    mt: "45px",
                  }}
                >
                  <Typography sx={{ fontWeight: "500" }}>
                    Shipping Preferences
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      flexDirection: "column",
                      // width: "80%",
                      // mx: "auto",
                      mt: 1,
                    }}
                  >
                    {/* <Box>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="standard"
                          control={
                            <Radio
                              size="small"
                              checked={
                                storeObj.deliveryStatus === "standard"
                                  ? true
                                  : false
                              }
                            />
                          }
                          onChange={() =>
                            setStoreObj({
                              ...storeObj,
                              deliveryStatus: "standard",
                            })
                          }
                          label="Standard Delivery"
                        />
                        <FormControlLabel
                          value="free"
                          control={
                            <Radio
                              checked={
                                storeObj.deliveryStatus === "free"
                                  ? true
                                  : false
                              }
                              size="small"
                            />
                          }
                          onChange={() =>
                            setStoreObj({
                              ...storeObj,
                              deliveryStatus: "free",
                            })
                          }
                          label="Free Delivery"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box> */}

                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        mb: "10px",
                        width: "100%",
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "10px", mb: "5px" }}>
                          Delivery Charges
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <TextField
                            placeholder="0"
                            type="number"
                            id="outlined-size-small"
                            size="small"
                            sx={{ width: "100px", background: "#fff" }}
                            value={storeObj.deliveryCharges}
                            onChange={(e) => {
                              setStoreObj({
                                ...storeObj,
                                deliveryCharges: e.target.value,
                              });
                              setIsStoreEdited(true);
                            }}
                          />
                        </Box>
                      </Box>
                      {/* {storeObj.deliveryStatus === "standard" && (
                    )} */}

                      <Box>
                        <Typography sx={{ fontSize: "10px", mb: "5px" }}>
                          Minimum Order Amount for Free Delivery
                        </Typography>
                        <TextField
                          placeholder="0"
                          type="number"
                          id="outlined-size-small"
                          size="small"
                          sx={{ width: "100px", background: "#fff" }}
                          value={storeObj.freeDeliveryAmount}
                          onChange={(e) => {
                            setStoreObj({
                              ...storeObj,
                              freeDeliveryAmount: e.target.value,
                            });
                            setIsStoreEdited(true);
                          }}
                        />
                      </Box>
                      {/* {storeObj.deliveryStatus === "free" && (
                    )} */}
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "10px", mb: "5px" }}>
                        Days
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField
                          label="Min"
                          type="number"
                          id="outlined-size-small"
                          size="small"
                          sx={{ width: "100px", background: "#fff" }}
                          value={storeObj.minDays}
                          onChange={(e) => {
                            setStoreObj({
                              ...storeObj,
                              minDays: e.target.value,
                            });
                            setIsStoreEdited(true);
                          }}
                        />

                        <TextField
                          label="Max"
                          type="number"
                          id="outlined-size-small"
                          size="small"
                          sx={{ width: "100px", background: "#fff" }}
                          value={storeObj.maxDays}
                          onChange={(e) => {
                            setStoreObj({
                              ...storeObj,
                              maxDays: e.target.value,
                            });
                            setIsStoreEdited(true);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    // border: "1px solid #ACACAC",
                    borderRadius: "15px",
                    // p: "20px",
                  }}
                >
                  <Typography sx={{ fontWeight: "500", mb: "10px" }}>
                    Shop Category
                  </Typography>

                  <Box sx={{ display: "flex" }}>
                    <FormControl
                      sx={{
                        background: "#fff",
                        width: "100%",
                        // mx: "auto",
                      }}
                      variant="standard"
                      size="small"
                    >
                      <InputLabel
                        id="demo-multiple-chip-label"
                        sx={{ ml: "15px", mt: "-8px" }}
                      >
                        Select Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        multiple
                        label="Select Category"
                        value={storeObj.categories}
                        onChange={(event) => {
                          setStoreObj({
                            ...settingObj,
                            categories: event.target.value,
                          });
                          setIsStoreEdited(true);
                        }}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Select Category 1"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected?.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {names?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            // style={getStyles(name, selectedCategories, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  sx={{
                    // border: "1px solid #ACACAC",
                    borderRadius: "15px",
                    // p: "20px",
                  }}
                >
                  <Typography sx={{ fontWeight: "500" }}>
                    Terms & Conditions
                  </Typography>

                  <Box sx={{ mt: 1 }}>
                    <TextareaAutosize
                      minRows={7}
                      // placeholder="Minimum 3 rows"
                      style={{
                        width: "calc(100% - 32px)",
                        borderRadius: "5px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        resize: "vertical",
                      }}
                      value={storeObj.termNCondition}
                      onChange={(e) => {
                        setStoreObj({
                          ...storeObj,
                          termNCondition: e.target.value,
                        });
                        setIsStoreEdited(true);
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    // border: "1px solid #ACACAC",
                    borderRadius: "15px",
                    // p: "20px",
                  }}
                >
                  <Typography sx={{ fontWeight: "500" }}>
                    Return Policy
                  </Typography>

                  <Box sx={{ mt: 1 }}>
                    <TextareaAutosize
                      type="textarea"
                      minRows={7}
                      style={{
                        width: "calc(100% - 32px)",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        resize: "vertical",
                        borderRadius: "5px",
                      }}
                      value={storeObj.returnPolicy}
                      onChange={(e) => {
                        setStoreObj({
                          ...storeObj,
                          returnPolicy: e.target.value,
                        });
                        setIsStoreEdited(true);
                      }}
                    />
                  </Box>
                </Box>

                <Button
                  sx={{
                    background: "#FDC532",
                    ":hover": { background: "#FDC532" },
                    "&.Mui-disabled": {
                      background: "#707070",
                      color: "#fff",
                    },
                    color: "#fff",
                    borderRadius: "10px",
                    mr: 0,
                    width: "100px",
                    height: "35px",
                    px: "30px",
                    ml: "auto",
                  }}
                  onClick={handleUpdateStore}
                  disabled={!isStoreEdited}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Container>
            </Box>
          </TabPanel>
          <TabPanel value="ship" sx={{ p: "0", py: "25px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // p: "25px",
                // background: "#F1F1F1",
                borderRadius: "15px",
                mb: "15px",
                gap: 1,
                position: "relative",
              }}
            >
              <Container
                maxWidth="xlPlus"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Typography sx={{ fontWeight: "500" }}>
                  Basic Information{" "}
                  <i style={{ fontSize: "13px", fontWeight: "400" }}>
                    (Once you have added information, it becomes uneditable.)
                  </i>
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "start",
                    // width: "80%",
                    // mx: "auto",
                    // mt: "45px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      flexDirection: "column",
                      width: { xs: "100%", sm: "50%" },
                    }}
                  >
                    <Autocomplete
                      options={leopardCities}
                      getOptionLabel={(option) => option.name}
                      sx={{
                        width: "100%",
                      }}
                      size="small"
                      value={shiperObj?.city}
                      onChange={(e, newVal) => {
                        setShiperObj({ ...shiperObj, city: newVal });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="City" />
                      )}
                    />

                    <TextField
                      size="small"
                      label="Shipper Name"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={shiperObj.shipment_name}
                      onChange={(e) => {
                        setShiperObj({
                          ...shiperObj,
                          shipment_name: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      size="small"
                      label="Email"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={shiperObj.shipment_email}
                      onChange={(e) => {
                        setShiperObj({
                          ...shiperObj,
                          shipment_email: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      size="small"
                      label="Phone"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={shiperObj.shipment_phone}
                      onChange={(e) => {
                        setShiperObj({
                          ...shiperObj,
                          shipment_phone: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      size="small"
                      label="CNIC"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={shiperObj.cnic}
                      onChange={(e) => {
                        setShiperObj({ ...shiperObj, cnic: e.target.value });
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      flexDirection: "column",
                      width: { xs: "100%", sm: "50%" },
                      height: "100%",
                    }}
                  >
                    <textarea
                      placeholder="Shipper Address (Character Limit 255)"
                      id="outlined-size-small"
                      size="small"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        outline: "none",
                        resize: "vertical",
                        borderRadius: "5px",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        paddingTop: "8.5px",
                        paddingBottom: "8.5px",
                        width: "calc(100% - 28px)",
                      }}
                      value={shiperObj.shipment_address}
                      onChange={(e) => {
                        setShiperObj({
                          ...shiperObj,
                          shipment_address: e.target.value,
                        });
                      }}
                      required
                    />

                    <textarea
                      placeholder="Return Address (Character Limit 255)"
                      id="outlined-size-small"
                      size="small"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        outline: "none",
                        resize: "vertical",
                        borderRadius: "5px",
                        paddingLeft: "14px",
                        paddingRight: "14px",
                        paddingTop: "8.5px",
                        paddingBottom: "8.5px",
                        width: "calc(100% - 28px)",
                      }}
                      value={shiperObj.return_address}
                      onChange={(e) => {
                        setShiperObj({
                          ...shiperObj,
                          return_address: e.target.value,
                        });
                      }}
                      required
                    />
                  </Box>
                </Box>

                <Button
                  sx={{
                    background: "#FDC532",
                    ":hover": { background: "#FDC532" },
                    "&.Mui-disabled": {
                      background: "#707070",
                      color: "#fff",
                    },
                    color: "#fff",
                    borderRadius: "10px",
                    mr: 0,
                    width: "100px",
                    height: "35px",
                    px: "30px",
                    ml: "auto",
                  }}
                  onClick={handleAddShipper}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Container>
            </Box>
          </TabPanel>
          <TabPanel value="bank" sx={{ p: "0", py: "25px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // p: "25px",
                // background: "#F1F1F1",
                borderRadius: "15px",
                mb: "15px",
                gap: 1,
                position: "relative",
              }}
            >
              <Container
                maxWidth="xlPlus"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Typography sx={{ fontWeight: "500" }}>
                  Bank Information
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      flexDirection: "column",
                      width: { xs: "100%", sm: "50%" },
                    }}
                  >
                    <Autocomplete
                      options={leopardBanks}
                      getOptionLabel={(option) => option.bank_name_eng}
                      sx={{
                        width: "100%",
                      }}
                      size="small"
                      value={bankObj?.bank}
                      onChange={(e, newVal) => {
                        setBankObj({ ...bankObj, bank: newVal });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Bank" />
                      )}
                    />

                    <TextField
                      size="small"
                      label="Bank Account IBAN"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={bankObj?.bank_account_iban_no}
                      onChange={(e) => {
                        setBankObj({
                          ...bankObj,
                          bank_account_iban_no: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      size="small"
                      label="Bank Account No"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={bankObj?.bank_account_no}
                      onChange={(e) => {
                        setBankObj({
                          ...bankObj,
                          bank_account_no: e.target.value,
                        });
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 4,
                      flexDirection: "column",
                      width: { xs: "100%", sm: "50%" },
                    }}
                  >
                    <TextField
                      size="small"
                      label="Bank Account Title"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={bankObj?.bank_account_title}
                      onChange={(e) => {
                        setBankObj({
                          ...bankObj,
                          bank_account_title: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      size="small"
                      label="Bank Branch"
                      sx={{
                        background: "#fff",
                        width: "100%",
                      }}
                      value={bankObj?.bank_branch}
                      onChange={(e) => {
                        setBankObj({
                          ...bankObj,
                          bank_branch: e.target.value,
                        });
                      }}
                    />
                  </Box>
                </Box>

                <Button
                  sx={{
                    background: "#FDC532",
                    ":hover": { background: "#FDC532" },
                    "&.Mui-disabled": {
                      background: "#707070",
                      color: "#fff",
                    },
                    color: "#fff",
                    borderRadius: "10px",
                    mr: 0,
                    width: "100px",
                    height: "35px",
                    px: "30px",
                    ml: "auto",
                  }}
                  onClick={handleAddShipperBankInfo}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Container>
            </Box>
          </TabPanel>
        </Box>
      </TabContext>

      <Typography sx={{ fontSize: "11px", mx: "auto", mt: "auto" }}>
        Uchlo <Link to={"https://uchlo.com/privacy"}>Privacy Policy</Link>
      </Typography>

      <Dialog
        open={catModal}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: "15px", width: { xs: "", md: "80%" } },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              background: "#fff",
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
      >
        <DialogContent
          sx={{
            position: "relative",
            padding: "25px",
          }}
        >
          <Box sx={{}}>
            <Typography sx={{ fontWeight: "500", mb: "10px" }}>
              {edit?.isEdit ? "Edit Category" : "Enter Category"}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
              <TextField
                size="small"
                placeholder="Category Name"
                sx={{ width: "70%" }}
                value={porductCategoryVal}
                onChange={(e) => setProductCategoryVal(e.target.value)}
              />
              {edit.isEdit ? (
                <Button
                  sx={{
                    background: "#272727",
                    ":hover": { background: "#272727" },
                    color: "#fff",
                    width: "30%",
                  }}
                  onClick={handleSaveEdit}
                >
                  Save
                </Button>
              ) : (
                <Button
                  sx={{
                    background: "#272727",
                    color: "#fff",
                    width: "30%",
                    ":hover": { background: "#272727" },
                  }}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              )}
            </Box>
          </Box>

          <Button
            style={{
              position: "absolute",
              color: "#272727",
              top: 12,
              right: 12,
              padding: "0",
              minWidth: "fit-content",
            }}
            onClick={handleClose}
          >
            <HighlightOffIcon />
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SettingComp;
