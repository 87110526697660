import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";

const Facebookpages = ({
  facebookDialog,
  setFacebookDialog,
  facebookPages,
  facebookPagging,
  handleFacebookPagging,
  handleConnectToFBPage,
  facebookLoading,
  handleRefreshFacebookPages,
}) => {
  // !--------------------------------- STATES HERE ---------------------------------!
  const [isHovered, setIsHovered] = useState(false);
  // !--------------------------------- FUNCTIONS HERE ---------------------------------!
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  // !--------------------------------- RETURN HERE ---------------------------------!

  return (
    <Box>
      <Dialog
        open={facebookDialog}
        onClose={setFacebookDialog}
        PaperProps={{ style: { borderRadius: "5px" } }}
        scroll={"body"}
      >
        <DialogContent
          sx={{
            background: "#fff",
            position: "relative",
            padding: { sm: "25px", xs: "10px" },
            overflow: "visible",
            py: "30px",
            px: { xs: "20px", sm: "50px" },
            width: { xs: "250px", sm: "400px" },
          }}
        >
          <Button
            style={{
              position: "absolute",
              color: isHovered ? "white" : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: "fit-content",
              borderRadius: "50%",
              transition: "background-color 0.3s ease-in-out",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
            }}
            onClick={setFacebookDialog}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: 2, sm: "6px" },
              color: "#272727",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: { xs: "25px", sm: 0 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "13px", sm: "18px" },
                  fontWeight: "500",
                }}
              >
                Choose a facebook page
              </Typography>

              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "#4D6FA9",
                  color: "#fff",
                  textTransform: "none",
                  py: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "none",
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "#4D6FA9",
                  },
                }}
                onClick={handleRefreshFacebookPages}
              >
                <CachedIcon
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    mr: { xs: 0, sm: 1 },
                  }}
                />
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block" },
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Refresh
                </Typography>
              </Button>
            </Box>
            <Typography
              sx={{
                fontSize: { xs: "11px", sm: "11px" },
                fontWeight: "400",
                color: "#272727",
                pb: "10px",
              }}
            >
              Choose a facebook page you would like to connect to Uchlo
            </Typography>

            {!facebookLoading
              ? facebookPages.map((page) => (
                  <Box
                    key={page.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "6px",
                      mb: "16px", 
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={page.page_profile_picture}
                        alt="profile"
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "600",
                          }}
                        >
                          {page.page_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          Facebook Page
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          page.is_connected === false &&
                            handleConnectToFBPage({
                              page_id: page.page_id,
                              type: "facebook",
                              status: true,
                            });
                        }}
                      >
                        {page.is_connected === true ? "Connected" : "Connect"}
                      </Typography>
                    </Box>
                  </Box>
                ))
              : [1, 2, 3, 4, 5, 6, 7].map((item) => (
                  <Box
                    key={item}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1px",
                      mb: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Skeleton variant="circular" width={40} height={40} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1px",
                        }}
                      >
                        <Skeleton variant="text" width={100} />
                        <Skeleton variant="text" width={80} />
                      </Box>
                    </Box>
                    <Skeleton variant="text" width={60} />
                  </Box>
                ))}
          </Box>

          {facebookPagging.totalPages > 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "20px",
              }}
            >
              <Pagination
                count={facebookPagging.totalPages}
                onChange={handleFacebookPagging}
                variant="outlined"
                color="primary"
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Facebookpages;
