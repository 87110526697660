import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AOS from "aos";
import { appRouter, shopRouter } from "./routes";
import ToastContainer from "./components/Toast";

import {
  FACEBOOK_APP_ID,
  FACEBOOK_SDK_URL,
  GOOGLE_CLIENT_ID,
} from "./utils/config";

import "aos/dist/aos.css";
import "./App.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const theme = createTheme({
  palette: {
    badgeColor: { main: "#ED1C24" },
    primary: {
      main: "#FFBD54",
    },
    secondary: {
      main: "#252525",
    },
    saas_button_bg: {
      main: "#253F4B",
      hover: "#35515f",
    },
    seprator_color: {
      main: "#3F3F3F33",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  paragraph: {
    fontFamily: "Poppins",
  },
  heading: {
    fontFamily: "Poppins",
  },
  breakpoints: {
    values: {
      xs: 0,
      // xss:200,
      xsPlus: 400,
      br500: 500,
      sm: 600,
      xsm: 700,
      md: 900,
      xmd: 1000,
      mdPlus: 1100,
      lg: 1200,
      xlg: 1400,
      xl: 1536,
      xlPlus: 2000,
    },
  },
});

AOS.init();

function App() {
  const getSubdomainFromURL = () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];
    return subdomain;
  };

  const getRouter = () => {
    const subdomain = getSubdomainFromURL();
    if (
      subdomain !== "www" &&
      subdomain !== "localhost" &&
      subdomain !== "uchlo" &&
      subdomain !== "lively"
    ) {
      return shopRouter;
    }

    return appRouter;
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = FACEBOOK_SDK_URL;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  // * disabled console.log, console.warn, console.error in production
  if (process.env.REACT_APP_MODE === "production") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }


  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={getRouter()} />
          <ToastContainer />
        </ThemeProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
