// FIXME: Find a better place for this.
//
// Technically this should be added in the existing theme object in src/App.js and there
// should be a way to access that theme object outside the React component function.

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    postStatusColor: {
      active: "#2EB43F",
      inactive: "#FF1E5A",
      draft: "#4DA2F8",
    },
    destinationIconColor: {
      facebook: "#4267B2",
      youtube: "#FF0000",
      instagram: "#C13584",
    },
  },
});

export default theme;
