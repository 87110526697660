import { toast } from "../components/Toast";
import axios from "./axios";

export const addProduct = async (data) => {
  try {
    return await axios.post(`/product/create-product`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      if (error?.response?.data?.message?.split(" ")[0] === "E11000") {
        toast.error("This product name is already taken. Please choose a different name.");
      } else {
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error("Oops! Something went wrong while adding the product. Please try again later.");
    }
  }
};

export const getAllProducts = async (page = 1, limit = 10) => {
  try {
    return await axios.get(`/product/fetch-products?page=${page}&pageSize=${limit}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while fetching the products. Please try again later.");
    }
  }
};
// get single product by id
export const getSingleProduct = async (id) => {
  try {
    return await axios.get(`/product/fetch-products/${id}`);
  } catch (error) {
    toast.error("Oops! Something went wrong while getting the product. Please try again later.");
  }
};

export const getAllActiveProducts = async (page = 1, limit = 10) => {
  try {
    return await axios.get(`/product/active?page=${page}&pageSize=${limit}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while fetching the products. Please try again later.");
    }
  }
};

export const fetchAllProductsByShopName = async (shopName, page, category) => {
  try {
    return await axios.get(
      `/product/listing?shop_name=${shopName}&page=${page}&category=${category}`
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while fetching the products. Please try again later.");
    }
  }
};

export const updateProducts = async (dataArr) => {
  try {
    return await axios.post(`/product/update-products`, { products: dataArr });
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while updating the products. Please try again later.");
    }
  }
};

export const verifyName = async (name) => {
  try {
    return await axios.post(`/product/verifyProduct`, { product_name: name });
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while verifying the product name. Please try again later.");
    }
  }
};

export const deleteProducts = async (prodArr) => {
  try {
    return await axios.post(`/product/deleteProducts`, { products: prodArr });
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while deleting the products. Please try again later.");
    }
  }
};
// handle deleteVariant
export const deleteVariant = async (id) => {
  try {
    return await axios.delete(`/product/delete-varient/${id}`);
  } catch (error) {
    toast.error("Oops! Something went wrong while deleting the variant. Please try again later.");
  }
};
// deleteVariantOptionApi
export const deleteVariantOptionApi = async (id) => {
  try {
    return await axios.delete(`/product/delete-varient-option/${id}`);
  } catch (error) {
    toast.error("Oops! Something went wrong while deleting the variant. Please try again later.");
  }
};
// deleteGroupsOption
export const deleteGroupsOption = async (productid, optionid) => {
  try {
    return await axios.delete(`/product/delete-option-group/${productid}/${optionid}`);
  } catch (error) {
    toast.error("Oops! Something went wrong while deleting the option group. Please try again later.");
  }
};
export const getProductCategories = async (shopName) => {
  try {
    return await axios.get(`/product/getCategories?shop_name=${shopName}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while fetching the product categories. Please try again later.");
    }
  }
};

export const searchProducts = async (shopName, query, category) => {
  try {
    return await axios.get(
      `/product/searchProducts?shop_name=${shopName}&query=${query}&category=${category}`
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while searching for products. Please try again later.");
    }
  }
};

export const getFeaturedProduct = async (shopName) => {
  try {
    return await axios.get(`/product/getFeatured?shop_name=${shopName}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while getting the featured product. Please try again later.");
    }
  }
};

export const getNewArrivalProduct = async (shopName) => {
  try {
    return await axios.get(`/product/getNewArrivals?shop_name=${shopName}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while getting the new arrival product. Please try again later.");
    }
  }
};

export const getProductbyId = async (id) => {
  try {
    return await axios.get(`/product/details/${id}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while getting the product details. Please try again later.");
    }
  }
};

export const addProductImage = async (data) => {
  try {
    return await axios.post(`/product/image`, data);
  } catch (error) {
    toast.error("Oops! Something went wrong while adding the product. Please try again later.");
  }
};
export const uploadVideo = async (data) => {
  try {
    return await axios.post(`/product/video`, data);
  } catch (error) {
    toast.error("Oops! Something went wrong while uploading the video. Please try again later.");
  }
}
export const removeProductVideo = async (videoPath) => {
  try {
    return await axios.delete(
      `/product/video?videoPath=${encodeURIComponent(videoPath)}`
    );
  } catch (error) {
    toast.error("Oops! Something went wrong while removing the product video. Please try again later.");
  }
}

export const removeProductImage = async (imagePath) => {
  try {
    return await axios.delete(
      `/product/image?imagePath=${encodeURIComponent(imagePath)}`
    );
  } catch (error) {
    toast.error("Oops! Something went wrong while removing the product image. Please try again later.");
  }
};

export const searchProductsbyStore = async (
  product_name,
  page = 1,
  limit = 10
) => {
  try {
    return await axios.get(
      `/product/searchProductsbyStore?product_name=${encodeURIComponent(
        product_name
      )}&page=${page}&limit=${limit}`
    );
  } catch (error) {
    toast.error("Oops! Something went wrong while searching for products. Please try again later.");
  }
};


/**
 * check keyword is present in db or not
 */
export const checkKeyword = async (keyword, cancelToken) => {
  try {
    const response = await axios.post(`/product/check-keyword`, {
      keyword,
    }, {
      cancelToken: cancelToken.token,
    });
    return response;
  } catch (error) {
    return error;
  }
};

// get featured product 
export const getFeatured = async () => {
  try {
    const response = await axios.get(`/product/featured`);
    return response;
  } catch (error) {
    return error;
  }
};
