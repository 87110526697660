import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import PagesIcon from "@mui/icons-material/Pages";
import StoreIcon from "@mui/icons-material/Store";
import GoLive from "./components/GoLive";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  createSocialMediaAccount,
  getAllConnectedAccounts,
  reconnectAccount,
} from "../../api/socialMediaAccounts";
import { toast } from "../Toast";
import ReconnectAlerts from "./components/ReconnectAlerts";
import { useGoogleLogin } from "@react-oauth/google";

const MainSection = () => {
  const [liveStreamDialogBox, setLiveStreamDialogBox] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState([]);
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme=useTheme();

  const handleReconnectAccount = async (data) => {
    try {
      const resp = await reconnectAccount(data);
      if (resp.status === 200) {
        connectedAccounts();
        toast.success("Account reconnected successfully");
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const handleReconnectToFBPage = async (
    page_id,
    user_access_token,
    token_status
  ) => {
    if (token_status) {
      window.FB.api(
        `/${page_id}?fields=picture,name,access_token,id&access_token=${user_access_token}`,
        "GET",
        function (response) {
          if (response && !response.error) {
            let data = {
              type: "facebook",
              facebook_pages: [
                {
                  page_id: response.id,
                  page_name: response.name,
                  page_access_token: response.access_token,
                  page_profile_picture: response.picture.data.url,
                },
              ],
            };

            handleReconnectAccount(data);
          }
        }
      );
    } else {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            window.FB.api(
              `/${page_id}?fields=picture,name,access_token,id&access_token=${accessToken}`,
              function (response) {
                if (response && !response.error) {
                  let data = {
                    type: "facebook",
                    facebook_user_access_token: accessToken,
                    facebook_pages: [
                      {
                        page_id: response.id,
                        page_name: response.name,
                        page_access_token: response.access_token,
                        page_profile_picture: response.picture.data.url,
                      },
                    ],
                  };

                  handleReconnectAccount(data);
                }
              }
            );
          }
        },
        {
          scope:
            "pages_read_engagement,pages_show_list,pages_read_user_content,pages_manage_posts,pages_manage_engagement,pages_manage_metadata,business_management",
        }
      );
    }
  };

  const connectedAccounts = async () => {
    try {
      const response = await getAllConnectedAccounts();
      if (response.status === 200) {
        setConnectedAccount(response.data.connectedAccounts);
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const onSuccessLogin = async (tokenResponse) => {
    try {
      const { access_token } = tokenResponse;

      const profileResponse = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (profileResponse.ok) {
        const profileData = await profileResponse.json();

        let data = {
          youtube: [
            {
              channel_id: profileData.id,
              channel_name: profileData.name,
              channel_access_token: access_token,
              channel_token_created_at: new Date(),
              channel_profile_picture: profileData.picture,
              is_connected: true,
            },
          ],
        };

        const response = await createSocialMediaAccount(data);
        if (response.status === 200) {
          connectedAccounts();
          toast.success("Channel connected successfully");
        }
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const Glogin = useGoogleLogin({
    onSuccess: onSuccessLogin,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    scope: "https://www.googleapis.com/auth/youtube.force-ssl",
  });

  useEffect(() => {
    connectedAccounts();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            py: { xs: "6px", md: "13px" },
          }}
        >
          <Typography
            sx={{
              p: "10px",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Create
          </Typography>
        </Box>
    
        <Box
          sx={{
            width: "100%",
            height: "1px",
            backgroundColor: "#00000029",
           
          }}
        ></Box>

        <ReconnectAlerts
          connectedAccount={connectedAccount}
          handleReconnectToFBPage={handleReconnectToFBPage}
          Glogin={Glogin}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "center", sm: "flex-start" },
            px: "10px",
            py: { xs: `10px`, sm: `80px` },
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              height: "60px",
              width: "270px",
              backgroundColor: "transparent",
              border: "1px solid #00000029",
              px: "4px",
              pt: "5px",
              pb: "6px",
              gap: "20px",
              cursor: "pointer",
              ":hover": {
                border: "1px solid #FDC532",
                color: "#253F4B",
                fontWeight: "500",
              },
              alignItems: "center",
            }}
            onClick={() => setLiveStreamDialogBox(true)}
          >
            <Box
              sx={{
                display: "flex",
                p: 2,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.saas_button_bg.main,
                borderRadius: "5px",
              }}
            >
              <VideocamIcon
                fontSize="large"
                sx={{
                  color: "#fff",
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                p: 1,
                textAlign: "center",
                color: "#253F4B",
                ":hover": {
                  fontWeight: "500",
                },
              }}
            >
              Live Shopping
            </Typography>
          </Box>
          {/* posts */}
          <Box
            sx={{
              display: "flex",
              height: "60px",
              width: "270px",
              backgroundColor: "transparent",
              border: "1px solid #00000029",
              px: "4px",
              pt: "5px",
              pb: "6px",
              gap: "20px",
              cursor: "pointer",
              ":hover": {
                border: "1px solid #FDC532",
            
                // color: "#FDC532"
              },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                p: 2,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.saas_button_bg.main,
                borderRadius: "5px",
              }}
            >
              <PagesIcon
                fontSize="large"
                sx={{
                  color: "#fff",
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                p: 1,
                textAlign: "center",
                ":hover": {
                  fontWeight: "500",
                },
              }}
            >
              Shopable Content
            </Typography>
          </Box>
          {/* shops */}
          <Box
            sx={{
              display: "flex",
              height: "60px",
              width: "270px",
              backgroundColor: "transparent",
              border: "1px solid #00000029",
              px: "4px",
              pt: "5px",
              pb: "6px",
              gap: "20px",
              cursor: "pointer",
              ":hover": {
                border: "1px solid #FDC532",
                // color: "#FDC532"
              },
              alignItems: "center",
            }}
            onClick={() => {
              userData?.user?.hasOwnProperty("store")
                ? navigate("/dashboard/index")
                : navigate("/store");
            }}
          >
            <Box
              sx={{
                display: "flex",
                p: 2,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.saas_button_bg.main,
                borderRadius: "5px",
              }}
            >
              <StoreIcon
                fontSize="large"
                sx={{
                  color: "#fff",
                }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                p: 1,
                textAlign: "center",
                ":hover": {
                  fontWeight: "500",
                },
              }}
            >
              Online Shops
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            backgroundColor: "#00000029",
            margin: "10px 0px",
          }}
        ></Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            p: "10px",
            py: "18px",
            justifyContent:{xs:"center",sm:"flex-start"},
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "transparent",
              color: "#253F4B",
              fontWeight: "500",
              ":hover": {
                backgroundColor: "#253F4B",
                color: "#fff",
                border: "none",
              },
            }}
            onClick={() => navigate("/broadcast/destinations")}
          >
            Add Destinations
          </Button>
        </Box>
        
      </Box>
      <GoLive
        liveStreamDialogBox={liveStreamDialogBox}
        setLiveStreamDialogBox={setLiveStreamDialogBox}
        connectedAccount={connectedAccount}
        isEdit={false}
      />
    </>
  );
};

export default MainSection;
