import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import Link from "@mui/material/Link";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import proImg from "../../../assets/Img/addproduct.png";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate, useParams } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Container from "@mui/material/Container";
import { getProductbyId } from "../../../api/ProductApis";
import { ShopContext } from "../../../context/ShopContext";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import { toast } from "../../Toast";
import ChartModal from "./ChartModal";

const ShareFacebookButton = () => {
  const shareUrl = "https://your-link.com";
  return (
    <FacebookShareButton url={shareUrl}>
      <FacebookIcon size={32} round={true} />
    </FacebookShareButton>
  );
};

const ShareInstaButton = () => {
  const shareUrl = "https://your-link.com";
  const caption = "Check out this link";
  return (
    <InstapaperShareButton url={shareUrl} title={caption}>
      <InstapaperIcon size={32} round={true} />
    </InstapaperShareButton>
  );
};

const ShareWhatsappButton = () => {
  const shareUrl = "https://your-link.com";
  const message = "Check out this link";
  return (
    <WhatsappShareButton url={shareUrl} title={message}>
      <WhatsappIcon size={32} round={true} />
    </WhatsappShareButton>
  );
};

const ShareTwitterButton = () => {
  const shareUrl = "https://your-link.com";
  const hashtag = "your-hashtag";
  return (
    <TwitterShareButton url={shareUrl} hashtags={[hashtag]}>
      <TwitterIcon size={32} round={true} />
    </TwitterShareButton>
  );
};

const ShareLinkedinButton = () => {
  const shareUrl = "https://your-link.com";
  const hashtag = "your-hashtag";
  return (
    <LinkedinShareButton url={shareUrl} hashtags={[hashtag]}>
      <LinkedinIcon size={32} round={true} />
    </LinkedinShareButton>
  );
};

function NextArrow(props) {
  const { onClick } = props;
  return (
    <Button
      sx={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50%",
        minWidth: "0",
        p: "3px",
        position: "absolute",
        right: "-20px",
        transform: "translateY(-50%)",
        top: "50%",
      }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon sx={{ color: "black", fontSize: "14px" }} />{" "}
    </Button>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <Button
      sx={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50%",
        minWidth: "0",
        p: "3px",
        position: "absolute",
        left: "-20px",
        transform: "translateY(-50%)",
        top: "50%",
        zIndex: "100",
      }}
      onClick={onClick}
    >
      <ArrowBackIosNewIcon sx={{ color: "black", fontSize: "14px" }} />{" "}
    </Button>
  );
}

const getSettings = (images) => {
  return {
    //   dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: images > 3 ? 3 : images,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
};

function handleClick(event) {
  event.preventDefault();
}

const Index = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const endpoint = window.location.pathname;

  //-----useContext for add to cart
  const { shopDetails, addToCart, store } = useContext(ShopContext);

  //------fetch products details
  const [product, setProduct] = React.useState({});
  const [selectedImg, setSelectedImg] = React.useState("");
  const [item_id, setItem_id] = React.useState("");
  const [user_id, setUser_id] = React.useState("");
  const [variants, setVariants] = React.useState([]);
  const [selectedParentOp, setSelectedParentOp] = React.useState({});
  const [selectedChildOp, setSelectedChildOp] = React.useState({});
  const [sizeChartDialog, setSizeChartDialog] = React.useState(false);
  function handleGoBack() {
    navigate(-1);
  }

  const handleAdd = () => {
    if (
      Object.keys(selectedParentOp).length > 0 &&
      Object.keys(selectedChildOp).length > 0
    ) {
      if (selectedChildOp?.v_total_stock === 0) {
        toast.error("This variant is currently out of stock");
        return;
      }
      addToCart({
        _id: product?._id,
        thumbnail_image: selectedChildOp?.v_image,
        product_name: product?.product_name,
        total_stock: selectedChildOp?.v_total_stock,
        discount_price: selectedChildOp?.discounted_price,
        price: selectedChildOp?.v_price,
        variant: `${selectedParentOp?.type} - ${selectedChildOp?.type}`,
        option_id: selectedParentOp?._id,
        group_id: selectedChildOp?._id,

        is_varient: true,
      });
    } else if (
      variants?.length >= 2 &&
      Object.keys(selectedParentOp).length > 0 &&
      Object.keys(selectedChildOp).length === 0 &&
      product?.is_variant_display
    ) {
      toast.error(`Please select ${variants[1]?.varient_name}`);
    } else if (
      Object.keys(selectedParentOp).length > 0 &&
      Object.keys(selectedChildOp).length === 0 &&
      product?.is_variant_display
    ) {
      if (selectedParentOp?.v_total_stock === 0) {
        toast.error("This variant is currently out of stock");
        return;
      }
      addToCart({
        _id: product?._id,
        product_name: product?.product_name,
        thumbnail_image: selectedParentOp?.v_image,
        total_stock: selectedParentOp?.v_total_stock,
        discount_price: selectedParentOp?.discounted_price,
        variant: selectedParentOp?.type,
        price: selectedParentOp?.v_price,
        option_id: selectedParentOp?._id,
        group_id: null,
        is_varient: true,
      });
    } else {
      if (variants?.length > 0) {
        const firstOptionOfFirstVariant = variants[0]?.options?.[0];
        if (firstOptionOfFirstVariant?.v_total_stock === 0) {
          toast.error("This variant is currently out of stock");
          return;
        }
      }
      addToCart(product);
    }
  };

  useEffect(() => {
    if (variants?.length > 0) {
      const firstOptionOfFirstVariant = variants[0]?.options?.[0];
      if (firstOptionOfFirstVariant?.v_total_stock === 0) {
        return setSelectedParentOp({});
      }
      if (firstOptionOfFirstVariant && product?.is_variant_display) {
        setSelectedParentOp(firstOptionOfFirstVariant);
      }
    }
  }, [variants]);

  const handleBuyNow = () => {
    if (
      Object.keys(selectedParentOp).length > 0 &&
      Object.keys(selectedChildOp).length > 0
    ) {
      if (selectedChildOp?.v_total_stock === 0) {
        toast.error(
          "This item is currently out of stock. Please try again later."
        );
        return;
      }
      addToCart({
        _id: product?._id,
        thumbnail_image: selectedChildOp?.v_image,
        product_name: product?.product_name,
        total_stock: selectedChildOp?.v_total_stock,
        discount_price: selectedChildOp?.discounted_price,
        price: selectedChildOp?.v_price,
        variant: `${selectedParentOp?.type} - ${selectedChildOp?.type}`,
        option_id: selectedParentOp?._id,
        group_id: selectedChildOp?._id,

        is_varient: true,
      });
      navigate("/cart");
    } else if (
      variants?.length >= 2 &&
      Object.keys(selectedParentOp).length > 0 &&
      Object.keys(selectedChildOp).length === 0 &&
      product?.is_variant_display
    ) {
      toast.error(`Please select ${variants[1]?.varient_name}`);
    } else if (
      Object.keys(selectedParentOp).length > 0 &&
      Object.keys(selectedChildOp).length === 0
    ) {
      if (selectedParentOp?.v_total_stock === 0) {
        toast.error(
          "This item is currently out of stock. Please try again later."
        );
        return;
      }
      addToCart({
        _id: product?._id,
        product_name: product?.product_name,
        thumbnail_image: selectedParentOp?.v_image,
        total_stock: selectedParentOp?.v_total_stock,
        discount_price: selectedParentOp?.discounted_price,
        variant: selectedParentOp?.type,
        price: selectedParentOp?.v_price,
        option_id: selectedParentOp?._id,
        group_id: null,
        is_varient: true,
      });
      navigate("/cart");
    } else {
      if (variants?.length > 0) {
        const firstOptionOfFirstVariant = variants[0]?.options?.[0];
        if (firstOptionOfFirstVariant?.v_total_stock === 0) {
          toast.error(
            "This variant is currently out of stock. Please select another variant."
          );
          return;
        }
      }
      addToCart(product);
      navigate("/cart");
    }
  };

  const fetchProduct = async () => {
    setLoading(true);
    const response = await getProductbyId(id);
    if (response?.status === 200) {
      setProduct(response.data.product);
      setVariants(response.data?.product?.variants);
      setItem_id(response?.data?.product?._id);
      setUser_id(response?.data?.product?.user_id);
      if (response?.data?.product?.video) {
        // add video improduct images array
        response?.data?.product?.images?.push(response?.data?.product?.video);
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    id && fetchProduct();
  }, [id]);

  const breadcrumbs = [
    <Link key="1" onClick={handleGoBack} style={{ textDecoration: "none" }}>
      <Typography
        sx={{
          textDecoration: "none",
          color: "#E6A31E",
          ":hover": { cursor: "pointer" },
        }}
      >
        Home
      </Typography>
    </Link>,
    <Typography key="2" sx={{ color: "#E6A31E", fontWeight: "500" }}>
      {product?.product_name}
    </Typography>,
  ];

  if (!product) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={20} color="inherit" />
      </Box>
    );
  }

  return (
    <Container
      maxWidth="lg"
      sx={
        {
          // minHeight: "100vh",
        }
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: "20px",
          gap: 1,
        }}
      >
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="small" sx={{ color: "#E6A31E" }} />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", md: "row" },
          py: "20px",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "65%" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 2,
            background: "#fff",
            borderRadius: "10px",
            p: { sm: "15px", xs: "0px" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "65%", md: "50%" },
              display: "flex",
              //   justifyContent: "center",
              flexDirection: "column",
              gap: 1,
              mx: "auto",
            }}
          >
            {loading ? (
              <Skeleton
                variant="rectangular"
                sx={{ width: "80%", height: "280px", mx: "auto" }}
              />
            ) : // <img
            //   src={selectedImg || product.thumbnail_image || proImg}
            //   alt="pro"
            //   style={{ width: "100%", height: "250px", objectFit: "contain" }}
            // />

            product?.is_video_thumbnail ? (
              <video
                src={product?.video}
                controls
                style={{ width: "100%", height: "250px", objectFit: "contain" }}
              />
            ) : (
              <Box sx={{}}>
                <ReactImageMagnify
                  className="image-magnify "
                  {...{
                    smallImage: {
                      alt: "Wristwatch by Ted Baker London",
                      isFluidWidth: true,

                      src: selectedImg || product.thumbnail_image || proImg,
                    },
                    largeImage: {
                      src: selectedImg || product.thumbnail_image || proImg,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImageContainerDimensions: {
                      width: "200%",
                      height: "200%",
                    },
                    enlargedImageContainerStyle: {
                      background: "#fff",
                      zIndex: 9,
                    },
                  }}
                />
              </Box>
            )}
            {product.images?.length > 1 && (
              <Box
                sx={{
                  my: "10px",
                  mx: "20px",
                  maxHeight: "80px",
                }}
              >
                <Slider
                  {...getSettings(product?.images?.length)}
                  // direction="horizontal"
                >
                  {product.images?.map((img, index) => (
                    <Box key={index} sx={{ mr: "20px" }}>
                      {img?.includes("mp4") ? (
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                            height: "80px",
                            cursor: "pointer",
                          }}
                          // onClick={() => {
                          //   setSelectedImg(img);
                          //   setProduct({ ...product, is_video_thumbnail: true });
                          // }}
                          onMouseEnter={() => {
                            setSelectedImg(img);
                            setProduct({
                              ...product,
                              is_video_thumbnail: true,
                            });
                          }}
                        >
                          <video
                            src={img}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                          <PlayArrowIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white",
                              fontSize: "30px",
                            }}
                          />
                        </Box>
                      ) : (
                        <img
                          src={img}
                          alt="pro"
                          style={{
                            width: "80px",
                            height: "80px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          // onClick={() => {
                          //   setSelectedImg(img);
                          //   setProduct({ ...product, is_video_thumbnail: false });
                          // }}
                          onMouseEnter={() => {
                            setSelectedImg(img);
                            setProduct({
                              ...product,
                              is_video_thumbnail: false,
                            });
                          }}
                        />
                      )}
                    </Box>
                  ))}
                </Slider>
              </Box>
            )}
          </Box>

          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Typography
              sx={{
                fontWeight: "500",
                color: "#322F2F",
                fontSize: "25px",
                px: { xs: "15px" },
              }}
            >
              {loading ? <Skeleton /> : product?.product_name}
            </Typography>

            {/* <Typography
              sx={{
                mb: "10px",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#707070B7",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <StarIcon sx={{ color: "#FAC13B", fontSize: "20px" }} /> 4.6
              <Divider orientation="vertical" sx={{ height: "100%" }} />
              41 Reviews
            </Typography> */}

            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "space-between",
                px: { xs: "15px" },
              }}
            >
              {/* <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#264D59",
                  background: "#EFF9FD",
                  py: "3px",
                  px: "8px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <LocalOfferIcon sx={{ fontSize: "20px" }} /> {product?.discount}
                %
              </Typography> */}

              {product?.discount !== 0 && (
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "500",
                    color: "#FE9B0E",
                    py: "3px",
                    // px: "8px",
                    // textDecoration: "line-through",
                  }}
                >
                  {loading ? (
                    <Skeleton sx={{ width: "60px" }} />
                  ) : (
                    <>
                      {Object.keys(selectedParentOp).length === 0 &&
                        Object.keys(selectedChildOp).length === 0 && (
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {product.discount_price !== 0 ? (
                              <Typography
                                sx={{
                                  fontSize: { sm: "25px", xs: "22px" },
                                  fontWeight: "500",
                                  color: "#FE9B0E",
                                }}
                              >
                                Rs. {product.discount_price}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: { sm: "25px", xs: "22px" },
                                  fontWeight: "500",
                                  color: "#FE9B0E",
                                }}
                              >
                                Rs. {product.price}
                              </Typography>
                            )}

                            {product.discount_price !== 0 ? (
                              <Box
                                sx={{
                                  mt: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    position: "relative",
                                    fontSize: { sm: "15px", xs: "14px" },
                                    fontWeight: "400",
                                    color: "gray",
                                    ml: "4px",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  Rs. {product.price}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      {Object.keys(selectedParentOp).length > 0 &&
                        Object.keys(selectedChildOp).length === 0 && (
                          <Box sx={{ display: "flex", flexDirection: "row" }}>
                            {selectedParentOp?.discounted_price !== 0 ? (
                              <Typography
                                sx={{
                                  fontSize: { sm: "25px", xs: "22px" },
                                  fontWeight: "500",
                                  color: "#FE9B0E",
                                }}
                              >
                                Rs. {selectedParentOp?.discounted_price}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: { sm: "25px", xs: "22px" },
                                  fontWeight: "500",
                                  color: "#FE9B0E",
                                }}
                              >
                                Rs. {selectedParentOp?.v_price}
                              </Typography>
                            )}

                            {selectedParentOp?.discounted_price !== 0 ? (
                              <Box
                                sx={{
                                  mt: "8px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    position: "relative",
                                    fontSize: { sm: "15px", xs: "14px" },
                                    fontWeight: "400",
                                    color: "gray",
                                    ml: "4px",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  Rs. {selectedParentOp?.v_price}
                                </Typography>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      {Object.keys(selectedChildOp).length > 0 && (
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {selectedChildOp?.discounted_price !== 0 ? (
                            <Typography
                              sx={{
                                fontSize: { sm: "25px", xs: "22px" },
                                fontWeight: "500",
                                color: "#FE9B0E",
                              }}
                            >
                              Rs. {selectedChildOp?.discounted_price}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: { sm: "25px", xs: "22px" },
                                fontWeight: "500",
                                color: "#FE9B0E",
                              }}
                            >
                              Rs. {selectedChildOp?.v_price}
                            </Typography>
                          )}

                          {selectedChildOp?.discounted_price !== 0 ? (
                            <Box
                              sx={{
                                mt: "8px",
                              }}
                            >
                              <Typography
                                sx={{
                                  position: "relative",
                                  fontSize: { sm: "15px", xs: "14px" },
                                  fontWeight: "400",
                                  color: "gray",
                                  ml: "4px",
                                  textDecoration: "line-through",
                                }}
                              >
                                Rs. {selectedChildOp?.v_price}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      )}
                    </>
                  )}
                </Typography>
              )}

              {/* <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#707070B7",
                  py: "3px",
                  // px: "8px",
                  // textDecoration: "line-through",
                }}
              >
                {loading ? (
                  <Skeleton sx={{ width: "60px" }} />
                ) : (
                  product?.total_stock + " in Stock"
                )}
              </Typography> */}
              {/* 
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#322F2F",
                }}
              >
                Rs {calculateDiscount(product?.price, product?.discount)}
              </Typography> */}
            </Box>
            {product?.is_varient && product?.is_variant_display && (
              <>
                <Divider sx={{ my: 3 }} />
                {/* variants section */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  {variants &&
                    variants?.slice(0, 1).map((variant, index) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              px: { xs: "15px" },
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#322F2F",
                                textTransform: "capitalize",
                              }}
                            >
                              {variant?.varient_name} :
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: "400",
                                color: "#272727",
                                textTransform: "capitalize",
                              }}
                            >
                              {selectedParentOp?.type}
                            </Typography>
                          </Box>
                          {index === 0 && product?.chart_img && (
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#286FCF",
                                  cursor: "pointer",
                                }}
                                onClick={() => setSizeChartDialog(true)}
                              >
                                Size Chart
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 1,
                            px: { xs: "15px" },
                          }}
                        >
                          {variant &&
                            variant?.options?.map((POption, index) => (
                              <Button
                                key={index}
                                variant="outlined"
                                sx={{
                                  position: "relative",
                                  background: "transparent",
                                  color: "#707070",
                                  border: "1px solid #707070",
                                  textTransform: "capitalize",
                                  height: "25px",
                                  opacity:
                                    selectedParentOp?.type === POption?.type
                                      ? "1"
                                      : "0.5",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "0 10px",
                                }}
                                disabled={POption?.v_total_stock === 0}
                                onClick={() => {
                                  setSelectedParentOp(POption);
                                  setProduct({
                                    ...product,
                                    is_video_thumbnail: false,
                                  });
                                  setSelectedImg(POption?.v_image);
                                  setSelectedChildOp({});
                                }}
                              >
                                {POption?.type}
                                {POption?.v_total_stock === 0 && (
                                  <Box
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      width: "100%",
                                      height: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "gray",
                                      pointerEvents: "none",
                                      opacity: 0.5,
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    >
                                      <line
                                        x1="-20"
                                        y1="0"
                                        x2="48"
                                        y2="24"
                                      ></line>
                                      <line
                                        x1="48"
                                        y1="0"
                                        x2="-20"
                                        y2="24"
                                      ></line>
                                    </svg>
                                  </Box>
                                )}
                              </Button>
                            ))}
                        </Box>
                      </>
                    ))}
                  {/* Parent  variants groups*/}
                  {variants &&
                    variants[1] &&
                    variants[1]?.options?.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          px: { xs: "15px" },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#322F2F",
                              textTransform: "capitalize",
                            }}
                          >
                            {variants[1]?.varient_name}:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#272727",
                              textTransform: "capitalize",
                            }}
                          >
                            {selectedChildOp?.type}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      px: { xs: "15px" },
                      gap: 1,
                    }}
                  >
                    {selectedParentOp &&
                      selectedParentOp?.groups?.map((option, index) => (
                        <Button
                          key={index}
                          variant="outlined"
                          sx={{
                            background: "transparent",
                            position: "relative",
                            color: "#707070",
                            border: "1px solid #707070",
                            textTransform: "uppercase",
                            height: "25px",
                            opacity:
                              selectedChildOp?.type === option?.type
                                ? "1"
                                : "0.5",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0 10px",
                          }}
                          onClick={() => {
                            setSelectedChildOp(option);
                            setProduct({
                              ...product,
                              is_video_thumbnail: false,
                            });
                            setSelectedImg(option?.v_image);
                          }}
                          disabled={option?.v_total_stock === 0}
                        >
                          {option?.type}
                          {option?.v_total_stock === 0 && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "gray", // Custom gray color
                                pointerEvents: "none",
                                opacity: 0.5,
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <line x1="-20" y1="0" x2="48" y2="24"></line>
                                <line x1="48" y1="0" x2="-20" y2="24"></line>
                              </svg>
                            </Box>
                          )}
                        </Button>
                      ))}
                  </Box>
                </Box>
              </>
            )}

            <Divider sx={{ my: 3 }} />

            <Typography
              sx={{
                fontWeight: "500",
                color: "#322F2F",
                fontSize: "15px",
                px: { xs: "15px" },
              }}
            >
              {loading ? (
                <Skeleton sx={{ width: "40px" }} />
              ) : (
                "Product Description"
              )}
            </Typography>

            <Typography
              sx={{ fontSize: "14px", color: "#322F2F", px: { xs: "15px" } }}
              dangerouslySetInnerHTML={{ __html: product?.description }}
            />

            <Divider sx={{ my: 3 }} />

            <Typography
              sx={{
                fontWeight: "500",
                color: "#322F2F",
                fontSize: "15px",
                px: { xs: "15px" },
              }}
            >
              {loading ? <Skeleton sx={{ width: "40px" }} /> : "Shipping"}
            </Typography>

            <Typography
              sx={{
                fontSize: "12px",
                color: "#322F2F",
                display: "flex",
                alignItems: "center",
                px: { xs: "15px" },
                gap: 1,
              }}
            >
              <AirportShuttleIcon sx={{ color: "black" }} /> Estimated Shipping{" "}
              <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                Rs {shopDetails?.delivery?.delivery_charges || 0}
              </span>
            </Typography>

            {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography sx={{ fontWeight: "bold", display: "flex" }}>
                Color&nbsp;
                <Typography sx={{ color: "#707070B7" }}>(2)</Typography>
              </Typography>

              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  sx={{
                    background: "#264D59",
                    color: "#fff",
                    borderRadius: "10px",
                    px: "10px",
                  }}
                >
                  Black
                </Button>
                <Button
                  sx={{
                    color: "#707070",
                    border: "1px solid #707070",
                    borderRadius: "10px",
                    px: "10px",
                  }}
                >
                  White
                </Button>
              </Box>
            </Box> */}
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "calc(100% - 40px)", md: "35%" },
            display: "flex",
            flexDirection: "column",
            p: "20px",
            height: "fit-content",
            background: "#fff",
            borderRadius: "10px",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "350px",
              px: "0px !important",
            }}
          >
            <Button
              sx={{
                background: "#FE9B0E",
                border: "1px solid #FE9B0E",
                transition: "background 0.5s, color 0.5s, border 0.5s",

                ":hover": { background: "transparent", color: "black" },
                color: "#fff",
                borderRadius: "20px",
                mb: "10px",
              }}
              disabled={!loading || product?.total_stock > 0 ? false : true}
              onClick={handleAdd}
            >
              Add to Cart
            </Button>
            <Button
              sx={{
                background: "black",
                color: "#FE9B0E",
                border: "1px solid #FE9B0E",
                transition: "background 0.5s, color 0.5s, border 0.5s",

                borderRadius: "20px",
                mb: "10px",
              }}
              disabled={loading}
              onClick={handleBuyNow}
            >
              Buy Now
            </Button>
            {shopDetails?.on_Whatsapp && shopDetails?.phone_number && (
              <Button
                sx={{
                  background: "#10B914",
                  color: "#fff",
                  border: "1px solid #FE9B0E",
                  borderRadius: "20px",

                  transition: "background 0.5s, color 0.5s, border 0.5s",

                  ":hover": {
                    background: "transparent",
                    color: "#10B914",
                    border: "1px solid #10B914",
                  },
                  mb: "10px",
                }}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_FRONTEND_URL}/whatsapp-checkout?pid=${item_id}&bid=${user_id}`
                  )
                }
              >
                Order via Whatsapp
              </Button>
            )}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                // alignItems: "center",
                width: "calc(100% - 40px)",
                mt: 3,
              }}
            >
              <AirportShuttleIcon sx={{ color: "black", fontSize: "34px" }} />

              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "15px",
                  justifyContent: "space-between",
                  fontWeight: "500",
                }}
              >
                {/* {product?.store_id?.store_data?.min_days} -
                {product?.store_id?.store_data?.max_days} Days */}
                Standard Delivery
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#707070B7",
                  }}
                >
                  {store?.delivery?.min_days}-{store?.delivery?.max_days} Days
                </Typography>
              </Typography>
            </Box>

            <Divider sx={{ my: 3 }} />
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                fontSize: "15px",
                justifyContent: "space-between",
                mb: "10px",
              }}
            >
              Services
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <VerifiedOutlinedIcon />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "15px",
                    justifyContent: "space-between",
                    mb: "10px",
                  }}
                >
                  100% Authentic
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "10px",
              }}
            >
              <Box sx={{ display: "flex", gap: 2 }}>
                <ReplayIcon />
                <Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                    }}
                  >
                    14 days easy return
                  </Typography>
                  <Typography sx={{ color: "#707070B7", fontSize: "12px" }}>
                    Change of mind is not applicable
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: "30px",
              }}
            >
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <ShareOutlinedIcon sx={{ color: "#707070B7" }} />
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "15px",
                    justifyContent: "space-between",
                    mb: "10px",
                  }}
                >
                  Share
                </Typography>
              </Box> */}

              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              > */}
              {/* <Box sx={{ gap: 1, display: "flex" }}>
                  <ShareWhatsappButton />
                  <ShareFacebookButton /> */}
              {/* <ShareInstaButton /> */}
              {/* <ShareTwitterButton /> */}
              {/* </Box> */}

              {/* <Button>
              <ContactSupportIcon sx={{ color: "#52ABE6", fontSize: "30px" }} />
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "14px",
                  alignItems: "start",
                }}
              >
                Chat
                <Typography
                  sx={{
                    fontSize: "10px",
                    color: "#707070B7",
                  }}
                >
                  with Seller
                </Typography>
              </Typography>
            </Button> */}
              {/* </Box> */}
            </Box>
          </Container>
        </Box>
      </Box>
      <ChartModal
        sizeChartDialog={sizeChartDialog}
        handleClose={() => setSizeChartDialog(false)}
        chart={product?.chart_img}
      />
    </Container>
  );
};

export default Index;
