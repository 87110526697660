import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import GradingRoundedIcon from "@mui/icons-material/GradingRounded";
import SendTimeExtensionRoundedIcon from "@mui/icons-material/SendTimeExtensionRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import OfflinePinRoundedIcon from "@mui/icons-material/OfflinePinRounded";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DatePicker } from "@mui/x-date-pickers";
import { getAllStreamMatrics } from "../../../../api/StreamApis";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";

const CACHE_KEY = "streamMatricsData";
const CACHE_TIMESTAMP_KEY = "streamMatricsDataTimestamp";
const CACHE_EXPIRATION_MS = 1000 * 60 * 15; // 15 minutes

const LiveSection = () => {
  const [open, setOpen] = useState({
    detail: false,
    data: [],
    date: null,
  });
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
  });

  const [streamArr, setStreamArr] = useState(null);
  const [orderData, setOrderData] = useState(null);

  const handleClose = () => {
    setOpen({ ...open, detail: false, data: [], date: null });
  };

  const handleCloseAn = (list) => {
    setOpen({ ...open, [list]: false, data: [], date: null });
  };

  const getAllStreamMatricData = async () => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);
    const now = new Date().getTime();

    if (
      cachedData &&
      cachedTimestamp &&
      now - cachedTimestamp < CACHE_EXPIRATION_MS
    ) {
      const parsedData = JSON.parse(cachedData);
      setStreamArr(parsedData.streamMatricsObj);
      setOrderData(parsedData.ordersObj);
    } else {
      try {
        const response = await getAllStreamMatrics();
        if (response.status === 200) {
          const dataToCache = {
            streamMatricsObj: response.data.streamMatricsObj,
            ordersObj: response.data.ordersObj,
          };
          localStorage.setItem(CACHE_KEY, JSON.stringify(dataToCache));
          localStorage.setItem(CACHE_TIMESTAMP_KEY, now.toString());
          setStreamArr(response.data.streamMatricsObj);
          setOrderData(response.data.ordersObj);
        }
      } catch (error) {
        return error;
      }
    }
  };

  useEffect(() => {
    getAllStreamMatricData();
  }, []);

  useEffect(() => {
    let start = filterData.startDate?.$d;
    let end = filterData.endDate?.$d;
    if (start && end) {
      // * convert format to mm/dd/yyyy
      start = `${
        start.getMonth() + 1
      }/${start.getDate()}/${start.getFullYear()}`;
      end = `${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear()}`;

      // * filter the streamArr
      const filteredStreamArr = {};
      if (streamArr === null) return setStreamArr([]);
      Object.keys(streamArr).forEach((dateKey) => {
        if (dateKey >= start && dateKey <= end) {
          filteredStreamArr[dateKey] = streamArr[dateKey];
        }
      });

      setStreamArr(filteredStreamArr);
    }
  }, [filterData]);

  return (
    <Box
    sx={{
      px:"6px"
    }}
    >
      <Box sx={{ display: "flex", gap: 1, mb: 1, pt: "8px" }}>
        <DatePicker
          label="Start Date"
          sx={{
            width: "100%",
          }}
          onChange={(e) => {
            setFilterData({ ...filterData, startDate: e });
          }}
        />
        <DatePicker
          label="End Date"
          sx={{
            width: "100%",
          }}
          onChange={(e) => {
            setFilterData({ ...filterData, endDate: e });
          }}
        />
      </Box>
      <Box
        sx={{
          height: "calc(100% - 44px)",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "static",
            mb: "5px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontWeight: "500" }}>My Streams</Typography>

          <Box>
            <Button
              sx={{
                color: "#707070",
                background: "#fff",
                //   border: "1px solid #707070",
                py: "2px",
                fontSize: "12px",
              }}
              onClick={(e) => {
                getAllStreamMatricData();
                setFilterData({ startDate: null, endDate: null });
              }}
            >
              Reset
              <ArrowDropDownIcon />
            </Button>
            <Menu
              anchorEl={open.anchor}
              open={open.profile}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={() => handleCloseAn("profile")}
              sx={{
                "& .MuiPaper-root": { background: "#8E8E8E", color: "#fff" },
              }}
            >
              <MenuItem>
                <Typography textAlign="center">Fulfilled</Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="center">In Progress</Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="center">Cancelled</Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="center">Dispatched</Typography>
              </MenuItem>
              <MenuItem>
                <Typography textAlign="center">Return</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        {/* <Box
          sx={{
            border: "1px solid #707070",
            borderRadius: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pr: "10px",
            pl: "15px",
            mb: "10px",
          }}
        >
          <InputBase placeholder="Filter Orders" sx={{ width: "100%" }} />
          <SearchIcon sx={{ color: "#707070" }} />
        </Box> */}

        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            mb: "10px",
            "&::-webkit-scrollbar": {
              width: "2px",
              borderRadius: "50px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#646669",
              borderRadius: "50px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#FDC532",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#fff",
              borderRadius: "50px",
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              height: "300px",
            }}
          >
            {/* {reviewProductList &&
            reviewProductList.map((item, index) => ( */}
            {streamArr !== null &&
              Object.keys(streamArr).map((dateKey) => {
                const streamData = streamArr[dateKey];
                const firstStream = streamData.streams[0];
                const totalLeads = streamData.totalLeads;
                return (
                  <div key={dateKey}>
                    {" "}
                    {/* Add a wrapping div with a unique key */}
                    <Box
                      sx={{
                        ":hover": {
                          background: "#272727",
                          color: "#fff",
                          cursor: "pointer",
                        },
                        p: "10px",
                        justifyContent: "space-between",
                        background: "#F1F1F1",
                        // width: "calc(100%-10px)",
                        alignItems: "center",
                        display: "flex",
                        color: "black",
                      }}
                      onClick={() =>
                        setOpen({
                          ...open,
                          detail: true,
                          data: [streamData],
                          date: dateKey,
                        })
                      }
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "13px",
                          }}
                        >
                          {firstStream.streamTitle.slice(0, 40)}{" "}
                          {/* Render the stream title of the first stream */}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {firstStream.streamDescription.slice(0, 40)}{" "}
                          {/* Render the stream description of the first stream */}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          Orders: {totalLeads || 0}
                        </Typography>
                        
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {dateKey} {/* Render the dynamic date */}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                );
              })}

            {/* <Divider /> */}
            {/* ))}
          {(!reviewProductList || reviewProductList.length === 0) && (
            <Typography sx={{ textAlign: "center", my: "auto" }}>
              No items found
            </Typography>
          )} */}
          </Box>
        </Box>
      </Box>

      <Dialog
        open={open.detail}
        onClose={() => handleClose()}
        PaperProps={{
          style: { borderRadius: "5px", width: { xs: "", md: "80%" } },
        }}
      >
        <DialogContent
          sx={{
            background: "#EFF9FD",
            // color: "#707070B7",
            position: "relative",
            padding: "25px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid black",
                pb: "7px",
                mb: "15px",
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  {open.data[0]?.streams[0]?.streamTitle}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {open.data[0]?.streams[0]?.streamDescription}
                </Typography>
              </Box>

              <Box>
                <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                  Date
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>{open.date}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #0000002E",
                mb: "5px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Keywords Generated
              </Typography>

              <Typography sx={{ fontSize: "12px" }}>
                {open.data[0]?.totalAutoReplyComments}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #0000002E",
                mb: "5px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>Stream Duration</Typography>

              <Typography sx={{ fontSize: "12px" }}>
                {open.data[0]?.streams[0]?.facebookStreamDuration}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #0000002E",
                mb: "5px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>Total Comments</Typography>

              <Typography sx={{ fontSize: "12px" }}>
                {open.data[0]?.totalComments}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #0000002E",
                mb: "5px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>Leads</Typography>

              <Typography sx={{ fontSize: "12px" }}>
                {open.data[0]?.totalLeads}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #0000002E",
                mb: "5px",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>WhatsApp link</Typography>

              <Typography sx={{ fontSize: "12px" }}>
                {open.data[0]?.whatsappLeads}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                mb: "5px",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                Order Status Count
              </Typography>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        border: "1px solid #707070",
                        p: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <GradingRoundedIcon
                        sx={{
                          position: "absolute",
                          left: "15px",
                          color: "#4DA2F8",
                        }}
                      />

                      <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                        {orderData?.acceptedOrders}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Accepted{" "}
                        <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        border: "1px solid #707070",
                        p: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <SendTimeExtensionRoundedIcon
                        sx={{
                          position: "absolute",
                          left: "15px",
                          color: "#FDC532",
                        }}
                      />
                      <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                        {orderData?.dispatchedOrders}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Dispatched{" "}
                        <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        border: "1px solid #707070",
                        p: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <CancelRoundedIcon
                        sx={{
                          position: "absolute",
                          left: "15px",
                          color: "#FF1E5A",
                        }}
                      />
                      <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                        {orderData?.cancelledOrders}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Cancelled{" "}
                        <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        border: "1px solid #707070",
                        p: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <OfflinePinRoundedIcon
                        sx={{
                          position: "absolute",
                          left: "15px",
                          color: "#2EB43F",
                        }}
                      />
                      <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                        {orderData?.fullfilledOrders}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Fillfilled{" "}
                        <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box
                      sx={{
                        border: "1px solid #707070",
                        p: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <HourglassBottomRoundedIcon
                        sx={{
                          position: "absolute",
                          left: "15px",
                          color: "orange",
                        }}
                      />
                      <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                        {orderData?.cancelledOrders}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Pending{" "}
                        <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        border: "1px solid #707070",
                        p: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <AssignmentReturnedIcon
                        sx={{
                          position: "absolute",
                          left: "15px",
                          color: "brown",
                        }}
                      />
                      <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
                        {orderData?.fullfilledOrders}
                      </Typography>
                      <Typography sx={{ fontSize: "12px" }}>
                        Returned{" "}
                        <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Button
            style={{
              position: "absolute",
              color: isHovered ? "white" : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: "fit-content",
              borderRadius: "50%",
              transition: "background-color 0.3s ease-in-out",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
            }}
            onClick={handleClose}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LiveSection;
