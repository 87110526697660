import { Stack, Fab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModeIcon from "@mui/icons-material/Mode";
import SubjectIcon from "@mui/icons-material/Subject";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";

const PostsStatusFilters = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={isSmallScreen ? 2 : 3}
    >
      <Fab
        variant={isSmallScreen ? "circular" : "extended"}
        size={isSmallScreen ? "small" : "medium"}
        color="primary"
      >
        <SubjectIcon sx={{ mr: isSmallScreen ? 0 : 1 }} />
        {!isSmallScreen && "All"}
      </Fab>

      <Fab
        variant={isSmallScreen ? "circular" : "extended"}
        size={isSmallScreen ? "small" : "medium"}
      >
        <CheckCircleIcon sx={{ mr: isSmallScreen ? 0 : 1 }} />
        {!isSmallScreen && "Active"}
      </Fab>

      <Fab
        variant={isSmallScreen ? "circular" : "extended"}
        size={isSmallScreen ? "small" : "medium"}
      >
        <UnpublishedIcon sx={{ mr: isSmallScreen ? 0 : 1 }} />
        {!isSmallScreen && "Inactive"}
      </Fab>

      <Fab
        variant={isSmallScreen ? "circular" : "extended"}
        size={isSmallScreen ? "small" : "medium"}
      >
        <ModeIcon sx={{ mr: isSmallScreen ? 0 : 1 }} />
        {!isSmallScreen && "Draft"}
      </Fab>
    </Stack>
  );
};

export default PostsStatusFilters;
