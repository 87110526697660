import { Alert, Box } from "@mui/material";
import React from "react";

const ReconnectAlerts = ({
    connectedAccount,
    handleReconnectToFBPage,
    Glogin
}) => {
  return (
    <Box
      sx={{
        px: "5px",
        width: "80%",
      }}
    >
      {connectedAccount.map((row, i) => {
        if (!row.status) {
          return (
            <Alert
              key={i}
              severity="error"
              sx={{
                marginBottom: "10px",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              Uchlo has lost access to your {row.type} account ({row.name}).
              Don't worry, we've got you covered !!
              <span
                style={{
                  color: "blue",
                  cursor: "pointer",
                  textDecoration: "underline",
                  margin: "0px 10px",
                }}
                onClick={() => {
                  row?.type === "Facebook" &&
                  handleReconnectToFBPage(
                    row.id,
                    row.facebook_user_access_token,
                    row.facebook_user_access_token_created_at
                  );

                  row?.type === "youtube" && Glogin();
                }}
              >
                Click here to reconnect your destination
              </span>
            </Alert>
          );
        }
      })}
    </Box>
  );
};

export default ReconnectAlerts;
