import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Box, Container, Typography, Paper, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  return (
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <ErrorOutlineIcon color="primary" sx={{ fontSize: 64, mb: 2 }} />
            <Typography variant="h4" component="h1" gutterBottom>
              Oops!
            </Typography>
            <Typography variant="body1" paragraph>
              Sorry, an unexpected error has occurred.
            </Typography>
            {/* <Typography variant="body2" color="text.secondary" paragraph>
              <i>{error.statusText || error.message}</i>
            </Typography> */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.href = '/'}
            >
              Go to Homepage
            </Button>
          </Paper>
        </Container>
      </Box>
  );
}

export default ErrorPage;