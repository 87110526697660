import { useState, useCallback } from 'react';

function useApiResponse() {
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleApiCall = useCallback(async (apiFunction, ...args) => {
    setIsLoading(true);
    setResponseData(null);
    setError(null);

    try {
      const response = await apiFunction(...args);
      if (response?.status === 200 || response?.status === 201) {
        setResponseData(response.data);
        return { success: true, data: response.data };
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setError(err.response.data);
        return { success: false, error: err.response.data };
      } else {
        setError('An unexpected error occurred');
        return { success: false, error: 'An unexpected error occurred' };
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { responseData, error, isLoading, handleApiCall };
}

export default useApiResponse;