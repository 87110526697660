import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  createLiveVideo,
  getIframeUrl,
} from "../utils/FacebookRTMPLinkGeneration";
import { toast } from "../components/Toast";
import {
  createInAppStreamRoom,
  createYoutubeBroadcast,
  getInAppStreamRoomInfo,
  getStreamGraphics,
  storeStreamingMatrics,
  toggleLiveStreamingStatus,
  updateInAppStreamRoomStatus,
  updateOfflineMatrics,
} from "../api/StreamApis";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "./AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApiResponse from "../hooks/useApiResponse";
export const StreamingContext = createContext();
const intoInitialState = {
  title: "",
  subtitle: "",
  background: "rgba(253, 197, 50, 1)",
  textColor: "rgba(0, 0, 0, 1)",
  fontSize: "2",
  fontFamily: "SuezOne",
  isActive: false,
  isUpdated: false,
  isPreview: false,
  isSaved: false,
};

const initialObj = {
  facebook: null,
  instagram: null,
  youtube: null,
  in_app: null,
  likedComments: [],
  displayedComments: [],
  isFacebookActive: false,
  isFacebookRTMPActive: false,
  isInstagramActive: false,
  isYoutubeActive: false,
  isInAppActive: false,
  isSocialMediaDetails: false,
  instaDetails: false,
  youtubeDetails: false,
  facebookDetails: false,
  faceBookRTMPDetails: false,
};

const graphicObj = {
  bannerLowerThird: null,
  lowerThird: null,
  intro: null,
  ticker: null,
  logo: [],
  products: null,
  ads: null,
  isCommentHide: false,
};

const facebookObjec = {
  username: "",
  userId: "",
  selectedPage: localStorage.getItem("fbSelectedPage") || "",
  pageAccessToken: "",
  pageId: "",
  title: "",
  description: "",
  streaming_link: "",
  videoId: "",
  facebookLive: false,
};

const instaObjec = {
  rtmpUrl: "",
  isInstagramLive: false,
};

const youtubeObjec = {
  title: "",
  description: "",
  streaming_link: "",
  liveChatId: "",
  youtubeLive: false,
};
let initialState = {
  topLine: "",
  bottomLine: "",
  bgColor: "rgba(0, 0, 0, 1)",
  textColor: "rgba(255, 255, 255, 1)",
  fontFamily: "Roboto",
  titleFontSize: "2",
  subTitleFontSize: "1.5",
  showEmoji: false,
  emoji: "🚀",
  isEnableEmoji: false,
  position: "bottom-left",
  isActive: false,
  isSaved: false,
  isPreview: false,
};
const initialStateTicker = {
  text: "",
  isActive: false,
  isEdited: false,
  isPreview: false,
  isSaved: false,
};

export const StreamingProvider = ({ children }) => {
  // ! ----------------- REACT ROUTER HERE ---------------------------
  const { roomName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  // ! ----------------- ALL STATES HERE   -----------------
  const [position, setPosition] = useState("bottom-left");
  const [isEditProductGhps, setIsEditProductGhps] = useState(false);
  const [ticker, setTicker] = useState(initialStateTicker);
  const [isEditTicker, setIsEditTicker] = useState(false);
  const [lowerthirdState, setLowerthirdState] = useState(initialState);
  const [isEditLowerThird, setIsEditLowerThird] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [IntroState, setIntroState] = useState(intoInitialState);
  const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);
  const [isAddsAddPanelOpen, setIsAddsAddPanelOpen] = useState(false);
  const [streamData, setStreamData] = useState(initialObj);
  const [drawerRight, setDrawerRight] = useState(false);
  const [guestDrawer, setGuestDrawer] = useState(false);
  const [graphicsData, setgraphicsData] = useState(graphicObj);
  const [allPages, setAllPages] = useState([]);
  const [facebookObj, setFacebookObj] = useState(facebookObjec);
  const [instaObj, setInstaObj] = useState(instaObjec);
  const [youtubeObj, setYoutubeObj] = useState(youtubeObjec);
  const [chatHistory, setChatHistory] = useState([]);
  const [liveStreamActive, setLiveStreamActive] = useState(false);
  const [isOnMain, setIsOnMain] = useState(true);
  const [disabledComments, setDisabledComments] = useState(false);
  const [oauthSessionExpired, setOauthSessionExpired] = useState(false);
  const [socialDrawer, setSocialDrawer] = useState(false);
  const [optionToggleMenu, setOptionToggleMenu] = useState(false);
  const [menuResp, setMenuResp] = useState(false);
  const [recordedStreamsModal, setRecordedStreamsModal] = useState(false);
  const [crossPostPages, setCrossPostPages] = useState([]);
  const [loadingCrossPostPages, setLoadingCrossPostPages] = useState([]);
  const [state, setState] = useState({
    expand: false,
    commentAnchor: null,
    commentSwitchAnchor: null,

    openedSection: "main",
  });
  const [platforms, setPlatforms] = useState({
    facebook: false,
    instagram: false,
    youtube: false,
    in_app: false,
  });
  const [inAppLiveUrl, setInAppLiveUrl] = useState("");

  const [graphicState, setGraphicState] = useState({
    openedSection: "logo",
  });

  const [prodState, setProdState] = useState({
    openedSection: "list",
  });
  const [dailyCallframe, setDailyCallframe] = useState(null);
  const [streamLoading, setStreamLoading] = useState(false);
  const [readyToStream, setReadyToStream] = useState(false);
  const [streamMetaData, setStreamMetaData] = useState({
    title: "",
    description: "",
  });
  const [orientation, setOrientation] = useState("landscape");
  const [IsLiveStream, setIsLiveStream] = useState(false);
  const [UUIDS, setUUIDS] = useState({
    instaUUID: "",
    fbUUID: "",
  });
  const [streamMode, setStreamMode] = useState("single");
  const [activeParticipant, setActiveParticipant] = useState("");
  const [activeParticipantIndex, setActiveParticipantIndex] = useState(0);
  const [activeParticipantsIndex, setActiveParticipantsIndex] = useState([]);
  const [activeVideos, setActiveVideos] = useState(["*"]);
  const [pipActiveCam, setPipActiveCam] = useState({
    primary: "",
    secondary: "",
  });
  const [countdown, setCountdown] = useState(10);
  const [listStreamGraphics, setListStreamGraphics] = useState(graphicObj);
  const [guests, setGuests] = useState({});
  const [splitScreenMode, setSplitScreenMode] = useState("dual");
  const [activeParticipantSession, setActiveParticipantSession] = useState({
    session_id: "",
    status: false,
  });
  const [screenParticipants, setScreenParticipants] = useState([]);
  const [isLeftStudios, setIsLeftStudios] = useState(false);

  // ! ------------ USE REF HERE -----------------

  // ! -------------- ALL CONTEXT IMPORTS HERE   -----------------
  const { userData } = useContext(AuthContext);

  // ! ------------- HOOKS ---------------------------------
  const { handleApiCall } = useApiResponse();

  // ! ----------------- ALL FUNCTIONS HERE   -----------------

  const handleStreamModal = () => {
    setRecordedStreamsModal(!recordedStreamsModal);
  };

  const HandleMenuResp = () => {
    setMenuResp(!menuResp);
  };

  // toggleDrawer
  const SocialtoggleDrawer = () => {
    setSocialDrawer(!socialDrawer);
  };

  // toggle option menu handler
  const HandleOptiontoggleMenu = () => {
    setOptionToggleMenu(!optionToggleMenu);
  };

  const handleGraphicsData = (type, value) => {
    setgraphicsData({ ...graphicsData, [type]: value });
  };

  const streamAction = async () => {
    try {
      // * get the stream data from local storage
      let facebookResponse = JSON.parse(
        localStorage.getItem("facebookResponse")
      );
      let youtubeResponse = JSON.parse(localStorage.getItem("YoutubeResponse"));
      let instagramResponse = JSON.parse(
        localStorage.getItem("instagramResponse")
      );

      let inAppResponse = localStorage.getItem("in_app_rtmp");

      // * save response
      let fb = null;
      let yt = null;

      setStreamLoading(true);

      // * check if facebook is selected
      if (facebookResponse !== null) {
        fb = Promise.resolve(facebookRtmpUrl());
      }

      // * check if youtube is selected
      if (youtubeResponse !== null) {
        yt = Promise.resolve(youtubeRtmpUrl());
      }

      // * check if instagram is selected
      if (instagramResponse !== null) {
        // * nothing
      }

      // Wait for the promises to resolve using Promise.allSettled()
      const resolvedPromises = await Promise.allSettled([fb, yt]);

      // Extract the resolved values from the promises
      const response = resolvedPromises.map((result) =>
        result.status === "fulfilled" ? result.value : null
      );

      // * start stream if all the rtmp url are generated & according to the platform
      if (response[0] !== null && response[1] !== null) {
        // * start stream
        setStreamLoading(false);
        setReadyToStream(true);
      } else if (response[0] !== null && response[1] === null) {
        // * start stream
        setStreamLoading(false);
        setReadyToStream(true);
      } else if (response[0] === null && response[1] !== null) {
        // * start stream
        setStreamLoading(false);
        setReadyToStream(true);
      } else if (
        instagramResponse !== null &&
        response[0] === null &&
        response[1] === null
      ) {
        // * start stream
        setStreamLoading(false);
        setReadyToStream(true);
      } else if (
        inAppResponse !== null &&
        response[0] === null &&
        response[1] === null
      ) {
        // * start stream
        setStreamLoading(false);
        setReadyToStream(true);
      } else if (
        localStorage.getItem("testFbResp") !== undefined &&
        response[0] === null &&
        response[1] === null
      ) {
        // * start stream
        setStreamLoading(false);
        setReadyToStream(true);
      } else {
        toast.error(
          "Please select at least one platform to start the live stream."
        );
        setStreamLoading(false);
      }
    } catch (error) {
      toast.error("Failed to start the stream. Please try again later.");
    }
  };

  // * generate facebook rtmp url
  const facebookRtmpUrl = async () => {
    try {
      const response = await createLiveVideo(
        facebookObj.pageId,
        facebookObj.pageAccessToken,
        streamMetaData?.title,
        streamMetaData?.description
      );

      const iframeUrl = await getIframeUrl(
        response.id,
        facebookObj.pageAccessToken,
        orientation
      );

      let combinedResp = {
        ...response,
        pageId: facebookObj.pageId,
        pageAccessToken: facebookObj.pageAccessToken,
        title: streamMetaData?.title,
        description: streamMetaData?.description,
        videoId: response.id,
        streaming_link: response.stream_url,
        iframeUrl: iframeUrl,
        facebookLive: true,
      };

      localStorage.setItem(
        "facebookStreamingResponse",
        JSON.stringify(combinedResp)
      );

      return true;
    } catch (error) {
      toast.error(
        "Failed to generate Facebook RTMP URL. Please check your Facebook credentials and try again."
      );
    }
  };

  // * generate youtube rtmp url
  const youtubeRtmpUrl = async () => {
    try {
      const data = {
        title: streamMetaData?.title,
        description: streamMetaData?.description,
        thumbnail:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFEgo1VoaRkE_Kyn6y00YdRqoi56dEGDl87A&usqp=CAU",
        access_token: JSON.parse(localStorage.getItem("YoutubeResponse")),
      };

      createYoutubeBroadcast(data)
        .then((response) => {
          let url =
            response.data.data.rtmp_url + "/" + response.data.data.stream_key;

          setYoutubeObj({
            ...youtubeObj,
            streaming_link: url,
            liveChatId: response.data.data.liveChatId,
            title: streamMetaData?.title,
            description: streamMetaData?.description,
            iframeUrl: response.data.data.id,
          });

          localStorage.setItem(
            "youtubeStreamingResponse",
            JSON.stringify({
              rtmpUrl: url,
              liveChatId: response.data.data.liveChatId,
              title: streamMetaData?.title,
              description: streamMetaData?.description,
              iframeUrl: response.data.data.id,
            })
          );
        })
        .catch((error) => {
          toast.error(
            "YouTube channel is not enabled. Please check your YouTube account settings and try again."
          );
        });
      return true;
    } catch (error) {
      toast.error(
        "Failed to generate YouTube streaming URL. Please check your YouTube account settings and try again."
      );
    }
  };

  const startLive = async () => {
    const enabledPlatforms = {
      facebook: JSON.parse(localStorage.getItem("facebookStreamingResponse")),
      youtube: JSON.parse(localStorage.getItem("youtubeStreamingResponse")),
      instagram: JSON.parse(localStorage.getItem("instagramStreamingResponse")),
      inApp: userData?.user?.inApp_Streaming_key,
      test: localStorage.getItem("testFbResp"),
    };

    const activePlatforms = Object.values(enabledPlatforms).filter(Boolean);

    if (activePlatforms.length === 0) {
      toast.error(
        "Please connect at least one social media account to start the live stream."
      );
      return;
    }

    setIsLiveStream(true);
    setLiveStreamActive(true);

    const endPoints = [];
    const uuids = {
      insta: uuidv4(),
      fb: uuidv4(),
    };

    setUUIDS(uuids);

    const platformData = createPlatformData(enabledPlatforms, uuids);

    try {
      const response = await toggleLiveStreamingStatus({
        streaming_as: "guest",
        platform: platformData,
      });
      if (response?.status !== 200)
        throw new Error("Failed to start the live stream");

      await Promise.all(
        platformData.map(
          (platform) =>
            platform.status &&
            storeStreamingMatrics(createStreamingMetricsDTO(platform, uuids))
        )
      );

      await setupPlatforms(enabledPlatforms, endPoints);

      if (endPoints.length > 0) {
        await startLiveStreaming(endPoints);
        toast.success("Live stream started successfully.");
      }
    } catch (error) {
      toast.error("Failed to start the live stream. Please try again later.");
    }
  };

  const createPlatformData = (enabledPlatforms, uuids) => {
    return [
      {
        platform: "facebook_live",
        status: !!enabledPlatforms.facebook,
        videoId:
          enabledPlatforms.facebook?.id ||
          (enabledPlatforms.test ? uuids.fb : null),
        pageId: enabledPlatforms.facebook?.pageId,
        accessToken: enabledPlatforms.facebook?.pageAccessToken,
      },
      {
        platform: "youtube_live",
        status: !!enabledPlatforms.youtube,
        chatId: enabledPlatforms.youtube?.liveChatId,
        youtube_accessToken: JSON.parse(
          localStorage.getItem("YoutubeResponse")
        ),
      },
      {
        platform: "instagram_live",
        status: !!enabledPlatforms.instagram,
      },
      {
        platform: "in_app_live",
        status: !!enabledPlatforms.inApp,
      },
    ];
  };

  const createStreamingMetricsDTO = (platform, uuids) => {
    const platformMap = {
      facebook_live: "facebook",
      youtube_live: "youtube",
      instagram_live: "instagram",
      in_app_live: "in_app",
    };

    return {
      platform: platformMap[platform.platform],
      stream_id:
        platform.platform === "facebook_live"
          ? platform.videoId
          : platform.platform === "instagram_live"
          ? uuids.insta
          : platform.platform === "in_app_live"
          ? JSON.parse(localStorage.getItem("in_app_rtmp"))?.room
          : platform.chatId,
      streamTitle: streamMetaData?.title,
      streamDescription: streamMetaData?.description,
    };
  };

  const setupPlatforms = async (enabledPlatforms, endPoints) => {
    if (enabledPlatforms.facebook) await setupFacebook(endPoints);
    if (enabledPlatforms.youtube) await setupYoutube(endPoints);
    if (enabledPlatforms.instagram) await setupInstagram(endPoints);
    if (enabledPlatforms.inApp) await setupInApp(endPoints);
    if (enabledPlatforms.test) setupTest(endPoints);
  };

  const setupFacebook = async (endPoints) => {
    const facebookResponse = JSON.parse(
      localStorage.getItem("facebookStreamingResponse")
    );
    setFacebookObj({
      ...facebookObj,
      facebookLive: true,
      videoId: facebookResponse.videoId,
    });
    endPoints.push({ endpoint: facebookResponse.stream_url });
  };
  const setupYoutube = async (endPoints) => {
    const youtubeResponse = JSON.parse(
      localStorage.getItem("youtubeStreamingResponse")
    );
    setYoutubeObj({ ...youtubeObj, youtubeLive: true });
    endPoints.push({ endpoint: youtubeResponse.rtmpUrl });
  };

  const setupInstagram = async (endPoints) => {
    const instagramResponse = JSON.parse(
      localStorage.getItem("instagramStreamingResponse")
    );
    setInstaObj({ ...instaObj, isInstagramLive: true });
    endPoints.push({ endpoint: instagramResponse.rtmpUrl });
  };

  const setupInApp = async (endPoints) => {
    let fbObj = JSON.parse(localStorage.getItem("facebookStreamingResponse"));
    let ytobj = JSON.parse(localStorage.getItem("youtubeStreamingResponse"));
    if (!fbObj?.iframeUrl && !ytobj?.iframeUrl) {
      endPoints.push({
        endpoint: `${process.env.REACT_APP_UCHLO_STREAM_RTMP_URL}/${userData?.user?.inApp_Streaming_key}`,
      });
    }
    const data = {
      urls: {
        facebookUrl: fbObj?.iframeUrl || "",
        youtubeUrl: ytobj?.iframeUrl
          ? `https://www.youtube.com/embed/${ytobj?.iframeUrl}`
          : "",
        InAppUrl: userData?.user?._id,
        facebookPortait: orientation === "portrait",
        videoSource: fbObj?.iframeUrl || ytobj?.iframeUrl ? "Social" : "inApp",
        fb_token: fbObj?.iframeUrl ? fbObj.pageAccessToken : "",
        fb_vodId: fbObj?.iframeUrl ? fbObj.videoId : "",
      },
      orientation: orientation,
      room_name: roomName,
      is_active: true,
      is_display: true,
    };

    const resp = await updateInAppStreamRoomStatus(data);
    setInAppLiveUrl(resp?.data?.room);
  };

  const setupTest = (endPoints) => {
    const rtmp = JSON.parse(localStorage.getItem("testFbResp"));
    endPoints.push({ endpoint: rtmp });
    toast.success("Custom...");
  };

  const startLiveStreaming = async (endPoints) => {
    const { intro, bannerLowerThird, ticker, products, lowerThird, logo } =
      graphicsData;

    await dailyCallframe.startLiveStreaming({
      endpoints: endPoints,
      width: getStreamWidth(),
      height: getStreamHeight(),
      fps: 30,
      videoBitrate: 5000,
      audioBitrate: 320,
      minIdleTimeOut: 600,
      layout: {
        preset: "custom",
        composition_params: {
          mode: streamMode,
          "videoSettings.preferredParticipantIds": activeParticipant,
          "videoSettings.pip.position": "bottom-right",
          // * INTRO
          ...createIntroConfig(intro),

          // * LOWERTHIRD
          ...createBannerConfig(bannerLowerThird),

          // * TOAST
          ...createToastConfig(ticker),

          // * PRODUCTS
          ...createProductConfig(products, lowerThird),

          // * LOGO
          ...createLogoConfig(logo),
        },
        participants: {
          video: activeVideos,
          audio: ["*"],
          sort: "active",
        },
        session_assets: {
          "images/logo": `${process.env.REACT_APP_BASE_MAIN_URL}${logo[0]?.image}`,
        },
      },
    });

    await updateSendSettings();
  };

  const getStreamWidth = () => {
    return orientation === "landscape"
      ? 1920
      : orientation === "portrait"
      ? 720
      : 1080;
  };

  const getStreamHeight = () => {
    return orientation === "landscape"
      ? 1080
      : orientation === "portrait"
      ? 1280
      : 1080;
  };

  const createLogoConfig = (logo) => ({
    showImageOverlay: (logo?.length > 0 && logo?.isSaved) || false,
    "image.assetName": "logo",
    "image.position": logo[0]?.position || "top-left",
    "image.fullScreen": false,
    "image.aspectRatio": 1.078,
    "image.height_gu": 8,
    "image.margin_gu": 0,
    "image.opacity": logo[0]?.opacity,
    "image.enableFade": true,
    "image.zPosition": "foreground",
  });

  const createProductConfig = (products, lowerThird) => ({
    showWebFrameOverlay: (products?.isActive && lowerThird?.isSaved) || false,
    "webFrame.margin_gu": 0.8,
    "webFrame.height_gu":
      orientation === "portrait" ? 7 : orientation === "square" ? 7 : 7,
    "webFrame.viewportWidth_px":
      orientation === "portrait" ? 210 : orientation === "square" ? 210 : 210,
    "webFrame.viewportHeight_px":
      orientation === "portrait" ? 81 : orientation === "square" ? 81 : 81,
    "webFrame.position": lowerThird?.position || "top-left",
    "webFrame.url": `${process.env.REACT_APP_BASE_MAIN_URL}/${
      orientation === "portrait"
        ? "webframe-protrait"
        : orientation === "square"
        ? "webframe-square"
        : "webframe"
    }?code=${products?.productKeywords}&name=${products?.productName}&price=${
      products?.discount_price != 0
        ? products?.discount_price
        : products?.productPrice
    }&image=${products?.productImage?.split("images/")[1]}`,
  });

  const createIntroConfig = (intro) => ({
    showTitleSlate: (intro?.isActive && intro?.isSaved) || false,
    "titleSlate.title": `${intro?.title || ""}`,
    "titleSlate.subtitle": `${intro?.subtitle || ""}`,
    "titleSlate.titleAlign": "center",
    "titleSlate.bgColor": intro?.background
      ? intro?.background
      : "rgba(253, 197, 50, 1)",
    "titleSlate.textColor": intro?.textColor
      ? intro?.textColor
      : "rgba(0, 0, 0, 1)",
    "titleSlate.fontFamily": "SuezOne",
    "titleSlate.fontWeight": "400",
    "titleSlate.fontSize_gu": 5,
    "titleSlate.subtitle.fontSize_pct": 60,
    "titleSlate.subtitle.fontWeight": "400",
  });

  const createBannerConfig = (bannerLowerThird) => ({
    showBannerOverlay:
      (bannerLowerThird?.isActive && bannerLowerThird.isSaved) || false,
    "banner.title": bannerLowerThird?.topLine,
    "banner.subtitle": bannerLowerThird?.bottomLine,
    "banner.source": "param",
    "banner.position": bannerLowerThird?.position || "bottom-left",
    "banner.enableTransition": true,
    "banner.showIcon": bannerLowerThird?.isEnableEmoji,
    "banner.icon.emoji": bannerLowerThird?.emoji,
    "banner.title.fontSize_gu": bannerLowerThird?.titleFontSize,
    "banner.subtitle.fontSize_gu": bannerLowerThird?.subTitleFontSize,
    "banner.color": bannerLowerThird?.bgColor,
    "banner.text.color": bannerLowerThird?.textColor,
    "banner.text.fontFamily": bannerLowerThird?.fontFamily,
    "banner.margin_y_gu": 0,
    "banner.alwaysUseMaxW": false,
    "banner.maxW_pct_default": 65,
    "banner.maxW_pct_portrait": 90,
  });

  const createToastConfig = (ticker) => ({
    "toast.text": `${ticker?.text}`,
    "toast.color": "rgba(255, 69, 0, 1)",
    "toast.strokeColor": "rgba(255, 69, 0, 1)",
    "toast.text.color": "white",
    "toast.text.fontFamily": "SuezOne",
    "toast.duration_secs": 4,
    "toast.numTextLines": 3,
    "toast.text.fontSize_pct": 120,
    "toast.text.fontWeight": "400",
    "toast.key": ticker?.isActive === true ? new Date() : 0,
  });

  const updateSendSettings = async () => {
    await dailyCallframe.updateSendSettings({
      video: {
        maxQuality: "medium",
        encodings: {
          low: {
            maxBitrate: 200000,
            scaleResolutionDownBy: 4,
            maxFramerate: 15,
          },
          medium: {
            maxBitrate: 1200000,
            scaleResolutionDownBy: 1.333,
            maxFramerate: 30,
          },
        },
      },
    });
  };

  const endLiveStream = async () => {
    if (!window.confirm("This will end your live stream!!!.")) {
      return;
    }

    try {
      setIsLiveStream(false);
      setLiveStreamActive(false);
      setReadyToStream(false);
      setCountdown(10);
      setStreamLoading(false);

      if (dailyCallframe) {
        await dailyCallframe.stopLiveStreaming();
        await endFacebookStream();
        await updateStreamingStatus();
        await storeAllStreamingMetrics();
        await cleanupLocalStorage();
        await updateInAppStreamRoomStatus({
          room_name: roomName,
          is_active: false,
          is_display: true,
        });

        window.location.href = "/broadcast/home";
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again later.");
    }
  };

  const endFacebookStream = async () => {
    const fbData = JSON.parse(
      localStorage.getItem("facebookStreamingResponse")
    );
    if (fbData) {
      return new Promise((resolve) => {
        window.FB.api(
          `/${fbData.id}`,
          "POST",
          {
            end_live_video: true,
            access_token: fbData.pageAccessToken,
          },
          (response) => {
            resolve();
          }
        );
      });
    }
  };

  const updateStreamingStatus = async () => {
    const data = {
      streaming_as: "guest",
      platform: [
        {
          platform: "facebook_live",
          status: false,
          videoId: null,
          pageId: null,
          accessToken: null,
        },
        {
          platform: "youtube_live",
          status: false,
          chatId: null,
          youtube_accessToken: null,
        },
      ],
    };

    const response = await toggleLiveStreamingStatus(data);
    if (response?.status !== 200) {
      throw new Error("Failed to update streaming status");
    }
  };

  const storeAllStreamingMetrics = async () => {
    const platforms = [
      {
        name: "youtube",
        getId: () =>
          JSON.parse(localStorage.getItem("youtubeStreamingResponse"))
            ?.liveChatId,
      },
      {
        name: "facebook",
        getId: () => {
          const fbData = JSON.parse(
            localStorage.getItem("facebookStreamingResponse")
          );
          return (
            fbData?.id ||
            (localStorage.getItem("testFbResp") ? UUIDS.fbUUID : null)
          );
        },
      },
      { name: "instagram", getId: () => UUIDS.instaUUID },
      {
        name: "in_app",
        getId: () => JSON.parse(localStorage.getItem("in_app_rtmp"))?.room,
      },
    ];

    await Promise.all(
      platforms.map(async (platform) => {
        const dto = {
          platform: platform.name,
          stream_id: platform.getId() || null,
        };
        await storeStreamingMatrics(dto);
      })
    );

    await updateOfflineMatrics(
      JSON.parse(localStorage.getItem("facebookStreamingResponse"))?.id
    );
  };

  const cleanupLocalStorage = () => {
    const keysToRemove = [
      "facebookStreamingResponse",
      "youtubeStreamingResponse",
      "in_app_rtmp",
      "testFbResp",
      "YoutubeResponse",
      "instagramStreamingResponse",
      "facebookResponse",
      "youtubeResponse",
      "instagramResponse",
      "fbSelectedPage",
    ];

    keysToRemove.forEach((key) => localStorage.removeItem(key));
  };

  const updateLiveStreaming = async () => {
    const { intro, bannerLowerThird, ticker, products, lowerThird } =
      graphicsData;
    if (dailyCallframe) {
      await dailyCallframe.updateLiveStreaming({
        layout: {
          preset: "custom",
          composition_params: {
            mode: streamMode,
            "videoSettings.preferredParticipantIds": activeParticipant,
            "videoSettings.pip.position": "bottom-right",

            // * INTRO
            ...createIntroConfig(intro),

            // * LOWERTHIRD
            ...createBannerConfig(bannerLowerThird),

            // * TOAST
            ...createToastConfig(ticker),

            // * PRODUCTS
            ...createProductConfig(products, lowerThird),
          },
          participants: {
            video: activeVideos,
            audio: ["*"],
            sort: "active",
          },
        },
      });
      await updateSendSettings();
    }
  };

  const updateLiveStreamForAds = async (status) => {
    if (dailyCallframe) {
      await dailyCallframe.updateLiveStreaming({
        layout: {
          backgroundColor: "rgba(2, 100, 120, 1)",
          preset: "custom",
          composition_params: {
            mode: "grid",
            showTextOverlay: false,
            showTitleSlate: false,
            showWebFrameOverlay: status,

            "webFrame.fullScreen": true,
            "webFrame.url": `${process.env.REACT_APP_BASE_MAIN_URL}/uchlo-ads?file=${graphicsData?.ads?.file_name}&ad_type=${graphicsData?.ads?.ad_type}`,
          },
        },
      });
    }
  };

  const fetchRoomInfoFunc = async (room, is_active, is_display) => {
    const response = await getInAppStreamRoomInfo(room, is_active, is_display);
    if (response.status === 200) {
      setStreamMetaData({
        title: response.data.title,
        description: response.data.description,
        category: response.data.category,
        thumbnail: response.data.thumbnail,
      });
      return;
    }

    navigate("/broadcast/home");
    toast.error("Invalid room. Please create new stream");
  };

  const getAllGraphicsFunc = async () => {
    const result = await handleApiCall(getStreamGraphics);
    if (result.success) {
      const { logos, intro, lowerthird, toast, product, ads } =
        result?.data?.data;
      if (intro === null) {
        setIsEdit(true);
      }
      if (lowerthird === null) {
        setIsEditLowerThird(true);
      }
      if (toast === null) {
        setIsEditTicker(true);
      }
      if (product === null) {
        setIsEditProductGhps(true);
      }
      let activeLogoId = sessionStorage.getItem("activeLogoId");
      let activeAdId = sessionStorage.getItem("activeAdId");
      let activeProduct = JSON.parse(sessionStorage.getItem("activeProduct"));
      let activeLogo = [];
      let activeAd = [];
      if (activeLogoId) {
        activeLogo = logos.filter((logo) => logo._id === activeLogoId);
      } else {
        activeLogo = logos;
      }
      if (activeAdId) {
        activeAd = ads.filter((ad) => ad._id === activeAdId);
      } else {
        activeAd = ads;
      }
      setListStreamGraphics({
        ...listStreamGraphics,
        logo: logos,
        intro: intro,
        toast: toast,
        bannerLowerThird: lowerthird,
        products: product,
      });

      setgraphicsData({
        ...graphicsData,
        intro: {
          title: intro?.title,
          subtitle: intro?.subtitle,
          background: intro?.background_color,
          textColor: intro?.text_color,
          fontSize: intro?.font_size,
          fontFamily: intro?.font_family,
          isSaved: intro !== null ? true : false,
          isActive: false,
        },
        bannerLowerThird: {
          bgColor: lowerthird?.background_color,
          textColor: lowerthird?.text_color,
          titleFontSize: lowerthird?.title_font_size,
          subTitleFontSize: lowerthird?.subtitle_font_size,
          fontFamily: lowerthird?.font_family,
          topLine: lowerthird?.title,
          bottomLine: lowerthird?.subtitle,
          isEnableEmoji: lowerthird?.is_emoji,
          emoji: lowerthird?.emoji_icon,
          position: lowerthird?.positon,
          isActive: false,
          isSaved: lowerthird !== null ? true : false,
        },
        ticker: {
          text: toast?.text,
          isActive: false,
          isSaved: toast !== null ? true : false,
        },
        logo: [
          {
            image: activeLogo[0]?.logo_url,
            _id: activeLogo[0]?._id,
            opacity: activeLogo[0]?.opacity,
            height: activeLogo[0]?.height,
            margin: activeLogo[0]?.margin,
            position: activeLogo[0]?.position,
            isActive: false,
            isSaved: activeLogo.length > 0 ? true : false,
          },
        ],
        ads: {
          adId: activeAd[0]?._id,
          adTitle: activeAd[0]?.ad_title,
          adTimer: activeAd[0]?.timer,
          ad_link: activeAd[0]?.ad_link,
          file_name: activeAd[0]?.file_name,
          ad_type: activeAd[0]?.ad_type,
          isSaved: activeAd.length > 0 ? true : false,
          isActive: false,
        },
        products: {
          productId: activeProduct?._id,
          productName: activeProduct?.product_name,
          productPrice: activeProduct?.price,
          discount_price: activeProduct?.discount_price,
          productKeywords: activeProduct?.keyword,
          productImage: activeProduct?.thumbnail_image,
          isActive: false,
          isSaved: activeProduct !== null ? true : false,
        },
        lowerThird: {
          position: product?.position,
          isSaved: product !== null ? true : false,
        },
      });
    } else {
      toast.error(result.error);
    }
  };

  const leaveStudio = () => {
    if (
      window.confirm(
        "Do you want to leave the studio? You will lose all the settings."
      )
    ) {
      cleanupLocalStorage();
      sessionStorage.removeItem("roomActive");
      setIsLeftStudios(true);
      window.location.href = "/broadcast/home";
    }
  };

  const toggleGuestScreen = (participants) => {
    const matchingGuests = participants
      .map((session_id) => guests[session_id])
      .filter(Boolean);
    setScreenParticipants(
      matchingGuests.reduce((acc, participant) => {
        acc[participant.session_id] = {
          track: participant.tracks.video.track,
          userName: participant.user_name,
          audioTrack: participant.tracks.audio.track,
          isLocalUser: false,
        };
        return acc;
      }, {})
    );
    switch (participants.length) {
      case 1:
        setStreamMode("single");
        // setActiveVideos("*");
        // setActiveParticipant(participants[0]?.user_id);
        break;
      case 2:
        setStreamMode("split");
        setSplitScreenMode("dual");
        // setActiveVideos("*");
        // setActiveParticipant("*");
        break;
      case 3:
        setStreamMode("grid");
        setSplitScreenMode("triple");
        // setActiveVideos("*");
        // setActiveParticipant("*");
        break;
      case 4:
        setStreamMode("grid");
        setSplitScreenMode("quad");
        // setActiveVideos("*");
        // setActiveParticipant("*");
        break;
      default:
        break;
    }
  };
  // ! ----------------- ALL USEEFFECTS HERE   -----------------
  useEffect(() => {
    if (roomName) {
      fetchRoomInfoFunc(roomName, false, false);
      getAllGraphicsFunc();
    }
  }, [roomName]);

  useEffect(() => {
    // set facebook object
    let fbObj = JSON.parse(localStorage.getItem("facebookResponse"));
    if (fbObj) {
      setFacebookObj({
        ...facebookObj,
        username: fbObj.name,
        pageAccessToken: fbObj.access_token,
        pageId: fbObj.id,
        title: streamMetaData?.title,
      });
    }
  }, []);

  useEffect(() => {
    if (IsLiveStream) {
      updateLiveStreaming();
    }
  }, [graphicsData, IsLiveStream, streamMode]);

  useEffect(() => {
    if (graphicsData?.ads !== null && graphicsData?.ads?.isActive) {
      updateLiveStreamForAds(true);
    }
    if (graphicsData?.ads?.isActive === false) {
      updateLiveStreamForAds(false);
    }
  }, [graphicsData?.ads?.isActive]);

  useEffect(() => {
    if(location){
      const activeRoom = sessionStorage.getItem("roomActive");
      if(activeRoom === null || activeRoom === "undefined"){
        sessionStorage.setItem("roomActive", roomName);
      } else if(location.pathname !== `/streaming/${roomName}` && activeRoom !== roomName){
        window.location.href = `/streaming/${activeRoom}`;
      }
    }
  }, [location, roomName]);

  return (
    <StreamingContext.Provider
      value={{
        streamData,
        setStreamData,
        graphicsData,
        setgraphicsData,
        handleGraphicsData,
        allPages,
        setAllPages,
        facebookObj,
        setFacebookObj,
        instaObj,
        setInstaObj,
        youtubeObj,
        setYoutubeObj,
        chatHistory,
        setChatHistory,
        liveStreamActive,
        setLiveStreamActive,
        isOnMain,
        setIsOnMain,
        disabledComments,
        setDisabledComments,
        platforms,
        setPlatforms,
        state,
        setState,
        graphicState,
        setGraphicState,
        prodState,
        setProdState,
        oauthSessionExpired,
        setOauthSessionExpired,
        inAppLiveUrl,
        setInAppLiveUrl,
        SocialtoggleDrawer,
        socialDrawer,
        setSocialDrawer,
        HandleOptiontoggleMenu,
        optionToggleMenu,
        HandleMenuResp,
        menuResp,
        handleStreamModal,
        recordedStreamsModal,
        crossPostPages,
        setCrossPostPages,
        loadingCrossPostPages,
        setLoadingCrossPostPages,
        dailyCallframe,
        setDailyCallframe,
        orientation,
        setOrientation,
        streamMetaData,
        setStreamMetaData,
        streamAction,
        IsLiveStream,
        setIsLiveStream,
        UUIDS,
        setUUIDS,
        streamMode,
        setStreamMode,
        activeParticipant,
        setActiveParticipant,
        activeParticipantIndex,
        setActiveParticipantIndex,
        activeParticipantsIndex,
        setActiveParticipantsIndex,
        activeVideos,
        setActiveVideos,
        pipActiveCam,
        setPipActiveCam,
        startLive,
        readyToStream,
        setReadyToStream,
        streamLoading,
        setStreamLoading,
        countdown,
        endLiveStream,
        listStreamGraphics,
        setListStreamGraphics,
        getAllGraphicsFunc,
        leaveStudio,
        drawerRight,
        setDrawerRight,
        guestDrawer,
        setGuestDrawer,
        roomName,
        guests,
        setGuests,
        toggleGuestScreen,
        splitScreenMode,
        setSplitScreenMode,
        activeParticipantSession,
        setActiveParticipantSession,
        isAddPanelOpen,
        setIsAddPanelOpen,
        setIsAddsAddPanelOpen,
        isAddsAddPanelOpen,
        screenParticipants,
        setScreenParticipants,
        IntroState,
        setIntroState,
        isEdit,
        setIsEdit,
        lowerthirdState,
        setLowerthirdState,
        isEditLowerThird,
        setIsEditLowerThird,
        ticker,
        setTicker,
        isEditTicker,
        setIsEditTicker,
        position,
        setPosition,
        isEditProductGhps,
        setIsEditProductGhps,
        isLeftStudios,
      }}
    >
      {children}
    </StreamingContext.Provider>
  );
};
