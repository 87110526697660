import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DataTable from "react-data-table-component";
import { customStyles } from "./customStyle";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  createOrder,
  deleteOrders,
  getAllOrdersForBusinessUsers,
  searchOrders,
  updateOrders,
} from "../../api/OrderApis";
import { toast } from "../Toast";
import { useReactToPrint } from "react-to-print";
import InvoicePDF from "./InvoicePDF";
import { countries } from "../../utils/CountryCodes";
import { getAllProducts } from "../../api/ProductApis";
import EmptyView from "../../assets/Img/test.png";
import { AuthContext } from "../../context/AuthContext";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import {
  bookPacket,
  cancelBookedPackets,
  getAllCities,
  trackBookedPacket,
} from "../../api/LeopardApis";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SimCardDownloadRoundedIcon from "@mui/icons-material/SimCardDownloadRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseIcon from "@mui/icons-material/Close";

import { Link, useNavigate } from "react-router-dom";
import { cities } from "../../utils/leoapardCities";

const SubHeaderComponent = ({
  search,
  handleDelete,
  setFilterOrder,
  status,
  isSearched,
  handleSearch,
  setStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState({
    profile: false,
    anchor: "",
    notifyAnchor: null,
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (list, event) => {
    setOpen({ ...open, [list]: !open[list], anchor: event.currentTarget });
  };

  const handleCloseAn = (list) => {
    setOpen({ ...open, [list]: false });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "end",
      }}
    >
      <Box
        sx={{
          border: "1px solid #707070",
          borderRadius: "40px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: "10px",
        }}
      >
        <InputBase
          value={search}
          placeholder="Filter Orders"
          onChange={(e) => setFilterOrder(e.target.value)}
        />
        <Button sx={{ m: "0", p: "0", minWidth: "0" }} onClick={handleSearch}>
          {isSearched ? (
            <CloseRoundedIcon />
          ) : (
            <SearchIcon sx={{ color: "#707070" }} />
          )}
        </Button>
      </Box>
      <Box>
        <Button
          sx={{
            color: "#707070",
            background: "#fff",
            //   border: "1px solid #707070",
            py: "2px",
          }}
          onClick={(e) => handleToggle("profile", e)}
        >
          {status}
          <ArrowDropDownIcon />
        </Button>
        <Menu
          anchorEl={open.anchor}
          open={open.profile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => handleCloseAn("profile")}
          sx={{ "& .MuiPaper-root": { background: "#8E8E8E", color: "#fff" } }}
        >
          <MenuItem
            value={"all"}
            onClick={() => {
              setStatus("all");
              setOpen({ ...open, profile: false });
            }}
            sx={{ fontSize: "13px", py: "4px" }}
          >
            All
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", py: "4px" }}
            onClick={() => {
              setStatus("pending");
              setOpen({ ...open, profile: false });
            }}
          >
            Pending
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", py: "4px" }}
            onClick={() => {
              setStatus("accepted");
              setOpen({ ...open, profile: false });
            }}
          >
            Accepted
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", py: "4px" }}
            onClick={() => {
              setStatus("dispatched");
              setOpen({ ...open, profile: false });
            }}
          >
            Dispatched
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", py: "4px" }}
            onClick={() => {
              setStatus("fullfilled");
              setOpen({ ...open, profile: false });
            }}
          >
            Fulfilled
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", py: "4px" }}
            onClick={() => {
              setStatus("cancelled");
              setOpen({ ...open, profile: false });
            }}
          >
            Cancelled
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "13px", py: "4px" }}
            onClick={() => {
              setStatus("return");
              setOpen({ ...open, profile: false });
            }}
          >
            Return
          </MenuItem>
        </Menu>
      </Box>
      {/* <Button
        sx={{
          color: "#707070",
          background: "#fff",
          //   border: "1px solid #707070",
          py: "2px",
        }}
      >
        <FilterListIcon /> Filter
      </Button>
      <Button
        sx={{
          color: "#707070",
          background: "#fff",
          //   border: "1px solid #707070",
          py: "2px",
        }}
      >
        <SortByAlphaIcon /> Sort
      </Button> */}
      <Box>
        <Button
          size="small"
          onClick={handleMenu}
          sx={{
            background: "#fff",
            color: "#707070",
            // border: "1px solid #707070",
            width: "100%",
            height: "30px",
            minWidth: "0",
          }}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem
            onClick={() => {
              handleUpdateOrder();
              handleClose();
            }}
          >
            Update
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              handleDelete();
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

const orderInit = {
  dialog: false,
  name: "",
  product: "",
  address: "",
  city: {},
  countryCode: countries[0],
  phoneNumber: "",
  postal_code: "",
  status: "",
  contact: "",
  quantity: 0,
  loading: false,
  email: "",
};

const OrdersComp = () => {
  const [loading, setLoading] = useState({
    prodLoading: false,
    orderLoading: false,
  });
  const { userData } = useContext(AuthContext);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState();
  const [limit, setLimit] = useState(5);

  const [selectedOrder, setSelectedOrder] = useState({});
  const [dialog, setDialog] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleCloseOrderDialog = () => {
    setOrderState({ ...orderState, dialog: false });
  };
  const [search, setSearch] = React.useState("");
  const [isSearched, setIsSearched] = React.useState(false);

  const [prevDialog, setPrevDialog] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [categroy, setCategory] = React.useState("all");
  const [leopardCities, setLeopardCities] = useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState({
    main: null,
    sub: null,
    actionAn: null,
    selected: {},
  });

  const [orderState, setOrderState] = React.useState(orderInit);
  const [parentOp, setParentOp] = React.useState([]);
  const [selectedSingleOp, setSelectedSingleOp] = React.useState({});
  const [childOp, setChildOp] = React.useState([]);
  const [selectedChildOp, setSelectedChildOp] = React.useState({});
  const [state, setState] = useState({
    selected: {},
    prevDialog: false,
    trakingDialog: false,
    completeDialog: false,
    bookPacketDialog: false,
    bookingPacket: false,
  });

  const componentRef = useRef();

  const formatDate = (date) => {
    const inputDate = new Date(date);

    // Formatting options
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
      // timeZoneName: "short",
    };

    return inputDate.toLocaleDateString("en-US", options);
  };

  const handleOpen = (event, anchor) => {
    setAnchorEl({ ...anchorEl, [anchor]: event.currentTarget });
  };

  const handleOpenStatus = (e, item) => {
    handleOpen(e, "sub");
    setSelectedOrder(item);
  };

  const handleUpdateStatus = async (status) => {
    let dataArr = [
      {
        ...selectedOrder,
        status,
      },
    ];

    const response = await updateOrders(dataArr);
    if (response.status === 200) {
      toast.success("Order successfully updated.");
    } else {
      toast.error(response.data.message);
    }

    setAnchorEl({ ...anchorEl, sub: null });
    fetchAllOrders(page, limit);
  };

  const getLeopardCities = async () => {
    // const citiesData = await getAllCities();
    setLeopardCities(cities);
  };

  const fetchAllOrders = async (page, limit) => {
    setLoading((prev) => ({ ...prev, orderLoading: true }));
    const resp = await getAllOrdersForBusinessUsers(page, limit, categroy);
    if (resp?.status === 200) {
      setData(resp.data.orders);
      setPage(resp.data.currentPage);
      setTotalOrders(resp.data.totalOrders);
    }
    setLoading((prev) => ({ ...prev, orderLoading: false }));
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleChangeProduct = (key, event) => {
    setOrderState({
      ...orderState,
      [key]: JSON.parse(event.target.value),
      quantity: 1,
      isEdited: true,
    });
  };
  const handleChangeParentOp = (event) => {
    const selectedValue = event.target.value;
    setSelectedSingleOp(selectedValue);
    if (selectedValue?.groups.length > 0) {
      setChildOp(selectedValue?.groups);
    }
  };

  const handleChangeGroup = (event) => {
    const selectedValue = event.target.value;
    setSelectedChildOp(selectedValue);
  };

  useEffect(() => {
    if (orderState.product) {
      let firtsVarient =
        orderState?.product?.variants[0] &&
        orderState?.product?.variants[0]?.options;
      setParentOp(firtsVarient);
      if (firtsVarient && firtsVarient.length > 0) {
        setSelectedSingleOp(firtsVarient[0]);
      } else {
        setSelectedSingleOp({});
      }
      if (firtsVarient && firtsVarient[0]?.groups?.length > 0) {
        setChildOp(firtsVarient[0]?.groups);
        setSelectedChildOp(firtsVarient[0]?.groups[0]);
      } else {
        setChildOp([]);
        setSelectedChildOp({});
      }
    }
  }, [orderState]);

  const handleUpdate = async () => {
    if (selectedOrder === "{}") {
      return;
    }

    const resp = await updateOrders([selectedOrder]);

    if (resp.status === 200) {
      setSelectedOrder({});
      setDialog(false);
      fetchAllOrders(page, limit);
      toast.success(resp?.data?.message);
    } else {
      toast.error(resp?.data?.message);
    }
  };

  function validateEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleUpdateOrder = async () => {
    if (
      !validateEmail(state.selected?.shipping_details?.email) ||
      state.selected?.shipping_details?.email === ""
    ) {
      toast.error("Please provide a valid email address");
      return;
    }

    const resp = await updateOrders([state.selected]);

    if (resp.status === 200) {
      setState({ ...state, completeDialog: false });
      setTimeout(() => fetchAllOrders(page, limit), 2000);
      toast.success(resp?.data?.message);
    } else {
      toast.error(resp?.data?.message);
    }
    setAnchorEl({ ...anchorEl, actionAn: null });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchAllProducts = async () => {
    setLoading((prev) => ({ ...prev, prodLoading: true }));

    const resp = await getAllProducts();
    if (resp?.status === 200) {
      setProducts(resp.data.products);
    }
    setLoading((prev) => ({ ...prev, prodLoading: false }));
  };

  const handleChange = (key, event) => {
    setOrderState({ ...orderState, [key]: event.target.value });
  };

  const validateFormData = () => {
    if (
      !orderState.product ||
      !orderState.name ||
      // !orderState.postalCode ||
      !orderState.address ||
      !orderState.city?.name ||
      !orderState.phoneNumber ||
      !orderState.email ||
      orderState.quantity === 0
    ) {
      toast.error("Please make sure to fill in all the required fields.");
      return false;
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    if (!emailRegex.test(orderState.email)) {
      toast.error("Please enter valid email address.");
      return false; // Validation failed
    }

    // Validate name as a non-empty string
    if (
      typeof orderState.name !== "string" ||
      orderState.name.trim().length === 0
    ) {
      toast.error("Name must be a non-empty string.");
      return false; // Validation failed
    }

    // // Validate postalCode as a number
    // if (isNaN(orderState.postalCode)) {
    //   toast.error("Error: Postal code must be a number.");
    //   return false; // Validation failed
    // }

    // Validate address as a string with at least one number
    if (
      typeof orderState.address !== "string" ||
      !/\d/.test(orderState.address)
    ) {
      toast.error("Address must be a string containing at least one number.");
      return false; // Validation failed
    }

    // Validate phoneNumber as a proper Pakistani number format
    const phoneNumberRegex = /^(\+92|92|0)?3\d{9}$/;
    if (
      !phoneNumberRegex.test(
        orderState.countryCode.dial_code + orderState.phoneNumber
      )
    ) {
      toast.error("Phone number must be in proper format.");
      return false; // Validation failed
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFormData()) {
      return;
    }

    let price =
      Object.keys(selectedSingleOp).length === 0 &&
      Object.keys(selectedChildOp).length === 0
        ? orderState?.product?.discount_price > 0
          ? orderState?.product?.discount_price * orderState?.quantity >
            userData?.user?.store?.delivery?.free_delivery_amount
            ? orderState?.product?.discount_price * orderState?.quantity
            : orderState?.product?.discount_price * orderState?.quantity +
              (userData?.user?.store?.delivery?.delivery_charges || 0)
          : orderState?.product?.price * orderState?.quantity >
            userData?.user?.store?.delivery?.free_delivery_amount
          ? orderState?.product?.price * orderState?.quantity
          : orderState?.product?.price * orderState?.quantity +
            (userData?.user?.store?.delivery?.delivery_charges || 0)
        : Object.keys(selectedSingleOp).length > 0 &&
          Object.keys(selectedChildOp).length === 0
        ? selectedSingleOp?.discounted_price > 0
          ? selectedSingleOp?.discounted_price * orderState?.quantity >
            userData?.user?.store?.delivery?.free_delivery_amount
            ? selectedSingleOp?.discounted_price * orderState?.quantity
            : selectedSingleOp?.discounted_price * orderState?.quantity +
              (userData?.user?.store?.delivery?.delivery_charges || 0)
          : selectedSingleOp?.v_price * orderState?.quantity >
            userData?.user?.store?.delivery?.free_delivery_amount
          ? selectedSingleOp?.v_price * orderState?.quantity
          : selectedSingleOp?.v_price * orderState?.quantity +
            (userData?.user?.store?.delivery?.delivery_charges || 0)
        : selectedChildOp?.discounted_price > 0
        ? selectedChildOp?.discounted_price * orderState?.quantity >
          userData?.user?.store?.delivery?.free_delivery_amount
          ? selectedChildOp?.discounted_price * orderState?.quantity
          : selectedChildOp?.discounted_price * orderState?.quantity +
            (userData?.user?.store?.delivery?.delivery_charges || 0)
        : selectedChildOp?.v_price * orderState?.quantity >
          userData?.user?.store?.delivery?.free_delivery_amount
        ? selectedChildOp?.v_price * orderState?.quantity
        : selectedChildOp?.v_price * orderState?.quantity +
          (userData?.user?.store?.delivery?.delivery_charges || 0);
    const makeProductArr = () => {
      if (
        Object.keys(selectedSingleOp).length === 0 &&
        Object.keys(selectedChildOp).length === 0
      ) {
        return [
          {
            product_id: orderState.product._id,
            quantity: orderState.quantity,
            is_varient: false,
            variant: "default",
            discount_price: orderState.product?.discount_price,
            option_id: null,
            price: orderState.product?.price,
          },
        ];
      } else if (
        Object.keys(selectedSingleOp).length > 0 &&
        Object.keys(selectedChildOp).length === 0
      ) {
        return [
          {
            product_id: orderState.product._id,
            option_id: selectedSingleOp._id,
            quantity: orderState.quantity,
            is_varient: true,
            variant: selectedSingleOp?.type,
            discount_price: selectedSingleOp?.discounted_price,
            price: selectedSingleOp?.v_price,
          },
        ];
      } else {
        return [
          {
            group_id: selectedChildOp._id,
            option_id: selectedSingleOp._id,
            quantity: orderState.quantity,
            is_varient: true,
            variant: selectedSingleOp?.type + "-" + selectedChildOp?.type,
            discount_price: selectedChildOp?.discounted_price,
            product_id: orderState.product._id,
            price: selectedChildOp?.v_price,
          },
        ];
      }
    };

    let data = {
      products: makeProductArr(),
      shipping_details: {
        full_name: orderState.name,
        address: orderState.address,
        phone_number:
          orderState.countryCode?.dial_code + "-" + orderState.phoneNumber,
        city: orderState.city,
        postal_code: orderState.postalCode,
        email: orderState.email,
      },
      total_price: price,
      status: orderState?.status,
      source: "store",
      payment_details: { payment_method: "COD" },
      user_id: userData?.user?._id,
      delivery: {},
      delivery_charges: userData?.user?.store?.delivery?.delivery_charges,
    };

    setOrderState({ ...orderState, loading: true });

    try {
      const response = await createOrder(data);
      if (response.status === 200) {
        fetchAllOrders(page, limit);
        setOrderState(orderInit);
        setSelectedChildOp({});
        setSelectedSingleOp({});
        setParentOp([]);
        setChildOp([]);
        toast.success("Order created successfully.");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to create order. Please try again later.");
    }

    setOrderState(orderInit);
  };

  const handleRowSelect = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleDelete = async () => {
    let ids = selectedRows?.map((row) => row?._id);

    if (!ids.length > 0) {
      return;
    }

    const response = await deleteOrders(ids);

    if (response?.status === 200) {
      toast.success("Order successfully deleted.");
    } else {
      toast.error(response.data.message);
    }

    fetchAllOrders(page, limit);
  };

  const isFilled = (name, address, city) => {
    if (name === "" || address === "" || JSON.stringify(city) === "{}") {
      return false;
    }
    return true;
  };

  const handleBookPacket = async (uchloAsShipper) => {
    setState({ ...state, bookingPacket: true });

    if (uchloAsShipper) {
      state.selected.shipment_name_eng =
        process.env.REACT_APP_SHIPMENT_NAME_ENG;
      state.selected.shipment_email = process.env.REACT_APP_SHIPMENT_EMAIL;
      state.selected.shipment_phone = process.env.REACT_APP_SHIPMENT_PHONE;
      state.selected.shipment_address = process.env.REACT_APP_SHIPMENT_ADDRESS;
    } else {
      state.selected.shipment_name_eng =
        userData?.user?.store?.store_shipper?.shipment_name;
      state.selected.shipment_email =
        userData?.user?.store?.store_shipper?.shipment_email;
      state.selected.shipment_phone =
        userData?.user?.store?.store_shipper?.shipment_phone;
      state.selected.shipment_address =
        userData?.user?.store?.store_shipper?.shipment_address;
    }

    const resp = await bookPacket(state.selected);

    setState({ ...state, bookingPacket: false });
    setState({ ...state, bookPacketDialog: false });

    if (resp?.status !== 200) {
      toast.error("Failed to book the packet. Please try again later.");
    } else {
      toast.success("Packet successfully booked!");
    }

    setTimeout(() => fetchAllOrders(page, limit), 1000);
  };

  const handleOpenSlip = (delivery) => {
    window.open(delivery.slip_link, "_blank");
  };

  const handleDialog = (item) => {
    setState({ ...state, selected: item, prevDialog: true });
  };

  const shareWhatsApp = () => {
    let header;
    if (state?.selected?.delivery?.track_number) {
      header = `Your Order ID is ${state.selected?.order_number}, with Tracking Number: ${state?.selected?.delivery?.track_number} and we are delighted to inform you that your order is ready for dispatch. Kindly confirm this order by replying to this message.`;
    } else {
      header = `Your Order ID is ${state.selected?.order_number}, and we are delighted to inform you that your order is ready for dispatch. Kindly confirm this order by replying to this message.`;
    }

    let encodedText = encodeURIComponent(
      `*Order Confirmation*
      Dear ${state.selected?.shipping_details[0]?.full_name}

      ${header}
  
      *Order Details*
      Product: ${state.selected?.products[0]?.product_id?.productName} 
      Price: 
      if(${state.selected?.products[0]?.product_id?.discount_price}>0){
        ${state.selected?.products[0]?.product_id?.discount_price} 
      }
      else{
        ${state.selected?.products[0]?.product_id?.price}
      }
      Quantity: ${state.selected?.products[0]?.quantity} 
      Total Price: ${state.selected?.total_price} 

      *Billing Details*
      Purchaser: ${state.selected?.shipping_details[0]?.full_name}
      Address: ${state.selected?.shipping_details[0]?.address} 
      City: ${state.selected?.shipping_details[0]?.city?.name}  
      Postal Code: ${state.selected?.shipping_details[0]?.postal_code} 
     
      Thank you for choosing us for your purchase. If you have any further questions or require additional information, please don't hesitate to contact us.

      Best Regards,`
    );

    const dest = `https://wa.me/${
      state.selected?.shipping_details[0]?.phone_number.split("-")[0]
    }${
      state.selected?.shipping_details[0]?.phone_number.split("-")[1]
    }?text=${encodedText}`;

    window.open(dest, "_blank");
  };

  const handleMenu = (event, row) => {
    setAnchorEl({ ...anchorEl, actionAn: event.currentTarget, selected: row });
  };

  const searchAllOrders = async (page, limit) => {
    setLoading({ ...loading, orderLoading: true });

    try {
      const resp = await searchOrders(search, page, limit, categroy);

      if (resp?.status === 200) {
        setData(resp.data.orders);
        setTotalOrders(resp.data.totalOrders);
        setPage(resp.data.currentPage);
      }
    } catch (error) {
      toast.error("Failed to fetch orders. Please try again later.");
    } finally {
      setLoading({ ...loading, orderLoading: false });
    }
  };

  const handleCancelPacket = async () => {
    setAnchorEl({ ...anchorEl, actionAn: null });
    if (!anchorEl.selected?.delivery?.track_number) {
      toast.error("Please book the packet before tracking it.");
      return;
    }
    setLoading((prev) => ({ ...prev, orderLoading: true }));

    await cancelBookedPackets({
      track_number: anchorEl.selected.delivery.track_number,
      _id: anchorEl.selected._id,
    });

    setLoading((prev) => ({ ...prev, orderLoading: false }));

    fetchAllOrders(page, limit);
  };

  const handleTrackPacket = async (row) => {
    setAnchorEl({ ...anchorEl, actionAn: null });

    if (!row?.delivery?.track_number) {
      toast.error("Please book the packet before tracking it.");
      return;
    }
    setLoading({ ...loading, orderLoading: true });

    const resp = await trackBookedPacket({
      track_number: row.delivery.track_number,
    });
    setLoading({ ...loading, orderLoading: false });

    setSelectedOrder(resp?.data?.packet[0]);
    setState({ ...state, trakingDialog: true });
  };

  const handleBookPacketDialog = (row) => {
    row.delivery?.slip_link
      ? handleOpenSlip(row.delivery)
      : isFilled(
          row?.shipping_details?.full_name,
          row?.shipping_details?.address,
          row?.shipping_details?.city
        )
      ? setState({ ...state, bookPacketDialog: true, selected: row })
      : setState({ ...state, completeDialog: true, selected: row });
  };

  const handlePageChange = async (page) => {
    setPage(page);
    if (!isSearched) {
      fetchAllOrders(page, limit);
    } else {
      // searchAllProducts(page, limit);
    }
  };

  const handleRowChange = async (row) => {
    setLimit(row);
    if (!isSearched) {
      fetchAllOrders(page, row);
    } else {
      // searchAllProducts(page, row);
    }
  };

  const handleSearch = () => {
    if (isSearched) {
      setPage(1);
      setIsSearched(false);
      setSearch("");
      fetchAllOrders(1, limit);
    } else {
      setIsSearched(true);
      searchAllOrders(1, limit);
    }
  };

  useEffect(() => {
    fetchAllOrders(page, limit);
    fetchAllProducts();
    getLeopardCities();
  }, []);

  useEffect(() => {
    if (isSearched) {
      searchAllOrders(1, limit);
    } else {
      fetchAllOrders(page, limit);
    }
  }, [categroy]);

  const columns = [
    {
      name: <Typography sx={{ fontSize: "13px" }}>Order Id</Typography>,
      selector: (row) => (
        <Button
          sx={{ p: "0", m: "0", minWidth: "0" }}
          onClick={() => handleDialog(row)}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              textDecoration: "underline",
            }}
          >
            {row?.order_number}
          </Typography>
        </Button>
      ),
      center: true,
      minWidth: "100px",
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Date & Time</Typography>,
      selector: (row) => formatDate(row?.createdAt),
      minWidth: "170px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Customer</Typography>,
      selector: (row) => {
        return row?.shipping_details?.full_name;
      },
      minWidth: "150px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Source</Typography>,
      selector: (row) => (
        <Typography sx={{ fontSize: "13px", textTransform: "uppercase " }}>
          {row?.source}
        </Typography>
      ),
      minWidth: "150px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>City</Typography>,
      selector: (row) => row?.shipping_details?.city?.name,
      minWidth: "100px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Contact</Typography>,
      selector: (row) => row?.shipping_details?.phone_number,
      minWidth: "150px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Price</Typography>,
      selector: (row) => row?.total_price,
      // sortable: true,
      minWidth: "80px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Status</Typography>,
      selector: (row) => (
        // <Box
        //   sx={{
        //     m: "0",
        //     minWidth: "90px",
        //     borderRadius: "10px",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     gap: 0.5,
        //     color:
        //       row.status === "Cancelled" || row.status === "Fulfilled"
        //         ? "#fff"
        //         : row.status === "In Progress"
        //         ? "#E6A31E"
        //         : row.status === "Return"
        //         ? "#FF3333"
        //         : "#59C894",
        //     background:
        //       row.status === "Cancelled"
        //         ? "#FF3333"
        //         : row.status === "Fulfilled"
        //         ? "#59C894"
        //         : "transparent",
        //     border:
        //       row.status === "In Progress"
        //         ? "1px solid #E6A31E"
        //         : row.status === "Return"
        //         ? "1px solid #FF3333"
        //         : row.status === "Dispatched"
        //         ? "1px solid #59C894"
        //         : "#fff",
        //   }}
        //   // onClick={() => handleRowSelect(row, setDialog)}
        // >
        //   <FiberManualRecordIcon sx={{ fontSize: "12px" }} /> {row?.status}
        // </Box>

        <Button
          sx={{
            py: "0",
            px: "7px",
            m: "0",
            minWidth: "100px",
            borderRadius: "10px",
            background:
              row.status === "cancelled"
                ? "#FFCCCB"
                : row.status === "pending"
                ? "#FFD580"
                : row.status === "dispatched"
                ? "#F4BB44"
                : row.status === "fullfilled"
                ? "#90ee90"
                : row.status === "return"
                ? "#FFA500"
                : "#00A36C",
            ":hover": { background: "#A7DDFF" },
            height: "20px",
            fontSize: "11px",
            color: "black",
            zIndex: "10",
            width: "100%",
          }}
          onClick={(e) => handleOpenStatus(e, row)}
        >
          {row?.status}
          <KeyboardArrowDownRoundedIcon sx={{ fontSize: "15px", ml: "auto" }} />
        </Button>
      ),
      minWidth: "150px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Tracking No.</Typography>,
      selector: (row) => (
        <Button
          sx={{ p: "0", m: "0", minWidth: "0" }}
          onClick={() => handleTrackPacket(row)}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              textDecoration: row?.delivery?.track_number
                ? "underline"
                : "none",
            }}
          >
            {row?.delivery?.track_number || "Not Found"}
          </Typography>
        </Button>
      ),
      minWidth: "120px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Booking Date</Typography>,
      selector: (row) => row?.delivery?.booking_date || "Not Found",
      minWidth: "120px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Book Packet</Typography>,
      selector: (row) => (
        <Box>
          <Button
            sx={{
              height: "20px",
              fontSize: "11px",
              width: "100%",
              borderRadius: "12px",
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => handleBookPacketDialog(row)}
          >
            {row?.delivery?.slip_link
              ? "Open Slip"
              : isFilled(
                  row?.shipping_details?.full_name,
                  row?.shipping_details?.address,
                  row?.shipping_details?.city
                )
              ? "Book"
              : "InComplete"}
          </Button>
        </Box>
      ),
      minWidth: "120px",
      center: true,
    },
    {
      name: <Typography sx={{ fontSize: "13px" }}>Action</Typography>,
      selector: (row) => (
        <Box>
          <Button
            size="small"
            sx={{
              background: "#fff",
              color: "#707070",
              // border: "1px solid #707070",
              width: "100%",
              height: "30px",
              minWidth: "0",
            }}
            onClick={(e) => handleMenu(e, row)}
          >
            <MoreHorizIcon />
          </Button>
          <Menu
            anchorEl={anchorEl.actionAn}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl.actionAn)}
            onClose={() => setAnchorEl({ ...anchorEl, actionAn: null })}
          >
            <MenuItem
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  completeDialog: true,
                  selected: anchorEl.selected,
                }))
              }
            >
              Edit
            </MenuItem>
            <MenuItem onClick={handleCancelPacket}>Cancel</MenuItem>
            <MenuItem onClick={() => handleTrackPacket(anchorEl.selected)}>
              Track Packet
            </MenuItem>
          </Menu>
        </Box>
      ),
      minWidth: "120px",
      center: true,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "visible",
        position: "relative",
      }}
    >
      {(loading.orderLoading || loading.prodLoading) && (
        <LinearProgress
          sx={{ position: "absolute", top: "-10px", width: "100%" }}
        />
      )}

      <Box
        sx={{
          overflowY: "auto",
        }}
        className="scrollable-content"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            mb: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: "15px",
              gap: 1,
            }}
          >
            <Box
              sx={{ width: "5px", background: "#FDC532", height: "25px" }}
            ></Box>
            <Typography
              sx={{
                display: "flex",
                fontWeight: "500",
                color: "#707070",
                fontSize: "26px",
                lineHeight: "1",
              }}
            >
              Orders
            </Typography>
          </Box>

          <Button
            sx={{
              background: "#322F2F",
              color: "#fff",
              borderRadius: "20px",
              px: "20px",
              ":hover": { background: "#322F2F" },
            }}
            onClick={() => setOrderState({ ...orderState, dialog: true })}
          >
            Create Order
          </Button>
        </Box>

        <Box
          sx={{
            width: "100%",
            //   height: "100%",
            // border: "1px solid #00000029",
            // overflowX: "auto",
            borderRadius: "20px",
            // minWidth: "450px",
            minWidth: "300px",
            typography: "body1",
          }}
        >
          <DataTable
            data={data}
            columns={columns}
            customStyles={customStyles}
            selectableRows
            responsive
            onSelectedRowsChange={handleRowSelect}
            subHeader
            subHeaderComponent={
              <SubHeaderComponent
                setFilterOrder={setSearch}
                search={search}
                handleUpdateOrder={handleUpdate}
                status={categroy}
                handleDelete={handleDelete}
                isSearched={isSearched}
                handleSearch={handleSearch}
                setStatus={setCategory}
              />
            }
            pagination
            paginationServer
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[5, 10, 15]}
            paginationTotalRows={totalOrders}
            onChangeRowsPerPage={(row) => handleRowChange(row)}
            onChangePage={(page) => handlePageChange(page)}
          />
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl.sub}
        open={Boolean(anchorEl.sub)}
        onClose={() => setAnchorEl({ ...anchorEl, sub: null })}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#A7DDFF",
            // width: "220px",
            borderRadius: "10px",
            mt: "10px",
            // boxShadow: "none",
            "& > ul": {
              py: "1px",
            },
          },
        }}
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: "right",
        // }}
      >
        <MenuItem
          sx={{ fontSize: "12px", py: "4px" }}
          onClick={() => handleUpdateStatus("pending")}
        >
          Pending
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "12px", py: "4px" }}
          onClick={() => handleUpdateStatus("accepted")}
        >
          Accepted
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "12px", py: "4px" }}
          onClick={() => handleUpdateStatus("dispatched")}
        >
          Dispatched
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "12px", py: "4px" }}
          onClick={() => handleUpdateStatus("fullfilled")}
        >
          Fulfilled
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "12px", py: "4px" }}
          onClick={() => handleUpdateStatus("cancelled")}
        >
          Cancelled
        </MenuItem>
        <MenuItem
          sx={{ fontSize: "11px", py: "4px" }}
          onClick={() => handleUpdateStatus("return")}
        >
          Return
        </MenuItem>
      </Menu>

      <Dialog
        open={dialog}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"body"}
        // fullWidth={true}
        // maxWidth={"sm"}
        // sx={{
        //   "& .MuiDialog-container": {
        //     "& .MuiPaper-root": {
        //       width: "100%",
        //       maxWidth: {
        //         xs: "250px",
        //         xsPlus: "400px",
        //         md: edit ? "500px" : "700px",
        //         lg: edit ? "500px" : "800px",
        //       }, // Set your width here
        //     },
        //   },
        // }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#fff",
            position: "relative",
            padding: "25px",
            overflow: "visible",
            // p: { xs: "20px", md: "20px", lg: "40px" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl sx={{ minWidth: 120, width: "100%" }} size="small">
              <InputLabel id="demo-select-small">Delivery Status</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                // value={selectedOrder?.status}
                label="Delivery Status"
                // onChange={handleStatusChange}
              >
                <MenuItem value={"pending"}>pending</MenuItem>
                <MenuItem value={"processing"}>processing</MenuItem>
                <MenuItem value={"completed"}>completed</MenuItem>
                <MenuItem value={"cancelled"}>cancelled</MenuItem>
              </Select>
            </FormControl>
            <Button
              sx={{ background: "#24a0ed", color: "#fff" }}
              //   onClick={handleUpdate}
            >
              Save
            </Button>
          </Box>

          <Button
            style={{
              position: "absolute",
              color: "black",
              top: 5,
              right: 5,
              padding: "0",
              minWidth: "fit-content",
            }}
            onClick={handleClose}
          >
            <HighlightOffIcon sx={{ fontSize: "16px" }} />
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={prevDialog}
        onClose={() => setPrevDialog(true)}
        PaperProps={{
          style: { borderRadius: "5px", width: { xs: "", md: "80%" } },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px", // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            px: "20px",
            pt: "50px",
            color: "#fff",
            position: "relative",
          }}
        >
          <InvoicePDF ref={componentRef} invoice={selectedOrder} />

          <Button
            style={{
              position: "absolute",
              color: "#fff",
              background: "#DC143C",
              top: 9,
              right: 20,
            }}
            onClick={() => setPrevDialog(false)}
          >
            Close
          </Button>

          <Button
            style={{
              position: "absolute",
              color: "#fff",
              background: "green",
              top: 9,
              right: 90,
            }}
            onClick={handlePrint}
          >
            Generate Invoice
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={orderState.dialog}
        onClose={() => setOrderState({ ...orderState, dialog: false })}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: {
                xs: "250px",
                xsPlus: "400px",
              }, // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#fff",
            position: "relative",
            padding: "25px",
            // width: "800px",
            overflow: "visible",
            p: { xs: "20px", md: "20px", lg: "40px" },
          }}
        >
          <Box
            sx={{
              pb: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">Create Order</Typography>
          </Box>
          <Box
            sx={{
              // height: "100%",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
              py: "10px",
            }}
          >
            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">
                Select Product
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Select Product"
                value={JSON.stringify(orderState.product)}
                onChange={(e) => handleChangeProduct("product", e)}
                MenuProps={{
                  sx: {
                    maxHeight: "250px",
                    // overflowY: "auto",
                  },
                }}
              >
                {products.map((prod, idx) => (
                  <MenuItem value={JSON.stringify(prod)} key={idx}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        py: "5px",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          height: "35px",
                          width: "35px",
                          borderRadius: "5px",
                          border: "1px solid #70707078",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          src={prod?.thumbnail_image || EmptyView}
                          alt=""
                          style={{
                            width: "35px",
                            height: "35px",
                            objectFit: "contain",
                            overflow: "hidden",
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          flexGrow: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                        >
                          {prod?.product_name && prod?.product_name?.length > 30
                            ? `${prod?.product_name?.substring(0, 30)}...`
                            : prod?.product_name}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{ fontSize: "12px", fontWeight: "500", my: "auto" }}
                      >
                        Rs:&nbsp;
                        {prod?.discount_price > 0
                          ? prod?.discount_price
                          : prod?.price}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* parent op */}
            {parentOp && parentOp.length > 0 && (
              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">
                  {orderState?.product?.variants[0]?.variant_name}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label={orderState?.product?.variants[0]?.variant_name}
                  value={selectedSingleOp}
                  onChange={handleChangeParentOp}
                  MenuProps={{
                    sx: {
                      maxHeight: "250px",
                      // overflowY: "auto",
                    },
                  }}
                >
                  {parentOp &&
                    parentOp?.map((prod, idx) => (
                      <MenuItem value={prod} key={idx}>
                        {" "}
                        {/* Just pass prod as value */}
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            py: "5px",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "5px",
                              border: "1px solid #70707078",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                            }}
                          >
                            <img
                              src={prod?.v_image || EmptyView}
                              alt=""
                              style={{
                                width: "35px",
                                height: "35px",
                                objectFit: "contain",
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              flexGrow: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "500",
                                cursor: "pointer",
                              }}
                            >
                              {prod?.type && prod.type.length > 30
                                ? `${prod.type.substring(0, 30)}...`
                                : prod?.type}
                            </Typography>
                          </Box>

                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              my: "auto",
                            }}
                          >
                            Rs:&nbsp;
                            {prod?.discounted_price > 0
                              ? prod?.discounted_price
                              : prod?.v_price}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {/* groups  */}
            {parentOp &&
              parentOp.length > 0 &&
              childOp &&
              childOp.length > 0 && (
                <FormControl sx={{ minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">
                    {orderState?.product?.variants[1]?.variant_name}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label={orderState?.product?.variants[1]?.variant_name}
                    value={selectedChildOp} // Use selectedGroup directly as the value
                    onChange={handleChangeGroup} // Pass the event directly to handleChangeGroup
                    MenuProps={{
                      sx: {
                        maxHeight: "250px",
                        // overflowY: "auto",
                      },
                    }}
                  >
                    {childOp &&
                      childOp?.map((prod, idx) => (
                        <MenuItem value={prod} key={idx}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              py: "5px",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                height: "35px",
                                width: "35px",
                                borderRadius: "5px",
                                border: "1px solid #70707078",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              <img
                                src={prod?.v_image || EmptyView}
                                alt=""
                                style={{
                                  width: "35px",
                                  height: "35px",
                                  objectFit: "contain",
                                }}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                flexGrow: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  fontWeight: "500",
                                  cursor: "pointer",
                                }}
                              >
                                {prod?.type && prod.type.length > 30
                                  ? `${prod.type.substring(0, 30)}...`
                                  : prod?.type}
                              </Typography>
                            </Box>

                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                my: "auto",
                              }}
                            >
                              Rs:&nbsp;
                              {prod?.discounted_price > 0
                                ? prod?.discounted_price
                                : prod?.v_price}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

            <TextField
              label="Name"
              id="outlined-size-small"
              size="small"
              value={orderState?.name}
              onChange={(e) => handleChange("name", e)}
              required
            />

            <TextField
              label="Email"
              id="outlined-size-small"
              size="small"
              value={orderState?.email}
              onChange={(e) => handleChange("email", e)}
              required
            />

            <TextField
              label="Address"
              id="outlined-size-small"
              size="small"
              value={orderState?.address}
              onChange={(e) => handleChange("address", e)}
              required
            />

            {/* <TextField
              label="City"
              id="outlined-size-small"
              size="small"
              value={orderState?.city}
              onChange={(e) => handleChange("city", e)}
              required
            /> */}

            <Autocomplete
              options={leopardCities}
              getOptionLabel={(option) => option.name || ""}
              sx={{
                width: "100%",
              }}
              size="small"
              value={orderState?.city}
              onChange={(e, newVal) => {
                setOrderState({ ...orderState, city: newVal });
              }}
              renderInput={(params) => <TextField {...params} label="City" />}
            />

            {/* <TextField
              label="Postal Code"
              id="outlined-size-small"
              size="small"
              value={orderState?.postalCode}
              onChange={(e) => handleChange("postalCode", e)}
              required
            /> */}

            <FormControl sx={{ minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Status</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Status"
                value={orderState.status}
                onChange={(e) => handleChange("status", e)}
                MenuProps={{
                  sx: {
                    maxHeight: "250px",
                    // overflowY: "auto",
                  },
                }}
              >
                <MenuItem value={"accepted"}>
                  <Typography>Accepted</Typography>
                </MenuItem>
                <MenuItem value={"pending"}>
                  <Typography>Pending</Typography>
                </MenuItem>
                <MenuItem value={"dispatched"}>
                  <Typography>Dispatched</Typography>
                </MenuItem>
                <MenuItem value={"fullfilled"}>
                  <Typography>Fullfilled</Typography>
                </MenuItem>
                <MenuItem value={"cancelled"}>
                  <Typography>Cancelled</Typography>
                </MenuItem>
                <MenuItem value={"return"}>
                  <Typography>Return</Typography>
                </MenuItem>
              </Select>
            </FormControl>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                background: "#fff",
                border: "1px solid gray",
                height: "40px",
                overflow: "hidden",
              }}
            >
              <Select
                labelId="phone-code-select-label"
                id="phone-code-select"
                value={orderState.countryCode?.dial_code}
                onChange={(e) => handleChange("countryCode", e)}
                disableUnderline
                label="Phone Code"
                sx={{
                  width: "110px",
                  pl: "10px",
                  pt: "5px",
                }}
                variant="standard"
                size="small"
              >
                {countries.map((c) => (
                  <MenuItem
                    key={c.code}
                    value={c.dial_code}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      className={`fi fi-${c.code.toLocaleLowerCase()}`}
                    ></span>{" "}
                    {c.dial_code}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                size="small"
                label="Contact No."
                type={"number"}
                placeholder="3456789100"
                sx={{
                  width: "100%",
                }}
                InputProps={{ disableUnderline: true }}
                variant="filled"
                value={orderState?.phoneNumber}
                onChange={(e) => handleChange("phoneNumber", e)}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  my: "auto",
                  fontWeight: "500",
                  color: "gray",
                }}
              >
                Quantity
              </Typography>

              <ButtonGroup size="small" aria-label="small button group">
                <Button
                  onClick={() => {
                    if (orderState.quantity > 0) {
                      setOrderState({
                        ...orderState,
                        quantity: --orderState.quantity,
                        isEdited: true,
                      });
                    }
                  }}
                >
                  -
                </Button>
                <Button>
                  <Typography sx={{ fontWeight: "500" }}>
                    {orderState?.quantity}
                  </Typography>
                </Button>
                <Button
                  onClick={() =>
                    setOrderState({
                      ...orderState,
                      quantity: ++orderState.quantity,
                      isEdited: true,
                    })
                  }
                >
                  +
                </Button>
              </ButtonGroup>
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "10px",
              }}
            >
              <Typography sx={{ fontSize: "13px", color: "gray" }}>
                Price
              </Typography>
              {Object.keys(selectedSingleOp).length === 0 &&
                Object.keys(selectedChildOp).length === 0 && (
                  <Typography sx={{ fontSize: "13px", color: "gray" }}>
                    Rs{" "}
                    {orderState?.product?.discount_price > 0
                      ? orderState?.product?.discount_price
                      : orderState?.product?.price}
                    {/* Rs {orderState?.product?.price || 0} */}
                  </Typography>
                )}
              {Object.keys(selectedSingleOp).length > 0 &&
                Object.keys(selectedChildOp).length === 0 && (
                  <Typography sx={{ fontSize: "13px", color: "gray" }}>
                    Rs{" "}
                    {selectedSingleOp?.discounted_price > 0
                      ? selectedSingleOp?.discounted_price
                      : selectedSingleOp?.v_price}
                    {/* Rs {orderState?.product?.price || 0} */}
                  </Typography>
                )}
              {((selectedSingleOp && Object.keys(selectedChildOp).length > 0) ||
                (Object.keys(selectedSingleOp).length > 0 &&
                  Object.keys(selectedChildOp) > 0)) && (
                <Typography sx={{ fontSize: "13px", color: "gray" }}>
                  Rs{" "}
                  {selectedChildOp?.discounted_price > 0
                    ? selectedChildOp?.discounted_price
                    : selectedChildOp?.v_price}
                  {/* Rs {orderState?.product?.price || 0} */}
                </Typography>
              )}
            </Box>
            {orderState?.product?.price * orderState?.quantity <
              userData?.user?.store?.delivery?.free_delivery_amount && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography sx={{ fontSize: "13px", color: "gray" }}>
                  Delivery Charges
                </Typography>
                <Typography sx={{ fontSize: "13px", color: "gray" }}>
                  Rs {userData?.user?.store?.delivery?.delivery_charges || 0}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "10px",
              }}
            >
              <Typography
                sx={{ fontSize: "13px", fontWeight: "500", color: "gray" }}
              >
                Total
              </Typography>
              {Object.keys(selectedSingleOp).length === 0 &&
                Object.keys(selectedChildOp).length === 0 && (
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: "500", color: "gray" }}
                  >
                    Rs{" "}
                    {orderState?.product?.discount_price > 0
                      ? orderState?.product?.discount_price *
                          orderState?.quantity >
                        userData?.user?.store?.delivery?.free_delivery_amount
                        ? orderState?.product?.discount_price *
                          orderState?.quantity
                        : orderState?.product?.discount_price *
                            orderState?.quantity +
                            (userData?.user?.store?.delivery
                              ?.delivery_charges || 0) || 0
                      : orderState?.product?.price * orderState?.quantity >
                        userData?.user?.store?.delivery?.free_delivery_amount
                      ? orderState?.product?.price * orderState?.quantity
                      : orderState?.product?.price * orderState?.quantity +
                          (userData?.user?.store?.delivery?.delivery_charges ||
                            0) || 0}
                  </Typography>
                )}
              {Object.keys(selectedSingleOp).length > 0 &&
                Object.keys(selectedChildOp).length === 0 && (
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: "500", color: "gray" }}
                  >
                    Rs{" "}
                    {selectedSingleOp?.discounted_price > 0
                      ? selectedSingleOp?.discounted_price *
                          orderState?.quantity >
                        userData?.user?.store?.delivery?.free_delivery_amount
                        ? selectedSingleOp?.discounted_price *
                          orderState?.quantity
                        : selectedSingleOp?.discounted_price *
                            orderState?.quantity +
                            (userData?.user?.store?.delivery
                              ?.delivery_charges || 0) || 0
                      : selectedSingleOp?.v_price * orderState?.quantity >
                        userData?.user?.store?.delivery?.free_delivery_amount
                      ? selectedSingleOp?.v_price * orderState?.quantity
                      : selectedSingleOp?.v_price * orderState?.quantity +
                          (userData?.user?.store?.delivery?.delivery_charges ||
                            0) || 0}
                  </Typography>
                )}
              {((selectedSingleOp && Object.keys(selectedChildOp).length > 0) ||
                (Object.keys(selectedSingleOp).length > 0 &&
                  Object.keys(selectedChildOp) > 0)) && (
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "500", color: "gray" }}
                >
                  Rs{" "}
                  {selectedChildOp?.discounted_price > 0
                    ? selectedChildOp?.discounted_price * orderState?.quantity >
                      userData?.user?.store?.delivery?.free_delivery_amount
                      ? selectedChildOp?.discounted_price * orderState?.quantity
                      : selectedChildOp?.discounted_price *
                          orderState?.quantity +
                          (userData?.user?.store?.delivery?.delivery_charges ||
                            0) || 0
                    : selectedChildOp?.v_price * orderState?.quantity >
                      userData?.user?.store?.delivery?.free_delivery_amount
                    ? selectedChildOp?.v_price * orderState?.quantity
                    : selectedChildOp?.v_price * orderState?.quantity +
                        (userData?.user?.store?.delivery?.delivery_charges ||
                          0) || 0}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
              {/* <Button
            variant="contained"
            sx={{
              p: "0",
              borderRadius: "15px",
              color: "#fff",
              minWidth: "100px",
            }}
            // onClick={handleCancel}
          >
            Clear
          </Button> */}
              <Button
                variant="contained"
                sx={{
                  p: "0",
                  // py: "10px",
                  borderRadius: "15px",
                  color: "#fff",
                  minWidth: "100px",
                  height: "30px",
                }}
                onClick={handleSubmit}
                disabled={orderState.loading}
              >
                {orderState.loading ? (
                  <CircularProgress sx={{ color: "#fff" }} size="22px" />
                ) : (
                  "Create"
                )}
              </Button>
            </Box>
          </Box>

          <Button
            style={{
              position: "absolute",
              color: isHovered ? "white" : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: "fit-content",
              borderRadius: "50%",
              transition: "background-color 0.3s ease-in-out",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
            }}
            onClick={handleCloseOrderDialog}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.prevDialog}
        onClose={() => setState({ ...state, prevDialog: false })}
        PaperProps={{
          style: { borderRadius: "15px", width: { xs: "", md: "80%" } },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px", // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            px: "20px",
            pt: "50px",
            pb: "70px",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              color: "black",
              top: 9,
              left: "50%",
              transform: "translateX(-50%)",
              fontWeight: "500",
              fontSize: "17px",
            }}
          >
            Invoice
          </Typography>

          <InvoicePDF ref={componentRef} invoice={state.selected} />

          <Typography
            sx={{
              color: "black",
              fontWeight: "500",
              mx: "auto",
              textAlign: "center",
            }}
          >
            Thank you for your purchase.
          </Typography>

          <Button
            sx={{
              position: "absolute",
              color: "black",
              p: "0",
              m: "0",
              minWidth: "0",
              top: 9,
              right: 20,
            }}
            onClick={() => setState({ ...state, prevDialog: false })}
          >
            <CloseRoundedIcon />
          </Button>

          <Box
            sx={{
              position: "absolute",
              bottom: 10,
              transform: " translateX(-50%)",
              left: "50%",
              display: "flex",
              // flexDirection: "column",
              gap: 1,
            }}
          >
            <Button
              style={{
                borderRadius: "20px",
                color: "#fff",
                background: "#FA0F00",
                width: "250px",
              }}
              onClick={handlePrint}
            >
              <SimCardDownloadRoundedIcon /> Generate Invoice
            </Button>
            <Button
              style={{
                borderRadius: "20px",
                color: "#fff",
                background: "#25D366",
                width: "250px",
              }}
              onClick={shareWhatsApp}
            >
              <SendRoundedIcon /> Share on Whatsapp
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.trakingDialog}
        onClose={() => setState({ ...state, trakingDialog: false })}
        PaperProps={{
          style: { borderRadius: "15px", width: { xs: "", md: "80%" } },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            px: "20px",
            pt: "50px",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              color: "black",
              top: 9,
              left: "50%",
              transform: "translateX(-50%)",
              fontWeight: "500",
              fontSize: "17px",
            }}
          >
            Tracking Details
          </Typography>

          <Typography sx={{ color: "black" }}></Typography>

          <Box
            sx={{ p: "20px", display: "flex", flexDirection: "column", gap: 1 }}
          >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography sx={{ color: "black", fontWeight: "500" }}>
                Packet Id:
              </Typography>
              <Typography sx={{ color: "black" }}>
                {selectedOrder.booked_packet_id}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "500",
                }}
              >
                Total Piece in Packet:
              </Typography>
              <Typography sx={{ color: "black" }}>
                {selectedOrder.booked_packet_no_piece}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ color: "black", fontWeight: "500" }}>
                Packet Status:&nbsp;
              </Typography>
              <Typography sx={{ color: "black" }}>
                {selectedOrder.booked_packet_status}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography sx={{ color: "black", fontWeight: "500" }}>
                Packet Weight:&nbsp;
              </Typography>
              <Typography sx={{ color: "black" }}>
                {selectedOrder.booked_packet_weight}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography sx={{ color: "black", fontWeight: "500" }}>
                Booking Date:&nbsp;
              </Typography>
              <Typography sx={{ color: "black" }}>
                {selectedOrder.booking_date}
              </Typography>
            </Box>
          </Box>

          <Button
            sx={{
              position: "absolute",
              color: "black",
              p: "0",
              m: "0",
              minWidth: "0",
              top: 9,
              right: 20,
            }}
            onClick={() => setState({ ...state, trakingDialog: false })}
          >
            <CloseRoundedIcon />
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.bookPacketDialog}
        onClose={() => setState({ ...state, bookPacketDialog: false })}
        PaperProps={{
          style: { borderRadius: "15px", width: { xs: "", md: "80%" } },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            px: "20px",
            pt: "50px",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              color: "black",
              top: 9,
              left: "50%",
              transform: "translateX(-50%)",
              fontWeight: "500",
              fontSize: "17px",
            }}
          >
            Choose Shipper
          </Typography>

          <Box
            sx={{ p: "20px", display: "flex", flexDirection: "column", gap: 1 }}
          >
            <Button
              sx={{
                width: "100%",
                borderRadius: "12px",
                boxShadow: "none",
              }}
              variant="contained"
              onClick={() => handleBookPacket(true)}
              disabled={state.bookingPacket}
            >
              {state.bookingPacket ? (
                <CircularProgress sx={{ color: "#fff" }} size="22px" />
              ) : (
                "Uchlo As Shipper"
              )}
            </Button>

            {(userData?.user?.store?.store_shipper &&
              Object.keys(userData?.user?.store?.store_shipper).length) > 0 ? (
              <Button
                sx={{
                  width: "100%",
                  borderRadius: "12px",
                  boxShadow: "none",
                }}
                variant="contained"
                onClick={() => handleBookPacket(false)}
                disabled={state.bookingPacket}
              >
                {state.bookingPacket ? (
                  <CircularProgress sx={{ color: "#fff" }} size="22px" />
                ) : (
                  `${userData?.user?.store?.store_name} As Shipper`
                )}
              </Button>
            ) : (
              // <Typography
              //   sx={{
              //     color: "black",
              //     fontWeight: "500",
              //     fontSize: "12px",
              //     textAlign: "center",
              //   }}
              // >
              //   You can add your store as shipper in <br />
              //   <Link to={"/dashboard/settings"} state={"ship"}>
              //     settings {">"} shipper
              //   </Link>
              // </Typography>
              <Button
                sx={{
                  width: "100%",
                  borderRadius: "12px",
                  boxShadow: "none",
                }}
                variant="contained"
                onClick={() =>
                  navigate("/dashboard/settings", { state: "ship" })
                }
              >
                Add Shipper Details
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={state.completeDialog}
        onClose={() => setState({ ...state, completeDialog: false })}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: {
                xs: "250px",
                xsPlus: "400px",
              }, // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#fff",
            position: "relative",
            padding: "25px",
            // width: "800px",
            overflow: "visible",
            p: { xs: "20px", md: "20px", lg: "40px" },
          }}
        >
          <Box
            sx={{
              // height: "100%",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 2,
              py: "10px",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                color: "black",
                top: 9,
                left: "50%",
                transform: "translateX(-50%)",
                fontWeight: "500",
                fontSize: "17px",
              }}
            >
              Update Order
            </Typography>

            <TextField
              label="Name"
              id="outlined-size-small"
              size="small"
              value={state.selected?.shipping_details?.full_name}
              onChange={(e) =>
                setState({
                  ...state,
                  selected: {
                    ...state.selected,
                    shipping_details: {
                      ...state.selected?.shipping_details,
                      full_name: e.target.value,
                    },
                  },
                })
              }
              required
            />

            <TextField
              label="Email"
              id="outlined-size-small"
              size="small"
              value={state.selected?.shipping_details?.email}
              onChange={(e) =>
                setState({
                  ...state,
                  selected: {
                    ...state.selected,
                    shipping_details: {
                      ...state.selected?.shipping_details,
                      email: e.target.value,
                    },
                  },
                })
              }
              required
            />

            <TextField
              label="Address"
              id="outlined-size-small"
              size="small"
              value={state.selected?.shipping_details?.address}
              onChange={(e) =>
                setState({
                  ...state,
                  selected: {
                    ...state.selected,
                    shipping_details: {
                      ...state.selected?.shipping_details,
                      address: e.target.value,
                    },
                  },
                })
              }
              required
            />

            <Autocomplete
              options={leopardCities}
              getOptionLabel={(option) => option.name || ""}
              sx={{
                width: "100%",
              }}
              size="small"
              value={state.selected?.shipping_details?.city}
              onChange={(e, newVal) => {
                setState({
                  ...state,
                  selected: {
                    ...state.selected,
                    shipping_details: {
                      ...state.selected?.shipping_details,
                      city: newVal,
                    },
                  },
                });
              }}
              renderInput={(params) => <TextField {...params} label="City" />}
            />

            <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
              {/* <Button
            variant="contained"
            sx={{
              p: "0",
              borderRadius: "15px",
              color: "#fff",  
              minWidth: "100px",
            }}
            // onClick={handleCancel}
          >
            Clear
          </Button> */}
              <Button
                variant="contained"
                sx={{
                  p: "0",
                  // py: "10px",
                  borderRadius: "15px",
                  color: "#fff",
                  minWidth: "100px",
                  height: "30px",
                }}
                onClick={handleUpdateOrder}
                disabled={orderState.loading}
              >
                {orderState.loading ? (
                  <CircularProgress sx={{ color: "#fff" }} size="22px" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>

          <Button
            style={{
              position: "absolute",
              color: "black",
              top: 10,
              right: 10,
              padding: "0",
              minWidth: "fit-content",
            }}
            onClick={() => setState({ ...state, completeDialog: false })}
          >
            <HighlightOffIcon />
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default OrdersComp;
