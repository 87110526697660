import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextareaAutosize,
  Tooltip,
  Typography,
  CircularProgress,
  Chip,
  OutlinedInput,
  InputBase,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Stack from "@mui/material/Stack";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import ShareSharpIcon from "@mui/icons-material/ShareSharp";

import DeleteIcon from "@mui/icons-material/Delete";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import SmartDisplayRoundedIcon from "@mui/icons-material/SmartDisplayRounded";
import TvRoundedIcon from "@mui/icons-material/TvRounded";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import {
  UpdateStreamDetail,
  getStreamDetail,
  getUserRecordedStreams,
  addStreamThumbnail,
  removeStreamThumbnail,
  deleteStream,
  searchStream,
  FilterStreams,
} from "../../api/StreamApis";
import { convertToWebP } from "../../utils/image_copmression";
import Dropzone, { useDropzone } from "react-dropzone";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "../Toast";

const MyStreamsModal = ({ handleStreamModal, recordedStreamsModal }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [deleteStreamModal, setDeleteStreamModal] = useState(false);
  const [selectedStream, setSelectedStream] = useState(null);
  const [streams, setStreams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalStreams, setTotalStreams] = useState(0);
  const [imageloading, setImageLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [removeImgLoading, setRemoveImgLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [streamDetail, setStreamDetail] = useState({});
  const [salesPerformance, setSalesPerformance] = useState({});
  const [viewShipMAtrics, setViewShipMatrics] = useState({});
  const [duration, setDuration] = useState("");
  const [changeThumbnail, setChangeThumbnail] = useState(false);
  const [sort, setSort] = React.useState("All");
  const [streamCategory, setStreamCategory] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [description, setDescription] = useState("");
  const [streamTitle, setStreamTitle] = useState("");
  // search logic
  const handleSearched = () => {
    setIsSearched(!isSearched);
  };
  const removeSearch = () => {
    handleSearched();
    setSearchQuery("");
    fetchUsersStreams(currentPage);
  };
  // filter
  const sortStreamByCat = async () => {
    if (sort === "All") {
      fetchUsersStreams(1);
    } else {
      const resp = await FilterStreams(sort);
      if (resp.status === 200) {
        let data = resp.data.streams;
        setStreams(data);
      }
    }
  };
  useEffect(() => {
    sortStreamByCat();
  }, [sort]);
  useEffect(() => {
    if (sort === "All") {
      setCurrentPage(1);
      fetchUsersStreams(currentPage, true);
    }
  }, [sort]);
  const handleSearch = async () => {
    handleSearched();
    const resp = await searchStream(searchQuery);
    if (resp.status === 200) {
      let data = resp?.data?.streams;
      setStreams(data);
    }
  };
  const handleDeleteStreamModal = () => {
    setDeleteStreamModal(!deleteStreamModal);
  };
  const handleEditClick = () => {
    setEditing(true);
  };
  const defaultThumbnails = [
    require("../../assets/Img/t1.png"),
    require("../../assets/Img/t2.png"),
    require("../../assets/Img/t3.png"),
    require("../../assets/Img/t4.png"),
  ];

  const getRandomIndex = () =>
    Math.floor(Math.random() * defaultThumbnails.length);
  const handleMouseEnterThumbnail = () => {
    setChangeThumbnail(true);
  };
  const handleMouseLeaveThumbnail = () => {
    setChangeThumbnail(false);
  };
  const handleShareClick = () => {
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const message = `Check out My Store: ${streamDetail?.short_url}`;

    if (isMobile) {
      // If on a mobile device, open the WhatsApp app
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        message
      )}`;
    } else {
      // If on desktop, open WhatsApp Web
      window.open("https://web.whatsapp.com/");
    }
  };
  const handleRemove = async (imagePath) => {
    setRemoveImgLoading(true);
    try {
      const urlObject = new URL(imagePath);
      const pathWithoutHost = urlObject.pathname.slice(1);

      const resp = await removeStreamThumbnail(pathWithoutHost);

      if (resp?.status === 200) {
        setThumbnail("");
      }
    } catch (error) {
      toast.error(
        "Oops! Something went wrong while removing the image. Please try again later."
      );
    } finally {
      setRemoveImgLoading(false);
    }
  };
  const handleDrop = async (newFiles) => {
    setImageLoading(true);
    if (!newFiles || newFiles.length === 0) {
      return;
    }

    if (thumbnail != "") {
      await handleRemove(thumbnail);
    }

    const webpFiles = await convertToWebP(newFiles);

    if (!webpFiles || webpFiles.length === 0) {
      return;
    }

    let _formData = new FormData();

    webpFiles.forEach((file) => {
      _formData.append("files", file);
    });

    try {
      const resp = await addStreamThumbnail(_formData);

      if (resp?.status === 200) {
        setThumbnail(resp?.data?.imageUrls[0]);
        setEditing(true);
      }
    } catch (error) {
      return error;
    } finally {
      setImageLoading(false);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
  });
  // delete stream function
  const handleDelete = async () => {
    const response = await deleteStream(selectedStream);

    if (response?.status === 201) {
      toast.success("Stream has been successfully deleted");
      setEditing(false);
      setDeleteStreamModal(false);
    }
  };
  const fetchUsersStreams = async (page) => {
    setIsLoading(true);
    const response = await getUserRecordedStreams(page);
    if (response?.status === 200) {
      setStreams(response?.data?.Recorded);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
      setTotalStreams(response?.data?.totalStreams);
      setIsLoading(false);
    }
  };
  const fetchCardDetail = async (roomid) => {
    const response = await getStreamDetail(roomid);
    if (response?.status === 200) {
      setDuration(response?.data?.duration);
      setStreamDetail(response?.data?.stream);
      setSalesPerformance(response?.data?.salesPerformance);
      setViewShipMatrics(response?.data?.viewshipMatrics);
    }
  };
  // categories list
  const categoryList = {
    Fashion: ["Dresses", "Shoes", "Accessories", "Other"],
    Beauty: ["Skincare", "Makeup", "Fragrances", "Other"],
    Electronics: ["Smartphones", "Laptops", "Headphones", "Other"],
    "Home Goods": ["Furniture", "Kitchen Appliances", "Decor", "Other"],
    Toys: ["Action Figures", "Board Games", "Dolls", "Other"],
    "Health and Wellness": [
      "Vitamins",
      "Fitness Equipment",
      "Supplements",
      "Other",
    ],
    "Pet Supplies": ["Dog Food", "Cat Toys", "Fish Tanks", "Other"],
    "Outdoor Gear": ["Camping Gear", "Hiking Boots", "Bicycles", "Other"],
    Books: ["Fiction", "Non-Fiction", "Mystery", "Other"],
    Others: ["Miscellaneous", "Random", "Unspecified", "Other"],
    "Groceries & Pets": [
      "Fresh Produce",
      "Fresh Fruit",
      "Fresh Vegetables",
      "Fish & Cold Pressed Juices",
      "Bread",
      "Eggs",
      "Dry Fruits",
      "Breakfast, Choco's & Snacks",
      "Breakfast Cereals",
      "Jams, Honey & Spreads",
      "Breakfast Bars",
      "Instant Breakfast Drinks",
      "Chocolate & Candy",
      "Chewing Gums & Mints",
      "Biscuit & Cookies",
      "Cakes & Sweets",
      "Chips & Crisps",
      "Nuts & Legumes",
      "Oatmeal",
      "Pancake & Waffle Mixes",
      "Beverages",
      "Tea",
      "Coffee",
      "UHT, Milk & Milk Powder",
      "Soft Drinks",
      "Juices",
      "Water",
      "Flavouring Syrup",
      "Powdered Drinks",
      "Food Staples",
      "Oil",
      " Rice",
      "Condiment Dressing",
      "Cooking Ingredients",
      "Noodles & Pasta",
      "Instant & Ready-to-Eat",
      "Grains, Beans & Pulses",
      "Home Baking & Sugar",
      "Jarred Food",
      "Canned Food",
      "Laundry & Household",
      "AirCare",
      "Cleaning",
      "Dishwashing",
      "Laundry",
      "Pest Control",
      "Tissue Paper",
      "Foils & Cling Films",
      " Frozen Food",
      "Chicken",
      "Beef",
      "Other Frozen Food",
      "Cat Food",
      "Cat Beds, Mats & Houses",
      "Cat Carriers & Travel",
      "Cat Grooming",
      "Cat Litter & Toilet",
      "Cat Cages, Crates & Doors",
      "Cat Toys",
      "Cat Treats",
      "Cat Trees, Condos & Scratchers",
      "Cat Bowls & Feeders",
      "Dog Collars",
      "Dog Toys",
      "Dog Harnesses",
      "Dog Brushes & Combs",
      "Dog Shampoos & Bath Accessories ",
      "Dog Bowls & Diners",
      "Dog Beds",
      "Fish",
      "Decorations",
      "Water pumps",
      "Aquariums",
      "Filters & Accessories",
      "Air Pumps & Accessories",
      "Temperature control supplies",
      "Food",
    ],
    "Health & Beauty": [
      "Makeup",
      "Makeup Accessories",
      "Lips",
      "Nails",
      "Face",
      "Eyes",
      "Makeup Removers",
      "Makeup Palettes & Sets",
      "Bulk Deals",
      "Beauty Tools",
      "Skin Care Tools",
      "Hair Removal Appliances",
      "Slimming & Electric Massagers",
      "Hair Styling Appliances",
      "Foot Relief Tools",
      "Skin Care",
      "Moisturizers and Cream",
      "Facial Cleansers",
      "Serum & Essence",
      "Face Mask & Packs",
      "Sunscreen & Aftersun",
      "Lip Balm & Treatment",
      "Eye Care",
      "Face Scrubs & Exfoliators",
      "Toner & Mists",
      "Hair Care",
      "Shampoo & Conditioner",
      "Hair Care Accessories",
      "Hair Treatments",
      "Oil & Serums",
      "Hair Coloring",
      " Hair Styling",
      "Body Soaps & Shower Gels",
      "Hair Removal",
      "Body & Massage Oils",
      "Bath & Body Accessories",
      "Foot Care",
      "Hand Care",
      "Bath & Body",
      "Hair Care",
      "Shaving & Grooming",
      "Personal Care",
      "Pads & Tampons",
      "Menstrual Cups",
      "Oral Care",
      "Eye Care",
      "Deodorants",
      "Adult Diapers",
      "Personal Safety & Security",
      " Ear Care",
      "Fragrances for Women",
      "Fragrances for Men",
      "Fragrances for Unisex",
      "Sexual Wellness",
      "Condoms",
      "Lubricants",
      "Medical Supplies",
      "Health Monitors & Tests",
      "Health Accessories",
      "First Aid Supplies",
      "Ointments and Creams",
      "Surgical Masks",
      "Nebulizer & Aspirators",
      "Stethoscopes",
    ],

    "Men's Fashion": [
      "T Shirts & Tanks",
      "V Neck",
      "Round Neck",
      "Graphic tees",
      "Top Rated",
      "Shirts & Polo",
      "Casual Shirts",
      "Formal Shirts",
      "Polos",
      "Pants & Jeans",
      "Chinos",
      "Cargo",
      "Jeans",
      "Shorts, Joggers & Sweats",
      "Shorts",
      "Joggers & Sweats",
      "Unstitched Fabric",
      "Kurtas & Shalwar Kameez",
      "Kurtas",
      "Shalwar",
      "Shawls",
      "Winter Clothing",
      "Jackets & Coats",
      "Hoodies & Sweatshirts",
      "Sweaters & Cardigans",
      "Inner Wear",
      "Briefs",
      "Trunk & Boxers",
      "Nightwear",
      "Vests",
      "Thermal",
      "Socks",
      "Shoes",
      "Slip-Ons & Loafers",
      "Flip Flops & Sandals",
      "Sneakers",
      "Formal Shoes",
      "Boots",
      "Khusa & Kolapuri",
      "Shoes Accessories",
      "Accessories",
      "Belts",
      "Sunglasses",
      "Hats & Caps",
      "Boy's Clothing",
      "T-Shirts & Shirts",
      "Pants & Jeans",
      "Shorts",
      "Kurtas & Shalwar Kameez",
      "Underwear & Socks",
      "Ties & Bows",
      "Shoes",
      "Sneakers",
      "Slip-Ons & Loafers",
      "School Shoes",
      "Flip Flops, Slides & Sandals",
      "Formal Shoes",
      "Boots ",
      "Sandals",
      "Boy's Accessories",
      "Belts",
      "Inner Wear",
      "Socks",
      "Hats & Caps",
    ],

    "Women's Fashion": [
      "Unstitched Fabric",
      "Branded Unstitched",
      "Sarees",
      "Kurtas & Shalwar Kameez",
      "Kurtis",
      " Shalwar Kameez",
      "Branded Pret",
      "Trousers & Palazzos",
      "Muslim Wear",
      "Dupattas, Stoles & Shawls",
      "Abayas & Hijabs",
      "Scarves",
      " Hair Accessories",
      "Tops",
      "Blouses & Shirts",
      "Tunics",
      " T-Shirts",
      "Bras",
      "Panties",
      "Lingerie Sets",
      "Beachwear and Bikinis",
      "Socks & Tights",
      "Sleepwear & Innerwear",
      "Nightwear",
      "Robe and Gown sets",
      "Tanks & Camisoles",
      "Shapewear",
      "Pants",
      "Leggings",
      "Jeans",
      "Shorts",
      "Jeggings",
      "Dresses & Skirts",
      "Ethnic Dresses",
      "Western Dresses",
      "Skirts",
      " Formal Wear",
      "Winter Clothing",
      "Jackets & Coats",
      "Hoodies & Sweatshirts",
      "Sweaters & Cardigans",
      "Shawls and Poncho's",
      "Shrugs",
      "Shoes",
      "Sandals",
      "Flat Shoes",
      "Heels",
      "Khussa & Kohlapuri",
      "Slides & Flip FLops",
      " Wedges",
      "Sneakers",
      "Boots",
      "Shoes Accessories",
      "Girls Clothing",
      "Dresses",
      "Tops",
      "Underwear & Sleepwear",
      "Bottoms",
      "Socks & Tights",
      "Jackets & Coats",
      "Swimsuits",
      "Hoodies",
      "Hair Accessories",
      "Hats & Caps",
      "Belts",
      "Gloves, Scarves & Cold Weather",
      "Girls Shoes",
      "Sneakers",
      "Flats & Slip - Ons",
      "Formal Shoes",
      "Sandals",
      "Flip Flops, Slides & Sandals",
      "School Shoes",
      "Boots",
    ],

    "Mother & Baby": [
      "Milk Formula & Baby Food",
      " Maternal",
      "Infant Milk (0-6 Months)",
      "Infant Milk (6-12 Months)",
      " Toddler Milk (1 - under 3 yrs)",
      "Growing-up Milk (3 yrs +)",
      "Baby & Toddler Foods",
      "Diapering & Potty",
      "Disposable diapers",
      "Diaper Bags",
      "Wipes & Holders",
      "Changing Tables, Pads & Kits",
      "Diapering Care",
      "Cloth Diapers & Accessories",
      "Potty Training",
      "Feeding",
      "Utensils",
      "Bottle-Feeding",
      "Breastfeeding",
      "Food Blenders",
      "Pacifiers & Teethers",
      "Baby & Toddler Foods",
      "Maternity Care",
      "Maternity Wear",
      "Maternity Accessories",
      "Nursing Covers",
      "Pregnancy Pillows",
      "Nipple Care",
      "Breast Shells",
      "Breast Pumps",
      "Baby Gear",
      "Kids Bag",
      "Swings, Jumpers & Bouncers",
      "Strollers",
      "Car Seats",
      "Walkers",
      "Backpacks & Carriers",
      "Harnesses & Leashes",
      "Baby Safety",
      "Baby Monitor",
      "Highchairs & Booster Seats",
      "Baby Sofas",
      "Nursery ",
      "Baby Furniture",
      "Mattresses & Bedding",
      "Storage & Organization",
      "Nursery Decor",
      "Baby Personal Care",
      "Skin Care",
      "Bathing Tubs & Seats",
      "Soaps, Cleansers & Bodywash",
      "Baby Groomings & Care Kits",
      "Grooming & Healthcare Kits",
      "Toothbrushes & Toothpaste",
      "Shampoo & Conditioners",
      "Washcloths & Towels",
      "Baby Bath Mats",
      "Clothing & Accessories",
      "Newborn",
      "New Born Unisex (0 - 6 mnths)",
      "New born bodysuits",
      "New born sets & Packs",
      "Accessories",
      "Boys (Under 3 Years)",
      "Girls (Under 3 Years)",
      "Baby & Toddler Toys",
      "Activity Gym & Playmats",
      "Bath Toys",
      "Building Blocks Toys",
      "Crib Toys & Attachments",
      "Early Development Toys",
      "Music & Sound",
      "Rattles",
      "Push & Pull Toys",
      "Remote Control & Vehicles",
      "Die-Cast Vehicles",
      "Drones & Accessories",
      "Play Trains & Railway Sets",
      "Play Vehicles",
      "RC Vehicles & Batteries",
      "Sports & Outdoor Play",
      "Inflatable Bouncers",
      "Swimming Pool & Water Toys",
      "Fidget Spinners",
      "Toys & Games",
      "Arts & Crafts for Kids",
      "Action Figures & Collectibles",
      "Blocks & Building toys",
      "Dolls & Accessories",
      "Learning & Education",
      "Puzzle & Boardgames",
      "Slime & Squishy Toys",
      "Stuffed Toys",
      "Pretend Play",
    ],

    " Home & Lifestyle": [
      "Bath Mats",
      "Bath Towels",
      "Bathrobes",
      "Bathroom Scales",
      "Bathroom Shelving",
      "Shower Caddies & Hangers",
      "Shower Curtains",
      "Towel Rails & Warmers",
      "Bed Sheets",
      "Bedding Accessories",
      "Blankets & Throws",
      "Comforters, Quilts & Duvets",
      "Mattress Protectors",
      "Pillow Cases",
      "Pillows & Bolsters",
      "Artificial Flowers & Plants",
      "Candles & Candleholders",
      "Clocks",
      "Curtains",
      "Cushions & Covers",
      "Mirrors",
      "Picture Frames",
      "Rugs & Carpets",
      "Vases & Vessels",
      "Wall Stickers & Decals",
      "Bedroom",
      "Gaming Furniture",
      "Home Office",
      "Kitchen Furniture",
      "Living Room",
      "Bakeware",
      "Coffee & Tea",
      "Cookware",
      "Dinnerware",
      "Drinkware",
      "Kitchen & Table Linen",
      "Kitchen Storage & Accessories",
      "Kitchen Utensils",
      "Knives & Accessories",
      "Serve ware",
      "Ceiling Lights",
      "Floor Lamps",
      "Lamp Shades",
      "Light Bulbs",
      "Lighting Fixtures & Components",
      "Outdoor Lighting",
      "Rechargeables & Flashlights",
      "Table Lamps",
      "Laundry & Cleaning",
      "Brushes, Sponges & Wipers",
      "Brooms, Mops & Sweepers",
      "Laundry Baskets & Hampers",
      "Clothes Line & Drying Racks",
      "Ironing Boards",
      "Fixtures & Plumbing",
      " Electrical",
      "Hand Tools",
      "Lawn & Garden",
      "Power Tools",
      "Security",
      "Home Build Up",
      "Primers",
      "Paints",
      "Art Supplies",
      "Gifts & Wrapping",
      "Packaging & Cartons",
      "Paper Products",
      "School & Office Equipment",
      "Writing & Correction",
      "School Uniforms",
      "Books",
      " English Books",
      "Islamic Books",
      "English Literature Books",
      " English Story Books",
      "History Books",
      "eBooks",
      "Magazines",
      "Musical Instruments",
    ],

    "Electronic Devices": [
      "Apple iPhones",
      "Xiaomi Mi Mobiles",
      "Nokia Mobiles",
      "Redmi Mobiles",
      "Realme Mobiles",
      "Oppo Mobile Phones",
      "Oneplus Mobiles",
      "Infinix Mobiles",
      "Honor Mobiles",
      "Tecno Mobiles",
      "Samsung Mobile Phones",
      "Vivo Mobiles",
      "Feature Phones",
      "Tablets",
      "Monitors",
      "Laptops",
      "Refurbished Laptops",
      "Traditional Laptops",
      "Like New Laptops",
      "Desktops",
      "Smart Watches",
      "PlayStation Consoles",
      " Playstation Games",
      "Playstation Controllers",
      "Nintendo Games",
      "Xbox Games",
      "Video Camera",
      "DSLR",
      "Drones",
      "Point & Shoot",
      "Instant Cameras",
      "Security Cameras",
      "Landline Phones",
    ],

    "Electronic Accessories": [
      "Phone Cases",
      "Power Banks",
      "Cables & Converters",
      " Wall Chargers",
      "Wireless Chargers",
      "Tablet Accessories",
      "Car Chargers",
      "Screen Protectors",
      "Phone Camera Flash Lights",
      "Selfie Sticks",
      "Docks & Stands",
      "Car Mounts",
      "Headphones & Headsets",
      "Bluetooth Headsets",
      "Headphones & Headsets Access.",
      "Mono Headsets",
      "Over-The-Ear Headphones",
      "Wired Headsets",
      "Wireless Earbuds",
      "In-Ear Headphones",
      "Wearable",
      "Smartwatches",
      "Fitness & Activity Trackers",
      "Fitness Tracker Accessories",
      "Virtual Reality",
      "Camera Accessories",
      "Memory Cards",
      "Lenses",
      "Tripods & Monopods",
      "Camera Cases, Covers and Bags",
      "Action Camera Accessories",
      "Gimbals & Stabilizers",
      "Lighting & Studio Equipment",
      "Batteries",
      "Computer Accessories",
      "Cooling Pads/Cooling Stands",
      "Mouse",
      "Adapters & Cables",
      "PC Audio",
      "Mac Accessories",
      "Keyboards",
      "Storage",
      "External Hard Drives",
      "Internal Hard Drives",
      "Flash Drives",
      "Storage for Mac",
      "OTG Drives",
      "Printers",
      " Printers",
      "Ink & Toners",
      "Fax Machines",
      "Computer Components",
      "Graphic Cards",
      "Desktop Casings",
      "Motherboards",
      "Fans & Heatsinks",
      "Processors",
      "Portable Speakers",
      "Network Components",
      " Access Points",
      "Gaming Accessories",
      "Gaming Mouse",
      "Gaming Keyboards",
      "Gaming Headsets",
      "Monitors & Accessories",
    ],

    "TV & Home Appliances": [
      "Air Conditioner",
      "Televisions",
      "Smart Televisions",
      "LED Televisions",
      "Refrigerators & Freezers",
      "Refrigerators",
      " Freezers",
      "Home Audio & Theater",
      "Home Entertainment",
      "Live Sound & Stage Equipment",
      "Home Theater Systems",
      "Soundbar Speakers",
      "Washing Machine",
      "Kitchen Appliances",
      "Microwave",
      "Oven",
      "Air & Deep Fryers",
      "Blender, Mixer & Grinder",
      "Juicer & Fruit Extraction",
      "Water Dispensers",
      "Electric Kettle",
      "Dishwashers",
      "Cooktop & Range",
      "Rice Cooker",
      "Pressure Cookers",
      "Specialty Cookware",
      "Cooling & Heating",
      " Air Cooler",
      " Fan",
      " Air Purifier",
      " Dehumidifier",
      " Humidifier",
      "Water Heater",
      " Room Heater",
      "Irons & Garment Care",
      " Irons",
      " Garment Steamer",
      "Sewing Machine",
      "Generator, UPS & Solar",
      "Generators",
      "UPS",
      "Solar Inverters",
      "Projectors & Players",
      "Blu Ray & DVD Players",
      "Projectors",
      "TV Accessories",
      "TV Receivers",
      "Wall Mounts & Protectors",
      "TV Adapters",
      "TV Remote Controllers",
      "3D Glasses",
      "Antennas",
      "Cables",
      "Vacuums & Floor Care",
      "Vacuum Cleaner",
      "Floor Polisher",
      "Steam Mops",
      "Electric Brooms",
    ],

    "Sports & Outdoor": [
      "Exercise & Fitness",
      "Cardio Training Equipment",
      "Strength Training Equipments",
      "Treadmills",
      "Fitness Accessories",
      "Boxing, Martial Arts & MMA",
      "Weight",
      "Yoga",
      "Exercise Bands",
      "Exercise Bikes",
      "Supplements",
      "Proteins",
      "Post Workouts and Recovery",
      "Pre Workouts",
      "Fat Burners",
      "Post Workouts and recovery",
      "Shoes & Clothing",
      "Mens Shoes",
      "Mens Clothing",
      "Womens Shoes",
      "Womens Clothing",
      "Men Accessories",
      "Men Bags",
      "Women Accessories",
      "Women Bags",
      "Team Sports",
      "Cricket",
      "Football",
      "Hockey",
      "Basket Ball",
      "Volley balls",
      "Baseballs",
      "Racket Sports",
      "Rackets",
      "Shuttlecocks",
      "Balls",
      "Bats",
      "Nets",
      "Grips & Tapes",
      "Balls",
      "Outdoor Recreation",
      "Cycling",
      "Cycle accessories",
      "Camping / Hiking",
      "Other activities",
      "Water Sports",
      "Fishing",
      "Skate Boards",
      "Fitness Gadgets",
      "Fitness Trackers",
      "Fitness Trackers Accessories",
      "Sports Accessories",
      "Water Bottles",
      "Home Gym",
    ],

    "Watches, Bags & Jewellery": [
      "Men's Watches",
      "Chronograph-Men's Watches",
      "Branded-Men's Watches",
      "Analog-Men's Watches",
      "Digital-Men's Watches",
      "Accessories-Men's Watches",
      "Smart Watches-Men's Watches",
      "Women's Watches",
      "Chronograph-Women's Watches",
      "Branded-Women's Watches",
      "Analog-Women's Watches",
      "Digital-Women's Watches",
      "Accessories-Women's Watches",
      "Smart Watches-Women's Watches",
      "Kid's Watches",
      "Boys",
      "Girls",
      "Womens Bags",
      "Cross Body & Shoulder Bags",
      "Top-Handle Bags",
      "Tote Bags",
      "Backpacks",
      "Clutches",
      "Wallets & Accessories",
      "Wristlets",
      "Mens Bags",
      "Wallets & Cardholders",
      "Backpacks",
      "Crossbody Bags",
      "Business Bags",
      "Messenger Bags",
      "Cardholders & Keychains",
      "Luggage & Suitcase",
      "Travel Accessories",
      "Laptop Bags",
      "Luggage",
      "Duffel Bags",
      "Womens Jewellery",
      "Rings",
      "Earrings",
      "Necklaces",
      "Bracelets",
      "Anklets",
      "Jewellery Sets",
      "Mens Jewellery",
      "Rings",
      "Chains",
      "Bracelets",
      "Studs",
      "Pendants",
      "Mens Accessories",
      "Belts",
      "Hats & Caps",
      "Scarves",
      "Socks",
      "Ties & Bow Ties",
      "Gloves",
      "Women's Accessories",
      "Scarves",
      "Belts",
      "Socks & Tights",
      "Hats & Caps",
      "Gloves",
      "Hair Accessories",
      "Sunglasses & Eyewear",
      "Sunglasses",
      "Unisex Sunglasses",
      "Women Eyeglasses",
      "Unisex Eyeglasses",
      "Kids Sunglasses",
      "Women Sunglasses",
      "Men Eyeglasses",
      "Kids Eyeglasses",
      "Lenses",
    ],

    "Automotive & Motorbikes": [
      "Automotive",
      "Car Tires & Wheels",
      "Car Oils & Fluids",
      "Car Interior Accessories",
      "Car Tools & Equipment",
      "Car Parts & Spares",
      "Car Care",
      "Car Exterior Accessories",
      "Car Electronics",
      "Car Safety & Security",
      "Motorcycle",
      "Electric Bikes",
      "Standard Bikes",
      "ATVs & UTVs",
      "Sports Bikes",
      "Riding Gear",
      "Motorcycle Helmets",
      "Motorcycle Tires & Wheels",
      "Motorcycle Parts & Spares",
      "Motorcycle Oil & Fluids",
      "Motorcycle Accessories",
      "Loaders & Rickshaw",
      "Loaders",
      "Auto Rikshaw",
    ],
  };
  const categoryFilterList = {
    All: "",
    Fashion: ["Dresses", "Shoes", "Accessories", "Other"],
    Beauty: ["Skincare", "Makeup", "Fragrances", "Other"],
    Electronics: ["Smartphones", "Laptops", "Headphones", "Other"],
    "Home Goods": ["Furniture", "Kitchen Appliances", "Decor", "Other"],
    Toys: ["Action Figures", "Board Games", "Dolls", "Other"],
    "Health and Wellness": [
      "Vitamins",
      "Fitness Equipment",
      "Supplements",
      "Other",
    ],
    "Pet Supplies": ["Dog Food", "Cat Toys", "Fish Tanks", "Other"],
    "Outdoor Gear": ["Camping Gear", "Hiking Boots", "Bicycles", "Other"],
    Books: ["Fiction", "Non-Fiction", "Mystery", "Other"],
    Others: ["Miscellaneous", "Random", "Unspecified", "Other"],
    "Groceries & Pets": [
      "Fresh Produce",
      "Fresh Fruit",
      "Fresh Vegetables",
      "Fish & Cold Pressed Juices",
      "Bread",
      "Eggs",
      "Dry Fruits",
      "Breakfast, Choco's & Snacks",
      "Breakfast Cereals",
      "Jams, Honey & Spreads",
      "Breakfast Bars",
      "Instant Breakfast Drinks",
      "Chocolate & Candy",
      "Chewing Gums & Mints",
      "Biscuit & Cookies",
      "Cakes & Sweets",
      "Chips & Crisps",
      "Nuts & Legumes",
      "Oatmeal",
      "Pancake & Waffle Mixes",
      "Beverages",
      "Tea",
      "Coffee",
      "UHT, Milk & Milk Powder",
      "Soft Drinks",
      "Juices",
      "Water",
      "Flavouring Syrup",
      "Powdered Drinks",
      "Food Staples",
      "Oil",
      " Rice",
      "Condiment Dressing",
      "Cooking Ingredients",
      "Noodles & Pasta",
      "Instant & Ready-to-Eat",
      "Grains, Beans & Pulses",
      "Home Baking & Sugar",
      "Jarred Food",
      "Canned Food",
      "Laundry & Household",
      "AirCare",
      "Cleaning",
      "Dishwashing",
      "Laundry",
      "Pest Control",
      "Tissue Paper",
      "Foils & Cling Films",
      " Frozen Food",
      "Chicken",
      "Beef",
      "Other Frozen Food",
      "Cat Food",
      "Cat Beds, Mats & Houses",
      "Cat Carriers & Travel",
      "Cat Grooming",
      "Cat Litter & Toilet",
      "Cat Cages, Crates & Doors",
      "Cat Toys",
      "Cat Treats",
      "Cat Trees, Condos & Scratchers",
      "Cat Bowls & Feeders",
      "Dog Collars",
      "Dog Toys",
      "Dog Harnesses",
      "Dog Brushes & Combs",
      "Dog Shampoos & Bath Accessories ",
      "Dog Bowls & Diners",
      "Dog Beds",
      "Fish",
      "Decorations",
      "Water pumps",
      "Aquariums",
      "Filters & Accessories",
      "Air Pumps & Accessories",
      "Temperature control supplies",
      "Food",
    ],
    "Health & Beauty": [
      "Makeup",
      "Makeup Accessories",
      "Lips",
      "Nails",
      "Face",
      "Eyes",
      "Makeup Removers",
      "Makeup Palettes & Sets",
      "Bulk Deals",
      "Beauty Tools",
      "Skin Care Tools",
      "Hair Removal Appliances",
      "Slimming & Electric Massagers",
      "Hair Styling Appliances",
      "Foot Relief Tools",
      "Skin Care",
      "Moisturizers and Cream",
      "Facial Cleansers",
      "Serum & Essence",
      "Face Mask & Packs",
      "Sunscreen & Aftersun",
      "Lip Balm & Treatment",
      "Eye Care",
      "Face Scrubs & Exfoliators",
      "Toner & Mists",
      "Hair Care",
      "Shampoo & Conditioner",
      "Hair Care Accessories",
      "Hair Treatments",
      "Oil & Serums",
      "Hair Coloring",
      " Hair Styling",
      "Body Soaps & Shower Gels",
      "Hair Removal",
      "Body & Massage Oils",
      "Bath & Body Accessories",
      "Foot Care",
      "Hand Care",
      "Bath & Body",
      "Hair Care",
      "Shaving & Grooming",
      "Personal Care",
      "Pads & Tampons",
      "Menstrual Cups",
      "Oral Care",
      "Eye Care",
      "Deodorants",
      "Adult Diapers",
      "Personal Safety & Security",
      " Ear Care",
      "Fragrances for Women",
      "Fragrances for Men",
      "Fragrances for Unisex",
      "Sexual Wellness",
      "Condoms",
      "Lubricants",
      "Medical Supplies",
      "Health Monitors & Tests",
      "Health Accessories",
      "First Aid Supplies",
      "Ointments and Creams",
      "Surgical Masks",
      "Nebulizer & Aspirators",
      "Stethoscopes",
    ],

    "Men's Fashion": [
      "T Shirts & Tanks",
      "V Neck",
      "Round Neck",
      "Graphic tees",
      "Top Rated",
      "Shirts & Polo",
      "Casual Shirts",
      "Formal Shirts",
      "Polos",
      "Pants & Jeans",
      "Chinos",
      "Cargo",
      "Jeans",
      "Shorts, Joggers & Sweats",
      "Shorts",
      "Joggers & Sweats",
      "Unstitched Fabric",
      "Kurtas & Shalwar Kameez",
      "Kurtas",
      "Shalwar",
      "Shawls",
      "Winter Clothing",
      "Jackets & Coats",
      "Hoodies & Sweatshirts",
      "Sweaters & Cardigans",
      "Inner Wear",
      "Briefs",
      "Trunk & Boxers",
      "Nightwear",
      "Vests",
      "Thermal",
      "Socks",
      "Shoes",
      "Slip-Ons & Loafers",
      "Flip Flops & Sandals",
      "Sneakers",
      "Formal Shoes",
      "Boots",
      "Khusa & Kolapuri",
      "Shoes Accessories",
      "Accessories",
      "Belts",
      "Sunglasses",
      "Hats & Caps",
      "Boy's Clothing",
      "T-Shirts & Shirts",
      "Pants & Jeans",
      "Shorts",
      "Kurtas & Shalwar Kameez",
      "Underwear & Socks",
      "Ties & Bows",
      "Shoes",
      "Sneakers",
      "Slip-Ons & Loafers",
      "School Shoes",
      "Flip Flops, Slides & Sandals",
      "Formal Shoes",
      "Boots ",
      "Sandals",
      "Boy's Accessories",
      "Belts",
      "Inner Wear",
      "Socks",
      "Hats & Caps",
    ],

    "Women's Fashion": [
      "Unstitched Fabric",
      "Branded Unstitched",
      "Sarees",
      "Kurtas & Shalwar Kameez",
      "Kurtis",
      " Shalwar Kameez",
      "Branded Pret",
      "Trousers & Palazzos",
      "Muslim Wear",
      "Dupattas, Stoles & Shawls",
      "Abayas & Hijabs",
      "Scarves",
      " Hair Accessories",
      "Tops",
      "Blouses & Shirts",
      "Tunics",
      " T-Shirts",
      "Bras",
      "Panties",
      "Lingerie Sets",
      "Beachwear and Bikinis",
      "Socks & Tights",
      "Sleepwear & Innerwear",
      "Nightwear",
      "Robe and Gown sets",
      "Tanks & Camisoles",
      "Shapewear",
      "Pants",
      "Leggings",
      "Jeans",
      "Shorts",
      "Jeggings",
      "Dresses & Skirts",
      "Ethnic Dresses",
      "Western Dresses",
      "Skirts",
      " Formal Wear",
      "Winter Clothing",
      "Jackets & Coats",
      "Hoodies & Sweatshirts",
      "Sweaters & Cardigans",
      "Shawls and Poncho's",
      "Shrugs",
      "Shoes",
      "Sandals",
      "Flat Shoes",
      "Heels",
      "Khussa & Kohlapuri",
      "Slides & Flip FLops",
      " Wedges",
      "Sneakers",
      "Boots",
      "Shoes Accessories",
      "Girls Clothing",
      "Dresses",
      "Tops",
      "Underwear & Sleepwear",
      "Bottoms",
      "Socks & Tights",
      "Jackets & Coats",
      "Swimsuits",
      "Hoodies",
      "Hair Accessories",
      "Hats & Caps",
      "Belts",
      "Gloves, Scarves & Cold Weather",
      "Girls Shoes",
      "Sneakers",
      "Flats & Slip - Ons",
      "Formal Shoes",
      "Sandals",
      "Flip Flops, Slides & Sandals",
      "School Shoes",
      "Boots",
    ],

    "Mother & Baby": [
      "Milk Formula & Baby Food",
      " Maternal",
      "Infant Milk (0-6 Months)",
      "Infant Milk (6-12 Months)",
      " Toddler Milk (1 - under 3 yrs)",
      "Growing-up Milk (3 yrs +)",
      "Baby & Toddler Foods",
      "Diapering & Potty",
      "Disposable diapers",
      "Diaper Bags",
      "Wipes & Holders",
      "Changing Tables, Pads & Kits",
      "Diapering Care",
      "Cloth Diapers & Accessories",
      "Potty Training",
      "Feeding",
      "Utensils",
      "Bottle-Feeding",
      "Breastfeeding",
      "Food Blenders",
      "Pacifiers & Teethers",
      "Baby & Toddler Foods",
      "Maternity Care",
      "Maternity Wear",
      "Maternity Accessories",
      "Nursing Covers",
      "Pregnancy Pillows",
      "Nipple Care",
      "Breast Shells",
      "Breast Pumps",
      "Baby Gear",
      "Kids Bag",
      "Swings, Jumpers & Bouncers",
      "Strollers",
      "Car Seats",
      "Walkers",
      "Backpacks & Carriers",
      "Harnesses & Leashes",
      "Baby Safety",
      "Baby Monitor",
      "Highchairs & Booster Seats",
      "Baby Sofas",
      "Nursery ",
      "Baby Furniture",
      "Mattresses & Bedding",
      "Storage & Organization",
      "Nursery Decor",
      "Baby Personal Care",
      "Skin Care",
      "Bathing Tubs & Seats",
      "Soaps, Cleansers & Bodywash",
      "Baby Groomings & Care Kits",
      "Grooming & Healthcare Kits",
      "Toothbrushes & Toothpaste",
      "Shampoo & Conditioners",
      "Washcloths & Towels",
      "Baby Bath Mats",
      "Clothing & Accessories",
      "Newborn",
      "New Born Unisex (0 - 6 mnths)",
      "New born bodysuits",
      "New born sets & Packs",
      "Accessories",
      "Boys (Under 3 Years)",
      "Girls (Under 3 Years)",
      "Baby & Toddler Toys",
      "Activity Gym & Playmats",
      "Bath Toys",
      "Building Blocks Toys",
      "Crib Toys & Attachments",
      "Early Development Toys",
      "Music & Sound",
      "Rattles",
      "Push & Pull Toys",
      "Remote Control & Vehicles",
      "Die-Cast Vehicles",
      "Drones & Accessories",
      "Play Trains & Railway Sets",
      "Play Vehicles",
      "RC Vehicles & Batteries",
      "Sports & Outdoor Play",
      "Inflatable Bouncers",
      "Swimming Pool & Water Toys",
      "Fidget Spinners",
      "Toys & Games",
      "Arts & Crafts for Kids",
      "Action Figures & Collectibles",
      "Blocks & Building toys",
      "Dolls & Accessories",
      "Learning & Education",
      "Puzzle & Boardgames",
      "Slime & Squishy Toys",
      "Stuffed Toys",
      "Pretend Play",
    ],

    " Home & Lifestyle": [
      "Bath Mats",
      "Bath Towels",
      "Bathrobes",
      "Bathroom Scales",
      "Bathroom Shelving",
      "Shower Caddies & Hangers",
      "Shower Curtains",
      "Towel Rails & Warmers",
      "Bed Sheets",
      "Bedding Accessories",
      "Blankets & Throws",
      "Comforters, Quilts & Duvets",
      "Mattress Protectors",
      "Pillow Cases",
      "Pillows & Bolsters",
      "Artificial Flowers & Plants",
      "Candles & Candleholders",
      "Clocks",
      "Curtains",
      "Cushions & Covers",
      "Mirrors",
      "Picture Frames",
      "Rugs & Carpets",
      "Vases & Vessels",
      "Wall Stickers & Decals",
      "Bedroom",
      "Gaming Furniture",
      "Home Office",
      "Kitchen Furniture",
      "Living Room",
      "Bakeware",
      "Coffee & Tea",
      "Cookware",
      "Dinnerware",
      "Drinkware",
      "Kitchen & Table Linen",
      "Kitchen Storage & Accessories",
      "Kitchen Utensils",
      "Knives & Accessories",
      "Serve ware",
      "Ceiling Lights",
      "Floor Lamps",
      "Lamp Shades",
      "Light Bulbs",
      "Lighting Fixtures & Components",
      "Outdoor Lighting",
      "Rechargeables & Flashlights",
      "Table Lamps",
      "Laundry & Cleaning",
      "Brushes, Sponges & Wipers",
      "Brooms, Mops & Sweepers",
      "Laundry Baskets & Hampers",
      "Clothes Line & Drying Racks",
      "Ironing Boards",
      "Fixtures & Plumbing",
      " Electrical",
      "Hand Tools",
      "Lawn & Garden",
      "Power Tools",
      "Security",
      "Home Build Up",
      "Primers",
      "Paints",
      "Art Supplies",
      "Gifts & Wrapping",
      "Packaging & Cartons",
      "Paper Products",
      "School & Office Equipment",
      "Writing & Correction",
      "School Uniforms",
      "Books",
      " English Books",
      "Islamic Books",
      "English Literature Books",
      " English Story Books",
      "History Books",
      "eBooks",
      "Magazines",
      "Musical Instruments",
    ],

    "Electronic Devices": [
      "Apple iPhones",
      "Xiaomi Mi Mobiles",
      "Nokia Mobiles",
      "Redmi Mobiles",
      "Realme Mobiles",
      "Oppo Mobile Phones",
      "Oneplus Mobiles",
      "Infinix Mobiles",
      "Honor Mobiles",
      "Tecno Mobiles",
      "Samsung Mobile Phones",
      "Vivo Mobiles",
      "Feature Phones",
      "Tablets",
      "Monitors",
      "Laptops",
      "Refurbished Laptops",
      "Traditional Laptops",
      "Like New Laptops",
      "Desktops",
      "Smart Watches",
      "PlayStation Consoles",
      " Playstation Games",
      "Playstation Controllers",
      "Nintendo Games",
      "Xbox Games",
      "Video Camera",
      "DSLR",
      "Drones",
      "Point & Shoot",
      "Instant Cameras",
      "Security Cameras",
      "Landline Phones",
    ],

    "Electronic Accessories": [
      "Phone Cases",
      "Power Banks",
      "Cables & Converters",
      " Wall Chargers",
      "Wireless Chargers",
      "Tablet Accessories",
      "Car Chargers",
      "Screen Protectors",
      "Phone Camera Flash Lights",
      "Selfie Sticks",
      "Docks & Stands",
      "Car Mounts",
      "Headphones & Headsets",
      "Bluetooth Headsets",
      "Headphones & Headsets Access.",
      "Mono Headsets",
      "Over-The-Ear Headphones",
      "Wired Headsets",
      "Wireless Earbuds",
      "In-Ear Headphones",
      "Wearable",
      "Smartwatches",
      "Fitness & Activity Trackers",
      "Fitness Tracker Accessories",
      "Virtual Reality",
      "Camera Accessories",
      "Memory Cards",
      "Lenses",
      "Tripods & Monopods",
      "Camera Cases, Covers and Bags",
      "Action Camera Accessories",
      "Gimbals & Stabilizers",
      "Lighting & Studio Equipment",
      "Batteries",
      "Computer Accessories",
      "Cooling Pads/Cooling Stands",
      "Mouse",
      "Adapters & Cables",
      "PC Audio",
      "Mac Accessories",
      "Keyboards",
      "Storage",
      "External Hard Drives",
      "Internal Hard Drives",
      "Flash Drives",
      "Storage for Mac",
      "OTG Drives",
      "Printers",
      " Printers",
      "Ink & Toners",
      "Fax Machines",
      "Computer Components",
      "Graphic Cards",
      "Desktop Casings",
      "Motherboards",
      "Fans & Heatsinks",
      "Processors",
      "Portable Speakers",
      "Network Components",
      " Access Points",
      "Gaming Accessories",
      "Gaming Mouse",
      "Gaming Keyboards",
      "Gaming Headsets",
      "Monitors & Accessories",
    ],

    "TV & Home Appliances": [
      "Air Conditioner",
      "Televisions",
      "Smart Televisions",
      "LED Televisions",
      "Refrigerators & Freezers",
      "Refrigerators",
      " Freezers",
      "Home Audio & Theater",
      "Home Entertainment",
      "Live Sound & Stage Equipment",
      "Home Theater Systems",
      "Soundbar Speakers",
      "Washing Machine",
      "Kitchen Appliances",
      "Microwave",
      "Oven",
      "Air & Deep Fryers",
      "Blender, Mixer & Grinder",
      "Juicer & Fruit Extraction",
      "Water Dispensers",
      "Electric Kettle",
      "Dishwashers",
      "Cooktop & Range",
      "Rice Cooker",
      "Pressure Cookers",
      "Specialty Cookware",
      "Cooling & Heating",
      " Air Cooler",
      " Fan",
      " Air Purifier",
      " Dehumidifier",
      " Humidifier",
      "Water Heater",
      " Room Heater",
      "Irons & Garment Care",
      " Irons",
      " Garment Steamer",
      "Sewing Machine",
      "Generator, UPS & Solar",
      "Generators",
      "UPS",
      "Solar Inverters",
      "Projectors & Players",
      "Blu Ray & DVD Players",
      "Projectors",
      "TV Accessories",
      "TV Receivers",
      "Wall Mounts & Protectors",
      "TV Adapters",
      "TV Remote Controllers",
      "3D Glasses",
      "Antennas",
      "Cables",
      "Vacuums & Floor Care",
      "Vacuum Cleaner",
      "Floor Polisher",
      "Steam Mops",
      "Electric Brooms",
    ],

    "Sports & Outdoor": [
      "Exercise & Fitness",
      "Cardio Training Equipment",
      "Strength Training Equipments",
      "Treadmills",
      "Fitness Accessories",
      "Boxing, Martial Arts & MMA",
      "Weight",
      "Yoga",
      "Exercise Bands",
      "Exercise Bikes",
      "Supplements",
      "Proteins",
      "Post Workouts and Recovery",
      "Pre Workouts",
      "Fat Burners",
      "Post Workouts and recovery",
      "Shoes & Clothing",
      "Mens Shoes",
      "Mens Clothing",
      "Womens Shoes",
      "Womens Clothing",
      "Men Accessories",
      "Men Bags",
      "Women Accessories",
      "Women Bags",
      "Team Sports",
      "Cricket",
      "Football",
      "Hockey",
      "Basket Ball",
      "Volley balls",
      "Baseballs",
      "Racket Sports",
      "Rackets",
      "Shuttlecocks",
      "Balls",
      "Bats",
      "Nets",
      "Grips & Tapes",
      "Balls",
      "Outdoor Recreation",
      "Cycling",
      "Cycle accessories",
      "Camping / Hiking",
      "Other activities",
      "Water Sports",
      "Fishing",
      "Skate Boards",
      "Fitness Gadgets",
      "Fitness Trackers",
      "Fitness Trackers Accessories",
      "Sports Accessories",
      "Water Bottles",
      "Home Gym",
    ],

    "Watches, Bags & Jewellery": [
      "Men's Watches",
      "Chronograph-Men's Watches",
      "Branded-Men's Watches",
      "Analog-Men's Watches",
      "Digital-Men's Watches",
      "Accessories-Men's Watches",
      "Smart Watches-Men's Watches",
      "Women's Watches",
      "Chronograph-Women's Watches",
      "Branded-Women's Watches",
      "Analog-Women's Watches",
      "Digital-Women's Watches",
      "Accessories-Women's Watches",
      "Smart Watches-Women's Watches",
      "Kid's Watches",
      "Boys",
      "Girls",
      "Womens Bags",
      "Cross Body & Shoulder Bags",
      "Top-Handle Bags",
      "Tote Bags",
      "Backpacks",
      "Clutches",
      "Wallets & Accessories",
      "Wristlets",
      "Mens Bags",
      "Wallets & Cardholders",
      "Backpacks",
      "Crossbody Bags",
      "Business Bags",
      "Messenger Bags",
      "Cardholders & Keychains",
      "Luggage & Suitcase",
      "Travel Accessories",
      "Laptop Bags",
      "Luggage",
      "Duffel Bags",
      "Womens Jewellery",
      "Rings",
      "Earrings",
      "Necklaces",
      "Bracelets",
      "Anklets",
      "Jewellery Sets",
      "Mens Jewellery",
      "Rings",
      "Chains",
      "Bracelets",
      "Studs",
      "Pendants",
      "Mens Accessories",
      "Belts",
      "Hats & Caps",
      "Scarves",
      "Socks",
      "Ties & Bow Ties",
      "Gloves",
      "Women's Accessories",
      "Scarves",
      "Belts",
      "Socks & Tights",
      "Hats & Caps",
      "Gloves",
      "Hair Accessories",
      "Sunglasses & Eyewear",
      "Sunglasses",
      "Unisex Sunglasses",
      "Women Eyeglasses",
      "Unisex Eyeglasses",
      "Kids Sunglasses",
      "Women Sunglasses",
      "Men Eyeglasses",
      "Kids Eyeglasses",
      "Lenses",
    ],

    "Automotive & Motorbikes": [
      "Automotive",
      "Car Tires & Wheels",
      "Car Oils & Fluids",
      "Car Interior Accessories",
      "Car Tools & Equipment",
      "Car Parts & Spares",
      "Car Care",
      "Car Exterior Accessories",
      "Car Electronics",
      "Car Safety & Security",
      "Motorcycle",
      "Electric Bikes",
      "Standard Bikes",
      "ATVs & UTVs",
      "Sports Bikes",
      "Riding Gear",
      "Motorcycle Helmets",
      "Motorcycle Tires & Wheels",
      "Motorcycle Parts & Spares",
      "Motorcycle Oil & Fluids",
      "Motorcycle Accessories",
      "Loaders & Rickshaw",
      "Loaders",
      "Auto Rikshaw",
    ],
  };

  const categoryNames = Object.keys(categoryFilterList);
  const handleSave = async () => {
    const data = {
      title: streamTitle,
      description: description,
      thumbnail: thumbnail ? thumbnail : streamDetail?.thumbnail,
      category: streamCategory !== "" ? streamCategory : streamDetail?.category,
    };
    const response = await UpdateStreamDetail(selectedStream, data);
    if (response?.status === 201) {
      toast.success("Stream details have been successfully updated");
      fetchCardDetail(selectedStream);
      fetchUsersStreams(currentPage);
      setThumbnail("");
    }
    setEditing(false);
  };
  const handlePageChange = (event, newPage) => {
    fetchUsersStreams(newPage);
  };
  useEffect(() => {
    fetchUsersStreams(currentPage);
  }, []);
  useEffect(() => {
    setDescription(streamDetail?.description);
    setStreamTitle(streamDetail?.title);
  }, [streamDetail]);

  const buttonText = "categeory 4";
  const maxLength = 15;
  const handleCardClick = (roomid) => {
    setSelectedStream(roomid);
    fetchCardDetail(roomid);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const textareaStyle = {
    width: "100%",
    fontFamily: "Poppins, sans-serif",
    borderColor: "#F0F0F1",
    outline: "none",
    transition: "border-color 0.3s",
  };
  return (
    <div>
      <Dialog
        open={recordedStreamsModal}
        onClose={handleStreamModal}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: {
                xs: "290px",
                xsPlus: "330px",
                sm: "500px",
                md: "800px",
                lg: "1150px",
                br500: "430px",
              }, // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#fff",
            position: "relative",
            padding: "25px",
            // width: "800px",
            overflow: "visible",
            p: { xs: "30px", md: "30px", lg: "30px" },
          }}
        >
          {selectedStream ? (
            <>
              <Grid container spacing={2} rowSpacing={1}>
                <Grid item xs={12} sm={6} xmd={6} lg={6} my={2}>
                  <Card
                    sx={{
                      position: "relative",
                      maxWidth: 345,
                      backgroundColor: "#ffff",
                      boxShadow: "none",
                    }}

                    // onClick={() =>
                    //   (window.location.href = `/live/${el?.room}/${el?.business_id?._id}`)
                    // }
                  >
                    <CardMedia
                      sx={{
                        height: 230,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                      component="img"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        maxHeight: "300px",
                      }}
                      src={
                        thumbnail !== ""
                          ? thumbnail
                          : streamDetail?.thumbnail
                          ? streamDetail?.thumbnail
                          : require("../../assets/Img/t1.png")
                      }
                      alt="thumbnail"
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: "8px",
                        display: "block",
                      }}
                    >
                      {thumbnail === "" ? (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: "white",
                            color: "black",
                            transform: "none",
                            fontSize: "12px",
                            ":hover": {
                              background: "black",
                              color: "white",
                            },
                          }}
                          {...getRootProps()}
                          multiple={false}
                        >
                          <input
                            {...getInputProps()}
                            multiple={false}
                            disabled={imageloading}
                          />

                          {imageloading ? (
                            <CircularProgress sx={{ fontSize: "10px" }} />
                          ) : (
                            <React.Fragment>
                              <span
                                style={{
                                  display: "block",
                                  visibility: "visible",
                                }}
                              >
                                Change Thumbnail
                              </span>
                              <span
                                style={{
                                  display: "none",
                                  visibility: "hidden",
                                }}
                              >
                                Hidden Text
                              </span>
                            </React.Fragment>
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: "white",
                            color: "black",
                            transform: "none",
                            fontSize: "12px",
                            ":hover": {
                              background: "black",
                              color: "white",
                            },
                          }}
                          onClick={() => handleRemove(thumbnail)}
                        >
                          Delete Thumbnail
                        </Button>
                      )}
                    </Box>

                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "18px",
                              fontWeight: "600",
                            }}
                          >
                            {isEditing ? (
                              <textarea
                                style={textareaStyle}
                                rows={1}
                                value={streamTitle}
                                onChange={(e) => setStreamTitle(e.target.value)}
                              />
                            ) : (
                              streamTitle
                            )}
                          </Typography>
                          {isEditing ? (
                            <>
                              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    backgroundColor: "#F9B327",
                                    color: "white",
                                    borderRadius: "25px",
                                    transform: "none",
                                    fontSize: "12px",
                                    mr: "4px",
                                    ":hover": {
                                      background: "black",
                                    },
                                  }}
                                  onClick={handleSave}
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    backgroundColor: "transparent",
                                    borderRadius: "25px",

                                    color: "#F9B327",
                                    transform: "none",
                                    fontSize: "12px",
                                    ":hover": {
                                      background: "transparent",
                                    },
                                  }}
                                  onClick={() => setEditing(false)}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </>
                          ) : (
                            <ModeEditRoundedIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleEditClick()}
                            />
                          )}
                        </Box>

                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "13px",
                            my: 1,
                          }}
                        >
                          {isEditing ? (
                            <textarea
                              style={textareaStyle}
                              rows={4}
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          ) : (
                            description
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          mx: { xs: "3px" },
                          pb: 1,
                          width: "100%",
                          height: "auto",
                          overflowX: "auto",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": {
                            height: "4px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#808080",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: (theme) =>
                              theme.palette.background.paper,
                          },
                        }}
                      >
                        <Tooltip title={streamDetail?.category}>
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              backgroundColor: "black",
                              color: "white",
                              textTransform: "none",
                              fontSize: { xs: "9px", sm: "11px" },
                              whiteSpace: "nowrap",
                              marginRight: 1,
                              padding: "5px 10px",
                              minWidth: "auto",
                              flexShrink: 0,
                              "&:hover": {
                                backgroundColor: "black",
                              },
                            }}
                          >
                            {streamDetail?.category &&
                            streamDetail?.category.length > maxLength
                              ? `${streamDetail?.category.substring(
                                  0,
                                  maxLength
                                )}...`
                              : streamDetail?.category || "Others"}
                          </Button>
                        </Tooltip>
                      </Box>
                      {isEditing ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mb: "8px",
                          }}
                        >
                          <FormControl sx={{ minWidth: 200 }} size="small">
                            <InputLabel id="demo-multiple-chip-label">
                              Category
                            </InputLabel>
                            <Select
                              labelId="demo-multiple-chip-label"
                              id="demo-multiple-chip"
                              // multiple

                              onChange={(e) =>
                                setStreamCategory(e.target.value)
                              }
                              sx={{ width: "100%" }}
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  label="Category"
                                />
                              }
                              renderValue={(value) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  <Chip key={value} label={value} />
                                </Box>
                              )}
                              // MenuProps={MenuProps}
                            >
                              {Object.keys(categoryList).map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, formData?.category, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      ) : (
                        ""
                      )}
                      {isEditing ? (
                        <Box
                          sx={{
                            display: {
                              xs: "flex",
                              md: "none",
                              justifyContent: "flex-end",
                            },
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              backgroundColor: "#F9B327",
                              color: "white",
                              borderRadius: "25px",
                              transform: "none",
                              fontSize: "12px",
                              mr: "4px",
                              ":hover": {
                                background: "black",
                              },
                            }}
                            onClick={handleSave}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "25px",

                              color: "#F9B327",
                              transform: "none",
                              fontSize: "12px",
                              ":hover": {
                                background: "transparent",
                              },
                            }}
                            onClick={() => setEditing(false)}
                          >
                            Cancel
                          </Button>
                        </Box>
                      ) : (
                        ""
                      )}
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            color: "#272727",
                            display: "flex",
                            alignItems: "center",
                            mr: 2,
                            ":hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() =>
                            (window.location.href = `${streamDetail?.short_url}`)
                          }
                        >
                          <PlayCircleRoundedIcon
                            sx={{ color: "#4DA2F8", mr: "4px" }}
                          />{" "}
                          Watch This Stream
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor: "#E5E5E5",
                            color: "#0F0F0F",
                            transform: "none",
                            borderRadius: "25px",
                            fontSize: "12px",
                            ":hover": {
                              background: "#E5E5E5",
                            },
                          }}
                          onClick={handleShareClick}
                        >
                          <ShareSharpIcon sx={{ fontSize: "20px" }} /> Share
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          color: "#272727",
                          fontSize: "11px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          ":hover": {
                            cursor: "pointer",
                          },
                        }}
                        onClick={handleDeleteStreamModal}
                      >
                        <DeleteIcon />
                        <Typography
                          sx={{
                            color: "#272727",
                            fontSize: "11px",
                          }}
                        >
                          Delete
                        </Typography>
                      </Box>
                    </CardActions>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} xmd={6} lg={6} my={2}>
                  <Card
                    sx={{
                      position: "relative",
                      maxWidth: 345,
                      backgroundColor: "#ffff",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#939393",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "18.4px",
                            wordSpacing: "0",
                          }}
                        >
                          Date
                        </Typography>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "18.4px",
                            wordSpacing: "0",
                          }}
                        >
                          {moment(streamDetail?.createdAt).format(
                            "YYYY-MM-DD hh:mm:ss A"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#939393",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "18.4px",
                            wordSpacing: "0",
                          }}
                        >
                          Duration
                        </Typography>
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "15px",
                            fontWeight: "400",
                            lineHeight: "18.4px",
                            wordSpacing: "0",
                          }}
                        >
                          {duration}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#939393",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "18.4px",
                            wordSpacing: "0",
                          }}
                        >
                          Viewership Metrics:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "#F0F0F1",
                            pb: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            Viewers
                          </Typography>
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            {viewShipMAtrics?.viewers}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "#F0F0F1",
                            pb: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            Comments
                          </Typography>
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            {viewShipMAtrics?.comments}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "#F0F0F1",
                            pb: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            Likes
                          </Typography>
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            {viewShipMAtrics?.likes}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "#F0F0F1",
                            pb: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            Shares
                          </Typography>
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            {viewShipMAtrics?.shares}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#939393",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "18.4px",
                            wordSpacing: "0",
                          }}
                        >
                          Streaming Platforms:
                        </Typography>
                        <Box sx={{ mt: "2px" }}>
                          <FacebookRoundedIcon
                            sx={{ mr: 1, cursor: "pointer" }}
                          />
                          <SmartDisplayRoundedIcon
                            sx={{ mr: 1, cursor: "pointer" }}
                          />
                          <TvRoundedIcon sx={{ mr: 1, cursor: "pointer" }} />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#939393",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "18.4px",
                            wordSpacing: "0",
                          }}
                        >
                          Sale Performance:
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "#F0F0F1",
                            pb: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            Items Sold
                          </Typography>
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            {salesPerformance?.itemSold}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "#F0F0F1",
                            pb: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            Revenue Generated
                          </Typography>
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            {salesPerformance?.revenueGenerated}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: 1,
                            borderColor: "#F0F0F1",
                            pb: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            Conversion Rate
                          </Typography>
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "15px",
                              fontWeight: "400",
                              lineHeight: "18.4px",
                              wordSpacing: "0",
                            }}
                          >
                            {salesPerformance?.conversionRate}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          borderRadius: "25px",
                          ":hover": {
                            background: "black",
                          },
                        }}
                        onClick={() => {
                          setSelectedStream(null);
                          // fetchUsersStreams(currentPage)
                          setEditing(false);
                        }}
                      >
                        <KeyboardBackspaceIcon sx={{ color: "white" }} />
                        Back
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Box>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  MY STREAMS
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    mt: { sm: "0px", xs: "10px" },
                    mr: { sm: "25px", xs: "opx" },
                    width: { xs: "100%", sm: "30%" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    background: "#fff",
                    border: "1px solid #00000029",
                  }}
                >
                  <InputBase
                    placeholder="Search Stream ..."
                    size="small"
                    sx={{
                      borderTopRightRadius: "0",
                      borderBottomRightRadius: "0",
                      pl: "10px",
                      width: "100%",
                    }}
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />

                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{
                        color: "#fff",
                        background: "black",
                        ":hover": { background: "black" },
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                        height: "auto",
                      }}
                      onClick={async () => {
                        if (isSearched) {
                          removeSearch();
                        } else {
                          handleSearch();
                        }
                      }}
                    >
                      {isSearched ? <CloseIcon /> : <SearchIcon />}

                      {/* } */}
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: { sm: "0px", xs: "10px" },
                    width: { xs: "100%", sm: "130px" },
                  }}
                >
                  <FormControl
                    sx={{ minWidth: 130, width: "100%", background: "#fff" }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small">Categories</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      placeholder="All Categories"
                      id="demo-select-small"
                      value={sort}
                      label="Categories"
                      onChange={(event) => setSort(event.target.value)}
                      size="small"
                    >
                      {categoryNames.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Grid container spacing={2} rowSpacing={1} mb={2}>
                {streams.length > 0 ? (
                  streams.map((el, i) => (
                    <Grid item xs={12} sm={6} xmd={6} lg={3} my={4}>
                      <Card
                        sx={{
                          position: "relative",
                          maxWidth: 350,
                          backgroundColor: "#F2F2F2",
                          boxShadow: "0px 3px 6px #00000029",
                          cursor: "pointer",
                        }}

                        // onClick={() =>
                        //   (window.location.href = `/live/${el?.room}/${el?.business_id?._id}`)
                        // }
                      >
                        <CardMedia
                          sx={{
                            justifyContent: "center",
                          }}
                          component="img"
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "contain", // or 'contain'
                          }}
                          src={
                            el?.thumbnail
                              ? el?.thumbnail
                              : defaultThumbnails[getRandomIndex()]
                          }
                          alt="thumbnail"
                          onClick={() => handleCardClick(el?.room)}
                        />

                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "start",
                            }}
                          >
                            <Tooltip title={el?.title} placement="top">
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {el?.title && el.title.length > 18
                                  ? el.title.slice(0, 18) + "..."
                                  : el?.title}
                              </Typography>
                            </Tooltip>
                            <Tooltip title={el?.description} placement="bottom">
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "13px",
                                  mb: 1,
                                }}
                              >
                                {el?.description && el.description.length > 30
                                  ? el.description.slice(0, 30) + "..."
                                  : el?.description}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              mx: { xs: "3px" },
                              pb: 1,
                              width: "100%",
                              height: "auto",
                              overflowX: "auto",
                              scrollbarWidth: "thin",
                              "&::-webkit-scrollbar": {
                                height: "2px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#808080",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: (theme) =>
                                  theme.palette.background.paper,
                              },
                            }}
                          >
                            <Tooltip title={el?.category} placement="bottom">
                              <Button
                                size="small"
                                variant="contained"
                                sx={{
                                  backgroundColor: "black",
                                  color: "white",
                                  textTransform: "none",
                                  fontSize: { xs: "9px", sm: "11px" },
                                  whiteSpace: "nowrap",
                                  marginRight: 1,
                                  padding: "5px 10px",
                                  minWidth: "auto",
                                  flexShrink: 0,
                                  "&:hover": {
                                    backgroundColor: "black",
                                  },
                                }}
                              >
                                {el?.category.length > maxLength
                                  ? `${el?.category.substring(0, maxLength)}...`
                                  : el?.category || "Others"}
                              </Button>
                            </Tooltip>
                          </Box>
                        </CardContent>
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              color: "#272727",
                            }}
                          >
                            {moment(el?.createdAt).format("YYYY-MM-DD")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              color: "#272727",
                            }}
                          >
                            {moment(streamDetail?.createdAt).format(
                              "hh:mm:ss A"
                            )}
                          </Typography>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <>
                    {!isLoading && (
                      <Box
                        sx={{
                          display: "flex",
                          ml: 2,
                          mt: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: "18px",
                            fontWeight: 400,
                          }}
                        >
                          No Stream Found
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Grid>
              {/* skaletan */}
              {isLoading && (
                <Grid container spacing={2}>
                  {Array.from(new Array(4))?.map((items, idx) => (
                    <Grid item xs={12} sm={6} xmd={6} lg={3} my={4} key={idx}>
                      <Box
                        sx={{
                          background: "#EFF9FD",
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          flexDirection: "column",
                          "&:hover": {
                            boxShadow: "2px 2px 4px #888888",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {items ? (
                            <img
                              src={items?.images[0]?.image}
                              alt="item"
                              style={{
                                width: "210px",
                                height: "250px",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <Skeleton
                              variant="rectangular"
                              sx={{ width: "100%", height: "280px" }}
                            />
                          )}
                        </Box>

                        {items ? (
                          <>
                            <Typography
                              sx={{
                                textAlign: "left",
                                fontSize: "12px",
                                p: "5px",
                              }}
                            >
                              {items.store.stream_name}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                p: "5px",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: "16px", fontWeight: "bold" }}
                              >
                                {items.store.stream_name}
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <Box sx={{ py: 0.5, px: "10px" }}>
                            <Skeleton />
                            <Skeleton width="60%" />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { sm: "end", xs: "center" },
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </Box>
            </>
          )}

          <Button
            style={{
              position: "absolute",
              color: isHovered ? "white" : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: "fit-content",
              borderRadius: "50%",
              transition: "background-color 0.3s ease-in-out",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
            }}
            onClick={() => {
              handleStreamModal();
              setSelectedStream(null);
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>
        </DialogContent>
      </Dialog>
      {/* delete warning popup */}
      <Dialog
        open={deleteStreamModal}
        onClose={handleDeleteStreamModal}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: {
                xs: "290px",
                xsPlus: "330px",
                sm: "500px",
                md: "800px",
                lg: "500px",
                br500: "430px",
              }, // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#fff",
            position: "relative",
            padding: "25px",
            // width: "800px",
            overflow: "visible",
            p: { xs: "30px", md: "30px", lg: "30px" },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "black",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Do you want to delete this stream?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                py: 2,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  transform: "none",
                  borderRadius: "25px",
                  fontSize: "12px",
                  mr: "4px",
                  ":hover": {
                    background: "red",
                  },
                }}
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: "transparent",
                  borderRadius: "25px",

                  color: "#F9B327",
                  transform: "none",
                  fontSize: "12px",
                  ":hover": {
                    background: "transparent",
                  },
                }}
                onClick={handleDeleteStreamModal}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MyStreamsModal;
