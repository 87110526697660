import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
const InstaRTMPConnect = ({
  open,
  close,
  instaForm,
  setInstaForm,
  handleInstagramConnect,
  handleInstagramConnectByFB,
  instaLoading,
  instaPages,
  handleConnectToFBPage,
  handleRefreshInstagramPages
}) => {
  // !---------------------- STATES ----------------------
  const [isHovered, setIsHovered] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  // !---------------------- FUNCTIONS----------------------
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  // !---------------------- useEffect ----------------------
  useEffect(() => {
    if (selectedTab === 1) {
      handleInstagramConnectByFB();
    }
  }, [selectedTab]);
  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => {
          close();
          setSelectedTab(0);
        }}
        PaperProps={{
          style: { borderRadius: "5px" },
        }}
        scroll={"body"}
      >
        <DialogContent
          sx={{
            background: "#fff",
            position: "relative",
            padding: { sm: "25px", xs: "10px" },
            overflow: "visible",
            py: "30px",
            px: { xs: "20px", sm: "50px" },
            width: { xs: "250px", sm: "400px" },
          }}
        >
          <Button
            style={{
              position: "absolute",
              color: isHovered ? "white" : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: "fit-content",
              borderRadius: "50%",
              transition: "background-color 0.3s ease-in-out",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
            }}
            onClick={close}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#272727",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "22px" },
                fontWeight: "500",
              }}
            >
              Connect to Instagram
            </Typography>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                minHeight: "unset",
                mb: "12px",
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.primary.main,
                  height: "1px",
                },
              }}
            >
              <Tab
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    Live
                  </Typography>
                }
              />
              <Tab
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    Post
                  </Typography>
                }
              />
            </Tabs>
            {selectedTab === 0 ? (
              <>
                <Typography
                  sx={{
                    fontSize: { xs: "13px", sm: "14px" },
                    fontWeight: "400",
                  }}
                >
                  For Instagram, Live commerce features like auto comments do
                  not work. You can only go live.
                </Typography>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "13px", sm: "14px" },
                      fontWeight: "400",
                      color: "#3F3F3F",
                    }}
                  >
                    Username
                  </Typography>

                  <input
                    label="Username"
                    placeholder="Add an instagram username..."
                    id="outlined-size-small"
                    size="small"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: { xs: "11px", sm: "14px" },
                      outline: "none",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      paddingTop: "8.5px",
                      paddingBottom: "8.5px",
                      "::placeholder": {
                        fontSize: "11px",
                      },
                    }}
                    value={instaForm.username}
                    onChange={(e) =>
                      setInstaForm({ ...instaForm, username: e.target.value })
                    }
                  />

                  <Typography
                    sx={{
                      fontSize: { xs: "13px", sm: "14px" },
                      fontWeight: "400",
                      color: "#3F3F3F",
                    }}
                  >
                    Stream Rtmp
                  </Typography>

                  <input
                    label="Stream Rtmp"
                    placeholder="Add a stream rtmp..."
                    id="outlined-size-small"
                    size="small"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      outline: "none",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      paddingTop: "8.5px",
                      paddingBottom: "8.5px",
                      "::placeholder": {
                        fontSize: "11px",
                      },
                    }}
                    value={instaForm.rtmpUrl}
                    onChange={(e) =>
                      setInstaForm({ ...instaForm, rtmpUrl: e.target.value })
                    }
                  />

                  <Typography
                    sx={{
                      fontSize: { xs: "13px", sm: "14px" },
                      fontWeight: "400",
                      color: "#3F3F3F",
                    }}
                  >
                    Stream Key
                  </Typography>

                  <input
                    label="Stream Key"
                    placeholder="Add a stream key..."
                    id="outlined-size-small"
                    size="small"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      outline: "none",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      paddingTop: "8.5px",
                      paddingBottom: "8.5px",
                      "::placeholder": {
                        fontSize: "11px",
                      },
                    }}
                    value={instaForm.streamKey}
                    onChange={(e) =>
                      setInstaForm({ ...instaForm, streamKey: e.target.value })
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "12px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      fontWeight: "500",
                      backgroundColor: "#3F3F3F",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "transparent",
                        color: "#000000",
                      },
                    }}
                    onClick={handleInstagramConnect}
                  >
                    Connect
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: 2, sm: "6px" },
                    color: "#272727",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: { xs: "25px", sm: 0 },
                      mb: { xs: "10px", sm: "20px" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "13px", sm: "16px" },
                        fontWeight: "400",
                      }}
                    >
                      Choose a instagram page
                    </Typography>

                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        background:
                          "linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)",
                        color: "#fff",
                        textTransform: "none",
                        py: "5px",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "none",
                        cursor: "pointer",
                        ":hover": {
                          background:
                            "linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)",
                        },
                      }}
                      onClick={handleRefreshInstagramPages}
                    >
                      <CachedIcon
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          mr: { xs: 0, sm: 1 },
                        }}
                      />
                      <Typography
                        sx={{
                          display: { xs: "none", sm: "block" },
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        Refresh
                      </Typography>
                    </Button>
                  </Box>
                  <>
                    {!instaLoading ? (
                      instaPages?.length > 0 ? (
                        instaPages.map((page, index) => (
                          <Box
                            key={page.id}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "6px",
                              mb: "16px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Avatar
                                src={page.page_profile_picture || ""}
                                alt={page?.insta_username}
                                sx={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  fontSize: "18px",
                                  backgroundColor: "#f0f0f0",
                                }}
                              >
                                {!page.page_profile_picture &&
                                  page?.insta_username?.[0]}
                              </Avatar>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {page?.insta_username}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (page.is_connected === false) {
                                    handleConnectToFBPage({
                                      page_id: page.insta_id,
                                      type: "instagram_posts_pages",
                                      status: true,
                                    });
                                  }
                                }}
                              >
                                {page.is_connected === true
                                  ? "Connected"
                                  : "Connect"}
                              </Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Typography>No pages available</Typography>
                      )
                    ) : (
                      [1, 2, 3, 4, 5, 6, 7].map((item) => (
                        <Box
                          key={item}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "1px",
                            mb: "16px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1px",
                              }}
                            >
                              <Skeleton variant="text" width={100} />
                              <Skeleton variant="text" width={80} />
                            </Box>
                          </Box>
                          <Skeleton variant="text" width={60} />
                        </Box>
                      ))
                    )}
                  </>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default InstaRTMPConnect;
