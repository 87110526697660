import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Avatar,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { categoryList } from "../../../utils/ProductCategories";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UchloU from "../../../assets/Img/uchlo U white.png";
import CreateStreamAlerts from "./CreateStreamAlerts";
import { checkFBEligibility } from "../../../utils/FacebookRTMPLinkGeneration";
import {
  addStreamThumbnail,
  checkYTEligibility,
  createInAppStreamRoom,
  updateStreamMetadata,
} from "../../../api/StreamApis";
import { toast } from "../../Toast";
import { convertToWebP } from "../../../utils/image_copmression";
import { StreamingContext } from "../../../context/StreamingContext";

const GoLive = ({
  liveStreamDialogBox,
  setLiveStreamDialogBox,
  connectedAccount = [],
  isEdit,
  streamMetaData,
  setStreamMetaData,
  roomName = "",
  IsLiveStream = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [formValues, setFormValues] = useState({
    streamTitle: "",
    description: "",
    streamCategory: "",
    thumbnail: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isHoveringThumbnail, setIsHoveringThumbnail] = useState(false);
  const [eligibilityError, setEligibilityError] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [textLength, setTextLength] = useState({
    streamTitle: 0,
    description: 0,
  });
  const [isFileChanged, setIsFileChanged] = useState(false);
  const { setStreamData, setFacebookObj, instaObj, setInstaObj } =
    useContext(StreamingContext);

  const naviagte = useNavigate();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "facebook":
        return (
          <FacebookIcon
            sx={{ color: "#4267B2", position: "absolute", right: 0, bottom: 0 }}
          />
        );
      case "youtube":
        return (
          <YouTubeIcon
            sx={{ color: "#FF0000", position: "absolute", right: 0, bottom: 0 }}
          />
        );
      case "instagram":
        return (
          <InstagramIcon
            sx={{ color: "#C13584", position: "absolute", right: 0, bottom: 0 }}
          />
        );
      default:
        return null;
    }
  };

  const handleSelectDestination = (destination) => {
    const newDestination = selectedDestination.map((row) => {
      if (row.type === destination.type) {
        if (row.id === destination.id) {
          return { ...row, opacity: row.opacity === 1 ? 0.5 : 1 };
        }
        return { ...row, opacity: 0.5 };
      }
      return row;
    });

    setSelectedDestination(newDestination);
  };

  const validationSchema = Yup.object().shape({
    streamTitle: Yup.string()
      .required("Stream title is required")
      .max(100, "Stream title must be at most 100 characters"),
    description: Yup.string()
      .required("Stream Description is required")
      .max(5000, "Description must be at most 1000 characters"),
    streamCategory: Yup.string()
      .required("Please choose a category")
      .oneOf(Object.keys(categoryList), "Invalid category selected"),
    thumbnail: Yup.mixed()
      .nullable()
      .test(
        "fileType",
        "Unsupported file format, only JPEG and PNG are allowed",
        (value) =>
          !value || (value && ["image/jpeg", "image/png"].includes(value.type))
      )
      .test(
        "fileSize",
        "File is too large (max 5MB)",
        (value) => !value || (value && value.size <= 5 * 1024 * 1024)
      ),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "streamTitle") {
      setTextLength((prev) => ({
        ...prev,
        streamTitle: value.length,
      }));
    }

    if (name === "description") {
      setTextLength((prev) => ({
        ...prev,
        description: value.length,
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormValues((prev) => ({ ...prev, thumbnail: file }));
    validateField("thumbnail", file);
    setIsFileChanged(true);
  };

  const validateField = async (name, value) => {
    try {
      await Yup.reach(validationSchema, name).validate(value);
      setFormErrors((prev) => ({ ...prev, [name]: undefined }));
    } catch (error) {
      setFormErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setEligibilityError([]);
      await validationSchema.validate(formValues, { abortEarly: false });
      const eligible = await checkDestinationEligibility(selectedDestination);
      let thumbnailUrl = null;
      if (eligible) {
        if (formValues.thumbnail && isFileChanged) {
          thumbnailUrl = await uploadThumbnail([formValues.thumbnail]);
          if (!thumbnailUrl) {
            return toast.error("An error occurred while uploading thumbnail");
          }
        }

        selectedDestination.forEach((destination) => {
          if (destination.opacity === 1) {
            if (destination.type === "facebook") {
              setFacebookObj((prevFacebookObj) => ({
                ...prevFacebookObj,
                pageId: destination.id,
                pageAccessToken: destination.access_token,
              }));

              setStreamData((prevData) => ({
                ...prevData,
                facebook: destination,
              }));

              localStorage.setItem(
                "facebookObj",
                JSON.stringify({
                  pageId: destination.id,
                  pageAccessToken: destination.access_token,
                })
              );

              localStorage.setItem(
                "facebookResponse",
                JSON.stringify(destination)
              );
            }

            if (destination.type === "youtube") {
              localStorage.setItem(
                "YoutubeResponse",
                JSON.stringify(destination.access_token)
              );

              setStreamData((prevData) => ({
                ...prevData,
                youtube: destination.access_token,
              }));
            }

            if (destination.type === "instagram") {
              let rtmpUrl = `${destination.rtmp_url}${destination.stream_key}`;
              localStorage.setItem(
                "instagramStreamingResponse",
                JSON.stringify({
                  rtmpUrl: rtmpUrl,
                })
              );

              setInstaObj({
                ...instaObj,
                rtmpUrl: rtmpUrl,
              });
            }
          }
        });

        const data = {
          title: formValues.streamTitle,
          description: formValues.description,
          category: formValues.streamCategory,
          thumbnail: thumbnailUrl,
        };

        const resp = await createInAppStreamRoom(data);

        if (resp.status === 200) {
          setLiveStreamDialogBox(false);
          window.location.href = `/streaming/${resp.data.room}`;
          return;
        }

        toast.error("An error occurred while creating stream");
      }
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      setFormErrors(errors);
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkDestinationEligibility = async (destinations) => {
    try {
      const eligibilityErrors = [];

      for (let destination of destinations) {
        if (destination.type === "facebook" && destination.opacity === 1) {
          const resp = await checkFBEligibility(
            destination.id,
            destination.access_token
          );

          if (!resp.is_published || resp.followers_count < 100) {
            eligibilityErrors.push({
              name: destination.name,
              type: destination.type,
              error: !resp.is_published
                ? "is not 60 days old to go live"
                : "must have at least 100 followers to go live",
            });
          }
        }

        if (destination.type === "youtube" && destination.opacity === 1) {
          const resp = await checkYTEligibility({
            access_token: destination.access_token,
          });

          if (resp?.response?.status === 400) {
            eligibilityErrors.push({
              name: destination.name,
              type: destination.type,
              error: resp.response.data.message,
            });
          }
        }
      }

      if (eligibilityErrors.length > 0) {
        setEligibilityError(eligibilityErrors);
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  };

  const uploadThumbnail = async (thumbnail) => {
    try {
      if (!thumbnail || thumbnail.length === 0) {
        return toast.error("Thumbnail is required");
      }

      const webpFiles = await convertToWebP(thumbnail);

      if (!webpFiles || webpFiles.length === 0) {
        return toast.error("An error occurred while converting thumbnail");
      }

      let formData = new FormData();

      webpFiles.forEach((file) => {
        formData.append("files", file);
      });

      const resp = await addStreamThumbnail(formData);

      if (resp.status === 200) {
        return resp?.data?.imageUrls[0];
      }

      toast.error("An error occurred while uploading thumbnail");
    } catch (error) {
      toast.error("An error occurred while uploading thumbnail");
    }
  };

  const handleEdit = async () => {
    try {
      setIsSubmitting(true);
      let validateFormValues = { ...formValues, thumbnail: isFileChanged ? formValues.thumbnail : null };
      await validationSchema.validate(validateFormValues, { abortEarly: false });
      let thumbnailUrl = null;
      if (formValues.thumbnail && isFileChanged) {
        thumbnailUrl = await uploadThumbnail([formValues.thumbnail]);
        if (!thumbnailUrl) {
          throw new Error("An error occurred while uploading thumbnail");
        }
      }
  
      const data = {
        title: formValues.streamTitle,
        description: formValues.description,
        category: formValues.streamCategory,
        thumbnail: isFileChanged ? thumbnailUrl : formValues.thumbnail,
        room_name: roomName
      };
  
      const resp = await updateStreamMetadata(data);
  
      if (resp.status === 201) {
        setStreamMetaData({
          title: resp?.data?.data?.title,
          description: resp?.data?.data?.description,
          category: resp?.data?.data?.category,
          thumbnail: resp?.data?.data?.thumbnail,
        });
        setLiveStreamDialogBox(false);
        return;
      }
  
      throw new Error("An error occurred while updating stream");
    } catch (error) {
      toast.error(error.message); 
    }finally{
      setIsSubmitting(false);
      setIsFileChanged(false);
    }
  };

  // render all useEffects here
  useEffect(() => {
    if (connectedAccount && connectedAccount.length > 0) {
      setSelectedDestination(connectedAccount);
    }
  }, [connectedAccount]);

  useEffect(() => {
    if(streamMetaData){
      setFormValues({
        streamTitle: streamMetaData?.title,
        description: streamMetaData?.description,
        streamCategory: streamMetaData?.category,
        thumbnail: streamMetaData?.thumbnail,
      })
    }
  }, [streamMetaData])

  return (
    <Box>
      <Dialog
        open={liveStreamDialogBox}
        onClose={() => setLiveStreamDialogBox(false)}
        PaperProps={{
          style: { borderRadius: "5px" },
        }}
        scroll={"body"}
      >
        <DialogContent
          sx={{
            background: "#fff",
            position: "relative",
            padding: { sm: "25px", xs: "10px" },
            overflow: "visible",
            py: "30px",
            px: { xs: "20px", sm: "50px" },
            width: { xs: "250px", sm: "400px" },
          }}
        >
          <Button
            style={{
              position: "absolute",
              color: isHovered ? "white" : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: "fit-content",
              borderRadius: "50%",
              transition: "background-color 0.3s ease-in-out",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
            }}
            onClick={() => setLiveStreamDialogBox(false)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: 2, sm: "6px" },
              color: "#272727",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "22px" },
                fontWeight: "500",
              }}
            >
              {isEdit ? "Edit" : "Create"} Live Stream
            </Typography>
            <CreateStreamAlerts eligibilityError={eligibilityError} />
            {!isEdit && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "18px" },
                    fontWeight: "400",
                    color: "#3F3F3F",
                  }}
                >
                  Select Destination
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                      opacity: 1,
                      backgroundColor: "#FFB342",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={UchloU}
                      alt="profile"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  {selectedDestination.map((row, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        opacity: row?.opacity ? row.opacity : 0.5,
                      }}
                      onClick={() => handleSelectDestination(row)}
                    >
                      {row.type === "instagram" ? (
                        <Avatar>{row.name.charAt(0).toUpperCase()}</Avatar>
                      ) : (
                        <img
                          src={row.profile_picture}
                          alt="profile"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                      )}

                      {getPlatformIcon(row.type)}
                    </Box>
                  ))}

                  <Box
                    sx={{
                      display: "flex",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: "transparent",
                      alignItems: "center",
                      border: "1px solid #00000029",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => naviagte("/broadcast/destinations")}
                  >
                    <AddIcon
                      sx={{
                        fontSize: "30px",
                        color: "#3F3F3F",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                sx={{
                  fontSize: { xs: "13px", sm: "14px" },
                  fontWeight: "400",
                  color: "#3F3F3F",
                }}
              >
                Stream Title
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <input
                  label="Stream title"
                  placeholder="Add a suitable title for this stream..."
                  id="outlined-size-small"
                  size="small"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    outline: "none",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    paddingLeft: "14px",
                    paddingRight: "14px",
                    paddingTop: "8.5px",
                    paddingBottom: "8.5px",
                  }}
                  name="streamTitle"
                  value={formValues.streamTitle}
                  onChange={handleInputChange}
                  onBlur={() =>
                    validateField("streamTitle", formValues.streamTitle)
                  }
                />
                {formErrors.streamTitle && (
                  <FormHelperText error>
                    {formErrors.streamTitle}
                  </FormHelperText>
                )}
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: { xs: "13px", sm: "14px" },
                    fontWeight: "400",
                    color: textLength.streamTitle > 100 ? "red" : "#3F3F3F",
                  }}
                >
                  {textLength.streamTitle}/100
                </Typography>
              </Box>
              {/* description */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: "13px", sm: "14px" },
                    fontWeight: "400",
                    color: textLength.description > 5000 ? "red" : "#3F3F3F",
                  }}
                >
                  Description
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <textarea
                    label="Stream Description"
                    placeholder="Say something about your stream..."
                    id="outlined-size-small"
                    size="small"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      outline: "none",
                      resize: "vertical",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      paddingTop: "8.5px",
                      paddingBottom: "8.5px",
                    }}
                    name="description"
                    value={formValues.description}
                    onChange={handleInputChange}
                    onBlur={() =>
                      validateField("description", formValues.description)
                    }
                  />
                  {formErrors.description && (
                    <FormHelperText error>
                      {formErrors.description}
                    </FormHelperText>
                  )}
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: { xs: "13px", sm: "14px" },
                      fontWeight: "400",
                      color: "#3F3F3F",
                    }}
                  >
                    {textLength.description}/5000
                  </Typography>
                </Box>
              </Box>
              {/* category select */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: "13px", sm: "14px" },
                    fontWeight: "400",
                    color: "#3F3F3F",
                  }}
                >
                  Category
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <FormControl
                    sx={{ minWidth: 200 }}
                    size="small"
                    error={!!formErrors.streamCategory}
                  >
                    <InputLabel id="category-select-label">
                      Choose Category
                    </InputLabel>
                    <Select
                      labelId="category-select-label"
                      id="category-select"
                      name="streamCategory"
                      value={formValues.streamCategory}
                      onChange={handleInputChange}
                      onBlur={() =>
                        validateField(
                          "streamCategory",
                          formValues.streamCategory
                        )
                      }
                      label="Choose Category" 
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ccc",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#aaa",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#ccc",
                        },
                      }}
                    >
                      {Object.keys(categoryList).map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.streamCategory && (
                      <FormHelperText error>
                        {formErrors.streamCategory}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>

              {/* thumbnail */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: { xs: "13px", sm: "14px" },
                    fontWeight: "400",
                    color: "#3F3F3F",
                  }}
                >
                  Thumbnail
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "120px",
                    height: "80px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    overflow: "hidden",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    document.getElementById("thumbnail-upload").click()
                  }
                  onMouseEnter={() => setIsHoveringThumbnail(true)}
                  onMouseLeave={() => setIsHoveringThumbnail(false)}
                >
                  {formValues.thumbnail ? (
                    <>
                      <img
                        src={(isEdit && !isFileChanged) ? formValues.thumbnail : URL.createObjectURL(formValues.thumbnail)}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      {isHoveringThumbnail && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                          }}
                        >
                          <ChangeCircleIcon
                            sx={{
                              fontSize: "30px",
                              color: "#FFFFFF",
                            }}
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <AddIcon
                      sx={{
                        fontSize: "30px",
                        color: "#3F3F3F",
                        margin: "auto",
                      }}
                    />
                  )}
                  <input
                    id="thumbnail-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Box>
                {formErrors.thumbnail && (
                  <Typography color="error" fontSize="12px">
                    {formErrors.thumbnail}
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      fontWeight: "500",
                      backgroundColor: "#3F3F3F",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "transparent",
                        color: "#000000",
                      },
                      "&.Mui-disabled": {
                        color: "#fff",
                        backgroundColor: "#3F3F3F",
                      },
                    }}
                    onClick={isEdit ? handleEdit : handleSubmit}
                    disabled={isSubmitting || IsLiveStream}
                  >
                    {isSubmitting ? (
                      <>
                        <CircularProgress
                          size={15}
                          sx={{
                            color: "#fff",
                            marginRight: "5px",
                          }}
                        />
                        One moment...
                      </>
                    ) : (
                      `${isEdit ? "Update Stream" : "Create Stream"}`
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default GoLive;
