import { TableRow, TableCell, Box, Typography } from "@mui/material";

const TableNoData = () => {
  return (
    <TableRow>
      <TableCell colSpan={9} style={{ height: 340, padding: 0 }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1">No results found.</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TableNoData;
