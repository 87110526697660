import React, { createContext, useCallback, useEffect, useState } from "react";
import { useSocket } from "./SocketContext";
import DailyIframe from "@daily-co/daily-js";
import { useParams } from "react-router-dom";
import { toast } from "../components/Toast";
import { set } from "lodash";

export const GuestContext = createContext();

const graphicObj = {
  bannerLowerThird: null,
  lowerThird: null,
  intro: null,
  ticker: null,
  logo: [],
  products: null,
  ads: null,
  isCommentHide: false,
};

export const GuestProvider = ({ children }) => {
  // ! ---------------- STATES ----------------
  const [callFrame, setCallFrame] = useState(null);
  const [isJoined, setIsJoined] = useState(false);
  const [guests, setGuests] = useState({});
  const [screenParticipants, setScreenParticipants] = useState([]);
  const [graphicsData, setgraphicsData] = useState(graphicObj);
  const [guestOwnerSessionId, setGuestOwnerSessionId] = useState(null);
  const [guestVideoSessionId, setGuestVideoSessionId] = useState(null);
  const [guestAudioSessionId, setGuestAudioSessionId] = useState(null);
  const [open, setOpen] = useState(false);
  const [orientation, setOrientation] = useState("landscape");
  const [videoCamera, setVideoCamera] = useState(true);
  const [audioMicrophone, setAudioMicrophone] = useState(true);

  // ! ---------------- REACT ROUTER DOM ----------------
  const { roomName } = useParams();

  // ! ---------------- CONTEXT ----------------
  const { socket, joinRoom, leftGuest, roomTitle } = useSocket();

  // ! --------------- LOCAL VARIABLES ---------------
  const userData = JSON.parse(localStorage.getItem("guestInfo"));

  // ! ---------------- CALLBACKS ----------------
  const trackStarted = useCallback((e) => {
    // let vidsContainer = document.getElementById("video-track");
    // if (!(e.track && e.track.kind === "video")) {
    //   return;
    // }

    // let vid = findVideoForParticipant(e.participant.session_id);
    // if (!vid) {
    //   vid = document.createElement("video");
    //   vid.session_id = e.participant.session_id;

    //   vid.style.width = "100%";
    //   vid.style.height = "100%";
    //   vid.style.position = "absolute";
    //   vid.style.top = "0";
    //   vid.style.left = "50%";
    //   vid.style.transform = "translateX(-50%)";
    //   vid.style.objectFit = "cover";

    //   vid.autoplay = true;
    //   vid.muted = true;
    //   vid.playsInline = true;
    //   vidsContainer.appendChild(vid);

    // joinRoom(roomName, {session_id: e.participant});
    // }

    // if (e.participant.local === false) {
    //   return;
    // }

    // vid.srcObject = new MediaStream([e.track]);
    let track = e?.participant?.tracks?.video?.track;
    let session_id = e?.participant?.session_id;
    let userName = e?.participant?.user_name;
    let audioTrack = e?.participant?.tracks?.audio?.track;
    setGuestOwnerSessionId(session_id);
    setScreenParticipants((prevParticipants) => ({
      ...prevParticipants,
      [session_id]: {
        track: track,
        userName: userName,
        audioTrack: audioTrack,
        isLocalUser: true
      },
    }));
    joinRoom(roomName, session_id);
  }, []);

  const initializeCallFrame = useCallback(async () => {
    if (!callFrame && userData?.room?.room_url) {
      let call_Frame = DailyIframe.createCallObject({
        dailyConfig: {
          experimentalChromeVideoMuteLightOff: true,
          showCameraNameInsteadOfParticipantName: true,
        },
      });

      console.log("callframe", call_Frame);

      setCallFrame(call_Frame);

      call_Frame
        .on("track-started", trackStarted)
        .on("track-stopped", (e) => console.log("track-stopped", e))
        .on("left-meeting", (e) => {
          console.log("left-meeting", e);
          setIsJoined(false);
        })
        .on("participant-updated", (e) => updateParticipant(e.participant))
        .on("participant-joined", (e) => updateParticipant(e.participant))
        .on("participant-left", (e) => removeParticipant(e.participant));

      await call_Frame.load({
        url: userData.room.room_url,
        token: userData.token,
      });
    }
  }, [callFrame, userData, trackStarted]);

  const joinCall = useCallback(() => {
    if (callFrame) {
      callFrame.join().then(() => setIsJoined(true));
    }
  }, [callFrame]);

  const leaveCall = useCallback(() => {
    if (callFrame) {
      callFrame.leave();
      setIsJoined(false);
      setOpen(true);

      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    }
  }, [callFrame]);

  const updateParticipant = useCallback((participant) => {
    setGuests((prevGuests) => ({
      ...prevGuests,
      [participant.session_id]: participant,
    }));
  }, []);

  const removeParticipant = useCallback((participant) => {
    console.log("participant delete", participant);
    setGuests((prevGuests) => {
      const updatedGuest = { ...prevGuests };
      delete updatedGuest[participant.session_id];
      return updatedGuest;
    });
  }, []);

  // ! ---------------- FUNCTIONS ----------------
  const findVideoForParticipant = (session_id) => {
    for (const vid of document.getElementsByTagName("video")) {
      if (vid.session_id === session_id) {
        return vid;
      }
    }
  };

  const GuestONScreen = (participants) => {
    const matchingGuests = participants
      .map((session_id) => guests[session_id])
      .filter(Boolean);
    setScreenParticipants(
      matchingGuests.reduce((acc, participant) => {
        acc[participant.session_id] = {
          track: participant.tracks.video.track,
          userName: participant.user_name,
          audioTrack: participant.tracks.audio.track,
          isLocalUser: guestOwnerSessionId === participant.session_id ? true : false
        };
        return acc;
      }, {})
    );
  };

  const toogleLocalVideo = useCallback(async () => {
    if (callFrame) {
      const newVideoState = !videoCamera;
      try {
        await callFrame.setLocalVideo(newVideoState);
        setVideoCamera(newVideoState);

        setTimeout(() => {
          setScreenParticipants((prevParticipants) => {
            const localParticipant = prevParticipants[guestOwnerSessionId];      
            if (localParticipant) {
              const updatedLocalParticipant = {
                ...localParticipant,
                track: newVideoState ? callFrame.participants().local.tracks.video.track : null,
              };
              return {
                ...prevParticipants,
                [guestOwnerSessionId]: updatedLocalParticipant,
              };
            }
            return prevParticipants;
          });
        }, 500);

      } catch (error) {
        console.error("Error toggling video:", error);
        toast.error("Failed to toggle video. Please try again.");
      }
    }
  }, [callFrame, videoCamera, guestOwnerSessionId]);

  const toggleLocalAudio = useCallback(async () => {
    if (callFrame) {
      const newAudioState = !audioMicrophone;
      try {
        await callFrame.setLocalAudio(newAudioState);
        setAudioMicrophone(newAudioState);

        setTimeout(() => {
          setScreenParticipants((prevParticipants) => {
            const localParticipant = prevParticipants[guestOwnerSessionId];      
            if (localParticipant) {
              const updatedLocalParticipant = {
                ...localParticipant,
                audioTrack: newAudioState ? callFrame.participants().local.tracks.audio.track : null,
              };
              return {
                ...prevParticipants,
                [guestOwnerSessionId]: updatedLocalParticipant,
              };
            }
            return prevParticipants;
          });
        }, 500);

      } catch (error) {
        console.error("Error toggling audio:", error);
        toast.error("Failed to toggle audio. Please try again.");
      }
    }
  }, [callFrame, audioMicrophone, guestOwnerSessionId]);

  // ! ---------------- USE EFFECT ----------------
  useEffect(() => {
    initializeCallFrame();

    return () => {
      if (callFrame) {
        callFrame
          .off("track-started", trackStarted)
          .off("track-stopped", (e) => console.log("track-stopped", e))
          .off("left-meeting", (e) => {
            console.log("left-meeting", e);
            setIsJoined(false);
          })
          .off("participant-updated", (e) => updateParticipant(e.participant))
          .off("participant-joined", (e) => updateParticipant(e.participant))
          .off("participant-left", (e) => removeParticipant(e.participant));
      }
    };
  }, [initializeCallFrame, callFrame, trackStarted]);

  useEffect(() => {
    if (callFrame && !isJoined) {
      joinCall();
    }
  }, [callFrame, isJoined, joinCall]);

  useEffect(() => {
    if (socket) {
      socket.on("guestOnScreen", (participants) => {
        GuestONScreen(participants);
      });

      socket.on("graphicsEnabled", (data) => {
        setgraphicsData({
          ...graphicsData,
          logo: data?.logo ? data?.logo : [],
          intro: data?.intro ? data?.intro : null,
          bannerLowerThird: data?.bannerLowerThird
            ? data?.bannerLowerThird
            : null,
          ticker: data?.ticker ? data?.ticker : null,
          products: data?.products ? data?.products : null,
          lowerThird: data?.lowerThird ? data?.lowerThird : null,
          ads: data?.ads ? data?.ads : null,
        });
      });

      socket.on("participantKicked", (session_id) => {
        if (session_id === guestOwnerSessionId) {
          leaveCall();
        }
      });

      socket.on("videoToggled", (session_id, videoEnabled) => {
        setVideoCamera(videoEnabled);
        setGuestVideoSessionId(session_id);
      });

      socket.on("audioToggled", (session_id, audioEnable) => {
        setAudioMicrophone(audioEnable);
        setGuestAudioSessionId(session_id);
      });

      socket.on("hostLeftRoom", () => {
        leaveCall();
      });

      socket.on("orientationChanged", (orientation) => {
        setOrientation(orientation);
      });

      return () => {
        socket.off("guestOnScreen");
        socket.off("graphicsEnabled");
        socket.off("participantKicked");
        socket.off("videoToggled");
        socket.off("audioToggled");
        socket.off("hostLeftRoom");
        socket.off("orientationChanged");
      };
    }
  }, [socket, guests, guestOwnerSessionId, leaveCall]);

  useEffect(() => {
    if (guestVideoSessionId !== null) {
      setScreenParticipants((prevParticipants) => {
        if (prevParticipants.hasOwnProperty(guestVideoSessionId)) {
          return {
            ...prevParticipants,
            [guestVideoSessionId]: {
              ...prevParticipants[guestVideoSessionId],
              track: guests[guestVideoSessionId].video ? guests[guestVideoSessionId].tracks.video.track : null,
            },
          };
        }
        return prevParticipants;
      });
    }
  
    if (guestAudioSessionId !== null) {
      setScreenParticipants((prevParticipants) => {
        if (prevParticipants.hasOwnProperty(guestAudioSessionId)) {
          return {
            ...prevParticipants,
            [guestAudioSessionId]: {
              ...prevParticipants[guestAudioSessionId],
              audioTrack: guests[guestAudioSessionId].audio ? guests[guestAudioSessionId].tracks.audio.track : null,
              // isLocalUser: guestAudioSessionId === guestOwnerSessionId ? true : false
            },
          };
        }
        return prevParticipants;
      });
    }
  }, [guests, guestVideoSessionId, guestAudioSessionId]);

  // useEffect(() => {
  //   console.log("leftGuest", leftGuest);
  //   if(leftGuest !== null && leftGuest !== guestOwnerSessionId) {
  //     setScreenParticipants((prevParticipants) => {
  //       const updatedParticipants = { ...prevParticipants };
  //       delete updatedParticipants[leftGuest];
  //       return updatedParticipants;
  //     });

  //     toast.warn("Guest left the room");
  //   }
  // }, [leftGuest])

  return (
    <GuestContext.Provider
      value={{
        callFrame,
        isJoined,
        guests,
        joinCall,
        leaveCall,
        screenParticipants,
        graphicsData,
        open,
        setOpen,
        orientation,
        toogleLocalVideo,
        toggleLocalAudio,
        videoCamera,
        audioMicrophone,
      }}
    >
      {children}
    </GuestContext.Provider>
  );
};
