import {
  Autocomplete,
  Box,
  Button,
  Breadcrumbs,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  FormControlLabel,
  InputBase,
  MenuItem,
  Link,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  Divider,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
// import Header from "../../../components/ShopComponents/ShopHeader";
// import Img from "../../../assets/Image/AboutImg.png";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentsIcon from "@mui/icons-material/Payments";
import { DateField } from "@mui/x-date-pickers/DateField";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { countries } from "../../../utils/CountryCodes";
// import { CartContext } from "../../../context/CartContext";
import { useNavigate, useParams } from "react-router-dom";
// import { createOrder } from "../../../api/Orders.api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
// import { CustomerContext } from "../../../context/CustomerContext";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import AddCommentRoundedIcon from "@mui/icons-material/AddCommentRounded";
import { Add } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ShopContext } from "../../../context/ShopContext";
import { toast } from "../../Toast";
import { createOrder } from "../../../api/OrderApis";
import { AuthContext } from "../../../context/AuthContext";
import { getAllCities } from "../../../api/LeopardApis";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { cities } from "../../../utils/leoapardCities";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const Index = () => {
  const [value, setValue] = React.useState("female");

  const [open, setOpen] = React.useState({ addDetail: false });
  const [confirm, setConfirm] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [leopardCities, setLeopardCities] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleCloseOrderDialog = () => {
    setOpen({ ...open, addDetail: false });
  };

  //-----useContext-------
  const {
    cartItems,
    setCartItems,
    setCheckoutItems,
    updateQuantity,
    store,
    checkoutItems,
    shopDetails,
    getCartItemsQuantity,
    getCheckoutItemsQuantity,
    removeCheckoutItem,
  } = useContext(ShopContext);
  // const { loginStatus, user, store } = useContext(CustomerContext);

  const navigate = useNavigate();

  //------Calculate Subtotal-------
  // const subtotal = cartItems.reduce(
  //   (acc, item) => acc + item.price * item.quantity,
  //   0
  // );

  //-----STATES for checkout ----
  const [checkoutObj, setCheckoutObj] = useState({
    store_name: "",
    products: [],
    shipping_details: [],
    payment_details: [],
    total_price: 0,
  });
  const bydefCity = cities.find((c) => c.name === "Karachi");

  const [shippingDetails, setShippingDetails] = useState({
    full_name: "",
    email: "",
    address: "",
    city: bydefCity,
    postal_code: "",
    phone_number: "",
    code: JSON.stringify(countries[0]),
    email_error: null,
    phone_error: null,
    postal_code_error: null,
    full_name_error: null,
    address_error: null,
    city_error: null,
  });

  const [methods, setMethods] = useState("COD");

  const [paymentDetails, setPaymentDetails] = useState({
    payment_method: "COD",
    card_number: "",
    card_holder_name: "",
    card_expiry_date: "",
    card_cvv: "",
  });

  //-----Handlers
  const addShippingHandler = () => {
    setCheckoutObj({
      ...checkoutObj,
      shipping_details: shippingDetails,
    });
    setOpen({ ...open, login: false });
  };

  const validateFormData = () => {
    // if (
    //   !shippingDetails.full_name ||
    //   !shippingDetails.email ||
    //   !shippingDetails.address ||
    //   !shippingDetails.city?.name ||
    //   !shippingDetails.phone_number
    // ) {
    //   toast.error("Error: Please fill in all the required fields.");
    //   return false;
    // }

    // Validate email format
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(shippingDetails.email)) {
      setShippingDetails({
        ...shippingDetails,
        email_error: "Email is not valid",
      });
      return false;
    }

    // Validate name as a non-empty string
    if (
      typeof shippingDetails.full_name !== "string" ||
      shippingDetails.full_name.trim().length === 0
    ) {
      setShippingDetails({
        ...shippingDetails,
        full_name_error: "Name required OR name invalid",
      });
      return false; // Validation failed
    }

    // // Validate postalCode as a number
    if (isNaN(shippingDetails.postal_code)) {
      setShippingDetails({
        ...shippingDetails,
        postal_code_error: "Postal code must be a number",
      });
      return false; // Validation failed
    }

    // Validate address as a string with at least one number
    if (
      typeof shippingDetails.address !== "string" ||
      !/\d/.test(shippingDetails.address)
    ) {
      setShippingDetails({
        ...shippingDetails,
        address_error: "Address must contain at least one number",
      });
      return false; // Validation failed
    }

    // Validate phoneNumber as a proper Pakistani number format
    const phoneNumberRegex = /^(\+92|92|0)?3\d{9}$/;
    if (
      !phoneNumberRegex.test(
        JSON.parse(shippingDetails.code).dial_code +
          shippingDetails.phone_number
      )
    ) {
      setShippingDetails({
        ...shippingDetails,
        phone_error: "Phone number is not valid",
      });
      return false; // Validation failed
    }
    if (!shippingDetails?.phone_number) {
      setShippingDetails({
        ...shippingDetails,
        phone_error: "Phone number is required",
      });
      return false; // Validation failed
    }
    return true;
  };

  const getCheckoutItems = () => {
    return checkoutItems.map((item) => {
      return {
        product_id: item?._id,
        quantity: item.quantity,
        is_varient: item?.is_varient,
        option_id: item?.option_id,
        group_id: item?.group_id,
        price: item?.price,
        discount_price: item?.discount_price,
        variant: item?.variant,
      };
    });
  };

  const getTotalPrice = () => {
    let price = 0;

    cartItems.forEach((item) => {
      price +=
        Number(item.quantity) * Number(item.discount_price || item.price);
    });

    return price;
  };

  const placeOrderHandler = async () => {
    if (!validateFormData()) {
      return;
    }

    if (!store?._id) {
      toast.error("Oops! Something went wrong. Please try again later.");
      return;
    }

    let data = {
      products: getCheckoutItems(),
      shipping_details: {
        full_name: shippingDetails.full_name,
        address: shippingDetails.address,
        phone_number:
          JSON.parse(shippingDetails.code)?.dial_code +
          "-" +
          shippingDetails.phone_number,
        city: shippingDetails.city,
        postal_code: shippingDetails.postal_code,
        email: shippingDetails.email,
      },
      total_price:
        getTotalPrice() +
        (getTotalPrice() >= shopDetails?.delivery?.free_delivery_amount
          ? 0
          : store?.delivery?.delivery_charges || 0),
      status: "pending",
      payment_details: {
        payment_method: methods,
        card_number: paymentDetails?.card_number,
        card_holder_name: paymentDetails?.card_holder_name,
        card_expiry_date: paymentDetails?.card_expiry_date,
        card_cvv: paymentDetails?.card_cvv,
      },
      delivery_charges:
        getTotalPrice() >= shopDetails?.delivery?.free_delivery_amount
          ? 0
          : store?.delivery?.delivery_charges || 0,
      store_id: store?._id,
      source: "store",
      delivery: {},
    };

    setLoading(true);
    try {
      const response = await createOrder(data);
      if (response.status === 200) {
        setConfirm(true);
        setCartItems([]);
        setCheckoutItems([]);
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again later.");
    }
    setLoading(false);
  };

  const handleClickOpen = () => {
    setOpen({ ...open, addDetail: true });
  };

  const handleClose = () => {
    setOpen({ ...open, addDetail: false });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleCodeChange = (event) => {
    setShippingDetails({ ...shippingDetails, code: event.target.value });
  };

  const handleQtyInc = (row) => {
    updateQuantity(row._id, ++row.quantity);
  };

  const handleQtyDec = (row) => {
    updateQuantity(row._id, --row.quantity);
  };

  const handleAdd = () => {
    if (!validateFormData()) {
      return;
    }

    localStorage.setItem("shippingDetails", JSON.stringify(shippingDetails));
    setOpen({ ...open, addDetail: false });
  };

  const handleUserEdit = () => {
    setOpen({ ...open, addDetail: true });
  };

  const getLeopardCities = async () => {
    // const citiesData = await getAllCities();
    setLeopardCities(cities);
  };

  useEffect(() => {
    let shipDetail = localStorage.getItem("shippingDetails");
    if (shipDetail) {
      setShippingDetails(JSON.parse(shipDetail));
    } else {
      setOpen({ ...open, addDetail: true });
    }

    getLeopardCities();
  }, []);

  const breadcrumbs = [
    <Link
      key="1"
      onClick={() => navigate("/")}
      style={{ textDecoration: "none" }}
    >
      <Typography
        sx={{
          textDecoration: "none",
          color: "#E6A31E",
          ":hover": { cursor: "pointer" },
        }}
      >
        {shopDetails?.store_name}
      </Typography>
    </Link>,
    <Link
      key="2"
      onClick={() => navigate("/cart")}
      style={{ textDecoration: "none" }}
    >
      <Typography
        sx={{
          textDecoration: "none",
          color: "#E6A31E",
          ":hover": { cursor: "pointer" },
        }}
      >
        Cart
      </Typography>
    </Link>,
    <Typography key="3" sx={{ color: "#E6A31E", fontWeight: "500" }}>
      Checkout
    </Typography>,
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: "80vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: "20px",
          gap: 1,
        }}
      >
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="small" sx={{ color: "#E6A31E" }} />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Divider />

      <Box
        sx={{
          py: "10px",
          display: "flex",
          gap: 3,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "65%" } }}>
          <Box
            sx={{
              width: "100%",
              minHeight: "80px",
              background: "#fff",
              borderRadius: "10px",
              display: "flex",
              mb: 3,
            }}
          >
            {!shippingDetails?.address ? (
              <Button
                sx={{
                  borderRadius: "20px",
                  color: "#4DA2F8",
                  ":hover": { cursor: "default" },
                  display: "flex",
                  gap: 1,
                  mx: "auto",
                  my: "auto",
                  height: "fit-content",
                }}
                onClick={handleClickOpen}
              >
                <AddCommentRoundedIcon /> Add Shipping Details
              </Button>
            ) : (
              <Typography
                sx={{
                  fontSize: "12px",
                  mx: "auto",
                  my: "auto",
                  borderRadius: "10px",
                  p: "10px",
                  width: "fit-content",
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span>
                  Delivery to:{" "}
                  <span style={{ fontWeight: "500" }}>
                    {shippingDetails?.full_name}
                  </span>
                </span>
                <span style={{ display: "flex", gap: 6 }}>
                  <span
                    style={{
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    +92 {shippingDetails?.phone_number}
                  </span>
                  <Divider orientation="vertical" />
                  <span>
                    {shippingDetails?.address || ""},{" "}
                    {shippingDetails?.city?.name || ""}
                    <Button
                      sx={{
                        color: "#4DA2F8",
                        textTransform: "none",
                        p: 0,
                        m: 0,
                        fontWeight: "400",
                      }}
                      onClick={() => setOpen({ ...open, addDetail: true })}
                    >
                      Change
                    </Button>
                  </span>
                </span>{" "}
              </Typography>
            )}
          </Box>

          <TableContainer
            sx={{ width: "100%", background: "#fff", borderRadius: "10px" }}
          >
            <Table aria-label="simple table">
              <TableBody>
                {checkoutItems.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell
                      // component="th"
                      // scope="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={row.thumbnail_image}
                        alt="test"
                        style={{
                          width: "50px",
                          height: "50px",
                          marginRight: "20px",
                          objectFit: "contain",
                        }}
                      />
                      <Typography sx={{ fontWeight: "500" }}>
                        {row.product_name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      Qty:&nbsp;{row.quantity}
                    </TableCell>
                    <TableCell align="right">
                      <Typography sx={{ fontWeight: "500" }}>
                        Rs&nbsp;
                        {Number(row.quantity) *
                          Number(row.discount_price || row.price)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        width: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          sx={{ minWidth: "0", p: "0" }}
                          onClick={() => removeCheckoutItem(row)}
                        >
                          <DeleteOutlineRoundedIcon sx={{ color: "#707070" }} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        border: "1px solid #4DA2F8",
                        borderRadius: "5px",
                        p: "10px",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          background: "#4DA2F8",
                          position: "absolute",
                          transform: "rotate(45deg)",
                          top: "-15px",
                          left: "-15px",
                        }}
                      ></Box>
                      <Box
                        sx={{ position: "absolute", top: "-1px", left: "-5px" }}
                      >
                        <DoneRoundedIcon
                          sx={{ color: "#fff", height: "15px" }}
                        />
                      </Box>

                      <Box sx={{ display: "flex", gap: 1, color: "#4DA2F8" }}>
                        <Typography sx={{ fontSize: "10px" }}>
                          Standard Delivery
                        </Typography>
                        <Divider
                          orientation="vertical"
                          sx={{ color: "#4DA2F8" }}
                        />
                        <Typography sx={{ fontSize: "10px" }}>
                          Rs {store?.delivery?.delivery_charges}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "10px" }}>
                          Received by {store?.delivery?.min_days}-
                          {store?.delivery?.max_days} Days
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    {getCheckoutItemsQuantity()}&nbsp;item(s)
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box
          sx={{
            width: { xs: "calc(100% - 40px)", md: "35%" },
            borderRadius: "10px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            p: "20px",
            height: "fit-content",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "350px",
              px: "0px !important",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#000000B7",
                fontWeight: "500",
              }}
            >
              Order Summary
            </Typography>

            <Box sx={{ mt: 3 }}>
              {/* <Box sx={{ display: "flex", position: "relative", mb: "10px" }}>
                <TextField
                  label="Promo Code"
                  id="outlined-size-small"
                  size="small"
                  sx={{
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    width: "100%",
                  }}
                />
                <Button
                  sx={{
                    color: "#fff",
                    position: "absolute",
                    background: "black",
                    ":hover": { background: "black" },
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    right: "0",
                    height: "100%",
                  }}
                >
                  Apply
                </Button>
              </Box> */}

              <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "black",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                SubTotal:&nbsp;{" "}
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
                >
                  {/* Rs {subtotal} */}
                  Rs {getTotalPrice()}
                </Typography>
              </Typography>

              {/* <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "#707070B7",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                Discount:&nbsp;{" "}
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
                >
                  0
                </Typography>
              </Typography> */}

              {/* {getTotalPrice() <
                shopDetails?.delivery?.free_delivery_amount && ( */}
              <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "black",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                Shipping:&nbsp;{" "}
                {store?.delivery?.delivery_charges > 0 &&
                getTotalPrice() <
                  shopDetails?.delivery?.free_delivery_amount ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Rs {store?.delivery?.delivery_charges || 0}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Free
                  </Typography>
                )}
              </Typography>
              {/* )} */}

              <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "#707070B7",
                  justifyContent: "space-between",
                  mb: "10px",
                  fontWeight: "bold",
                }}
              >
                Total:&nbsp;{" "}
                <Typography
                  sx={{ fontSize: "12px", color: "black", fontWeight: "bold" }}
                >
                  Rs{" "}
                  {(getTotalPrice() <
                  shopDetails?.delivery?.free_delivery_amount
                    ? getTotalPrice() + store?.delivery?.delivery_charges
                    : getTotalPrice()) || 0}
                </Typography>
              </Typography>
            </Box>

            <Divider />

            <Typography
              sx={{
                fontSize: "16px",
                color: "#000000B7",
                fontWeight: "500",
                mt: 2,
              }}
            >
              Payment Method
            </Typography>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={paymentDetails.payment_method === "COD" ? "COD" : "debit"}
              onChange={(e) =>
                setPaymentDetails({
                  ...paymentDetails,
                  payment_method: e.target.value,
                })
              }
              sx={{ mb: "10px" }}
            >
              <FormControlLabel
                value="COD"
                control={<Radio size="small" sx={{ p: "5px" }} />}
                label={
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "#322F2F",
                    }}
                    fontSize="small"
                  >
                    <PaymentsIcon fontSize="small" /> Cash on Delivery
                  </Typography>
                }
              />
              {/* <FormControlLabel
                value="debit"
                disabled
                control={<Radio size="small" sx={{ p: "5px" }} />}
                label={
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "#322F2F",
                    }}
                    fontSize="small"
                  >
                    <PaymentIcon fontSize="small" /> Online Payment
                  </Typography>
                }
              /> */}
            </RadioGroup>

            {paymentDetails.payment_method === "debit" && (
              <>
                <TextField
                  label="Name on Card"
                  id="outlined-size-small"
                  size="small"
                  sx={{
                    width: "100%",
                    mb: "10px",
                  }}
                  value={paymentDetails.card_holder_name}
                  onChange={(e) =>
                    setPaymentDetails({
                      ...paymentDetails,
                      card_holder_name: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Card No."
                  id="outlined-size-small"
                  size="small"
                  defaultValue={"0000-0000-0000-0000"}
                  sx={{
                    width: "100%",
                    mb: "10px",
                  }}
                  value={paymentDetails.card_number}
                  onChange={(e) =>
                    setPaymentDetails({
                      ...paymentDetails,
                      card_number: e.target.value,
                    })
                  }
                />

                <Box sx={{ display: "flex", gap: 1, mb: "10px" }}>
                  <TextField
                    label="CVV"
                    id="outlined-size-small"
                    size="small"
                    sx={{
                      width: "70px",
                    }}
                    value={paymentDetails.card_cvv}
                    onChange={(e) =>
                      setPaymentDetails({
                        ...paymentDetails,
                        card_cvv: e.target.value,
                      })
                    }
                  />

                  <DateField
                    label="Expiration Date"
                    size="small"
                    sx={{
                      flex: "auto",
                    }}
                    value={paymentDetails.card_expiry_date}
                    onChange={(e) =>
                      setPaymentDetails({
                        ...paymentDetails,
                        card_expiry_date: e.target.value,
                      })
                    }
                  />
                </Box>
              </>
            )}

            <Button
              sx={{
                background: "#FE9B0E",
                ":hover": { background: "#FE9B0E" },
                color: "#fff",
                borderRadius: "20px",
                mb: "10px",
              }}
              onClick={placeOrderHandler}
              disabled={cartItems?.length === 0 ? true : false}
            >
              {loading ? (
                <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
              ) : (
                "Place Order"
              )}
            </Button>
            <Button
              sx={{
                background: "transparent",
                color: "#FE9B0E",
                border: "1px solid #FE9B0E",
                borderRadius: "20px",
                mb: "10px",
              }}
              onClick={() => navigate(`/`)}
            >
              Continue Shopping
            </Button>
          </Container>
        </Box>
      </Box>

      <Dialog
        open={open.addDetail}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: "15px", width: "900px" },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#707070B7",
            position: "relative",
            px: { xs: "15px", sm: "40px" },
            py: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            gap: 3,
          }}
        >
          <Typography
            sx={{
              width: "100%",
              textAlign: "left",
              color: "black",
              fontWeight: "500",
            }}
          >
            Shipping Details
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: { xs: 1, sm: 3 },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: 1, sm: 3 },
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <TextField
                label="Full Name"
                id="outlined-size-small"
                required={true}
                error={!shippingDetails.full_name}
                helperText={shippingDetails.full_name_error}
                size="small"
                sx={{
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  width: "100%",
                }}
                value={shippingDetails.full_name}
                onChange={(e) =>
                  setShippingDetails({
                    ...shippingDetails,
                    full_name: e.target.value,
                    full_name_error: null,
                  })
                }
              />

              <TextField
                label="Email"
                id="outlined-size-small"
                required={true}
                helperText={shippingDetails.email_error}
                size="small"
                sx={{
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  width: "100%",
                }}
                value={shippingDetails.email}
                onChange={(e) =>
                  setShippingDetails({
                    ...shippingDetails,
                    email: e.target.value,
                    email_error: null,
                  })
                }
              />
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "5px",
                    border: "1px solid #00000029",
                  }}
                >
                  <Select
                    labelId="phone-code-select-label"
                    id="phone-code-select"
                    value={shippingDetails.code}
                    onChange={handleCodeChange}
                    disableUnderline
                    label="Phone Code"
                    sx={{
                      width: "110px",
                      pl: "10px",
                      pt: "5px",
                    }}
                    variant="standard"
                    size="small"
                  >
                    {countries.map((c) => (
                      <MenuItem
                        key={c.code}
                        value={JSON.stringify(c)}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          className={`fi fi-${c.code.toLocaleLowerCase()}`}
                        ></span>{" "}
                        {c.dial_code}
                      </MenuItem>
                    ))}
                  </Select>
                  <InputBase
                    placeholder="3456789100"
                    sx={{
                      width: "100%",
                    }}
                    value={shippingDetails.phone_number}
                    required={true}
                    helperText={shippingDetails.phone_error}
                    onChange={(e) =>
                      setShippingDetails({
                        ...shippingDetails,
                        phone_number: e.target.value,
                        phone_error: null,
                      })
                    }
                  />
                </Box>
                {shippingDetails.phone_error && (
                  <Typography sx={{ color: "red", p: "6px", fontSize: "12px" }}>
                    {shippingDetails.phone_error}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: 1, sm: 3 },
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <TextField
                label="Address"
                id="outlined-size-small"
                size="small"
                sx={{
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  width: "100%",
                }}
                value={shippingDetails.address}
                required={true}
                helperText={shippingDetails.address_error}
                onChange={(e) =>
                  setShippingDetails({
                    ...shippingDetails,
                    address: e.target.value,
                    address_error: null,
                  })
                }
              />

              <Autocomplete
                options={leopardCities}
                getOptionLabel={(option) => option.name}
                sx={{
                  width: "100%",
                }}
                value={shippingDetails.city}
                onChange={(e, newVal) => {
                  setShippingDetails({
                    ...shippingDetails,
                    city: newVal,
                  });
                }}
                size="small"
                renderInput={(params) => <TextField {...params} label="City" />}
              />
            </Box>
          </Box>

          <Button
            style={{
              color: "#fff",
              background: "#FE9B0E",
              borderRadius: "10px",
              width: "150px",
              ":hover": { background: "#FEBE0B" },
            }}
            onClick={handleAdd}
          >
            Save
          </Button>
        </DialogContent>
        <Button
          style={{
            position: "absolute",
            color: isHovered ? "white" : "black",
            top: 6,
            right: 8,
            padding: 1,
            minWidth: "fit-content",
            borderRadius: "50%",
            transition: "background-color 0.3s ease-in-out",
            backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
          }}
          onClick={handleCloseOrderDialog}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <CloseIcon />
        </Button>
      </Dialog>

      <Dialog
        open={confirm}
        onClose={() => setConfirm(false)}
        PaperProps={{
          style: { borderRadius: "15px", width: { xs: "", md: "80%" } },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#707070B7",
            position: "relative",
            padding: "35px",
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: "30px",
                gap: 1,
              }}
            >
              <CheckCircleIcon sx={{ fontSize: "50px", color: "#FC6B00" }} />

              <Typography sx={{ fontSize: "13px" }}>
                Hey {shippingDetails.full_name},
              </Typography>
              <Typography sx={{ fontWeight: "bold", color: "black" }}>
                Your Order is Confirmed
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ color: "black", fontSize: "12px" }}>
                  Thank you for your order,
                </Typography>
                <Typography sx={{ color: "black", fontSize: "12px" }}>
                  your product will be shipped soon!
                </Typography>
              </Box>

              <Button
                sx={{
                  background: "#FC6B00",
                  color: "#fff",
                  px: "20px",
                  borderRadius: "10px",
                  ":hover": { background: "#FC6B00" },
                }}
                onClick={() => navigate(`/`)}
              >
                Continue Shopping
              </Button>
            </Box>

            <Button
              style={{
                position: "absolute",
                color: "#707070B7",
                top: 3,
                right: 3,
                padding: "0",
                minWidth: "fit-content",
              }}
              onClick={() => setConfirm(false)}
            >
              <HighlightOffIcon />
            </Button>
          </>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Index;
