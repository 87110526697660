import axios from "./axios";

export const createSocialMediaAccount = async (data) => {
  try {
    const response = await axios.post(`/sso/create`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllFacebookPages = async (page) => {
  try {
    const response = await axios.get(
      `/sso/get-all-facebook-pages?page=${page}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const getAllInstagramPages = async () => {
  try {
    const response = await axios.get(`/sso/get-all-instagram-pages`);
    return response;
  } catch (error) {
    return error;
  }
}

export const updateConnectedStatus = async (data) => {
  try {
    const response = await axios.put(`/sso/update-connected-status`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllConnectedAccounts = async () => {
  try {
    const response = await axios.get(`/sso/get-all-connected-accounts`);
    return response;
  } catch (error) {
    return error;
  }
};

export const refreshFacebookPages = async (data) => {
  try {
    const response = await axios.post(`/sso/refresh-fb-pages`, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const refreshInstagramPages = async (data) => {
  try {
    const response = await axios.post(`/sso/refresh-instagram-pages`, data);
    return response;
  } catch (error) {
    return error;
  }
}

export const reconnectAccount = async (data) => {
  try {
    const response = await axios.put(`/sso/reconnect-account`, data);
    return response;
  } catch (error) {
    return error;
  }
};
