import { useTheme } from "@mui/material";
import { useMemo } from "react";


const useTags=()=>{
    // !------------------- LOCAL STATES -------------------
    const theme = useTheme();

    // !------------------- DEFINING SWITCH STYLE-------------------
const switchProps=useMemo(()=>({
    sx: {
        width: 50,
        "& .MuiSwitch-track": {
          backgroundColor: theme.palette.saas_button_bg.main,
        },
        "& .MuiSwitch-thumb": {
          width: 15,
          height: 15,
          backgroundColor: theme.palette.saas_button_bg.main,
          marginTop: 0.3,
        },
      },
}),[theme]);
return{
    switchProps
};
};
export default useTags;