import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDropzone } from "react-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import { useNavigate } from "react-router-dom";
import {
  addProduct,
  addProductImage,
  checkKeyword,
  removeProductImage,
  verifyName,
} from "../../../api/ProductApis";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { toast } from "../../Toast";
import { getShopAnalytics, getShopViews } from "../../../api/StoreApis";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { countries } from "../../../utils/CountryCodes";
import { convertToWebP } from "../../../utils/image_copmression";
import "../../../ckeditor.styles.css";
import axios from "axios";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    // opacity: "0.5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#fff",
    zIndex: "5",
  },
}));

const categoryList = {
  Fashion: ["Dresses", "Shoes", "Accessories", "Other"],
  Beauty: ["Skincare", "Makeup", "Fragrances", "Other"],
  Electronics: ["Smartphones", "Laptops", "Headphones", "Other"],
  "Home Goods": ["Furniture", "Kitchen Appliances", "Decor", "Other"],
  Toys: ["Action Figures", "Board Games", "Dolls", "Other"],
  "Health and Wellness": [
    "Vitamins",
    "Fitness Equipment",
    "Supplements",
    "Other",
  ],
  "Pet Supplies": ["Dog Food", "Cat Toys", "Fish Tanks", "Other"],
  "Outdoor Gear": ["Camping Gear", "Hiking Boots", "Bicycles", "Other"],
  Books: ["Fiction", "Non-Fiction", "Mystery", "Other"],
  Others: ["Miscellaneous", "Random", "Unspecified", "Other"],
  "Groceries & Pets": [
    "Fresh Produce",
    "Fresh Fruit",
    "Fresh Vegetables",
    "Fish & Cold Pressed Juices",
    "Bread",
    "Eggs",
    "Dry Fruits",
    "Breakfast, Choco's & Snacks",
    "Breakfast Cereals",
    "Jams, Honey & Spreads",
    "Breakfast Bars",
    "Instant Breakfast Drinks",
    "Chocolate & Candy",
    "Chewing Gums & Mints",
    "Biscuit & Cookies",
    "Cakes & Sweets",
    "Chips & Crisps",
    "Nuts & Legumes",
    "Oatmeal",
    "Pancake & Waffle Mixes",
    "Beverages",
    "Tea",
    "Coffee",
    "UHT, Milk & Milk Powder",
    "Soft Drinks",
    "Juices",
    "Water",
    "Flavouring Syrup",
    "Powdered Drinks",
    "Food Staples",
    "Oil",
    " Rice",
    "Condiment Dressing",
    "Cooking Ingredients",
    "Noodles & Pasta",
    "Instant & Ready-to-Eat",
    "Grains, Beans & Pulses",
    "Home Baking & Sugar",
    "Jarred Food",
    "Canned Food",
    "Laundry & Household",
    "AirCare",
    "Cleaning",
    "Dishwashing",
    "Laundry",
    "Pest Control",
    "Tissue Paper",
    "Foils & Cling Films",
    " Frozen Food",
    "Chicken",
    "Beef",
    "Other Frozen Food",
    "Cat Food",
    "Cat Beds, Mats & Houses",
    "Cat Carriers & Travel",
    "Cat Grooming",
    "Cat Litter & Toilet",
    "Cat Cages, Crates & Doors",
    "Cat Toys",
    "Cat Treats",
    "Cat Trees, Condos & Scratchers",
    "Cat Bowls & Feeders",
    "Dog Collars",
    "Dog Toys",
    "Dog Harnesses",
    "Dog Brushes & Combs",
    "Dog Shampoos & Bath Accessories ",
    "Dog Bowls & Diners",
    "Dog Beds",
    "Fish",
    "Decorations",
    "Water pumps",
    "Aquariums",
    "Filters & Accessories",
    "Air Pumps & Accessories",
    "Temperature control supplies",
    "Food",
  ],
  "Health & Beauty": [
    "Makeup",
    "Makeup Accessories",
    "Lips",
    "Nails",
    "Face",
    "Eyes",
    "Makeup Removers",
    "Makeup Palettes & Sets",
    "Bulk Deals",
    "Beauty Tools",
    "Skin Care Tools",
    "Hair Removal Appliances",
    "Slimming & Electric Massagers",
    "Hair Styling Appliances",
    "Foot Relief Tools",
    "Skin Care",
    "Moisturizers and Cream",
    "Facial Cleansers",
    "Serum & Essence",
    "Face Mask & Packs",
    "Sunscreen & Aftersun",
    "Lip Balm & Treatment",
    "Eye Care",
    "Face Scrubs & Exfoliators",
    "Toner & Mists",
    "Hair Care",
    "Shampoo & Conditioner",
    "Hair Care Accessories",
    "Hair Treatments",
    "Oil & Serums",
    "Hair Coloring",
    " Hair Styling",
    "Body Soaps & Shower Gels",
    "Hair Removal",
    "Body & Massage Oils",
    "Bath & Body Accessories",
    "Foot Care",
    "Hand Care",
    "Bath & Body",
    "Hair Care",
    "Shaving & Grooming",
    "Personal Care",
    "Pads & Tampons",
    "Menstrual Cups",
    "Oral Care",
    "Eye Care",
    "Deodorants",
    "Adult Diapers",
    "Personal Safety & Security",
    " Ear Care",
    "Fragrances for Women",
    "Fragrances for Men",
    "Fragrances for Unisex",
    "Sexual Wellness",
    "Condoms",
    "Lubricants",
    "Medical Supplies",
    "Health Monitors & Tests",
    "Health Accessories",
    "First Aid Supplies",
    "Ointments and Creams",
    "Surgical Masks",
    "Nebulizer & Aspirators",
    "Stethoscopes",
  ],

  "Men's Fashion": [
    "T Shirts & Tanks",
    "V Neck",
    "Round Neck",
    "Graphic tees",
    "Top Rated",
    "Shirts & Polo",
    "Casual Shirts",
    "Formal Shirts",
    "Polos",
    "Pants & Jeans",
    "Chinos",
    "Cargo",
    "Jeans",
    "Shorts, Joggers & Sweats",
    "Shorts",
    "Joggers & Sweats",
    "Unstitched Fabric",
    "Kurtas & Shalwar Kameez",
    "Kurtas",
    "Shalwar",
    "Shawls",
    "Winter Clothing",
    "Jackets & Coats",
    "Hoodies & Sweatshirts",
    "Sweaters & Cardigans",
    "Inner Wear",
    "Briefs",
    "Trunk & Boxers",
    "Nightwear",
    "Vests",
    "Thermal",
    "Socks",
    "Shoes",
    "Slip-Ons & Loafers",
    "Flip Flops & Sandals",
    "Sneakers",
    "Formal Shoes",
    "Boots",
    "Khusa & Kolapuri",
    "Shoes Accessories",
    "Accessories",
    "Belts",
    "Sunglasses",
    "Hats & Caps",
    "Boy's Clothing",
    "T-Shirts & Shirts",
    "Pants & Jeans",
    "Shorts",
    "Kurtas & Shalwar Kameez",
    "Underwear & Socks",
    "Ties & Bows",
    "Shoes",
    "Sneakers",
    "Slip-Ons & Loafers",
    "School Shoes",
    "Flip Flops, Slides & Sandals",
    "Formal Shoes",
    "Boots ",
    "Sandals",
    "Boy's Accessories",
    "Belts",
    "Inner Wear",
    "Socks",
    "Hats & Caps",
  ],

  "Women's Fashion": [
    "Unstitched Fabric",
    "Branded Unstitched",
    "Sarees",
    "Kurtas & Shalwar Kameez",
    "Kurtis",
    " Shalwar Kameez",
    "Branded Pret",
    "Trousers & Palazzos",
    "Muslim Wear",
    "Dupattas, Stoles & Shawls",
    "Abayas & Hijabs",
    "Scarves",
    " Hair Accessories",
    "Tops",
    "Blouses & Shirts",
    "Tunics",
    " T-Shirts",
    "Bras",
    "Panties",
    "Lingerie Sets",
    "Beachwear and Bikinis",
    "Socks & Tights",
    "Sleepwear & Innerwear",
    "Nightwear",
    "Robe and Gown sets",
    "Tanks & Camisoles",
    "Shapewear",
    "Pants",
    "Leggings",
    "Jeans",
    "Shorts",
    "Jeggings",
    "Dresses & Skirts",
    "Ethnic Dresses",
    "Western Dresses",
    "Skirts",
    " Formal Wear",
    "Winter Clothing",
    "Jackets & Coats",
    "Hoodies & Sweatshirts",
    "Sweaters & Cardigans",
    "Shawls and Poncho's",
    "Shrugs",
    "Shoes",
    "Sandals",
    "Flat Shoes",
    "Heels",
    "Khussa & Kohlapuri",
    "Slides & Flip FLops",
    " Wedges",
    "Sneakers",
    "Boots",
    "Shoes Accessories",
    "Girls Clothing",
    "Dresses",
    "Tops",
    "Underwear & Sleepwear",
    "Bottoms",
    "Socks & Tights",
    "Jackets & Coats",
    "Swimsuits",
    "Hoodies",
    "Hair Accessories",
    "Hats & Caps",
    "Belts",
    "Gloves, Scarves & Cold Weather",
    "Girls Shoes",
    "Sneakers",
    "Flats & Slip - Ons",
    "Formal Shoes",
    "Sandals",
    "Flip Flops, Slides & Sandals",
    "School Shoes",
    "Boots",
  ],

  "Mother & Baby": [
    "Milk Formula & Baby Food",
    " Maternal",
    "Infant Milk (0-6 Months)",
    "Infant Milk (6-12 Months)",
    " Toddler Milk (1 - under 3 yrs)",
    "Growing-up Milk (3 yrs +)",
    "Baby & Toddler Foods",
    "Diapering & Potty",
    "Disposable diapers",
    "Diaper Bags",
    "Wipes & Holders",
    "Changing Tables, Pads & Kits",
    "Diapering Care",
    "Cloth Diapers & Accessories",
    "Potty Training",
    "Feeding",
    "Utensils",
    "Bottle-Feeding",
    "Breastfeeding",
    "Food Blenders",
    "Pacifiers & Teethers",
    "Baby & Toddler Foods",
    "Maternity Care",
    "Maternity Wear",
    "Maternity Accessories",
    "Nursing Covers",
    "Pregnancy Pillows",
    "Nipple Care",
    "Breast Shells",
    "Breast Pumps",
    "Baby Gear",
    "Kids Bag",
    "Swings, Jumpers & Bouncers",
    "Strollers",
    "Car Seats",
    "Walkers",
    "Backpacks & Carriers",
    "Harnesses & Leashes",
    "Baby Safety",
    "Baby Monitor",
    "Highchairs & Booster Seats",
    "Baby Sofas",
    "Nursery ",
    "Baby Furniture",
    "Mattresses & Bedding",
    "Storage & Organization",
    "Nursery Decor",
    "Baby Personal Care",
    "Skin Care",
    "Bathing Tubs & Seats",
    "Soaps, Cleansers & Bodywash",
    "Baby Groomings & Care Kits",
    "Grooming & Healthcare Kits",
    "Toothbrushes & Toothpaste",
    "Shampoo & Conditioners",
    "Washcloths & Towels",
    "Baby Bath Mats",
    "Clothing & Accessories",
    "Newborn",
    "New Born Unisex (0 - 6 mnths)",
    "New born bodysuits",
    "New born sets & Packs",
    "Accessories",
    "Boys (Under 3 Years)",
    "Girls (Under 3 Years)",
    "Baby & Toddler Toys",
    "Activity Gym & Playmats",
    "Bath Toys",
    "Building Blocks Toys",
    "Crib Toys & Attachments",
    "Early Development Toys",
    "Music & Sound",
    "Rattles",
    "Push & Pull Toys",
    "Remote Control & Vehicles",
    "Die-Cast Vehicles",
    "Drones & Accessories",
    "Play Trains & Railway Sets",
    "Play Vehicles",
    "RC Vehicles & Batteries",
    "Sports & Outdoor Play",
    "Inflatable Bouncers",
    "Swimming Pool & Water Toys",
    "Fidget Spinners",
    "Toys & Games",
    "Arts & Crafts for Kids",
    "Action Figures & Collectibles",
    "Blocks & Building toys",
    "Dolls & Accessories",
    "Learning & Education",
    "Puzzle & Boardgames",
    "Slime & Squishy Toys",
    "Stuffed Toys",
    "Pretend Play",
  ],

  " Home & Lifestyle": [
    "Bath Mats",
    "Bath Towels",
    "Bathrobes",
    "Bathroom Scales",
    "Bathroom Shelving",
    "Shower Caddies & Hangers",
    "Shower Curtains",
    "Towel Rails & Warmers",
    "Bed Sheets",
    "Bedding Accessories",
    "Blankets & Throws",
    "Comforters, Quilts & Duvets",
    "Mattress Protectors",
    "Pillow Cases",
    "Pillows & Bolsters",
    "Artificial Flowers & Plants",
    "Candles & Candleholders",
    "Clocks",
    "Curtains",
    "Cushions & Covers",
    "Mirrors",
    "Picture Frames",
    "Rugs & Carpets",
    "Vases & Vessels",
    "Wall Stickers & Decals",
    "Bedroom",
    "Gaming Furniture",
    "Home Office",
    "Kitchen Furniture",
    "Living Room",
    "Bakeware",
    "Coffee & Tea",
    "Cookware",
    "Dinnerware",
    "Drinkware",
    "Kitchen & Table Linen",
    "Kitchen Storage & Accessories",
    "Kitchen Utensils",
    "Knives & Accessories",
    "Serve ware",
    "Ceiling Lights",
    "Floor Lamps",
    "Lamp Shades",
    "Light Bulbs",
    "Lighting Fixtures & Components",
    "Outdoor Lighting",
    "Rechargeables & Flashlights",
    "Table Lamps",
    "Laundry & Cleaning",
    "Brushes, Sponges & Wipers",
    "Brooms, Mops & Sweepers",
    "Laundry Baskets & Hampers",
    "Clothes Line & Drying Racks",
    "Ironing Boards",
    "Fixtures & Plumbing",
    " Electrical",
    "Hand Tools",
    "Lawn & Garden",
    "Power Tools",
    "Security",
    "Home Build Up",
    "Primers",
    "Paints",
    "Art Supplies",
    "Gifts & Wrapping",
    "Packaging & Cartons",
    "Paper Products",
    "School & Office Equipment",
    "Writing & Correction",
    "School Uniforms",
    "Books",
    " English Books",
    "Islamic Books",
    "English Literature Books",
    " English Story Books",
    "History Books",
    "eBooks",
    "Magazines",
    "Musical Instruments",
  ],

  "Electronic Devices": [
    "Apple iPhones",
    "Xiaomi Mi Mobiles",
    "Nokia Mobiles",
    "Redmi Mobiles",
    "Realme Mobiles",
    "Oppo Mobile Phones",
    "Oneplus Mobiles",
    "Infinix Mobiles",
    "Honor Mobiles",
    "Tecno Mobiles",
    "Samsung Mobile Phones",
    "Vivo Mobiles",
    "Feature Phones",
    "Tablets",
    "Monitors",
    "Laptops",
    "Refurbished Laptops",
    "Traditional Laptops",
    "Like New Laptops",
    "Desktops",
    "Smart Watches",
    "PlayStation Consoles",
    " Playstation Games",
    "Playstation Controllers",
    "Nintendo Games",
    "Xbox Games",
    "Video Camera",
    "DSLR",
    "Drones",
    "Point & Shoot",
    "Instant Cameras",
    "Security Cameras",
    "Landline Phones",
  ],

  "Electronic Accessories": [
    "Phone Cases",
    "Power Banks",
    "Cables & Converters",
    " Wall Chargers",
    "Wireless Chargers",
    "Tablet Accessories",
    "Car Chargers",
    "Screen Protectors",
    "Phone Camera Flash Lights",
    "Selfie Sticks",
    "Docks & Stands",
    "Car Mounts",
    "Headphones & Headsets",
    "Bluetooth Headsets",
    "Headphones & Headsets Access.",
    "Mono Headsets",
    "Over-The-Ear Headphones",
    "Wired Headsets",
    "Wireless Earbuds",
    "In-Ear Headphones",
    "Wearable",
    "Smartwatches",
    "Fitness & Activity Trackers",
    "Fitness Tracker Accessories",
    "Virtual Reality",
    "Camera Accessories",
    "Memory Cards",
    "Lenses",
    "Tripods & Monopods",
    "Camera Cases, Covers and Bags",
    "Action Camera Accessories",
    "Gimbals & Stabilizers",
    "Lighting & Studio Equipment",
    "Batteries",
    "Computer Accessories",
    "Cooling Pads/Cooling Stands",
    "Mouse",
    "Adapters & Cables",
    "PC Audio",
    "Mac Accessories",
    "Keyboards",
    "Storage",
    "External Hard Drives",
    "Internal Hard Drives",
    "Flash Drives",
    "Storage for Mac",
    "OTG Drives",
    "Printers",
    " Printers",
    "Ink & Toners",
    "Fax Machines",
    "Computer Components",
    "Graphic Cards",
    "Desktop Casings",
    "Motherboards",
    "Fans & Heatsinks",
    "Processors",
    "Portable Speakers",
    "Network Components",
    " Access Points",
    "Gaming Accessories",
    "Gaming Mouse",
    "Gaming Keyboards",
    "Gaming Headsets",
    "Monitors & Accessories",
  ],

  "TV & Home Appliances": [
    "Air Conditioner",
    "Televisions",
    "Smart Televisions",
    "LED Televisions",
    "Refrigerators & Freezers",
    "Refrigerators",
    " Freezers",
    "Home Audio & Theater",
    "Home Entertainment",
    "Live Sound & Stage Equipment",
    "Home Theater Systems",
    "Soundbar Speakers",
    "Washing Machine",
    "Kitchen Appliances",
    "Microwave",
    "Oven",
    "Air & Deep Fryers",
    "Blender, Mixer & Grinder",
    "Juicer & Fruit Extraction",
    "Water Dispensers",
    "Electric Kettle",
    "Dishwashers",
    "Cooktop & Range",
    "Rice Cooker",
    "Pressure Cookers",
    "Specialty Cookware",
    "Cooling & Heating",
    " Air Cooler",
    " Fan",
    " Air Purifier",
    " Dehumidifier",
    " Humidifier",
    "Water Heater",
    " Room Heater",
    "Irons & Garment Care",
    " Irons",
    " Garment Steamer",
    "Sewing Machine",
    "Generator, UPS & Solar",
    "Generators",
    "UPS",
    "Solar Inverters",
    "Projectors & Players",
    "Blu Ray & DVD Players",
    "Projectors",
    "TV Accessories",
    "TV Receivers",
    "Wall Mounts & Protectors",
    "TV Adapters",
    "TV Remote Controllers",
    "3D Glasses",
    "Antennas",
    "Cables",
    "Vacuums & Floor Care",
    "Vacuum Cleaner",
    "Floor Polisher",
    "Steam Mops",
    "Electric Brooms",
  ],

  "Sports & Outdoor": [
    "Exercise & Fitness",
    "Cardio Training Equipment",
    "Strength Training Equipments",
    "Treadmills",
    "Fitness Accessories",
    "Boxing, Martial Arts & MMA",
    "Weight",
    "Yoga",
    "Exercise Bands",
    "Exercise Bikes",
    "Supplements",
    "Proteins",
    "Post Workouts and Recovery",
    "Pre Workouts",
    "Fat Burners",
    "Post Workouts and recovery",
    "Shoes & Clothing",
    "Mens Shoes",
    "Mens Clothing",
    "Womens Shoes",
    "Womens Clothing",
    "Men Accessories",
    "Men Bags",
    "Women Accessories",
    "Women Bags",
    "Team Sports",
    "Cricket",
    "Football",
    "Hockey",
    "Basket Ball",
    "Volley balls",
    "Baseballs",
    "Racket Sports",
    "Rackets",
    "Shuttlecocks",
    "Balls",
    "Bats",
    "Nets",
    "Grips & Tapes",
    "Balls",
    "Outdoor Recreation",
    "Cycling",
    "Cycle accessories",
    "Camping / Hiking",
    "Other activities",
    "Water Sports",
    "Fishing",
    "Skate Boards",
    "Fitness Gadgets",
    "Fitness Trackers",
    "Fitness Trackers Accessories",
    "Sports Accessories",
    "Water Bottles",
    "Home Gym",
  ],

  "Watches, Bags & Jewellery": [
    "Men's Watches",
    "Chronograph-Men's Watches",
    "Branded-Men's Watches",
    "Analog-Men's Watches",
    "Digital-Men's Watches",
    "Accessories-Men's Watches",
    "Smart Watches-Men's Watches",
    "Women's Watches",
    "Chronograph-Women's Watches",
    "Branded-Women's Watches",
    "Analog-Women's Watches",
    "Digital-Women's Watches",
    "Accessories-Women's Watches",
    "Smart Watches-Women's Watches",
    "Kid's Watches",
    "Boys",
    "Girls",
    "Womens Bags",
    "Cross Body & Shoulder Bags",
    "Top-Handle Bags",
    "Tote Bags",
    "Backpacks",
    "Clutches",
    "Wallets & Accessories",
    "Wristlets",
    "Mens Bags",
    "Wallets & Cardholders",
    "Backpacks",
    "Crossbody Bags",
    "Business Bags",
    "Messenger Bags",
    "Cardholders & Keychains",
    "Luggage & Suitcase",
    "Travel Accessories",
    "Laptop Bags",
    "Luggage",
    "Duffel Bags",
    "Womens Jewellery",
    "Rings",
    "Earrings",
    "Necklaces",
    "Bracelets",
    "Anklets",
    "Jewellery Sets",
    "Mens Jewellery",
    "Rings",
    "Chains",
    "Bracelets",
    "Studs",
    "Pendants",
    "Mens Accessories",
    "Belts",
    "Hats & Caps",
    "Scarves",
    "Socks",
    "Ties & Bow Ties",
    "Gloves",
    "Women's Accessories",
    "Scarves",
    "Belts",
    "Socks & Tights",
    "Hats & Caps",
    "Gloves",
    "Hair Accessories",
    "Sunglasses & Eyewear",
    "Sunglasses",
    "Unisex Sunglasses",
    "Women Eyeglasses",
    "Unisex Eyeglasses",
    "Kids Sunglasses",
    "Women Sunglasses",
    "Men Eyeglasses",
    "Kids Eyeglasses",
    "Lenses",
  ],

  "Automotive & Motorbikes": [
    "Automotive",
    "Car Tires & Wheels",
    "Car Oils & Fluids",
    "Car Interior Accessories",
    "Car Tools & Equipment",
    "Car Parts & Spares",
    "Car Care",
    "Car Exterior Accessories",
    "Car Electronics",
    "Car Safety & Security",
    "Motorcycle",
    "Electric Bikes",
    "Standard Bikes",
    "ATVs & UTVs",
    "Sports Bikes",
    "Riding Gear",
    "Motorcycle Helmets",
    "Motorcycle Tires & Wheels",
    "Motorcycle Parts & Spares",
    "Motorcycle Oil & Fluids",
    "Motorcycle Accessories",
    "Loaders & Rickshaw",
    "Loaders",
    "Auto Rikshaw",
  ],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const pakistanCountryCode = countries.find((c) => c.code === "PK")?.dial_code;

const initialObj = {
  product_name: "",
  description: "",
  category: "",
  price: "",
  keyword: "",
  sub_category: "",
  cost: "",
  discount_price: 0,
  total_stock: "",
  images: [],
  thumbnail_image: "",
  weight: "",
  sellingMethod: "product",
  affiliateProductLink: "",
  phoneNumber: localStorage.getItem("productWhatsappNumber"),
  countryCode: pakistanCountryCode || "",
  whatsappNum: localStorage.getItem("productWhatsappNumber"),
  product_link: true,
};

const Analytics = ({ loading, setLoading }) => {
  const [productObj, setProductObj] = useState(initialObj);
  const [prductDialog, setPrductDialog] = useState(false);
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const theme = useTheme();

  const [prodSold, setProdSold] = useState(0);
  const [succOrders, setSuccOrders] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [shopViews, setShopViews] = useState(0);
  const [imageloading, setImageLoading] = useState(false);
  const [removeImgLoading, setRemoveImgLoading] = useState(false);

  const [error, setError] = useState({
    nameError: false,
    nameText: "Product name already exists",
  });
  // add product dialog on hovred closed
  const [isHovered, setIsHovered] = useState(false);
  const [keywordError, setKeywordError] = useState("");
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  // const handleDrop = async (newFiles) => {
  //   if (!newFiles || newFiles.length === 0) {
  //     return;
  //   }

  //   let formData = new FormData();

  //   newFiles.forEach((file) => {
  //     formData.append('files', file);
  //   });

  //   setImageLoading(true);
  //   const resp = await addProductImage(formData);

  //   setImageLoading(false);

  //   if (resp?.status === 200) {
  //     setProductObj({
  //       ...productObj,
  //       images: [...productObj.images, ...resp.data.imageUrls],
  //       thumbnail_image: resp.data.imageUrls[0],
  //     });
  //   }
  // };

  const handleDrop = async (newFiles) => {
    setImageLoading(true);
    if (!newFiles || newFiles.length === 0) {
      setImageLoading(false);
      return;
    }

    const webpFiles = await convertToWebP(newFiles);

    if (!webpFiles || webpFiles.length === 0) {
      setImageLoading(false);
      return;
    }

    let formData = new FormData();
    webpFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const resp = await addProductImage(formData);

      setImageLoading(false);

      if (resp?.status === 200) {
        setProductObj({
          ...productObj,
          images: [...productObj.images, ...resp.data.imageUrls],
          thumbnail_image: resp.data.imageUrls[0],
        });
      }
    } catch (error) {
      setImageLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    multiple: true,
  });

  const handleClose = async () => {
    // if(productObj.images.length>0){
    //   toast.error("Error: Please remove all images first");
    //   return;
    // }
    setPrductDialog(false);
    try {
      await Promise.all(
        productObj.images.map(async (img) => {
          const urlObject = new URL(img);
          const pathWithoutHost = urlObject.pathname.slice(1);
          await removeProductImage(pathWithoutHost);
        })
      );
      setPrductDialog(false);
      setProductObj((prevState) => ({
        ...prevState,
        images: [],
      }));
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    setProductObj({
      images: [],
      thumbnail_image: "",
      product_name: "",
      description: "",
      category: "",
      sub_category: "",
      price: "",
      keyword: "",
      cost: "",
      discount_price: 0,
      total_stock: "",
      weight: "",
      sellingMethod: "product",
      affiliate_productlink: "",
      phoneNumber: "",
      countryCode: "",
      whatsappNum: "",
      product_link: true,
    });
  }, [prductDialog]);

  const getRandomLetter = (excluded) => {
    const alphabet = "ABCDEFGHIJKLMNPQRSTUVWXYZ"; // Excluding 'O'
    let randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];

    // Ensure the random letter is not in the excluded string
    while (excluded.includes(randomLetter)) {
      randomLetter = alphabet[Math.floor(Math.random() * alphabet.length)];
    }

    return randomLetter;
  };

  const autoGenerateKeyword = (productName) => {
    const parsedData = JSON.parse(localStorage.getItem("businessUserdata"));

    const usernamePrefix = parsedData.name.slice(0, 1).toUpperCase();

    let productPrefix = (
      productName.length >= 2
        ? productName.slice(0, 2)
        : (productName + "X".repeat(2)).slice(0, 2)
    ).toUpperCase();

    productPrefix = productPrefix.replace(/O/g, () =>
      getRandomLetter(productPrefix)
    );

    const alphabet = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
    const selfLetter = alphabet[Math.floor(Math.random() * alphabet.length)];

    // Generate a random two-digit number from 0-9
    // const randomNumber = Math.floor(Math.random() * 15) + 11;

    // gerneate 1 digit number from 0-9
    const randomNumber = Math.floor(Math.random() * 9) + 1;

    // Combine the parts to form the keyword
    // const keyword = `${usernamePrefix}${productPrefix}${selfLetter}${randomNumber
    //   .toString()
    //   .padStart(2, "0")}`;

    const keyword = `${usernamePrefix}${productPrefix}${selfLetter}${randomNumber.toString()}`;

    return keyword;
  };

  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();

  const customKeywordChangeHandler = async (e) => {
    setKeywordError("");

    const keyword = e.target.value.trim().toUpperCase();

    if (keyword.includes(" ")) {
      setKeywordError("Keyword should not contain spaces");
      return;
    }

    if (keyword.length > 10) {
      setKeywordError("Keyword should not be more than 10 characters");
      return;
    }

    setProductObj({ ...productObj, keyword });

    source.cancel("Operation canceled by the user.");

    source = cancelToken.source();

    // Check keyword in the database
    try {
      const resp = await checkKeyword(keyword, source);

      if (resp?.status === 200 && resp?.data?.status === true) {
        setKeywordError("Keyword already exists");
        return;
      }
    } catch (error) {
      return error;
    }
  };

  const handleProductName = (e) => {
    const productName = e.target.value;
    const keyword = autoGenerateKeyword(productName);

    setProductObj((prevState) => ({
      ...prevState,
      product_name: productName,
      keyword: keyword,
    }));
  };

  const handleNameCheck = async (e) => {
    handleProductName(e);
    const resp = await verifyName(productObj?.product_name);

    if (resp?.status !== 200) {
      setError({ ...error, nameError: true });
    } else {
      setError({ ...error, nameError: false });
    }
  };

  const handleChange = (type, event) => {
    setProductObj({
      ...productObj,
      [type]: event.target.value,
    });
  };

  //-------Add Product Handler
  const AddProductHandler = async () => {
    let prodCopy = {
      product_name: productObj.product_name,
      description: productObj.description,
      category: productObj.category,
      sub_category: productObj.sub_category,
      price: productObj.price,
      discount_price: productObj.discount_price,
      weight: productObj.weight,
      total_stock: productObj.total_stock,
      thumbnail_image: productObj.thumbnail_image,
      images: productObj.images,
      keyword: productObj.keyword,
      affiliate_productlink:
        productObj.sellingMethod === "link"
          ? productObj.affiliate_productlink
          : "",
      phoneNumber:
        productObj.sellingMethod === "number" ? productObj.phoneNumber : "",
      countryCode:
        productObj.sellingMethod === "number" ? productObj.countryCode : "",
      added_from: "dashboard",
      product_link: productObj.product_link,
    };

    if (error?.nameError) {
      return;
    }

    if (prodCopy?.images?.length === 0) {
      toast.error("Please add product images.");
      return;
    }

    if (prodCopy?.category?.length === 0) {
      toast.error("Please select a product category.");
      return;
    }

    if (parseInt(prodCopy.weight) === 0 || prodCopy.weight === "") {
      toast.error("Please enter the product weight.");
      return;
    }

    if (
      // prodCopy?.cost === "" ||
      prodCopy?.price === "" ||
      prodCopy?.total_stock === "" ||
      prodCopy?.description === ""
    ) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    if (parseInt(prodCopy.discount_price) > parseInt(prodCopy.price)) {
      toast.error("Discount price cannot be greater than the actual price.");
      return;
    }

    setLoading(true);

    // if (data.length > 0) {
    //   let featuredDataLen = data.filter((prod) => prod.is_Featured).length;
    //   let arrivalDataLen = data.filter((prod) => prod.new_arrival).length;

    //   if (featuredDataLen === 0) {
    //     prodCopy.is_Featured = true;
    //   }

    //   if (arrivalDataLen < 3) {
    //     prodCopy.new_arrival = true;
    //   }
    // } else {
    //   prodCopy.is_Featured = true;
    //   prodCopy.new_arrival = true;
    // }

    prodCopy.is_Active = true;
    localStorage.setItem("productWhatsappNumber", productObj.phoneNumber);

    const resp = await addProduct(prodCopy);

    setLoading(false);

    if (resp?.status === 201) {
      setProductObj(initialObj);
      setPrductDialog(false);
      toast.success("Product created successfully.");
    }
  };

  const getAnalytics = async () => {
    setLoading(true);
    // const storeViews = await getShopViews();

    const analytics = await getShopAnalytics();

    setProdSold(analytics?.data?.soldOrders);
    setRevenue(analytics?.data?.totalRevenue);
    setSuccOrders(analytics?.data?.completedOrders);
    setShopViews(analytics?.data?.viewCounts);

    setLoading(false);
  };

  const handleRemove = async (imagePath) => {
    setRemoveImgLoading(true);

    try {
      const urlObject = new URL(imagePath);
      const pathWithoutHost = urlObject.pathname.slice(1);

      const resp = await removeProductImage(pathWithoutHost);

      if (resp?.status === 200) {
        const updatedImages = productObj.images.filter(
          (url) => url !== imagePath
        );

        setProductObj({
          ...productObj,
          images: updatedImages,
        });
      }
    } catch (error) {
      toast.error(
        "Oops! Something went wrong while removing the image. Please try again later."
      );
    } finally {
      setRemoveImgLoading(false);
    }
  };

  const handleChangeThumbnail = (e) => {
    setProductObj({
      ...productObj,
      thumbnail_image: e.target.value,
    });
  };

  const handleCodeChange = (event) => {
    setProductObj({ ...productObj, countryCode: event.target.value });
  };

  const handleOptionChange = (event) => {
    if (event.target.value === "number") {
      setProductObj({
        ...productObj,
        sellingMethod: event.target.value,
        phoneNumber: "",
        product_link: false,
      });
    } else if (event.target.value === "product") {
      setProductObj({
        ...productObj,
        sellingMethod: event.target.value,
        product_link: true,
      });
    } else {
      setProductObj({
        ...productObj,
        sellingMethod: event.target.value,
        product_link: false,
      });
    }
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: 2,
        flexDirection: { xs: "column", sm: "column" },
        position: "relative",
        overflow: "visible",
      }}
    >
      <Box sx={{ width: { xs: "100%", sm: "100%" }, mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} xsm={3}>
            <Box
              sx={{
                background: "#7F59B0",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: "15px",
                color: "#fff",
                maxHeight: "85px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "16px", xs: "12px" },
                  fontWeight: "400",
                  lineHeight: "1",
                }}
              >
                Store Views
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  {shopViews || 0}
                </Typography>
                <Box
                  sx={{
                    width: { sm: "50px", xs: "40" },
                    height: { sm: "50px", xs: "40px" },
                  }}
                >
                  <img
                    src={require("../../../assets/Img/store.png")}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>

              {/* <Box>
                <Typography sx={{ fontSize: "10px" }}>50 %</Typography>
                <BorderLinearProgress variant="determinate" value={50} />
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={6} xsm={3}>
            <Box
              sx={{
                background: "#63C5EA",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: "15px",
                color: "#fff",
                maxHeight: "85px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "16px", xs: "12px" },
                  fontWeight: "400",
                  lineHeight: "1",
                }}
              >
                Product Sold
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  {prodSold || 0}
                </Typography>
                <Box
                  sx={{
                    width: { sm: "50px", xs: "40" },
                    height: { sm: "50px", xs: "40px" },
                  }}
                >
                  <img
                    src={require("../../../assets/Img/sold.png")}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>

              {/* <Box>
                <Typography sx={{ fontSize: "10px" }}>50 %</Typography>
                <BorderLinearProgress variant="determinate" value={50} />
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={6} xsm={3}>
            <Box
              sx={{
                background: "#EA4B8B",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: "15px",
                color: "#fff",
                maxHeight: "85px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "16px", xs: "12px" },
                  fontWeight: "400",
                  lineHeight: "1",
                }}
              >
                Successful Orders
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  {succOrders || 0}
                </Typography>
                <Box
                  sx={{
                    width: { sm: "50px", xs: "40" },
                    height: { sm: "50px", xs: "40px" },
                  }}
                >
                  <img
                    src={require("../../../assets/Img/prod.png")}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>

              {/* <Box>
                <Typography sx={{ fontSize: "10px" }}>50 %</Typography>
                <BorderLinearProgress variant="determinate" value={50} />
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={6} xsm={3}>
            <Box
              sx={{
                background: "#FCB424",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: "15px",
                color: "#fff",
                maxHeight: "85px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "16px", xs: "12px" },
                  fontWeight: "400",
                  lineHeight: "1",
                }}
              >
                Total Revenue
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "500" }}>
                  {revenue || 0} Rs
                </Typography>
                <Box
                  sx={{
                    width: { sm: "50px", xs: "40" },
                    height: { sm: "50px", xs: "40px" },
                  }}
                >
                  <img
                    src={require("../../../assets/Img/rev.png")}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>

              {/* <Box>
                <Typography sx={{ fontSize: "10px" }}>50 %</Typography>
                <BorderLinearProgress variant="determinate" value={50} />
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ width: "90%", mx: "auto" }} />

      <Box
        sx={{
          width: { xs: "100%", sm: "100%" },
          // background: "#F1F1F1",
          // borderRadius: "15px",
          minHeight: "70px",
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid item xs={6} xsm={3}>
            <Button
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "40px",
                color: "#fff",
                background:
                  "transparent linear-gradient(180deg, #286FCF 0%, #6A84ED 100%) 0% 0% no-repeat padding-box",
              }}
              onClick={() => navigate("/dashboard/upload-product")}
            >
              Add Products
            </Button>
          </Grid>
          <Grid item xs={6} xsm={3}>
            <Button
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "40px",
                color: "#fff",
                background:
                  "transparent linear-gradient(180deg, #286FCF 0%, #6A84ED 100%) 0% 0% no-repeat padding-box",
              }}
              onClick={() => navigate("/dashboard/orders")}
            >
              Orders
            </Button>
          </Grid>
          <Grid item xs={6} xsm={3}>
            <Button
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "40px",
                color: "#fff",
                background:
                  "transparent linear-gradient(180deg, #286FCF 0%, #6A84ED 100%) 0% 0% no-repeat padding-box",
              }}
              onClick={() => navigate("/dashboard/products")}
            >
              Products
            </Button>
          </Grid>
          <Grid item xs={6} xsm={3}>
            <Button
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "40px",
                color: "#fff",
                background:
                  "transparent linear-gradient(180deg, #286FCF 0%, #6A84ED 100%) 0% 0% no-repeat padding-box",
              }}
              onClick={() => navigate("/dashboard/settings")}
            >
              Settings
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={prductDialog}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: {
                xs: "290px",
                xsPlus: "330px",
                sm: "500px",
                md: "400px",
                lg: "500px",
                br500: "430px",
              }, // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#fff",
            position: "relative",
            padding: "25px",
            // width: "800px",
            overflow: "visible",
            p: { xs: "30px", md: "30px", lg: "30px" },
          }}
        >
          {/* top */}
          <Box
            sx={{
              pb: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">Add Product</Typography>
          </Box>
          <Box
            sx={{
              display: "flex !important",
              width: "100%",
              height: "84px",
              mb: "20px",
              gap: 1,
              // pt:"12px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "8px",
                background: "#F0F0F0",
                width: "80px",
                height: "80px",
                border: "2px dashed gray",
                justifyContent: "center",
                ":hover": { cursor: "pointer" },
                opacity: isDragActive && "0.5",
              }}
              {...getRootProps()}
              multiple={false}
            >
              <input
                {...getInputProps()}
                multiple={true}
                disabled={imageloading}
              />
              <Box
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  width: "fit-content",
                  mx: "auto",
                  color: "gray",
                  my: "auto",
                  ":hover": { cursor: "pointer" },
                }}
              >
                {imageloading ? (
                  <CircularProgress />
                ) : (
                  <AddAPhotoOutlinedIcon sx={{ fontSize: "35px" }} />
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                width: "calc(100% - 92px)",
                overflowX: "auto",
                height: "90px",
              }}
              className="scrollable-content"
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                {productObj.images.map((image, idx) => (
                  <Box
                    sx={{
                      width: "80px",
                      height: "80px",
                      position: "relative",
                    }}
                    key={idx}
                  >
                    <img
                      src={image}
                      alt="imgprev"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "8px",
                        zIndex: 3,
                      }}
                    />

                    {!removeImgLoading && (
                      <Tooltip title="Remove">
                        <Button
                          sx={{
                            position: "absolute",
                            p: "0",
                            m: "0",
                            minWidth: "0",
                            top: "6px",
                            left: "1px",
                            color: "#f1807e",
                            background: "#fff",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            borderRadius: "50%",
                          }}
                          onClick={() => handleRemove(image)}
                        >
                          <DeleteRoundedIcon sx={{ p: "0", m: "0" }} />
                        </Button>
                      </Tooltip>
                    )}

                    <Tooltip title="Set Thumbnail">
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "6px",
                          right: "1px",
                        }}
                      >
                        <Radio
                          checked={productObj.thumbnail_image === image}
                          onChange={handleChangeThumbnail}
                          value={image}
                          sx={{
                            background: "#fff",
                            p: "0",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                          }}
                          size="small"
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
                color: "black",
              }}
            >
              <TextField
                label="Product Name"
                id="outlined-size-small"
                size="small"
                sx={{}}
                onBlur={handleProductName}
                value={productObj.product_name}
                onChange={(e) =>
                  setProductObj((prevState) => ({
                    ...prevState,
                    product_name: e.target.value,
                  }))
                }
                error={error?.nameError}
                helperText={error?.nameError ? error?.nameText : ""}
              />

              <CKEditor
                editor={ClassicEditor}
                data={productObj.description}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "|",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "fontColor",
                    "fontBackgroundColor",
                    "|", // Added fontColor and fontBackgroundColor buttons
                    "fontSize",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "link",
                    "|",
                    "undo",
                    "redo",
                    "|",
                  ],
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setProductObj({
                    ...productObj,
                    description: data,
                  });
                }}
              />

              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="demo-multiple-chip-label">Category</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  // multiple
                  value={productObj?.category}
                  onChange={(e) => handleChange("category", e)}
                  sx={{ width: "100%" }}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Category" />
                  }
                  renderValue={(value) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      <Chip key={value} label={value} />
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(categoryList).map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, productObj?.category, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="demo-multiple-chip-label">
                  Sub Category
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  // multiple
                  value={productObj?.sub_category}
                  onChange={(e) => handleChange("sub_category", e)}
                  sx={{ width: "100%" }}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Category" />
                  }
                  renderValue={(value) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      <Chip key={value} label={value} />
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {productObj?.category &&
                    categoryList[productObj?.category].map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, productObj?.category, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextField
                  label="Price"
                  id="outlined-size-small"
                  type="number"
                  size="small"
                  value={productObj?.price}
                  onChange={(e) =>
                    setProductObj({ ...productObj, price: e.target.value })
                  }
                />

                <TextField
                  label="Discount Price"
                  id="outlined-size-small"
                  size="small"
                  value={productObj?.discount_price}
                  onChange={(e) =>
                    setProductObj({
                      ...productObj,
                      discount_price: e.target.value,
                    })
                  }
                />
              </Box>

              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextField
                  label="Total Stock"
                  id="outlined-size-small"
                  type="number"
                  size="small"
                  value={productObj.total_stock}
                  onChange={(e) =>
                    setProductObj({
                      ...productObj,
                      total_stock: e.target.value,
                    })
                  }
                  inputProps={{ min: 0 }}
                />

                <TextField
                  label="Weight (Grams)"
                  id="outlined-size-small"
                  type="number"
                  size="small"
                  value={productObj?.weight}
                  onChange={(e) =>
                    setProductObj({ ...productObj, weight: e.target.value })
                  }
                  inputProps={{ min: 0 }}
                />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <TextField
                  // label="Product Keyword"
                  placeholder="Product Keyword"
                  id="outlined-size-small"
                  size="small"
                  sx={{ width: "100%" }}
                  value={productObj?.keyword}
                  onChange={customKeywordChangeHandler}
                />
                {keywordError && (
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {keywordError}
                  </Typography>
                )}
              </Box>

              <Box sx={{ mb: "20px" }}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <Typography sx={{ fontSize: "12px", color: "#959595" }}>
                      Choose your prefered selling method for your product:
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ justifyContent: "center", display: "flex" }}
                    value={productObj.sellingMethod}
                    onChange={handleOptionChange}
                  >
                    <FormControlLabel
                      value="product"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#959595",
                            fontWeight:
                              productObj.sellingMethod === "product" && "500",
                          }}
                        >
                          Product link
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      value="link"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#959595",
                            fontWeight:
                              productObj.sellingMethod === "link" && "500",
                          }}
                        >
                          Custom link
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="number"
                      control={<Radio size="small" />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#959595",
                            fontWeight:
                              productObj.sellingMethod === "number" && "500",
                          }}
                        >
                          Whatsapp
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>

                {productObj.sellingMethod === "link" && (
                  <TextField
                    label="Custom Link"
                    id="outlined-size-small"
                    size="small"
                    sx={{ width: "100%" }}
                    value={productObj?.affiliate_productlink}
                    onChange={(event) =>
                      setProductObj({
                        ...productObj,
                        affiliate_productlink: event.target.value,
                      })
                    }
                  />
                )}

                {productObj.sellingMethod === "number" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5px",
                      background: "#fff",
                      border: "1px solid gray",
                      height: "40px",
                      overflow: "hidden",
                    }}
                  >
                    <Select
                      labelId="phone-code-select-label"
                      id="phone-code-select"
                      value={productObj.countryCode}
                      onChange={handleCodeChange}
                      disableUnderline
                      label="Phone Code"
                      sx={{
                        width: "110px",
                        pl: "10px",
                        pt: "5px",
                      }}
                      variant="standard"
                      size="small"
                    >
                      {countries.map((c) => (
                        <MenuItem
                          key={c.code}
                          value={c.dial_code}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            className={`fi fi-${c.code.toLocaleLowerCase()}`}
                          ></span>{" "}
                          {c.dial_code}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      size="small"
                      label="Contact No."
                      type={"number"}
                      value={productObj?.phoneNumber}
                      onChange={(event) => {
                        setProductObj({
                          ...productObj,
                          phoneNumber: event.target.value,
                        });
                      }}
                      placeholder="3456789100"
                      sx={{
                        width: "100%",
                      }}
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                    />
                  </Box>
                )}
              </Box>
            </Box>

            <Button
              sx={{
                background: "#272727",
                ":hover": { background: "#272727" },
                borderRadius: "40px",
                // mb: "15px",
                width: "100%",
                color: "#fff",
                fontWeight: "bold",
              }}
              onClick={AddProductHandler}
              disabled={removeImgLoading || imageloading || keywordError}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <>Save</>
              )}
            </Button>
          </Box>

          <Button
            style={{
              position: "absolute",
              color: isHovered ? "white" : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: "fit-content",
              borderRadius: "50%",
              transition: "background-color 0.3s ease-in-out",
              backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
            }}
            onClick={handleClose}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Analytics;
