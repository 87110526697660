import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Button,
  Typography,
  DialogContent,
  Tabs,
  Tab,
  Grid,
  CircularProgress,
  TextField,
  Radio,
  Tooltip,
} from "@mui/material";
import _, { set } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { toast } from "../Toast";
import { convertToWebP } from "../../utils/image_copmression";
import { addProductImage, removeProductImage } from "../../api/ProductApis";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const EditVariantDialog = ({
  editDialog,
  handleCloseDialog,
  editRow,
  editIndex,
  setEditIndex,
  productObj,
  setProductObj,
  variants,
  setVariants,
  callFrom,
  setcallFrom,
  setEditRow,
  setEditVariantsMoods,
  editVariantsMoods,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [optionForm, setOptionForm] = useState({
    type: "",
    sku: "",
    v_price: 0,
    discounted_price: 0,
    v_weight: 0,
    v_total_stock: 0,
    v_image: "",
  });
  const [imageLoading, setImageLoading] = useState(false);
  const [removeImgLoading, setRemoveImgLoading] = useState(false);
  useEffect(() => {
    if (editRow && editDialog) {
      setOptionForm({
        type: editRow?.type,
        sku: editRow?.sku,
        v_price: editRow?.v_price,
        discounted_price: editRow?.discounted_price,
        v_weight: editRow?.v_weight,
        v_total_stock: editRow?.v_total_stock,
        v_image: editRow?.v_image,
      });
    }

    const tempProductObj = { ...productObj };
    const tempVariants = tempProductObj?.variants[0]?.options;

    if (tempVariants) {
      tempVariants.forEach((item) => {
        if (editRow?._id === item?._id || editRow?.index === item?.index) {
          if (!item?.groups || item?.groups?.length === 0) {
            setcallFrom("child");
          }
        }
      });
    }
  }, [editRow, editDialog]);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleEditOption = () => {
    if (
      !optionForm?.type ||
      !optionForm?.sku ||
      !optionForm?.v_price ||
      !optionForm?.v_weight
    ) {
      toast.error("Please make sure to fill in all the required fields");
      return;
    }
    if (
      optionForm?.v_price < 0 ||
      optionForm?.discounted_price < 0 ||
      optionForm?.v_weight < 0
    ) {
      toast.error("Please enter valid values");
      return;
    }
    if (optionForm?.discounted_price > optionForm?.v_price) {
      toast.error("Discounted price should be less than price");
      return;
    }

    if (editRow?.index) {
      const tempProductObj = { ...productObj };
      const tempVariants = tempProductObj?.variants[0]?.options;
      let temp = optionForm?.v_total_stock;

      let prevStock = 0;
      if (tempVariants) {
        tempVariants?.forEach((item, index) => {
          item?.groups?.forEach((subItem, subIndex) => {
            if (editRow.index === subItem?.index) {
              prevStock = subItem?.v_total_stock;
              // Modify subItem
              Object.assign(subItem, {
                type: optionForm?.type,
                sku: optionForm?.sku,
                v_price: optionForm?.v_price,
                discounted_price: optionForm?.discounted_price,
                v_weight: optionForm?.v_weight,
                v_total_stock: optionForm?.v_total_stock,
                v_image: optionForm?.v_image,
              });
              // update total stock of that group parent
              item.v_total_stock =
                parseInt(item.v_total_stock) -
                prevStock +
                parseInt(optionForm?.v_total_stock);
            }
          });

          if (editRow.index === item?.index) {
            // Modify item
            Object.assign(item, {
              type: optionForm?.type,
              sku: optionForm?.sku,
              v_price: optionForm?.v_price,
              discounted_price: optionForm?.discounted_price,
              v_weight: optionForm?.v_weight,
              v_total_stock: optionForm?.v_total_stock,
              v_image: optionForm?.v_image,
            });
          }
        });

        setProductObj(tempProductObj);
        setEditIndex(null);
        handleCloseDialog();
      }
    } else {
      const tempProductObj = { ...productObj };
      const tempVariants = tempProductObj?.variants[0]?.options;
      let prevStock = 0;
      if (tempVariants) {
        tempVariants?.forEach((item, index) => {
          item?.groups?.forEach((subItem, subIndex) => {
            if (editRow?._id === subItem?._id) {
              prevStock = subItem?.v_total_stock;
              // Modify subItem
              Object.assign(subItem, {
                type: optionForm?.type,
                sku: optionForm?.sku,
                v_price: optionForm?.v_price,
                discounted_price: optionForm?.discounted_price,
                v_weight: optionForm?.v_weight,
                v_total_stock: optionForm?.v_total_stock,
                v_image: optionForm?.v_image,
              });
              // update total stock of that group parent
              item.v_total_stock =
                parseInt(item.v_total_stock) -
                prevStock +
                parseInt(optionForm?.v_total_stock);
            }
          });

          if (editRow?._id === item?._id) {
            // Modify item
            Object.assign(item, {
              type: optionForm?.type,
              sku: optionForm?.sku,
              v_price: optionForm?.v_price,
              discounted_price: optionForm?.discounted_price,
              v_weight: optionForm?.v_weight,
              v_total_stock: optionForm?.v_total_stock,
              v_image: optionForm?.v_image,
            });
          }
        });
        // Initialize temp to 0
        let temp = 0;

        // Update total stock of product
        tempProductObj?.variants[0]?.options.forEach((item) => {
          temp += parseInt(item?.v_total_stock) || 0; // Use 0 if item.v_total_stock is undefined
        });

        // Assign the sum to the v_total_stock of the first variant
        if (tempProductObj?.variants[0]) {
          tempProductObj.total_stock = temp;
        }

        setProductObj(tempProductObj);
        setEditIndex(null);
        handleCloseDialog();
      }
    }
  };

  const handleChange = (e) => {
    setOptionForm({
      ...optionForm,
      [e.target.name]: e.target.value,
    });
    setEditVariantsMoods({
      ...editVariantsMoods,
      is_edit_variant_dialog: false,
    });
  };
  const handleImageCapture = async (event) => {
    setImageLoading(true);

    const file = event.target.files[0];
    // make array of files

    if (file) {
      const webpFiles = await convertToWebP([file]);
      if (!webpFiles || webpFiles.length === 0) {
        setImageLoading(false);
        return;
      }
      let formData = new FormData();
      formData.append("files", webpFiles[0]);
      try {
        let resp = await addProductImage(formData);
        if (resp) {
          setOptionForm({
            ...optionForm,
            v_image: resp?.data?.imageUrls[0],
          });
          setImageLoading(false);
          // also assign the parent image to the their child
          if (callFrom === "parent") {
            const tempProductObj = { ...productObj };
            const tempVariants = tempProductObj?.variants[0]?.options;
            tempVariants?.forEach((item, index) => {
              item?.groups?.forEach((subItem, subIndex) => {
                if (editRow?._id === subItem?._id) {
                  //   assign the parent image to their all childs
                  Object.assign(subItem, {
                    v_image: resp?.data?.imageUrls[0],
                  });
                }
              });
              if (editRow?._id === item?._id) {
                // assign the parent image to their all childs
                item?.groups?.forEach((subItem, subIndex) => {
                  Object.assign(subItem, {
                    v_image: resp?.data?.imageUrls[0],
                  });
                });
              }
            });
            setProductObj((pre) => ({ ...pre, ...tempProductObj }));
          }
        }
        setEditVariantsMoods({
          ...editVariantsMoods,
          is_edit_variant_dialog: false,
        });
      } catch (error) {
        setImageLoading(false);
      }
    }
  };
  const handleRemove = async (imagePath) => {
    setRemoveImgLoading(true);

    try {
      const urlObject = new URL(imagePath);
      const pathWithoutHost = urlObject.pathname.slice(1);

      const resp = await removeProductImage(pathWithoutHost);

      if (resp?.status === 200) {
        const updatedImages = productObj.images.filter(
          (url) => url !== imagePath
        );
        setOptionForm((pre) => ({
          ...pre,
          v_image: updatedImages,
        }));
      }
    } catch (error) {
      toast.error("Oops! Something went wrong while removing the image. Please try again later.");
    } finally {
      setRemoveImgLoading(false);
    }
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={editDialog}
      scroll={"body"}
      fullWidth={true}
      maxWidth={"sm"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: {
              xs: "300px",
              xsPlus: "330px",
              sm: "500px",
              md: "800px",
              lg: "800px",
              br500: "430px",
            }, // Set your width here
          },
        },
      }}
    >
      <DialogContent
        sx={{
          background: "#FFFFFF",
          color: "black",
          position: "relative",
          overflow: "visible",
          p: { xs: "15px", md: "30px", lg: "30px" },
          margin: { sm: "0 24px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: "12px", md: "20px" },

              pb: "4px",
              fontWeight: "500",
              pt: 2,
            }}
          >
            Edit Variant
          </Typography>
        </Box>

        {/* <Box sx={{ display: 'flex', justifyContent: 'end', my: 1 }}>
                    {/* Display circular progress only when loading is true */}
        {/* </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 1,
            mt: 1,
            ml: 1,
            width: "100%",
          }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleImageCapture}
            style={{ display: "none" }}
            id="imageUploadInput"
          />
          <label htmlFor="imageUploadInput">
            <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
                width: "60px",
                height: "60px",
                border: "1px dashed #494949",
                opacity: 0.5,
              }}
              component="span" // Make the button behave like a regular file input button
            >
              {imageLoading ? (
                <CircularProgress size={20} />
              ) : (
                <CameraAltIcon />
              )}
            </Button>
          </label>
          {/* display */}
          {optionForm?.v_image && (
            <Box
              sx={{
                width: "auto",
                height: "auto",
                position: "relative",
                height: "60px",
                width: "60px",
              }}
            >
              <img
                src={optionForm?.v_image}
                alt="imgprev"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "8px",
                  zIndex: 3,
                }}
              />

              {/* {!removeImgLoading && (
                              <Tooltip title="Remove">
                                <Button
                                  sx={{
                                    position: "absolute",
                                    p: "0",
                                    m: "0",
                                    minWidth: "0",
                                    top: "6px",
                                    left: "1px",
                                    color: "#f1807e",
                                    background: "#fff",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                                    borderRadius: "50%",
                                  }}
                                  onClick={() => handleRemove(optionForm?.v_image)}
                                >
                                  <DeleteRoundedIcon sx={{ p: "0", m: "0" }} />
                                </Button>
                              </Tooltip>
                            )} */}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Enter Type"
              disabled
              value={optionForm?.type}
              name="type"
              onChange={handleChange}
              sx={{ mt: 1 }}
              label="Option"
            />

            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="SKU"
              disabled
              value={optionForm?.sku}
              name="sku"
              onChange={handleChange}
              sx={{ mt: 1 }}
              label="SKU"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Price"
              value={optionForm?.v_price}
              onChange={handleChange}
              name="v_price"
              sx={{ mt: 1 }}
              label="Price"
            />
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Quantity"
              value={optionForm?.discounted_price}
              onChange={handleChange}
              name="discounted_price"
              sx={{ mt: 1 }}
              label="Discount Price"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Weight"
              value={optionForm?.v_weight}
              name="v_weight"
              onChange={handleChange}
              sx={{ mt: 1 }}
              label="Weight"
            />
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Quantity"
              value={optionForm?.v_total_stock}
              onChange={handleChange}
              name="v_total_stock"
              disabled={callFrom === "parent" ? true : false}
              sx={{ mt: 1 }}
              label="Total Stock"
            />
          </Box>
          {/* save button */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "20px",
                fontSize: "12px",
                textTransform: "none",
              }}
              onClick={handleEditOption}
              disabled={editVariantsMoods?.is_edit_variant_dialog}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Button
          style={{
            position: "absolute",
            color: "black",
            top: 6,
            right: 8,
            padding: 1,
            minWidth: "fit-content",
            borderRadius: "50%",
            transition: "background-color 0.3s ease-in-out",
            backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "transparent",
          }}
          onClick={() => {
            setcallFrom("");
            handleCloseDialog();
            setEditRow(null);
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <CloseIcon />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditVariantDialog;
