import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "../Toast";
import { convertToWebP } from "../../utils/image_copmression";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";

import {
  addStreamThumbnail,
  deleteStream,
  getStreamDetail,
  removeStreamThumbnail,
  UpdateStreamDetail,
} from "../../api/StreamApis";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import SmartDisplayRoundedIcon from "@mui/icons-material/SmartDisplayRounded";
import TvRoundedIcon from "@mui/icons-material/TvRounded";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import ShareSharpIcon from "@mui/icons-material/ShareSharp";
import { useNavigate, useParams } from "react-router-dom";
import { categoryList } from "../../utils/ProductCategories";
import { AuthContext } from "../../context/AuthContext";

const LiveStreamDetails = () => {
  const [deleteStreamModal, setDeleteStreamModal] = useState(false);
  const [imageloading, setImageLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [isEditing, setEditing] = useState(false);
  const [streamDetail, setStreamDetail] = useState({});
  const [salesPerformance, setSalesPerformance] = useState({});
  const [viewShipMAtrics, setViewShipMatrics] = useState({});
  const [duration, setDuration] = useState("");
  const [streamCategory, setStreamCategory] = useState("");
  const [description, setDescription] = useState("");
  const [streamTitle, setStreamTitle] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const { userData } = useContext(AuthContext);
  const { roomid } = useParams();
  const navigate = useNavigate();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleEditClick = () => {
    setEditing(true);
  };

  const handleShareClick = () => {
    // Check if the user is on a mobile device
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const message = `Check out My Store: ${streamDetail?.short_url}`;

    if (isMobile) {
      // If on a mobile device, open the WhatsApp app
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        message
      )}`;
    } else {
      // If on desktop, open WhatsApp Web
      window.open("https://web.whatsapp.com/");
    }
  };

  const handleRemove = async (imagePath) => {
    try {
      const urlObject = new URL(imagePath);
      const pathWithoutHost = urlObject.pathname.slice(1);

      const resp = await removeStreamThumbnail(pathWithoutHost);

      if (resp?.status === 200) {
        setThumbnail("");
      }
    } catch (error) {
      toast.error(
        "Oops! Something went wrong while removing the image. Please try again later."
      );
    }
  };
  const handleDrop = async (newFiles) => {
    setImageLoading(true);
    if (!newFiles || newFiles.length === 0) {
      return;
    }

    if (thumbnail != "") {
      await handleRemove(thumbnail);
    }

    const webpFiles = await convertToWebP(newFiles);

    if (!webpFiles || webpFiles.length === 0) {
      return;
    }

    let _formData = new FormData();

    webpFiles.forEach((file) => {
      _formData.append("files", file);
    });

    try {
      const resp = await addStreamThumbnail(_formData);

      if (resp?.status === 200) {
        setThumbnail(resp?.data?.imageUrls[0]);
        setEditing(true);
      }
    } catch (error) {
      return error;
    } finally {
      setImageLoading(false);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
  });
  // delete stream function
  const handleDelete = async () => {
    const response = await deleteStream(roomid);

    if (response?.status === 201) {
      toast.success("Stream has been deleted successfully.");
      setEditing(false);
      setDeleteStreamModal(false);
      navigate("/broadcast/live-streams");
    }
  };
  const handleDeleteStreamModal = () => {
    setDeleteStreamModal(!deleteStreamModal);
  };
  const fetchCardDetail = async (roomid) => {
    const response = await getStreamDetail(roomid);
    if (response?.status === 200) {
      setDuration(response?.data?.duration);
      setStreamDetail(response?.data?.stream);
      setSalesPerformance(response?.data?.salesPerformance);
      setViewShipMatrics(response?.data?.viewshipMatrics);
    }
  };

  const handleSave = async () => {
    const data = {
      title: streamTitle,
      description: description,
      thumbnail: thumbnail ? thumbnail : streamDetail?.thumbnail,
      category: streamCategory !== "" ? streamCategory : streamDetail?.category,
    };
    const response = await UpdateStreamDetail(roomid, data);
    if (response?.status === 201) {
      toast.success("Stream has been updated successfully.");
      fetchCardDetail(roomid);
      setThumbnail("");
    }
    setEditing(false);
  };

  useEffect(() => {
    setDescription(streamDetail?.description);
    setStreamTitle(streamDetail?.title);
  }, [streamDetail]);

  const maxLength = 15;

  const textareaStyle = {
    width: "100%",
    fontFamily: "Poppins, sans-serif",
    borderColor: "#F0F0F1",
    outline: "none",
    transition: "border-color 0.3s",
  };

  useEffect(() => {
    if (roomid) {
      fetchCardDetail(roomid);
    }
  }, [roomid]);
  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 70px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#646669",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#FDC532",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#fff",
          borderRadius: "50px",
        },
      }}
      className="scrollable-content"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: "10px",
          py: "18px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Live Stream Details
        </Typography>
      </Box>
      {/* seprator */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#00000029",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          mb: { xs: 6, md: 0 },
          //  p: {xs:"10px",md:"0px"},
        }}
      >
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item xs={12} sm={12} xmd={6} lg={6} my={2}>
            <Card
              sx={{
                position: "relative",
                width: { xs: "auto", md: 345 },
                backgroundColor: "#fff",
                boxShadow: "none",
              }}
            >
              <CardMedia
                sx={{
                  height: 230,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
                component="img"
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  maxHeight: "300px",
                }}
                src={
                  thumbnail !== ""
                    ? thumbnail
                    : streamDetail?.thumbnail
                    ? streamDetail?.thumbnail
                    : require("../../assets/Img/t1.png")
                }
                alt="thumbnail"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: "8px",
                  display: "block",
                }}
              >
                {thumbnail === "" ? (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      transform: "none",
                      fontSize: "12px",
                      ":hover": {
                        background: "black",
                        color: "white",
                      },
                    }}
                    {...getRootProps()}
                    multiple={false}
                  >
                    <input
                      {...getInputProps()}
                      multiple={false}
                      disabled={imageloading}
                    />

                    {imageloading ? (
                      <CircularProgress sx={{ fontSize: "10px" }} />
                    ) : (
                      <React.Fragment>
                        <span
                          style={{ display: "block", visibility: "visible" }}
                        >
                          Change Thumbnail
                        </span>
                        <span style={{ display: "none", visibility: "hidden" }}>
                          Hidden Text
                        </span>
                      </React.Fragment>
                    )}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      transform: "none",
                      fontSize: "12px",
                      ":hover": {
                        background: "black",
                        color: "white",
                      },
                    }}
                    onClick={() => handleRemove(thumbnail)}
                  >
                    Delete Thumbnail
                  </Button>
                )}
              </Box>

              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      {isEditing ? (
                        <textarea
                          style={textareaStyle}
                          rows={1}
                          value={streamTitle}
                          onChange={(e) => setStreamTitle(e.target.value)}
                        />
                      ) : (
                        streamTitle
                      )}
                    </Typography>
                    {isEditing ? (
                      <>
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              backgroundColor: "#F9B327",
                              color: "white",
                              borderRadius: "5px",
                              transform: "none",
                              fontSize: "12px",
                              mr: "4px",
                              ":hover": {
                                background: "transparent",
                                color: "#525252",
                                border: "1px solid #525252",
                              },
                            }}
                            onClick={handleSave}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              backgroundColor: "transparent",
                              borderRadius: "5px",

                              color: "#F9B327",
                              transform: "none",
                              fontSize: "12px",
                              ":hover": {
                                background: "transparent",
                              },
                            }}
                            onClick={() => setEditing(false)}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <ModeEditRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleEditClick()}
                      />
                    )}
                  </Box>

                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "13px",
                      my: 1,
                    }}
                  >
                    {isEditing ? (
                      <textarea
                        style={textareaStyle}
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    ) : (
                      description
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    mx: { xs: "3px" },
                    pb: 1,
                    width: "100%",
                    height: "auto",
                    overflowX: "auto",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                      height: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#808080",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: (theme) =>
                        theme.palette.background.paper,
                    },
                  }}
                >
                  <Tooltip title={streamDetail?.category}>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{
                        backgroundColor: "#525252",
                        color: "white",
                        textTransform: "none",
                        fontSize: { xs: "9px", sm: "11px" },
                        whiteSpace: "nowrap",
                        marginRight: 1,
                        borderRadius: "25px",
                        padding: "5px 10px",
                        minWidth: "auto",
                        flexShrink: 0,
                        "&:hover": {
                          backgroundColor: "#525252",
                        },
                      }}
                    >
                      {streamDetail?.category &&
                      streamDetail?.category.length > maxLength
                        ? `${streamDetail?.category.substring(0, maxLength)}...`
                        : streamDetail?.category || "Others"}
                    </Button>
                  </Tooltip>
                </Box>
                {isEditing ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mb: "8px",
                    }}
                  >
                    <FormControl sx={{ minWidth: 200 }} size="small">
                      <InputLabel id="demo-multiple-chip-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        // multiple

                        onChange={(e) => setStreamCategory(e.target.value)}
                        sx={{ width: "100%" }}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Category"
                          />
                        }
                        renderValue={(value) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            <Chip key={value} label={value} />
                          </Box>
                        )}
                        // MenuProps={MenuProps}
                      >
                        {Object.keys(categoryList).map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            // style={getStyles(name, formData?.category, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  ""
                )}
                {isEditing ? (
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        justifyContent: "flex-end",
                      },
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#F9B327",
                        color: "white",
                        borderRadius: "5px",
                        transform: "none",
                        fontSize: "12px",
                        mr: "4px",
                        ":hover": {
                          background: "black",
                        },
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        backgroundColor: "transparent",
                        borderRadius: "5px",
                        color: "#F9B327",
                        transform: "none",
                        fontSize: "12px",
                        ":hover": {
                          background: "transparent",
                        },
                      }}
                      onClick={() => setEditing(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "11px",
                      color: "#272727",
                      display: "flex",
                      alignItems: "center",
                      mr: 2,
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() =>
                      (window.location.href = `/live/${streamDetail?.room}/${userData?.user?._id}`)
                    }
                  >
                    <PlayCircleRoundedIcon
                      sx={{ color: "#4DA2F8", mr: "4px" }}
                    />{" "}
                    Watch This Stream
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: "#E5E5E5",
                      color: "#0F0F0F",
                      transform: "none",
                      borderRadius: "5px",
                      fontSize: "12px",
                      ":hover": {
                        background: "#E5E5E5",
                      },
                    }}
                    onClick={handleShareClick}
                  >
                    <ShareSharpIcon sx={{ fontSize: "20px" }} /> Share
                  </Button>
                </Box>
                <Box
                  sx={{
                    color: "#272727",
                    fontSize: "11px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={handleDeleteStreamModal}
                >
                  <DeleteIcon />
                  <Typography
                    sx={{
                      color: "#272727",
                      fontSize: "11px",
                    }}
                  >
                    Delete
                  </Typography>
                </Box>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} xmd={6} lg={6} my={2}>
            <Card
              sx={{
                position: "relative",
                width: { xs: "auto", md: 345 },
                backgroundColor: "#fff",
                boxShadow: "none",
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    mb: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#939393",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "18.4px",
                      wordSpacing: "0",
                    }}
                  >
                    Date
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "18.4px",
                      wordSpacing: "0",
                    }}
                  >
                    {moment(streamDetail?.createdAt).format(
                      "YYYY-MM-DD hh:mm:ss A"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    mb: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#939393",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "18.4px",
                      wordSpacing: "0",
                    }}
                  >
                    Duration
                  </Typography>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "18.4px",
                      wordSpacing: "0",
                    }}
                  >
                    {duration}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    mb: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#939393",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "18.4px",
                      wordSpacing: "0",
                    }}
                  >
                    Viewership Metrics:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: 1,
                      borderColor: "#F0F0F1",
                      pb: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      Viewers
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      {viewShipMAtrics?.viewers}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: 1,
                      borderColor: "#F0F0F1",
                      pb: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      Comments
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      {viewShipMAtrics?.comments}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: 1,
                      borderColor: "#F0F0F1",
                      pb: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      Likes
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      {viewShipMAtrics?.likes}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: 1,
                      borderColor: "#F0F0F1",
                      pb: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      Shares
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      {viewShipMAtrics?.shares}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    mb: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#939393",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "18.4px",
                      wordSpacing: "0",
                    }}
                  >
                    Streaming Platforms:
                  </Typography>
                  <Box sx={{ mt: "2px" }}>
                    <FacebookRoundedIcon sx={{ mr: 1, cursor: "pointer" }} />
                    <SmartDisplayRoundedIcon
                      sx={{ mr: 1, cursor: "pointer" }}
                    />
                    <TvRoundedIcon sx={{ mr: 1, cursor: "pointer" }} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    mb: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#939393",
                      fontSize: "13px",
                      fontWeight: "400",
                      lineHeight: "18.4px",
                      wordSpacing: "0",
                    }}
                  >
                    Sale Performance:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: 1,
                      borderColor: "#F0F0F1",
                      pb: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      Items Sold
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      {salesPerformance?.itemSold}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: 1,
                      borderColor: "#F0F0F1",
                      pb: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      Revenue Generated
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      {salesPerformance?.revenueGenerated}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: 1,
                      borderColor: "#F0F0F1",
                      pb: "2px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      Conversion Rate
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: "18.4px",
                        wordSpacing: "0",
                      }}
                    >
                      {salesPerformance?.conversionRate}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "#525252",
                    color: "white",
                    borderRadius: "5px",
                    ":hover": {
                      background: "#525252",
                    },
                  }}
                  onClick={() => {
                    setEditing(false);
                    navigate("/broadcast/live-streams");
                  }}
                >
                  <KeyboardBackspaceIcon sx={{ color: "white" }} />
                  Back
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {/* delete warning popup */}
        <Dialog
          open={deleteStreamModal}
          onClose={handleDeleteStreamModal}
          PaperProps={{
            style: { borderRadius: "5px" },
          }}
          scroll={"body"}
          fullWidth={true}
          maxWidth={"sm"}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: {
                  xs: "290px",
                  xsPlus: "330px",
                  sm: "500px",
                  md: "800px",
                  lg: "500px",
                  br500: "430px",
                }, // Set your width here
              },
            },
          }}
        >
          <DialogContent
            sx={{
              background: "#fff",
              color: "#fff",
              position: "relative",
              padding: "25px",
              // width: "800px",
              overflow: "visible",
              p: { xs: "30px", md: "30px", lg: "30px" },
            }}
          >
            <Button
              style={{
                position: "absolute",
                color: isHovered ? "white" : "black",
                top: 6,
                right: 8,
                padding: 1,
                minWidth: "fit-content",
                borderRadius: "50%",
                transition: "background-color 0.3s ease-in-out",
                backgroundColor: isHovered
                  ? "rgba(0, 0, 0, 0.5)"
                  : "transparent",
              }}
              onClick={handleDeleteStreamModal}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <CloseIcon />
            </Button>
            <Box>
              <Typography
                sx={{
                  color: "black",
                  fontSize: { xs: "20px", sm: "22px" },
                  fontWeight: "500",
                }}
              >
                Do you want to delete this stream?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  py: 2,
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    transform: "none",
                    borderRadius: "5px",
                    fontSize: "12px",
                    // mr: "4px",
                    ":hover": {
                      background: "transparent",
                      border: "1px solid red",
                      color: "red",
                    },
                  }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "5px",

                    color: "#F9B327",
                    transform: "none",
                    fontSize: "12px",
                    ":hover": {
                      background: "transparent",
                      border: "1px solid #F9B327",
                      color: "#F9B327",
                    },
                  }}
                  onClick={handleDeleteStreamModal}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default LiveStreamDetails;
