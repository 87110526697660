import { Tooltip, IconButton, Badge } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";

const PostsAllFilters = () => {
  return (
    <Tooltip title="Filter">
      <IconButton>
        <Badge color="primary" badgeContent={3}>
          <TuneIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default PostsAllFilters;
