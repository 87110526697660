export const dummy_categories = [
    {
        id:1,
        name:"Shoes",
        image:require('../assets/Svg/mainLanding/categories-imags/shoes.png')
    },
    {
        id:2,
        name:"Watches",
        image:require('../assets/Svg/mainLanding/categories-imags/pngfind.com-apple-watch-png-99752.png')
    },
    {
        id:3,
        name:"Mobiles",
        image:require('../assets/Svg/mainLanding/categories-imags/phone.png')
    },
    {
        id:4,
        name:"Bags",
        image:require('../assets/Svg/mainLanding/categories-imags/4522696.webp')
    },
    {
        id:5,
        name:"Shoes",
        image:require('../assets/Svg/mainLanding/categories-imags/shoes.png')
    },
    {
        id:6,
        name:"Watches",
        image:require('../assets/Svg/mainLanding/categories-imags/pngfind.com-apple-watch-png-99752.png')
    },
    {
        id:7,
        name:"Mobiles",
        image:require('../assets/Svg/mainLanding/categories-imags/phone.png')
    },
    {
        id:8,
        name:"Shoes",
        image:require('../assets/Svg/mainLanding/categories-imags/shoes.png')
    },
    {
        id:9,
        name:"Watches",
        image:require('../assets/Svg/mainLanding/categories-imags/pngfind.com-apple-watch-png-99752.png')
    },
    {
        id:10,
        name:"Mobiles",
        image:require('../assets/Svg/mainLanding/categories-imags/phone.png')
    },
    
    
    
]