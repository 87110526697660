import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";

const PostDestionations = () => {
  const theme = useTheme();
  const navigate=useNavigate();
  const getLogo = (pageType) => {
    switch (pageType) {
      case "Facebook":
        return (
          <FacebookIcon
            sx={{
              fontSize: "20px",
              color: "#4D6FA9",
              position: "absolute",
              right: -5,
              bottom: 0,
            }}
          />
        );
      case "Instagram":
        return (
          <InstagramIcon
            sx={{
              fontSize: "20px",
              color: "#864DBC",
              position: "absolute",
              right: -5,
              bottom: 0,
            }}
          />
        );
      case "YouTube":
        return (
          <YouTubeIcon
            sx={{
              fontSize: "20px",
              color: "#E21B1B",
              position: "absolute",
              right: -5,
              bottom: 0,
            }}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        color: theme.palette.saas_button_bg.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          p: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Add Destination
        </Typography>
      
      </Box>
      <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            p: "10px",
            justifyContent:{xs:"center",sm:"flex-start"},
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "transparent",
              color: "#253F4B",
              fontWeight: "500",
              ":hover": {
                backgroundColor: "#253F4B",
                color: "#fff",
                border: "none",
              },
            }}
            onClick={() => navigate("/broadcast/destinations")}
          >
            Add Destinations
          </Button>
        </Box>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          px: "10px",
          py: { xs: `10px`, sm: `20px` },
          alignItems: { xs: "center", sm: "flex-start" },
          gap: "20px",
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "60px",
            width: "250px",
            backgroundColor: "transparent",
            border: "1px solid #00000029",
            px: "4px",
            pt: "5px",
            pb: "6px",
            gap: "15px",
            cursor: "pointer",
            ":hover": {
              border: "1px solid #FDC532",
              opacity: "0.9",
            },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#4D6FA9",
              borderRadius: "5px",
            }}
          >
            <FacebookIcon
              fontSize="large"
              sx={{
                color: "#fff",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              p: 1,
              textAlign: "center",
              ":hover": {
                fontWeight: "500",
              },
            }}
          >
            Facebook Pages
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "60px",
            width: "250px",
            backgroundColor: "transparent",
            border: "1px solid #00000029",
            px: "4px",
            pt: "5px",
            pb: "6px",
            gap: "15px",
            cursor: "pointer",
            ":hover": {
              border: "1px solid #FDC532",
              opacity: "0.9",
            },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E21B1B",
              borderRadius: "5px",
            }}
          >
            <YouTubeIcon
              fontSize="large"
              sx={{
                color: "#fff",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              p: 1,
              textAlign: "center",
              ":hover": {
                fontWeight: "500",
              },
            }}
          >
            YouTube Channels
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "60px",
            width: "250px",
            backgroundColor: "transparent",
            border: "1px solid #00000029",
            px: "4px",
            pt: "5px",
            pb: "6px",
            gap: "10px",
            cursor: "pointer",
            ":hover": {
              border: "1px solid #FDC532",
              opacity: "0.9",
            },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "center",
              justifyContent: "center",
              background:
                "linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)",
              borderRadius: "5px",
            }}
          >
            <InstagramIcon
              fontSize="large"
              sx={{
                color: "#fff",
              }}
            />
          </Box>

          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              p: 1,
              textAlign: "center",
              ":hover": {
                fontWeight: "500",
              },
            }}
          >
            Instagram
          </Typography>
        </Box>
      </Box> */}
      {/* selected */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          px: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Selected
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {["Facebook", "Instagram", "YouTube"].map((pageType, index) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                gap: "5px",
              }}
            >
              <Box
                key={index}
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "lightgray",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative", // Important for logo positioning
                }}
              >
                {/* The logo will be placed in the corner */}
                {getLogo(pageType)}
              </Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textTransform: "capitalize",
                }}
              >
                AMDY
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {/* seprator */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: theme.palette.seprator_color.main,
          flexShrink: 0,
          my: "10px",
        }}
      />
    </Box>
  );
};

export default PostDestionations;
