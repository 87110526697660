import React, { createContext, useEffect, useState } from "react";
import { FilterStores, getAllStores, searchStores, searchSuggestion } from "../api/StoreApis";
import { set } from "react-hook-form";

export const StoresContext=createContext();

export const StoresProvider=({children,userToken})=>{
    const [sort, setSort] = React.useState("All");
  const [value, setValue] = React.useState("all");
    const [stores, setStores] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [loading, setLoading] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchSuggestions, setSearchSuggestion] = React.useState([]);
    const [totalStores,setTotalStores]=useState();
    const [test,setTest]=useState("hi");
   
   let query = "";
   const fetchStores = async (page, isNew) => {
    setLoading(true);
    const response = await getAllStores(page);
    if(response?.status === 200){
        let data = response.data.stores;
        if (isNew) {
            setStores([...data]);
        } else {
            setStores(prevStores => {
                let newStores = data.filter(newStore => !prevStores.some(store => store._id === newStore._id));
                return [...prevStores, ...newStores];
            }
            );  
        }
        setTotalStores(response.data.totalItems)
        setTotalPages(response.data.totalPages);
    }
    setLoading(false);
}
    const handleSearched=()=>{
        setIsSearched(!isSearched);
    }
    const removeSearch=()=>{
        handleSearched();
        setSearchQuery("");
        fetchStores(value,1,true);
    }
    // for search 
    const handleSearch=async()=>{
        handleSearched();
        const resp=await searchStores(searchQuery);
        if(resp.status===200)
        {
            let data=resp.data.stores
            setStores(data)
        } 
    }
    // for search suggestion
    const handleSearchSuggestion=async()=>{
        const resp=await searchSuggestion(searchQuery);
        if(resp?.status===200)
        {
            let data=resp.data;
            setSearchSuggestion(data)
        } 
    }
    // for filter
    const sortStoreByCat=async()=>{
        if(sort==="All")
        {
            fetchStores(page,true)
        }else{
            const resp=await FilterStores(sort);
            if(resp.status===200)
            {
                let data=resp.data.stores
                setStores(data)
            }
        }
        
    }
    const loadMore=()=>{
        setPage(page+1);
        fetchStores(page+1,false);
    }
    useEffect(()=>{
        if(sort==="All")
        {
            setPage(1);
            fetchStores(page,true);
        }
    },[sort]);
return (
    <StoresContext.Provider
    value={{
        test,
        stores,
        loadMore,
        page,
        searchQuery,
        setSearchQuery,
        loading,
        totalPages,
        removeSearch,
        handleSearch,
        query,
        setSearchQuery,
        sortStoreByCat,
        sort,
        setSort,
        setIsSearched,
        isSearched,
        handleSearchSuggestion,
        searchSuggestions,
        setSearchSuggestion,
        fetchStores,
        setPage,
        totalStores
    }}
    >
{children}
    </StoresContext.Provider>
)
};