import { Alert, Box } from "@mui/material";
import React from "react";

const CreateStreamAlerts = ({ eligibilityError }) => {
  return (
    <Box>
      {eligibilityError.length > 0 &&
        eligibilityError.map((error, index) => (
          <Alert
            severity="error"
            sx={{
              marginBottom: "10px",
              padding: "10px",
              borderRadius: "5px",
            }}
            key={index}
          >
            {error.type === "facebook"
              ? `Facebook (${error.name}) ${error.error}`
              : error.type === "youtube"
              ? `YouTube (${error.name}) ${error.error}`
              : null}
          </Alert>
        ))}
    </Box>
  );
};

export default CreateStreamAlerts;
