import React from "react";
import { useState } from "react";

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  styled,
  tableCellClasses,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

import RowMenu from "./RowMenu";
import TableNoData from "./TableNoData";
import theme from "../theme";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.saas_button_bg.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const destinationIconMap = {
  facebook: {
    icon: FacebookIcon,
    color: theme.palette.destinationIconColor.facebook,
  },
  youtube: {
    icon: YouTubeIcon,
    color: theme.palette.destinationIconColor.youtube,
  },
  instagram: {
    icon: InstagramIcon,
    color: theme.palette.destinationIconColor.instagram,
  },
};

const getIconForDestination = (destination) => {
  const iconData = destinationIconMap[destination.toLowerCase()];

  if (!iconData) {
    return null;
  }

  const { icon: Icon, color } = iconData;
  return <Icon fontSize="medium" sx={{ color }} />;
};

const columns = [
  {
    id: "title",
    label: "Title",
    minWidth: 170,
  },
  {
    id: "numberOfProducts",
    label: "No. of Products",
    minWidth: 110,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 110,
    align: "right",
    color: {
      active: theme.palette.postStatusColor.active,
      inactive: theme.palette.postStatusColor.inactive,
      draft: theme.palette.postStatusColor.draft,
    },
    transformer: (status) => status.charAt(0).toUpperCase() + status.slice(1),
  },
  {
    id: "duration",
    label: "Duration",
    minWidth: 110,
    align: "right",
  },
  {
    id: "startDate",
    label: "Start Date",
    minWidth: 110,
    align: "right",
    transformer: (startDate) =>
      new Date(startDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
  },
  {
    id: "endDate",
    label: "End Date",
    minWidth: 110,
    align: "right",
    transformer: (endDate) =>
      new Date(endDate).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
  },
  {
    id: "type",
    label: "Type",
    minWidth: 110,
    align: "right",
    transformer: (type) => type.charAt(0).toUpperCase() + type.slice(1),
  },
  {
    id: "destination",
    label: "Destination",
    minWidth: 110,
    align: "right",
    transformer: (destinations) =>
      destinations.map((destination, index) => (
        <React.Fragment key={index}>
          {getIconForDestination(destination)}
        </React.Fragment>
      )),
  },
];

function createData(
  id,
  title,
  numberOfProducts,
  status,
  duration,
  startDate,
  endDate,
  type,
  destination
) {
  return {
    id,
    title,
    numberOfProducts,
    status,
    duration,
    startDate,
    endDate,
    type,
    destination,
  };
}

const rows = [
  createData(
    1,
    "First Post",
    2,
    "active",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram", "youtube"]
  ),
  createData(
    2,
    "Second Post",
    1,
    "draft",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["youtube", "instagram"]
  ),
  createData(
    3,
    "This is a very long post title which should wrap into multiple lines",
    3,
    "active",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
  createData(
    4,
    "Another Post",
    6,
    "inactive",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["youtube", "instagram"]
  ),
  createData(
    5,
    "Post number 5",
    4,
    "draft",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
  createData(
    6,
    "Post # six",
    7,
    "active",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
  createData(
    7,
    "Post # 7",
    12,
    "inactive",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["youtube", "instagram"]
  ),
  createData(
    8,
    "8th Post",
    8,
    "active",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
  createData(
    9,
    "Ninth Post",
    6,
    "inactive",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "youtube"]
  ),
  createData(
    10,
    "Post 10",
    9,
    "active",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "youtube", "instagram"]
  ),
  createData(
    11,
    "Post 11",
    0,
    "draft",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
  createData(
    12,
    "Post 12",
    0,
    "active",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["youtube", "instagram"]
  ),
  createData(
    13,
    "Post 13",
    5,
    "inactive",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
  createData(
    14,
    "Second Last Post",
    2,
    "active",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
  createData(
    15,
    "Last Post 15",
    3,
    "inactive",
    "2 days",
    "2024-10-01",
    "2024-10-03",
    "post",
    ["facebook", "instagram"]
  ),
];

const noData = !rows.length;

const PostsTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={5}>
      <TableContainer
        sx={{
          minHeight: 420,
          maxHeight: 420,
          overflow: noData ? "hidden" : "auto",
        }}
      >
        <Table
          stickyHeader
          sx={noData ? { tableLayout: "fixed", width: "100%" } : {}}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="actions"
                align="right"
                style={{ minWidth: 50 }}
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <StyledTableRow key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          sx={{ color: column.color && column.color[value] }}
                        >
                          {column.transformer
                            ? column.transformer(value)
                            : value}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell key={row.id} align="right">
                      <RowMenu />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}

            {noData && <TableNoData />}
          </TableBody>
        </Table>
      </TableContainer>

      {!noData && (
        <TablePagination
          component="div"
          page={page}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      )}
    </Paper>
  );
};

export default PostsTable;
