import { Box } from '@mui/material'
import React from 'react'
import PostDestionations from './components/Posts/CreatePost/PostDestionations'
import Create from './components/Posts/CreatePost/Create'
import Campaign from './components/Posts/CreatePost/Campaign'

const CreatePost = () => {
  return (
    <Box
    sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 70px)",
        mb: { xs: 6, md: "0px" },
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#646669",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#FDC532",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#fff",
          borderRadius: "50px",
        },
    }}
       className="scrollable-content"
    >
        {/* post destinations */}
        <PostDestionations />
        {/* create */}
        <Create />
        {/* campaign */}
        <Campaign />
    </Box>
  )
}

export default CreatePost