import { toast } from "../components/Toast";
import axios from "./axios";


export const registerBusinessUser = async (data) => {


  try {
    return await axios.post(`/businessUser/register`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      return error?.response
      // setRegError(error?.response?.data?.message)
      // if(error?.response?.data?.message !== "Email already exists"){
      //   toast.error(error?.response?.data?.message);
      // }



    } else {
      toast.error("Failed to register business user. Please try again.");
    }
  }
};

export const loginBusinessUser = async (data) => {
  try {
    return await axios.post(`/businessUser/login`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      return error?.response
    } else {
      toast.error("Failed to login. Please try again.");
    }
  }
};

export const resetBusinessUserPassword = async (data) => {
  try {
    return await axios.post(`/businessUser/forgot`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      return error?.response
    } else {
      toast.error("Failed to reset business user password. Please try again.");
    }
  }
};

export const sendBusinessUserPassword = async (password, token) => {
  try {
    return await axios.put(`/businessUser/reset-password/${token}`, password);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Failed to send business user password. Please try again.");
    }
  }
};

export const getBusinessUser = async (token) => {
  try {
    if (token) {
      return await axios.get(`/businessUser/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return await axios.get(`/businessUser/profile`);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Failed to get business user. Please try again.");
    }
  }
};

export const verifyOTPAPI = async (data) => {
  try {
    const response = await axios.post(`/businessUser/verify-otp`, data);
    return response
  } catch (error) {
    return error;
  }
}

export const resendOTPAPI = async (data) => {
  try {
    const response = await axios.post(`/businessUser/resend-otp`, data);
    return response
  } catch (error) {
    return error;
  }
};

export const verifyResetOTPAPI = async (data) => {
  try {
    const response = await axios.post(`/businessUser/reset-password-otp-verify`, data);
    return response
  } catch (error) {
    return error;
  }
};