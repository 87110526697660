import { toast } from "../components/Toast";
import axios from "./axios";

export const createOrder = async (data) => {
  try {
    return await axios.post(`/order/create`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while creating the order. Please try again later.");
    }
  }
};

export const createOrderFromStream = async (data) => {
  try {
    return await axios.post(`/order/createFromStream`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while creating the order. Please try again later.");
    }
  }
};

export const getAllOrdersForBusinessUsers = async (
  page = 1,
  limit = 10,
  status = "all"
) => {
  try {
    return await axios.get(
      `/order?page=${page}&pageSize=${limit}&status=${status}`
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      // toast.error(error?.response?.data?.message);
    } else {
      // toast.error("Error: Something went wrong. ");
    }
  }
};

export const updateOrders = async (dataArr) => {
  try {
    return await axios.post(`/order/updateMany`, { orders: dataArr });
  } catch (error) {
    if (error?.response?.data?.message) {
      // toast.error(error?.response?.data?.message);
    } else {
      // toast.error("Error: Something went wrong. ");
    }
  }
};

export const deleteOrders = async (idArr) => {
  try {
    return await axios.post(`/order/deleteMany`, { ids: idArr });
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Oops! Something went wrong while deleting the order. Please try again later.");
    }
  }
};

export const getTotalSuccessfulOrders = async (data) => {
  try {
    return await axios.get(`/order/getTotalSuccessfulOrders`);
  } catch (error) {
    // if (error?.response?.data?.message) {
    //   toast.error(error?.response?.data?.message);
    // } else {
    //   toast.error("Error: Something went wrong. ");
    // }
  }
};

export const getTotalProductsSold = async (data) => {
  try {
    return await axios.get(`/order/getTotalProductsSold`);
  } catch (error) {
    // if (error?.response?.data?.message) {
    //   toast.error(error?.response?.data?.message);
    // } else {
    //   toast.error("Error: Something went wrong. ");
    // }
  }
};

export const getRevenue = async (data) => {
  try {
    return await axios.get(`/order/getRevenue`);
  } catch (error) {
    // if (error?.response?.data?.message) {
    //   toast.error(error?.response?.data?.message);
    // } else {
    //   toast.error("Error: Something went wrong. ");
    // }
  }
};

export const searchOrders = async (
  query,
  page = 1,
  limit = 10,
  status = "all"
) => {
  try {
    return await axios.get(
      `/order/searchOrder?search=${encodeURIComponent(
        query
      )}&page=${page}&pageSize=${limit}&status=${status}`
    );
  } catch (error) {
    toast.error("Oops! Something went wrong while searching for orders. Please try again later.");
  }
};
