import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import DeleteIcon from "@mui/icons-material/Delete";
import Facebookpages from "./components/Facebookpages";
import InstaRTMPConnect from "./components/InstaRTMPConnect";
import { StreamingContext } from "../../context/StreamingContext";
import {
  createSocialMediaAccount,
  getAllConnectedAccounts,
  getAllFacebookPages,
  getAllInstagramPages,
  reconnectAccount,
  refreshFacebookPages,
  refreshInstagramPages,
  updateConnectedStatus,
} from "../../api/socialMediaAccounts";
import { toast } from "../Toast";
import { useGoogleLogin } from "@react-oauth/google";
import CachedIcon from "@mui/icons-material/Cached";
import ReconnectAlerts from "./components/ReconnectAlerts";

const Destination = () => {
  // !------------------------------------ ALL STATES HERE ------------------------------------
  const [facebookDialog, setFacebookDialog] = useState(false);
  const [instaConnectDialog, setInstaConnectDialog] = useState(false);
  const [facebookPages, setFacebookPages] = useState([]);
  const [instaPages, setInstaPages] = useState([]);
  const [facebookPagging, setFacebookPagging] = useState({
    page: 1,
    totalPages: 1,
    limit: 6,
  });
  const [connectedAccount, setConnectedAccount] = useState([]);
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [instaLoading, setInstaLoading] = useState(false);

  const [instaForm, setInstaForm] = useState({
    username: "",
    rtmpUrl: "",
    streamKey: "",
  });
  const theme = useTheme();
  // !------------------------------------ USE CONTEXT HERE ------------------------------------
  const { facebookObj, setFacebookObj } = useContext(StreamingContext);
  // !------------------------------------ FUNCTIONS HERE ------------------------------------
  const getPlatformIcon = (platform) => {
    switch (platform) {
      case "facebook":
        return (
          <FacebookIcon
            sx={{ color: "#4267B2", position: "absolute", right: 0, bottom: 0 }}
          />
        );
      case "youtube":
        return (
          <YouTubeIcon
            sx={{ color: "#FF0000", position: "absolute", right: 0, bottom: 0 }}
          />
        );
      case "instagram":
        return (
          <InstagramIcon
            sx={{ color: "#C13584", position: "absolute", right: 0, bottom: 0 }}
          />
        );
      default:
        return null;
    }
  };

  const connectToFacebook = () => {
    try {
      const getCachedData = (key) => {
        const cached = localStorage.getItem(key);
        if (!cached) return null;

        const { timestamp, data } = JSON.parse(cached);

        const now = new Date().getTime();

        if (now - timestamp > 5 * 60 * 1000) {
          localStorage.removeItem(key);
          return null;
        }

        return data;
      };

      const setCachedData = (key, data) => {
        const cache = {
          timestamp: new Date().getTime(),
          data,
        };
        localStorage.setItem(key, JSON.stringify(cache));
      };

      const cacheKey = "facebookPages";

      const cachedData = getCachedData(cacheKey);

      if (cachedData) {
        setFacebookPages(cachedData.pages);
        setFacebookPagging({
          page: cachedData.pagination.page,
          totalPages: cachedData.pagination.totalPages,
          limit: cachedData.pagination.limit,
        });
        setFacebookDialog(true);
      } else {
        setFacebookDialog(true);
        getFacebookPages(facebookPagging.page).then((res) => {
          setFacebookLoading(true);
          if (res.data.facebook_pages.length > 0) {
            setFacebookPages(res.data.facebook_pages);
            setFacebookPagging({
              page: res.data.pagination.page,
              totalPages: res.data.pagination.totalPages,
              limit: res.data.pagination.limit,
            });

            setCachedData(cacheKey, {
              pages: res.data.facebook_pages,
              pagination: res.data.pagination,
            });
            setFacebookLoading(false);
          } else {
            window.FB.login(
              function (response) {
                if (response.authResponse) {
                  const accessToken = response.authResponse.accessToken;

                  window.FB.api("/me", function (response) {
                    setFacebookObj({
                      ...facebookObj,
                      username: response.name,
                      userId: response.id,
                    });
                  });

                  const fetchAllPages = async (
                    accessToken,
                    accumulatedPages = [],
                    nextPage = null
                  ) => {
                    return new Promise((resolve, reject) => {
                      const url =
                        nextPage ||
                        `/me/accounts?fields=picture,name,access_token,id&access_token=${accessToken}`;
                      window.FB.api(url, "GET", function (response) {
                        if (response && !response.error) {
                          const allPages = accumulatedPages.concat(
                            response.data
                          );
                          if (response.paging && response.paging.next) {
                            fetchAllPages(
                              accessToken,
                              allPages,
                              response.paging.next
                            )
                              .then(resolve)
                              .catch(reject);
                          } else {
                            resolve(allPages);
                          }
                        } else {
                          reject(response.error);
                        }
                      });
                    });
                  };

                  fetchAllPages(accessToken)
                    .then((allPages) => {
                      const data = {
                        facebook_user_access_token: accessToken,
                        facebook_pages: allPages.map((page) => {
                          return {
                            page_id: page.id,
                            page_name: page.name,
                            page_access_token: page.access_token,
                            page_token_created_at: new Date(),
                            page_profile_picture: page.picture.data.url,
                            is_connected: false,
                          };
                        }),
                      };

                      setFacebookDialog(true);
                      setFacebookLoading(true);
                      createSocialMediaAccount(data).then((res) => {
                        if (res.status === 200) {
                          getFacebookPages(facebookPagging.page).then((res) => {
                            if (res.data.facebook_pages.length > 0) {
                              setFacebookPages(res.data.facebook_pages);
                              setFacebookPagging({
                                page: res.data.pagination.page,
                                totalPages: res.data.pagination.totalPages,
                                limit: res.data.pagination.limit,
                              });

                              setCachedData(cacheKey, {
                                pages: res.data.facebook_pages,
                                pagination: res.data.pagination,
                              });
                              setFacebookLoading(false);
                            }

                            setFacebookLoading(false);
                          });
                        }
                      });
                    })
                    .catch((error) => {
                      toast.error(
                        "Oops! Something went wrong. Please try again."
                      );
                    });
                } else {
                  toast.error("Oops! Something went wrong. Please try again.");
                }
              },
              {
                scope:
                  "pages_read_engagement,pages_show_list,pages_read_user_content,pages_manage_posts,pages_manage_engagement,pages_manage_metadata,business_management",
              }
            );
          }
        });
      }
    } catch (error) {
      return error;
    }
  };
  const handleInstagramConnectByFB = () => {
    const getCachedData = (key) => {
      const cached = localStorage.getItem(key);
      if (!cached) return null;

      const { timestamp, data } = JSON.parse(cached);

      const now = new Date().getTime();

      if (now - timestamp > 5 * 60 * 1000) {
        localStorage.removeItem(key);
        return null;
      }

      return data;
    };

    const setCachedData = (key, data) => {
      const cache = {
        timestamp: new Date().getTime(),
        data,
      };
      localStorage.setItem(key, JSON.stringify(cache));
    };
    const fetchInstaPages = async (page) => {
      return new Promise((resolve, reject) => {
        window.FB.api(
          `/${page.id}?fields=instagram_business_account,username&ccess_token=${page.access_token}`,
          "GET",
          function (response) {
            if (response && !response.error) {
              if (response?.instagram_business_account) {
                resolve({
                  page_access_token: page.access_token,
                  fb_page_id: page.id,
                  insta_id: response?.instagram_business_account?.id,
                  insta_username: response?.username,
                });
              } else {
                resolve(null); 
              }
            } else {
              reject(response.error);
            }
          }
        );
      });
    };
  
    const fetchAllPages = async (accessToken, accumulatedPages = [], nextPage = null) => {
      return new Promise((resolve, reject) => {
        const url =
          nextPage ||
          `/me/accounts?fields=picture,name,access_token,id&access_token=${accessToken}`;
        window.FB.api(url, "GET", function (response) {
          if (response && !response.error) {
            const allPages = accumulatedPages.concat(response.data);
            if (response.paging && response.paging.next) {
              fetchAllPages(accessToken, allPages, response.paging.next)
                .then(resolve)
                .catch(reject);
            } else {
              resolve(allPages);
            }
          } else {
            reject(response.error);
          }
        });
      });
    };
  
    try {
    const cacheKey = "instaPages";
    const cachedData = getCachedData(cacheKey);
    if(cachedData) {
      setInstaPages(cachedData);
      setInstaLoading(false);
    }else{
      getInstaPostPages().then((res)=>{
        setInstaLoading(true);
        if(res.data.instagram_pages.length > 0){
          setInstaPages(res.data.instagram_pages);
          setInstaLoading(false);
          setCachedData(cacheKey,res.data.instagram_pages);
        }else{
          setInstaLoading(true);
          window.FB.login(
            function (response) {
              if (response.authResponse) {
                const accessToken = response.authResponse.accessToken;
                const processInstagramConnection = async () => {
                  try {
                    let newInstaPages = [];
                    const allPages = await fetchAllPages(accessToken);
                    for (const page of allPages) {
                      try {
                        const instaPage = await fetchInstaPages(page);
                        if (instaPage) {
                          const isExist = newInstaPages.some(
                            (item) => item?.insta_id === instaPage?.insta_id
                          );
                          if (!isExist) {
                            newInstaPages.push(instaPage);
                          }
                        }
                      } catch (error) {
                        toast.error("Oops! Something went wrong. Please try again.");
                      }
                    }
                    
                    let data={
                      facebook_user_access_token: accessToken,
                      instagram_posts_pages: newInstaPages
                    }
                    createSocialMediaAccount(data).then((res)=>{
                      if(res.status === 200){
                        getInstaPostPages().then((res)=>{
                          setInstaLoading(false);
                          setInstaPages(res.data.instagram_pages);
                          setCachedData(cacheKey,res.data.instagram_pages);
                          setInstaLoading(false);
                        })
                      }
                    })
                    
                  } catch (error) {
                    toast.error("Oops! Something went wrong. Please try again.");
                  }
                };
                processInstagramConnection();
              }
            },
            {
              scope:
                "instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement",
            }
          );
        }

      })
    }

     
    } catch (error) {
      console.error(error);
    }
  };
  const getInstaPostPages = async () => {
    try {
      const response = await getAllInstagramPages();
      return response;
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  }
  
  
  

  const getFacebookPages = async (page) => {
    try {
      const response = await getAllFacebookPages(page);
      return response;
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const handleFacebookPagging = (event, newPage) => {
    const cacheKey = "facebookPages";
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const data = JSON.parse(cachedData);

      const { pages, pagination } = data.data;

      if (pagination.page === newPage) {
        setFacebookPages(pages);
        setFacebookPagging(pagination);
        return;
      }

      getFacebookPages(newPage).then((res) => {
        if (res.data.facebook_pages.length > 0) {
          setFacebookPages(res.data.facebook_pages);
          setFacebookPagging({
            page: res.data.pagination.page,
            totalPages: res.data.pagination.totalPages,
            limit: res.data.pagination.limit,
          });

          localStorage.setItem(
            cacheKey,
            JSON.stringify({
              data: {
                pages: res.data.facebook_pages,
                pagination: res.data.pagination,
              },
              timestamp: new Date().getTime(),
            })
          );
        }
      });
    }
  };

  const handleConnectToFBPage = async (_data) => {
    const updateInstagramPagesInLocalStorage = (updatedInstaPages) => {
      const storedData = localStorage.getItem("instaPages");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const updatedData = {
          ...parsedData,
          data: updatedInstaPages,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("instaPages", JSON.stringify(updatedData));
      }
    };
    const updateFacebookPagesInLocalStorage = (updatedPages) => {
      const storedData = localStorage.getItem("facebookPages");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const updatedData = {
          ...parsedData,
          data: {
            ...parsedData.data,
            pages: updatedPages,
          },
          timestamp: new Date().getTime(),
        };
        localStorage.setItem("facebookPages", JSON.stringify(updatedData));
      }
    };
        
    try {
      const response = await updateConnectedStatus(_data);
  
      if (response.status === 200) {
        const { type } = response.data;
  
        if (type === "facebook") {
          const storedFacebookData = localStorage.getItem("facebookPages");
          if (storedFacebookData) {
            const parsedFacebookData = JSON.parse(storedFacebookData);
            const updatedPages = parsedFacebookData.data.pages.map((page) => {
              if (page.page_id === _data.page_id) {
                return { ...page, is_connected: _data.status };
              }
              return page;
            });
  
            updateFacebookPagesInLocalStorage(updatedPages);
            setFacebookPages(updatedPages);
            setFacebookDialog(false);
            toast.success(_data.status ? "Facebook Page connected" : "Facebook Page disconnected");
          }
        } else if (type === "instagram_posts_pages") {
          const storedInstagramData = localStorage.getItem("instaPages");
          if (storedInstagramData) {
            const parsedInstagramData = JSON.parse(storedInstagramData);
            const updatedInstaPages = parsedInstagramData.data.map((instaPage) => {
              if (instaPage.insta_id === _data.page_id) {
                return { ...instaPage, is_connected: _data.status };
              }
              return instaPage;
            });
            console.log(updatedInstaPages);
            updateInstagramPagesInLocalStorage(updatedInstaPages);
            setInstaPages(updatedInstaPages);
            setInstaConnectDialog(false);
            toast.success(_data.status ? "Instagram connected" : "Instagram disconnected");
          }
        }
      } else {
        throw new Error("Unexpected response status: " + response.status);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };
  
  
  

  const connectedAccounts = async () => {
    try {
      const response = await getAllConnectedAccounts();
      if (response.status === 200) {
        setConnectedAccount(response.data.connectedAccounts);
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const handleRefreshFacebookPages = () => {
    const cacheKey = "facebookPages";

    const setCachedData = (key, data) => {
      const cache = {
        timestamp: new Date().getTime(),
        data,
      };
      localStorage.setItem(key, JSON.stringify(cache));
    };

    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;

          window.FB.api("/me", function (response) {
            setFacebookObj({
              ...facebookObj,
              username: response.name,
              userId: response.id,
            });
          });

          const fetchAllPages = async (
            accessToken,
            accumulatedPages = [],
            nextPage = null
          ) => {
            return new Promise((resolve, reject) => {
              const url =
                nextPage ||
                `/me/accounts?fields=picture,name,access_token,id&access_token=${accessToken}`;
              window.FB.api(url, "GET", function (response) {
                if (response && !response.error) {
                  const allPages = accumulatedPages.concat(response.data);
                  if (response.paging && response.paging.next) {
                    fetchAllPages(accessToken, allPages, response.paging.next)
                      .then(resolve)
                      .catch(reject);
                  } else {
                    resolve(allPages);
                  }
                } else {
                  reject(response.error);
                }
              });
            });
          };

          fetchAllPages(accessToken)
            .then((allPages) => {
              const data = {
                facebook_pages: allPages.map((page) => {
                  return {
                    page_id: page.id,
                    page_name: page.name,
                    page_access_token: page.access_token,
                    page_token_created_at: new Date(),
                    page_profile_picture: page.picture.data.url,
                    is_connected: false,
                  };
                }),
              };

              setFacebookDialog(true);
              setFacebookLoading(true);
              refreshFacebookPages(data).then((res) => {
                if (res.status === 200) {
                  getFacebookPages(facebookPagging.page).then((res) => {
                    if (res.data.facebook_pages.length > 0) {
                      setFacebookPages(res.data.facebook_pages);
                      setFacebookPagging({
                        page: res.data.pagination.page,
                        totalPages: res.data.pagination.totalPages,
                        limit: res.data.pagination.limit,
                      });

                      setCachedData(cacheKey, {
                        pages: res.data.facebook_pages,
                        pagination: res.data.pagination,
                      });
                      setFacebookLoading(false);
                    }

                    setFacebookLoading(false);
                  });
                }
              });
            })
            .catch((error) => {
              toast.error("Oops! Something went wrong. Please try again.");
            });
        } else {
          toast.error("Oops! Something went wrong. Please try again.");
        }
      },
      {
        scope:
          "pages_read_engagement,pages_show_list,pages_read_user_content,pages_manage_posts,pages_manage_engagement,pages_manage_metadata,business_management",
      }
    );
  };

  const handleRefreshInstagramPages = () => {
    const cacheKey = "instaPages";
    const setCachedData = (key, data) => {
      const cache = {
        timestamp: new Date().getTime(),
        data,
      };
      localStorage.setItem(key, JSON.stringify(cache));
  }
  const fetchInstaPages = async (page) => {
    return new Promise((resolve, reject) => {
      window.FB.api(
        `/${page.id}?fields=instagram_business_account,username&ccess_token=${page.access_token}`,
        "GET",
        function (response) {
          if (response && !response.error) {
            if (response?.instagram_business_account) {
              resolve({
                page_access_token: page.access_token,
                fb_page_id: page.id,
                insta_id: response?.instagram_business_account?.id,
                insta_username: response?.username,
              });
            } else {
              resolve(null); 
            }
          } else {
            reject(response.error);
          }
        }
      );
    });
  };

  const fetchAllPages = async (accessToken, accumulatedPages = [], nextPage = null) => {
    return new Promise((resolve, reject) => {
      const url =
        nextPage ||
        `/me/accounts?fields=picture,name,access_token,id&access_token=${accessToken}`;
      window.FB.api(url, "GET", function (response) {
        if (response && !response.error) {
          const allPages = accumulatedPages.concat(response.data);
          if (response.paging && response.paging.next) {
            fetchAllPages(accessToken, allPages, response.paging.next)
              .then(resolve)
              .catch(reject);
          } else {
            resolve(allPages);
          }
        } else {
          reject(response.error);
        }
      });
    });
  };
  setInstaLoading(true);
  window.FB.login(
    function (response) {
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken;
        const processInstagramConnection = async () => {
          try {
            let newInstaPages = [];
            const allPages = await fetchAllPages(accessToken);
            for (const page of allPages) {
              try {
                const instaPage = await fetchInstaPages(page);
                if (instaPage) {
                  const isExist = newInstaPages.some(
                    (item) => item?.insta_id === instaPage?.insta_id
                  );
                  if (!isExist) {
                    newInstaPages.push(instaPage);
                  }
                }
              } catch (error) {
                toast.error("Oops! Something went wrong. Please try again.");
              }
            }
            
            let data={
              facebook_user_access_token: accessToken,
              instagram_posts_pages: newInstaPages
            }
            refreshInstagramPages(data).then((res)=>{
              if(res.status === 200){
                getInstaPostPages().then((res)=>{
                  setInstaLoading(false);
                  setInstaPages(res.data.instagram_pages);
                  setCachedData(cacheKey,res.data.instagram_pages);
                  setInstaLoading(false);
                })
              }
            })
            
          } catch (error) {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        };
        processInstagramConnection();
      }
    },
    {
      scope:
        "instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement",
    }
  );
    
}

  const onSuccessLogin = async (tokenResponse) => {
    try {
      const { access_token } = tokenResponse;

      const profileResponse = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (profileResponse.ok) {
        const profileData = await profileResponse.json();

        let data = {
          youtube: [
            {
              channel_id: profileData.id,
              channel_name: profileData.name,
              channel_access_token: access_token,
              channel_token_created_at: new Date(),
              channel_profile_picture: profileData.picture,
              is_connected: true,
            },
          ],
        };

        const response = await createSocialMediaAccount(data);
        if (response.status === 200) {
          connectedAccounts();
          toast.success("Channel connected successfully");
        }
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const Glogin = useGoogleLogin({
    onSuccess: onSuccessLogin,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    scope: "https://www.googleapis.com/auth/youtube.force-ssl",
  });

  const handleInstagramConnect = async () => {
    try {
      let data = {
        instagram: [
          {
            instagram_username: instaForm.username,
            instagram_rtmp_url: instaForm.rtmpUrl,
            instagram_stream_key: instaForm.streamKey,
          },
        ],
      };

      const response = await createSocialMediaAccount(data);

      if (response.status === 200) {
        connectedAccounts();
        setInstaConnectDialog(false);
        toast.success("Instagram connected successfully");
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const handleReconnectAccount = async (data) => {
    try {
      const resp = await reconnectAccount(data);
      if (resp.status === 200) {
        connectedAccounts();
        toast.success("Account reconnected successfully");
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    }
  };

  const handleReconnectToFBPage = async (
    page_id,
    user_access_token,
    token_status
  ) => {
    if (token_status) {
      window.FB.api(
        `/${page_id}?fields=picture,name,access_token,id&access_token=${user_access_token}`,
        "GET",
        function (response) {
          if (response && !response.error) {
            let data = {
              type: "facebook",
              facebook_pages: [
                {
                  page_id: response.id,
                  page_name: response.name,
                  page_access_token: response.access_token,
                  page_profile_picture: response.picture.data.url,
                },
              ],
            };

            handleReconnectAccount(data);
          }
        }
      );
    } else {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;

            window.FB.api(
              `/${page_id}?fields=picture,name,access_token,id&access_token=${accessToken}`,
              function (response) {
                if (response && !response.error) {
                  let data = {
                    type: "facebook",
                    facebook_user_access_token: accessToken,
                    facebook_pages: [
                      {
                        page_id: response.id,
                        page_name: response.name,
                        page_access_token: response.access_token,
                        page_profile_picture: response.picture.data.url,
                      },
                    ],
                  };

                  handleReconnectAccount(data);
                }
              }
            );
          }
        },
        {
          scope:
            "pages_read_engagement,pages_show_list,pages_read_user_content,pages_manage_posts,pages_manage_engagement,pages_manage_metadata,business_management",
        }
      );
    }
  };
  // !------------------------------------ USE EFFECT HERE ------------------------------------
  useEffect(() => {
    connectedAccounts();
  }, [facebookPages]);
  // !------------------------------------ RETURN HERE ------------------------------------
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 70px)",
        mb: { xs: 6, md: "0px" },
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "5px",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#646669",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#FDC532",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#fff",
          borderRadius: "50px",
        },
      }}
      className="scrollable-content"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          py: { xs: "6px", md: "13px" },
        }}
      >
        <Typography
          sx={{
            p: "10px",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Destination
        </Typography>
      </Box>
      {/* separator */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: theme.palette.seprator_color.main,
          flexShrink: 0,
        }}
      ></Box>
      <ReconnectAlerts
        connectedAccount={connectedAccount}
        handleReconnectToFBPage={handleReconnectToFBPage}
        Glogin={Glogin}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          px: "10px",
          py: { xs: `10px`, sm: `80px` },
          alignItems: { xs: "center", sm: "flex-start" },
          gap: "20px",
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "60px",
            width: "250px",
            backgroundColor: "transparent",
            border: "1px solid #00000029",
            px: "4px",
            pt: "5px",
            pb: "6px",
            gap: "15px",
            cursor: "pointer",
            ":hover": {
              border: "1px solid #FDC532",
              opacity: "0.9",
            },
            alignItems: "center",
          }}
          onClick={() => connectToFacebook()}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#4D6FA9",
              borderRadius: "5px",
            }}
          >
            <FacebookIcon
              fontSize="large"
              sx={{
                color: "#fff",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              p: 1,
              textAlign: "center",
              ":hover": {
                fontWeight: "500",
              },
            }}
          >
            Facebook Pages
          </Typography>
        </Box>
        {/* youtube */}
        <Box
          sx={{
            display: "flex",
            height: "60px",
            width: "250px",
            backgroundColor: "transparent",
            border: "1px solid #00000029",
            px: "4px",
            pt: "5px",
            pb: "6px",
            gap: "15px",
            cursor: "pointer",
            ":hover": {
              border: "1px solid #FDC532",
              opacity: "0.9",
            },
            alignItems: "center",
          }}
          onClick={() => Glogin()}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E21B1B",
              borderRadius: "5px",
            }}
          >
            <YouTubeIcon
              fontSize="large"
              sx={{
                color: "#fff",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              p: 1,
              textAlign: "center",
              ":hover": {
                fontWeight: "500",
              },
            }}
          >
            YouTube Channels
          </Typography>
        </Box>
        {/* instagram */}
        <Box
          sx={{
            display: "flex",
            height: "60px",
            width: "250px",
            backgroundColor: "transparent",
            border: "1px solid #00000029",
            px: "4px",
            pt: "5px",
            pb: "6px",
            gap: "10px",
            cursor: "pointer",
            ":hover": {
              border: "1px solid #FDC532",
              opacity: "0.9",
            },
            alignItems: "center",
          }}
          onClick={() => {
            setInstaConnectDialog(true);
            setInstaForm({ username: "", rtmpUrl: "", streamKey: "" });
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "center",
              justifyContent: "center",
              background:
                "linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)",
              borderRadius: "5px",
            }}
          >
            <InstagramIcon
              fontSize="large"
              sx={{
                color: "#fff",
              }}
            />
          </Box>

          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "400",
              p: 1,
              textAlign: "center",
              ":hover": {
                fontWeight: "500",
              },
            }}
          >
            Instagram
          </Typography>
        </Box>
      </Box>
      {/* separator */}
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#00000029",
          margin: "10px 0px",
          flexShrink: 0,
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: "10px",
          py: "13px",
          flexShrink: 0,
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Added Destination
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#00000029",
          margin: "10px 0px",
          flexShrink: 0,
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          py: "0px",
          gap: "20px",
          flexShrink: 0,
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectedAccount.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ position: "relative" }}>
                        {row.type === "instagram" ? (
                          <Avatar>{row.name.charAt(0).toUpperCase()}</Avatar>
                        ) : (
                          <img
                            src={row.profile_picture}
                            alt="profile"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        {getPlatformIcon(row.type)}
                      </Box>
                      <Typography
                        sx={{ fontSize: "13px", fontWeight: "500", pl: 2 }}
                      >
                        {row.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {row.status ? "connected" : "disconnected"}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            row?.type === "facebook" &&
                              handleConnectToFBPage({
                                page_id: row.id,
                                type: "facebook",
                                status: false,
                              });

                            row?.type === "youtube" &&
                              handleConnectToFBPage({
                                channel_id: row.id,
                                type: "youtube",
                                status: false,
                              });

                            row?.type === "instagram" &&
                              handleConnectToFBPage({
                                username: row.name,
                                type: "instagram",
                                status: false,
                              });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Reconnect">
                        <IconButton
                          onClick={() => {
                            if (row?.type === "facebook") {
                              handleReconnectToFBPage(
                                row.id,
                                row.facebook_user_access_token,
                                row.facebook_user_access_token_created_at
                              );
                            }

                            if (row?.type === "youtube") {
                              Glogin();
                            }

                            if (row?.type === "instagram") {
                              setInstaConnectDialog(true);
                              setInstaForm({
                                username: row.name,
                                rtmpUrl: row.rtmp_url,
                                streamKey: row.stream_key,
                              });
                            }
                          }}
                        >
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Facebookpages
        facebookDialog={facebookDialog}
        setFacebookDialog={() => setFacebookDialog(false)}
        facebookPages={facebookPages}
        facebookPagging={facebookPagging}
        handleFacebookPagging={handleFacebookPagging}
        handleConnectToFBPage={handleConnectToFBPage}
        facebookLoading={facebookLoading}
        handleRefreshFacebookPages={handleRefreshFacebookPages}
      />
      <InstaRTMPConnect
        open={instaConnectDialog}
        close={() => setInstaConnectDialog(false)}
        instaForm={instaForm}
        setInstaForm={setInstaForm}
        handleInstagramConnect={handleInstagramConnect}
        handleInstagramConnectByFB={handleInstagramConnectByFB}
        instaLoading={instaLoading}
        instaPages={instaPages}
        handleConnectToFBPage={handleConnectToFBPage}
        handleRefreshInstagramPages={handleRefreshInstagramPages}
      />
    </Box>
  );
};

export default Destination;
