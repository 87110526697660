import React, { createContext, useEffect, useState } from "react";
import { getBusinessUser } from "../api/AuthApis";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children, userType }) => {
  const navigate=useNavigate();
  const location=useLocation();
  useEffect(() => {
    const getQueryParams = (name) => {
      const params=new URLSearchParams(location.search);
      return params.get(name);

    }
    const accessToken=getQueryParams('access');
    if(accessToken){
      localStorage.setItem('businessUser',JSON.stringify( accessToken));
      navigate('/dashboard/index')
      window.location.reload();
    }

  },[location])
  const getToken = () => {
    const tokenString = localStorage.getItem(userType);
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [authToken, setToken] = useState(getToken());
  const [userData, setUserData] = useState([]);
  const {regError,setRegError}=useState("");
  const [globalCallFrame, setGlobalCallFrame] = React.useState(null);
// store dashboard global state
const [drawer, setDrawer] = React.useState(false);
const [broadCastDrawer, setBroadCastDrawer] = React.useState(false);
const toggleBroadcastDrawer=()=>{
  setBroadCastDrawer(!broadCastDrawer);
}

const toggleDrawer = () => {
  setDrawer(!drawer);
};
// end store dashboard global state
  const saveToken = (userToken) => {
    localStorage.setItem(userType, JSON.stringify(userToken));
    setToken(userToken);
  };

  const saveUserData = (data) => {
    setUserData(data);
    localStorage.setItem(userType + "data", JSON.stringify(data));
  };

  const removeToken = () => {
    localStorage.removeItem(userType);
    setToken(null);
  };

  const removeUserData = () => {
    localStorage.removeItem(userType + "data");
    setUserData(null);
  };

  const updateTourStatus = () => {
    userData.tour_status = true;
    setUserData({ ...userData, user: { ...userData.user, tour_status: true } });
    localStorage.setItem(
      userType + "data",
      JSON.stringify({
        ...userData,
        user: { ...userData.user, tour_status: true },
      })
    );
  };

  const getUserData = () => {
    if (authToken != null) {
      getBusinessUser(authToken).then((res) => {
        setUserData(res?.data);

        if (res?.data?.user?.store) {
          const { image, ...newObject } = res?.data?.user;
          
          // Check if 'shop_logo' exists in newObject.store before destructuring
          if (newObject.store.shop_logo) {
            const { shop_logo, ...withOutImage } = newObject.store;
            newObject.store = withOutImage;
          }
          
          localStorage.setItem(userType + "data", JSON.stringify(newObject));
        } else {
          localStorage.setItem(userType + "data", JSON.stringify(res?.data?.user));
        }
        
        // const { image, ...newObject } = res?.data?.user;
        // const { shop_logo, ...withOutImage } = newObject?.store;

        // newObject.store = withOutImage;

        // localStorage.setItem(userType + "data", JSON.stringify(newObject));

        // * set localstorage with two hours expiry
        const now = new Date();
        localStorage.setItem("loginExpiry", JSON.stringify(now.getTime()));
      });
    }
  };

  useEffect(() => {
    getUserData();
  }, [authToken]);

  useEffect(() => {
    const checkAndRemoveToken = () => {
      const tokenData = JSON.parse(localStorage.getItem("loginExpiry"));
      if (tokenData !== null) {
        const now = new Date();
        const tokenTimestamp = new Date(tokenData);

        const timeDifference = now - tokenTimestamp;
        const twoHoursInMilliseconds = 2 * 60 * 60 * 1000; // 1 hour
        // const twoHoursInMilliseconds = 20 * 1000;

        if (timeDifference >= twoHoursInMilliseconds) {
          localStorage.removeItem("YoutubeResponse");
          localStorage.removeItem("StreamingTimeout");
          localStorage.removeItem("facebookResponse");
          localStorage.removeItem("instagramStreamingResponse");
          localStorage.removeItem("youtubeStreamingResponse");
          localStorage.removeItem("YoutubeResponse");
          localStorage.removeItem("googleLoginObj");
          localStorage.removeItem("loginExpiry");

          removeToken();
          removeUserData();

          window.location.href = "/auth/login";
        }
      }
    };

    checkAndRemoveToken();

    const interval = setInterval(() => {
      checkAndRemoveToken();
    }, 1 * 60 * 1000); // 1 minutes in milliseconds

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        removeToken,
        saveToken,
        authToken,
        userData,
        saveUserData,
        removeUserData,
        updateTourStatus,
        getUserData,
        globalCallFrame,
        setGlobalCallFrame,
        toggleDrawer,
        drawer,
        setRegError,
        regError,
        toggleBroadcastDrawer,
        broadCastDrawer
        
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
