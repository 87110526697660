import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputBase,
  Typography,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GoogleAuthSvg from "../../assets/Svg/GoogleAuthSvg";
import { loginBusinessUser, registerBusinessUser } from "../../api/AuthApis";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "../Toast";

const phoneRegex = /^[0-9]{12}$/;

const schema = yup
  .object({
    contact: yup
      .string()
      .required("Enter your email or phone number")
      .test(
        "is-email-or-phone",
        "Must be a valid email or phone number",
        function (value) {
          const { path, createError } = this;
          const emailSchema = yup.string().email("Invalid email");
          const phoneSchema = yup.string().matches(phoneRegex, "Invalid phone number");

          if (emailSchema.isValidSync(value)) {
            return true;
          } else if (phoneSchema.isValidSync(value)) {
            return true;
          } else {
            return createError({ path, message: "Must be a valid email or phone number" });
          }
        }
      ),
    password: yup
      .string()
      .required("Enter your password"),
  })
  .required();

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema)
  });
  const { saveToken, saveUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    const requestData = {
      password: data.password,
      provider: "custom",
    };

    if (yup.string().email().isValidSync(data.contact)) {
      requestData.email = data.contact;
    } else if (phoneRegex.test(data.contact)) {
      requestData.phone_number = data.contact;
    }

    const response = await loginBusinessUser(requestData);

    if (response?.status === 200) {
      toast.success("You have successfully logged in.");
      saveToken(response?.data?.token);
      saveUserData(response.data);
      navigate("/broadcast/home");
    } else if (response?.status === 400) {
      if (response?.data?.message === "User is not verified") {
        sessionStorage.setItem("email", requestData.email);
        sessionStorage.setItem("phone_number", requestData.phone_number);
        sessionStorage.setItem("accountNotVerified", "true");
        navigate("/auth/register");
      }

      toast.error(response?.data?.message);
    }
  };

  const onSuccess = async (tokenResponse) => {
    const { access_token } = tokenResponse;

    // Make API call to fetch user's profile information
    const profileResponse = await fetch(
      "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    const profileData = await profileResponse.json();
    const { email, id, given_name, family_name } = profileData;

    const resp = await loginBusinessUser({
      email: email,
      password: id,
      provider: "social",
    });

    if (resp?.status === 200) {
      toast.success("You have successfully logged in.");
      saveToken(resp?.data?.token);
      saveUserData(resp.data);
      navigate("/broadcast/home");
    }
    else {
      const resp = await registerBusinessUser({
        name: given_name + family_name,
        email: email,
        password: id,
        provider: "social",
      });

      if (resp?.status === 201) {
        toast.success("You have successfully logged in.");
        saveToken(resp?.data?.token);
        saveUserData(resp.data);

        navigate("/broadcast/home");
      }
    }
  };

  const handleGoogleAuth = useGoogleLogin({
    onSuccess: onSuccess,
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 3,
      }}
    >
      <Box>
        <Typography
          sx={{ fontWeight: "600", fontSize: "20px", display: "flex" }}
        >
          READY
          <span style={{ fontWeight: "500", fontSize: "20px" }}>
            &nbsp;TO DIVE IN?
          </span>
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>
          Enter your email and password to get started.
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            px: "8px",
            py: "5px",
            borderRadius: "5px",
            border: "1px solid #B7B7B7",
            lineHeight: "0",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <MailIcon sx={{ color: "#B7B7B7" }} />
          <InputBase
            {...register("contact")}
            type="text"
            autoComplete="new-mail"
            placeholder="Email or WhatsApp no."
            sx={{
              ".MuiInputBase-input": { p: "0" },
              width: "100%",
            }}
          />
        </Box>
        <Typography
          style={{
            color: "#FF0000",
            fontWeight: "400",
            fontSize: "12px",
            margin: "4px",
            marginBottom: "0",
          }}
        >
          {errors.contact?.message}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            px: "8px",
            py: "5px",
            borderRadius: "5px",
            border: "1px solid #B7B7B7",
            lineHeight: "0",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <LockIcon sx={{ color: "#B7B7B7" }} />
          <InputBase
            {...register("password")}
            type={showPassword ? "text" : "password"}
            autoComplete="new-password"
            placeholder="Password"
            sx={{
              ".MuiInputBase-input": { p: "0" },
              width: "100%",
              background: "#fff",
            }}
          />
          {showPassword ? (
            <VisibilityIcon
              sx={{ color: "#B7B7B7", cursor: "pointer" }}
              onClick={handleClickShowPassword}
            />
          ) : (
            <VisibilityOffIcon
              sx={{ color: "#B7B7B7", cursor: "pointer" }}
              onClick={handleClickShowPassword}
            />
          )}
        </Box>
        <Typography
          style={{
            color: "#FF0000",
            fontWeight: "400",
            fontSize: "12px",
            margin: "4px",
            marginBottom: "0",
          }}
        >
          {errors.password?.message}
        </Typography>
      </Box>

      <Link to={"/auth/reset"} style={{ textDecoration: "none" }}>
        <Typography sx={{ fontSize: "12px", color: "#0079F2E8", float: "right" }}>
          Forgot your password?
        </Typography>
      </Link>

      <Button
        type="submit"
        variant="contained"
        color="secondary"
        sx={{ borderRadius: "20px" }}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
        ) : (
          "Sign In"
        )}
      </Button>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Divider
          sx={{
            width: "40%",
          }}
        />
        <Typography sx={{ color: "#615E5E" }}>Or</Typography>
        <Divider
          sx={{
            width: "40%",
          }}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography sx={{ color: "#615E5E", mx: "auto" }}>
          Continue login with
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              minWidth: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: "0",
              p: "0",
            }}
            onClick={handleGoogleAuth}
          >
            <GoogleAuthSvg />
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", lg: "row" },
          gap: 1,
        }}
      >
        <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>
          Don't have an account?
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "20px",
            py: "3px",
            boxShadow: "none",
            "&:hover": {
              background: "#FFBD54",
            },
          }}
          onClick={() => navigate("/auth/register")}
        >
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
