import React, { createContext, useEffect, useState } from "react";
import {
  fetchAllProductsByShopName,
  getProductCategories,
  searchProducts,
} from "../api/ProductApis";
import { getShopDetailsForCustomerView, getShopViewForCount, searchProductSuggestion, searchSuggestion } from "../api/StoreApis";
import { useLocation } from "react-router-dom";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [value, setValue] = React.useState("all");

  const [products, setProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCount,setTotalCount]=useState();
  const { search, pathname } = useLocation();
  const [searchSuggestions, setSearchSuggestion] = React.useState([]);
  const [shopName,setShopName]=useState(window.location.hostname.split(".")[0]);
  let query = "";
  if (window.location.hostname && window.location.pathname === "/") {
    query = window.location.hostname.split(".")[0]; // Set query variable for second URL
  } else if (search) {
    query = new URLSearchParams(search).get("q"); // Set query variable for first URL
  }

  const fetchProducts = async (category, page, isNew) => {
    setLoading(true);
    const response = await fetchAllProductsByShopName(query, page, category);
    if (response?.status === 200) {
      let data = response.data.products;
      isNew ? setProducts([...data]) : setProducts([...products, ...data]);
      setTotalPages(response.data.totalPages);
      setTotalCount(response.data.totalCount);
    }
    setLoading(false);
  };

  const handleSearched = () => {
    setIsSearched(!isSearched);
  };

  const removeSearch = () => {
    handleSearched();
    setSearchQuery("");
    fetchProducts(value, 1, true);
  };

  const handleSearch = async () => {
    handleSearched();
    const resp = await searchProducts(query, searchQuery, value);
    if (resp.status === 200) {
      let data = resp.data.products;

      setProducts(data);
      //   setTotalPages(response.data.totalPages);
    }
  };
    // for search suggestion
    const handleSearchSuggestion=async()=>{
      const resp=await searchProductSuggestion(searchQuery,shopName);
      if(resp?.status===200)
      {
          let data=resp.data;
          setSearchSuggestion(data)
      } 
  }
  const sortProductsByPrice = (sortDirection) => {
    if (sortDirection === "highToLow") {
      // Sort products by price from high to low
      setProducts(
        JSON.parse(JSON.stringify(products.sort((a, b) => b.price - a.price)))
      );
    } else if (sortDirection === "lowToHigh") {
      // Sort products by price from low to high
      setProducts(
        JSON.parse(JSON.stringify(products.sort((a, b) => a.price - b.price)))
      );
    } else {
      fetchProducts(value, page, true);
    }
  };

  const loadMore = () => {
    setPage(page + 1);
    fetchProducts(value, page + 1, false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setProducts([]);
    setPage(1);
    fetchProducts(newValue, 1, true);
  };

  const viewStore = async (shop_name) => {
    const resp = await getShopViewForCount(shop_name);
  }

  useEffect(() => {
    if (pathname === "/" && searchQuery === "") {
      fetchProducts(value, page, true);
      viewStore(window.location.hostname.split(".")[0])
    }
  }, [pathname]);

  return (
    <SearchContext.Provider
      value={{
        handleSearch,
        searchQuery,
        setSearchQuery,
        sortProductsByPrice,
        removeSearch,
        isSearched,
        value,
        page,
        totalPages,
        handleTabChange,
        products,
        query,
        loadMore,
        loading,
        totalCount,
        setPage,
        fetchProducts,
        searchSuggestions,
        handleSearchSuggestion,
        setSearchSuggestion
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
