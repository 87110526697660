import { useState } from "react";
import Toast from "./Toast";

let letsToast;

const ToastContainer = () => {
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const showToast = (message, severity) => {
    setToast({ open: true, message, severity });
  };

  const closeToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast((prev) => ({ ...prev, open: false }));
  };

  letsToast = showToast;

  return (
    <Toast
      open={toast.open}
      message={toast.message}
      severity={toast.severity}
      onClose={closeToast}
    />
  );
};

export const toast = {
  success: (message) => letsToast(message, "success"),
  error: (message) => letsToast(message, "error"),
  warn: (message) => letsToast(message, "warning"),
};

export default ToastContainer;
