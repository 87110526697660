import { Tooltip, IconButton, Badge } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";

const PostsSorters = () => {
  return (
    <Tooltip title="Sort">
      <IconButton>
        <Badge color="primary" variant="dot">
          <SwapVertIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default PostsSorters;
