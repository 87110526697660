import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import FacebookAuthSvg from "../../assets/Svg/FacebookAuthSvg";
import GoogleAuthSvg from "../../assets/Svg/GoogleAuthSvg";
import { useNavigate } from "react-router-dom";
import { toast } from "../Toast";
import {
  loginBusinessUser,
  registerBusinessUser,
  resendOTPAPI,
  verifyOTPAPI,
} from "../../api/AuthApis";
import { AuthContext } from "../../context/AuthContext";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .max(75, "Input must not exceed 75 characters.")
      .required("First Name is required"),
    email: yup
      .string()
      // .max(75, "Input must not exceed 75 characters.")
      // .email("Email must be valid")
      .test("contact", "Invalid Email or Phone Number", function (value) {
        const emailRegex = /^\S+@\S+\.\S+$/;
        const phoneRegex = /^\+\d{12}$/;
        return emailRegex.test(value) || phoneRegex.test(value);
      })
      .required("Email is required"),
    password: yup
      .string()
      .max(75, "Input must not exceed 75 characters.")
      .required("Password is required")
      .min(6),
    agreeTerms: yup.bool().oneOf([true]),
  })
  .required();

const Register = () => {
  const navigate = useNavigate();
  const { saveToken, saveUserData } = useContext(AuthContext);
  const [regError, setErr] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [expiryTime, setExpiryTime] = useState(300);
  const [otpError, setOtpError] = useState(false);
  const refs = useRef([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [state, setState] = useState({
    loading: false,
    isVisible: false,
  });

  const handleRegister = async (credentials) => {
    setState({ ...state, loading: true });
    // const resp = await registerBusinessUser({
    //   name: credentials.name,
    //   email: credentials.email,
    //   password: credentials.password,
    //   provider: "custom",
    // });
    const requestData = {
      name: credentials.name,
      password: credentials.password,
      provider: "custom",
    };

    if (credentials.email.includes("@")) {
      requestData.email = credentials.email;
      sessionStorage.setItem("email", credentials.email);
    } else {
      requestData.phone_number = credentials.email;
      sessionStorage.setItem("phone_number", credentials.email);
    }

    const resp = await registerBusinessUser(requestData);

    if (resp?.status === 201) {
      // toast.success(" Success: Account created. ");
      sessionStorage.setItem("accountNotVerified", "true");
      setOtpScreen(true);
      setExpiryTime(300);
      setOtpError(false);
      // saveToken(resp?.data?.token);
      // saveUserData(resp.data);
      navigate("/broadcast/home");
    }
    if (resp?.status === 400) {
      setErr(resp?.data?.message);
    }
    setState({ ...state, loading: false });
  };

  const handleFacebookAuth = async () => {
    try {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            window.FB.api(
              "/me",
              { fields: "name,email" },
              async function (response) {
                const { id, name } = response;

                const email = id.toString() + "@facebook.com";

                const resp = await registerBusinessUser({
                  name,
                  email: email,
                  password: id,
                  provider: "social",
                });

                if (resp?.status === 201) {
                    toast.success("Congratulations! Your account has been successfully created.");
                  saveToken(resp?.data?.token);
                  saveUserData(resp.data);

                  navigate("/streaming");
                }
              }
            );
          }
        },
        { scope: "email" }
      );
    } catch (error) {
      return error;
    }
  };

  const onSuccess = async (tokenResponse) => {
    const { access_token } = tokenResponse;

    // Make API call to fetch user's profile information
    const profileResponse = await fetch(
      "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    const profileData = await profileResponse.json();
    const { email, id, name, given_name, family_name } = profileData;

    const resp = await registerBusinessUser({
      name: given_name + family_name,
      email: email,
      password: id,
      provider: "social",
    });

    if (resp?.status === 201) {
      toast.success("Congratulations! Your account has been successfully created.");
      saveToken(resp?.data?.token);
      saveUserData(resp.data);

      navigate("/broadcast/home");
    } else {
      const resp = await loginBusinessUser({
        email: email,
        password: id,
        provider: "social",
      });

      if (resp?.status === 200) {
        // toast.success(" Success: Login successful. ");
        saveToken(resp?.data?.token);
        saveUserData(resp.data);
        // window.location.href = "/streaming";
        navigate("/broadcast/home")
      }
    }
  };

  const handleGoogleAuth = useGoogleLogin({
    onSuccess: onSuccess,
  });

  if (refs.current.length !== 6) {
    refs.current = Array.from({ length: 6 }, () => React.createRef());
  }

  const handleOTPChange = (index, value) => {
    // Ensure only numbers are entered
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Check if all OTP fields are filled
      if (newOtp.every((digit) => digit !== "")) {
        verifyOTP(newOtp.join(""));
      }

      // Focus on the next input field if not the last one
      if (index < refs.current.length - 1 && value !== "") {
        refs.current[index + 1].current.focus();
      }
    }
  };

  const verifyOTP = async (otp) => {
    // Your verification logic here
    let data = {
      email: sessionStorage.getItem("email"),
      phone_number: sessionStorage.getItem("phone_number"),
      otp: otp,
    };
    // Call your verification function here
    const response = await verifyOTPAPI(data);

    if (response?.status === 200) {
      saveToken(response?.data?.token);
      saveUserData(response.data);
      navigate("/broadcast/home");
      // window.location.href = "/streaming";
      sessionStorage.removeItem("accountNotVerified");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("phone_number");
    }

    if (response?.response?.status === 400) {
      setOtpError(true);
    }
  };

  const resendHandler = async () => {
    setOtpError(false);
    setExpiryTime(300);
    setOtp(["", "", "", "", "", ""]);

    let data = {
      email: sessionStorage.getItem("email"),
      phone_number: sessionStorage.getItem("phone_number"),
    };

    const resp = await resendOTPAPI(data);

    if (resp?.status === 200) {
      toast.success("OTP has been resent successfully.");
    }

    if (resp?.response?.status === 400) {
      toast.error(resp?.response?.data?.message);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setExpiryTime((prevTime) => {
        if (prevTime > 0 && !otpError && otpScreen) {
          return prevTime - 1;
        } else {
          setOtpError(true);
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [otpError, otpScreen]);

  const minutes = Math.floor(expiryTime / 60);
  const seconds = expiryTime % 60;

  useEffect(() => {
    // check in session storage if account is not verified
    if (sessionStorage.getItem("accountNotVerified") === "true") {
      setOtpScreen(true);
    }
  }, []);

  // useEffect(() => {
  //   if (Object.keys(errors).length) {
  //     toast.error("Error: Fill all fields before sign up.");
  //   }
  // }, [errors]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 3,
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "40px",
            lineHeight: "0.6",
            mb: "10px",
          }}
        >
          JOIN
          <br />
          <span style={{ fontWeight: "600", fontSize: "25px" }}>
            UCHLO TODAY
          </span>
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>
          Join the live commerce revolution and sign up for a Uchlo account
          today!
        </Typography>
        {/* {
          regError!=""?(
            <Typography sx={{ fontSize: "12px",fontWeight:"500", color: "red" }}>
            {regError}
          </Typography>
          ):(
          null
          )
        } */}
      </Box>

      {otpScreen ? (
        <Box>
          <Typography sx={{ fontSize: "12px", color: "#8E8E8E", my: 2 }}>
            Enter Your OTP
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 2,
              width: "100%",
              alignItems: "center",
            }}
          >
            {Array.from({ length: 6 }, (_, index) => (
              <TextField
                key={index}
                inputRef={refs.current[index]}
                value={otp[index]}
                onChange={(e) => handleOTPChange(index, e.target.value)}
                sx={
                  otpError && {
                    borderRadius: "10px",
                    border: "1px solid red",
                    pointerEvents: "none",
                    // disable focus
                    "&:focus": {
                      border: "1px solid red",
                    },
                  }
                }
                inputProps={{
                  maxLength: 1,
                  type: "text",
                  pattern: "[0-9]*", // Only allow numbers
                }}
              />
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography sx={{ fontSize: "12px", color: "#8E8E8E", my: 2 }}>
              {`OTP Expires in ${minutes}:${
                seconds < 10 ? `0${seconds}` : seconds
              }`}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ borderRadius: "20px" }}
              onClick={resendHandler}
            >
              Resend OTP
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "center",
            }}
          >
            <TextField
              size="small"
              label="Name"
              sx={{ width: "100%" }}
              {...register("name")}
              error={errors.name}
              helperText={errors?.name?.message}
            />
            {/* 
            <TextField
              size="small"
              label="Last Name"
              {...register("lastName")}
              error={errors.lastName}
              helperText={errors?.lastName?.message}
            /> */}
          </Box>

          <TextField
            size="small"
            label="Email or Whatsapp No."
            {...register("email")}
            error={errors.email}
            helperText={
              regError ? (
                <Typography
                  style={{
                    color: "#FF0000",
                    fontWeight: "400",
                    fontSize: "12px",
                    margin: "4px",
                    marginBottom: "0",
                  }}
                >
                  {regError}
                </Typography>
              ) : (
                errors?.email?.message
              )
            }
          />
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TextField
                size="small"
                label="Password"
                sx={{ width: "100%" }}
                {...register("password")}
                error={errors.password}
                helperText={errors?.password?.message}
                type={state.isVisible ? "text" : "password"}
              />
              {state.isVisible ? (
                <VisibilityIcon
                  sx={{ color: "#B7B7B7", cursor: "pointer" }}
                  onClick={() => setState({ ...state, isVisible: false })}
                />
              ) : (
                <VisibilityOffIcon
                  sx={{ color: "#B7B7B7", cursor: "pointer" }}
                  onClick={() => setState({ ...state, isVisible: true })}
                />
              )}
            </Box>

            <FormControlLabel
              sx={{ mt: "4px" }}
              control={<Checkbox size="small" {...register("agreeTerms")} />}
              label={
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: errors.agreeTerms ? "red" : "#939393",
                  }}
                >
                  By clicking Sign Up you agree to our Terms of Use
                  <span
                    style={{ color: "#0079F2E8" }}
                    onClick={() => navigate("/terms-of-service")}
                  >
                    {" "}
                    Terms & Conditions{" "}
                  </span>
                  and{" "}
                  <span
                    style={{ color: "#0079F2E8" }}
                    onClick={() => navigate("/privacy")}
                  >
                    {" "}
                    Privacy Policy
                  </span>
                </Typography>
              }
            />
            {errors.agreeTerms && errors.agreeTerms ? (
              <Typography
                style={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: "12px",
                  margin: "4px",
                  marginBottom: "0",
                }}
              >
                {regError}
              </Typography>
            ) : (
              ""
            )}
          </Box>

          <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: "20px" }}
            onClick={handleSubmit(handleRegister)}
            disabled={state.loading}
          >
            {state.loading ? (
              <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
            ) : (
              "Sign Up"
            )}
          </Button>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Divider
              sx={{
                width: "40%",
              }}
            />
            <Typography sx={{ color: "#615E5E" }}>Or</Typography>
            <Divider
              sx={{
                width: "40%",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography sx={{ color: "#615E5E", mx: "auto" }}>
              Sign up using
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Button
                sx={{
                  minWidth: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  m: "0",
                  p: "0",
                }}
                onClick={handleFacebookAuth}
              >
                <FacebookAuthSvg />
              </Button> */}
              <Button
                sx={{
                  minWidth: "30px",
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  m: "0",
                  p: "0",
                }}
                onClick={handleGoogleAuth}
              >
                <GoogleAuthSvg />
              </Button>
              {/* <img
                src={require("../../assets/Img/linkedAuth.png")}
                alt="linkedin"
                style={{ width: "40px" }}
              /> */}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", lg: "row" },
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>
              Already have an account?
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "20px",
                py: "3px",
                boxShadow: "none",
                "&:hover": {
                  background: "#FFBD54",
                },
              }}
              onClick={() => navigate("/auth/login")}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Register;
